export enum courierActionsTypes {

  //grid
  GET_COURIERS_LIST_LOADING = "@@couriers/GET_COURIERS_LIST_LOADING",
  GET_COURIERS_LIST_SUCCESS = "@@couriers/GET_COURIERS_LIST_SUCCESS",
  GET_COURIERS_LIST_ERROR = "@@couriers/GET_COURIERS_LIST_ERROR",

  //create courier
  CREATE_COURIER_LOADING = "@@couriers/CREATE_COURIER_LOADING",
  CREATE_COURIER_SUCCESS = "@@couriers/CREATE_COURIER_SUCCESS",
  CREATE_COURIER_ERROR = "@@couriers/CREATE_COURIER_ERROR",

  //update courier
  UPDATE_COURIER_LOADING = "@@couriers/UPDATE_COURIER_LOADING",
  UPDATE_COURIER_SUCCESS = "@@couriers/UPDATE_COURIER_SUCCESS",
  UPDATE_COURIER_ERROR = "@@couriers/UPDATE_COURIER_ERROR",

  //view courier
  VIEW_COURIER_LOADING = "@@couriers/VIEW_COURIER_LOADING",
  VIEW_COURIER_SUCCESS = "@@couriers/VIEW_COURIER_SUCCESS",
  VIEW_COURIER_ERROR = "@@couriers/VIEW_COURIER_ERROR",

  //getbyid courier details
  GETBY_ID_COURIER_LOADING = "@@couriers/GETBY_ID_COURIER_LOADING",
  GETBY_ID_COURIER_SUCCESS = "@@couriers/GETBY_ID_COURIER_SUCCESS",
  GETBY_ID_COURIER_ERROR = "@@couriers/GETBY_ID_COURIER_ERROR",

  //dealer dropdown
  GET_DEALER_DROPDOWN_LOADING = "@@couriers/GET_DEALER_DROPDOWN_LOADING",
  GET_DEALER_DROPDOWN_LOADING_SUCCESS = "@@couriers/GET_DEALER_DROPDOWN_LOADING_SUCCESS",
  GET_DEALER_DROPDOWN_LOADING_ERROR = "@@couriers/GET_DEALER_DROPDOWN_LOADING_ERROR",

  //reset
  RESET_COURIER_STATE = "@@couriers/RESET_COURIER_STATE",
  //clear message
  CLEAR_COURIER_STATE_MESSAGE = "@@couriers/CLEAR_COURIER_STATE_MESSAGE",
  //clear details
  CLEAR_COURIER_DETAILS = "@@couriers/CLEAR_COURIER_DETAILS",

  //get users contact details sender type

  GET_CONTACT_DETAILS_BYUSERID_LOADING = "@@couriers/GET_CONTACT_DETAILS_BYUSERID_LOADING",
  GET_CONTACT_DETAILS_BYUSERID_SUCCESS = "@@couriers/GET_CONTACT_DETAILS_BYUSERID_SUCCESS",
  GET_CONTACT_DETAILS_BYUSERID_ERROR = "@@couriers/GET_CONTACT_DETAILS_BYUSERID_ERROR",

  //reset senderDetails

  RESET_SENDER_DETAILS = "@@couriers/RESET_SENDER_DETAILS",

  //get users contact details receiver type

  GET_CONTACT_DETAILS_BYUSERID_RECEIVER_LOADING = "@@couriers/GET_CONTACT_DETAILS_BYUSERID_RECEIVER_LOADING",
  GET_CONTACT_DETAILS_BYUSERID_RECEIVER_SUCCESS = "@@couriers/GET_CONTACT_DETAILS_BYUSERID_RECEIVER_SUCCESS",
  GET_CONTACT_DETAILS_BYUSERID_RECEIVER_ERROR = "@@couriers/GET_CONTACT_DETAILS_BYUSERID_RECEIVER_ERROR",

  //get mel employee address
  GET_MEl_ADDRESS_LOADING = "@@couriers/GET_MEl_ADDRESS_LOADING",
  GET_MEl_ADDRESS_SUCCESS = "@@couriers/GET_MEl_ADDRESS_SUCCESS",
  GET_MEl_ADDRESS_ERROR = "@@couriers/GET_MEl_ADDRESS_ERROR",

  //reset mel employee address

  RESET_MEL_EMPLOYEE_ADDRESS = "@@couriers/RESET_MEL_EMPLOYEE_ADDRESS",

  //download pdf
  DOWNLOAD_PDF_COURIER_LOADING = "@@couriers/DOWNLOAD_PDF_COURIER_LOADING",
  DOWNLOAD_PDF_COURIER_SUCCESS = "@@couriers/DOWNLOAD_PDF_COURIER_SUCCESS",
  DOWNLOAD_PDF_COURIER_ERROR = "@@couriers/DOWNLOAD_PDF_COURIER_ERROR",


  //courier tracking status
  COURIER_TRACKING_STATUS_LOADING = "@@couriers/COURIER_TRACKING_STATUS_LOADING",
  COURIER_TRACKING_STATUS_SUCCESS = "@@couriers/COURIER_TRACKING_STATUS_SUCCESS",
  COURIER_TRACKING_STATUS_ERROR = "@@couriers/COURIER_TRACKING_STATUS_ERROR",

  //customer reference number
  GET_CUSTOMER_REFERENCE_NUMBER_LOADING = "@@couriers/GET_CUSTOMER_REFERENCE_NUMBER_LOADING",
  GET_CUSTOMER_REFERENCE_NUMBER_SUCCESS = "@@couriers/GET_CUSTOMER_REFERENCE_NUMBER_SUCCESS",
  GET_CUSTOMER_REFERENCE_NUMBER_ERROR = "@@couriers/GET_CUSTOMER_REFERENCE_NUMBER_ERROR",
}