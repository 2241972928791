export enum VoucherTypes {

    // Grid
    GET_VOUCHER_LIST = "@@voucher/GET_VOUCHER_LIST",
    GET_VOUCHER_LIST_SUCCESS = "@@voucher/GET_VOUCHER_LIST_SUCCESS",
    GET_VOUCHER_LIST_ERROR = "@@voucher/GET_VOUCHER_LIST_ERROR",

    GET_VOUCHER_TYPE = "@@voucher/GET_VOUCHER_TYPE",
    GET_VOUCHER_TYPE_SUCCESS = "@@voucher/GET_VOUCHER_TYPE_SUCCESS",
    GET_VOUCHER_TYPE_ERROR = "@@voucher/GET_VOUCHER_TYPE_ERROR",


     // Employee Dropdown
     GET_EMPLOYEE_DROPDOWN_LIST = "@@voucher/GET_EMPLOYEE_DROPDOWN_LIST",
     GET_EMPLOYEE_DROPDOWN_LIST_SUCCESS = "@@voucher/GET_EMPLOYEE_DROPDOWN_LIST_SUCCESS",
     GET_EMPLOYEE_DROPDOWN_LIST_ERROR = "@@voucher/GET_EMPLOYEE_DROPDOWN_LIST_ERROR",
 
     // Franchise Dropdown
     GET_FRANCHISE_DROPDOWN_LIST = "@@voucher/GET_FRANCHISE_DROPDOWN_LIST",
     GET_FRANCHISE_DROPDOWN_LIST_SUCCESS = "@@voucher/GET_FRANCHISE_DROPDOWN_LIST_SUCCESS",
     GET_FRANCHISE_DROPDOWN_LIST_ERROR = "@@voucher/GET_FRANCHISE_DROPDOWN_LIST_ERROR",

     // Dealer Dropdown
     GET_DEALER_DROPDOWN_LIST = "@@voucher/GET_DEALER_DROPDOWN_LIST",
     GET_DEALER_DROPDOWN_LIST_SUCCESS = "@@voucher/GET_DEALER_DROPDOWN_LIST_SUCCESS",
     GET_DEALER_DROPDOWN_LIST_ERROR = "@@voucher/GET_DEALER_DROPDOWN_LIST_ERROR",
 
     // Supplier Dropdown
     GET_SUPPLIER_DROPDOWN_LIST = "@@voucher/GET_SUPPLIER_DROPDOWN_LIST",
     GET_SUPPLIER_DROPDOWN_LIST_SUCCESS = "@@voucher/GET_SUPPLIER_DROPDOWN_LIST_SUCCESS",
     GET_SUPPLIER_DROPDOWN_LIST_ERROR = "@@voucher/GET_SUPPLIER_DROPDOWN_LIST_ERROR",
 
     // customer Dropdown
     GET_CUSTOMER_DROPDOWN_LIST = "@@voucher/GET_CUSTOMER_DROPDOWN_LIST",
     GET_CUSTOMER_DROPDOWN_LIST_SUCCESS = "@@voucher/GET_CUSTOMER_DROPDOWN_LIST_SUCCESS",
     GET_CUSTOMER_DROPDOWN_LIST_ERROR = "@@voucher/GET_CUSTOMER_DROPDOWN_LIST_ERROR",
 
     // create voucher
     CREATE_VOUCHER_LOADING = "@@voucher/CREATE_VOUCHER_LOADING",
     CREATE_VOUCHER_SUCCESS = "@@voucher/CREATE_VOUCHER_SUCCESS",
     CREATE_VOUCHER_ERROR = "@@voucher/CREATE_VOUCHER_ERROR",
 
     //update voucher
     UPDATE_VOUCHER_LOADING = "@@voucher/UPDATE_VOUCHER_LOADING",
     UPDATE_VOUCHER_SUCCESS = "@@voucher/UPDATE_VOUCHER_SUCCESS",
     UPDATE_VOUCHER_ERROR = "@@voucher/UPDATE_VOUCHER_ERROR",
 
    
    GET_VOUCHER_DETAILS = "@@voucher/GET_VOUCHER_DETAILS",
    GET_VOUCHER_DETAILS_SUCCESS = "@@voucher/GET_VOUCHER_DETAILS_SUCCESS",
    GET_VOUCHER_DETAILS_ERROR = "@@voucher/GET_VOUCHER_DETAILS_ERROR",
    
    DELETE_VOUCHER_DETAILS = "@@voucher/DELETE_VOUCHER_DETAILS",
    DELETE_VOUCHER_DETAILS_SUCCESS = "@@voucher/DELETE_VOUCHER_DETAILS_SUCCESS",
    DELETE_VOUCHER_DETAILS_ERROR = "@@voucher/DELETE_VOUCHER_DETAILS_ERROR",
    
    PRINT_VOUCHER_DETAILS_BY_ID_LOADING = "@@voucher/PRINT_VOUCHER_DETAILS_BY_ID_LOADING",
    PRINT_VOUCHER_DETAILS_BY_ID_SUCCESS = "@@voucher/PRINT_VOUCHER_DETAILS_BY_ID_SUCCESS",
    PRINT_VOUCHER_DETAILS_BY_ID_ERROR = "@@voucher/PRINT_VOUCHER_DETAILS_BY_ID_ERROR",
    
    GET_PAYMENT_TYPE = "@@voucher/GET_PAYMENT_TYPE",
    GET_PAYMENT_TYPE_SUCCESS = "@@voucher/GET_PAYMENT_TYPE_SUCCESS",
    GET_PAYMENT_TYPE_ERROR = "@@voucher/GET_PAYMENT_TYPE_ERROR",

    RESET_VOUCHER = "@@voucher/RESET_VOUCHER",
    CLEAR_VOUCHER = "@@voucher/CLEAR_VOUCHER",
}