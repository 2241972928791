
import { call, delay, put, takeEvery, takeLatest } from "redux-saga/effects";
// import { StockTransferPost } from "src/models/pages/stockTransferModel";
import { API } from "src/util/api";
import { approveStockTransferError, approveStockTransferSuccess, createStockTransferError, createStockTransferSuccess, deleteStockTransferListError, deleteStockTransferListSuccess, dispatchStockTransferError, dispatchStockTransferSuccess, emailStockTransferError, emailStockTransferSuccess, employeeListforStockTransferError, employeeListforStockTransferSuccess, franchiseListforStockTransferError, franchiseListforStockTransferSuccess, getStockTransferCourierListDropdownError, getStockTransferCourierListDropdownSuccess, getStockTransferDeliverablePersonDropdownError, getStockTransferDeliverablePersonDropdownSuccess, getStockTransferDetailError, getStockTransferDetailSuccess, getStockTransferListError, getStockTransferListSuccess, partSkuValidateError, partSkuValidateSuccess, partsAutoCompleteForStockTransferListError, partsAutoCompleteForStockTransferListSuccess, postDeliverableCourierStockTransferError, postDeliverableCourierStockTransferSuccess, printStockTransferError, printStockTransferSuccess, receiveStockTransferError, receiveStockTransferSuccess, rejectStockTransferError, rejectStockTransferSuccess, updateStockTransferError, updateStockTransferSuccess } from "./action";
import { StockTransferActionTypes } from "./actionTypes";

function* createStockTransfer({ payload }: any) {
  try {
    const response:Promise<any> = yield call(API.createStockTransfer, payload);
    yield put(createStockTransferSuccess(response));

  } catch (error) {
    yield put(createStockTransferError(error));
  }
}

function* updateStockTransfer({
  payload: { stockTransfer, id },
}: any): Generator<any, void, any> {
  try {
    const { data } = yield call(API.updateStockTransfer, stockTransfer, id);
    yield put(updateStockTransferSuccess(data));
  } catch (error) {
    yield put(updateStockTransferError(error));
  }
}



function* fetchStockTransfer({ payload }: any): Generator<any, void, any> {
  try {
    let { data } = yield call(API.getStockTransferDetails, payload);
    yield put(getStockTransferDetailSuccess(data));
  } catch (error) {
    yield put(getStockTransferDetailError(error));
  }
}

function* getStockTransferListAsync(action: any) {
  try {
    const response: Promise<any> = yield call(API.getStockTransferListAPI, {
      ...action.lazyState,
    });
    yield put(getStockTransferListSuccess(response));
  } catch (error) {
    yield put(getStockTransferListError(error));
  }
}

function* deleteStockTransferAsync(action: any) {
  try {
    const response: Promise<any> = yield call(API.deleteStockTransferListAPI, {
      id: action.payload.id, undo: action.payload.undo
    });
    yield put(deleteStockTransferListSuccess(response));
  } catch (error) {
    yield put(deleteStockTransferListError(error));
  }
}



//franchise list
function* getStockTransferFranchiseListAsync() {
  try {
    const response: Promise<any> = yield call(API.getIntentFranchiseFromMasterManagement)
    yield put(franchiseListforStockTransferSuccess(response));
  } catch (error) {
    yield put(franchiseListforStockTransferError(error));
  }

}
//employee list
function* getStockTransferEmployeeListAsync() {
  try {
    const response: Promise<any> = yield call(API.getEmployeeDrpFromIdentity)
    yield put(employeeListforStockTransferSuccess(response));
  } catch (error) {
    yield put(employeeListforStockTransferError(error));
  }

}


function* getPartsAutocompleteListAsync(action: any) {
  try {
    const response: Promise<any> = yield call(
      API.getPartsForIntentAutoCompleteAPI, action.params
    )
    yield put(partsAutoCompleteForStockTransferListSuccess(response));
  } catch (error) {
    yield put(partsAutoCompleteForStockTransferListError(error));
  }
}

function* approveStockTransfer(action: any) {
  console.log("ACTION PARTS ---> ", action)
  try {
    const response: Promise<any> = yield call(
      API.approveIntent, action.params.id, action.params.approvedNote
    )
    yield put(approveStockTransferSuccess(response));
  } catch (error) {
    yield put(approveStockTransferError(error));
  }
}

function* rejectStockTransfer(action: any) {
  try {
    const response: Promise<any> = yield call(
      API.rejectIntent, action.params.id, action.params.rejectedNote
    )
    yield put(rejectStockTransferSuccess(response));
  } catch (error) {
    yield put(rejectStockTransferError(error));
  }
}

function* dispatchStockTransfer(action: any) {
  try {
    const response: Promise<any> = yield call(
      API.dispatchStockTransfer, action.params
    )
    yield put(dispatchStockTransferSuccess(response));
  } catch (error) {
    yield put(dispatchStockTransferError(error));
  }
}

function* receiveStockTransfer(action: any) {
  try {
    const response: Promise<any> = yield call(
      API.receivedStockTransfer, action.params
    )
    yield put(receiveStockTransferSuccess(response));
  } catch (error) {
    yield put(receiveStockTransferError(error));
  }
}

//=========== courier list ===========//
function* getStockTransferCourierAsync() {
  try {
    const response: Promise<any> = yield call(API.getIntentCourierListApi);
    yield put(getStockTransferCourierListDropdownSuccess(response))
  } catch (error) {
    yield put(getStockTransferCourierListDropdownError(error))
  }
}


// ========== getStockTransferDeliverablePersonAsync ========== //

function* getStockTransferDeliverablePersonAsync() {
  try {
    const response: Promise<any> = yield call(API.getIntentDeliverablePersonApi);
    yield put(getStockTransferDeliverablePersonDropdownSuccess(response))
  } catch (error) {
    yield put(getStockTransferDeliverablePersonDropdownError(error))
  }
}


//  ============ pending dispatch change to courier received ========== /

function* postCourierStockTransferAsync(action: any) {
  try {
    const response: Promise<any> = yield call(API.postCourierStockTransferApi, action.payload, action.id);
    yield put(postDeliverableCourierStockTransferSuccess(response))
  } catch (error) {
    yield put(postDeliverableCourierStockTransferError(error))
  }
}

function* partSkuValidateAsync(action: any) {
  try {
    const response: Promise<any> = yield call(API.partSkuValidateForStockTransferApi, action.payload);
    yield put(partSkuValidateSuccess(response))
  } catch (error) {
    yield put(partSkuValidateError(error))
  }
}

function* printStockTransferAsync(action: any) {
  try {
    const response: Promise<any> = yield call(API.printStockTransferApi, action.payload);
    yield put(printStockTransferSuccess(response))
  } catch (error) {
    yield put(printStockTransferError(error))
  }
}

function* emailStockTransferAsync(action: any) {
  try {
    const response: Promise<any> = yield call(API.emailStockTransferApi, action.payload);
    yield put(emailStockTransferSuccess(response))
  } catch (error) {
    yield put(emailStockTransferError(error))
  }
}


function* StockTransferSaga() {
  yield takeEvery(StockTransferActionTypes.GET_STOCK_TRANSFER_BY_ID_LOADING, fetchStockTransfer);
  yield takeLatest(StockTransferActionTypes.CREATE_STOCK_TRANSFER_LOADING, createStockTransfer);
  yield takeEvery(StockTransferActionTypes.UPDATE_STOCK_TRANSFER_LOADING, updateStockTransfer);
  yield takeLatest(StockTransferActionTypes.GET_STOCK_TRANSFER_LIST_LOADING, getStockTransferListAsync);
  yield takeLatest(StockTransferActionTypes.DELETE_STOCK_TRANSFER_LIST_LOADING, deleteStockTransferAsync);
  yield takeLatest(StockTransferActionTypes.GET_FRANCHISE_LIST_FOR_STOCK_TRANSFER_LOADING, getStockTransferFranchiseListAsync);
  yield takeLatest(StockTransferActionTypes.GET_EMPLOYEE_LIST_FOR_STOCK_TRANSFER_LOADING, getStockTransferEmployeeListAsync);
  yield takeLatest(StockTransferActionTypes.GET_PART_LIST_FOR_STOCK_TRANSFER_LOADING, getPartsAutocompleteListAsync);
  yield takeLatest(StockTransferActionTypes.APPROVE_STOCK_TRANSFER_LOADING, approveStockTransfer);
  yield takeLatest(StockTransferActionTypes.REJECT_STOCK_TRANSFER_LOADING, rejectStockTransfer);
  yield takeLatest(StockTransferActionTypes.DISPATCH_STOCK_TRANSFER_LOADING, dispatchStockTransfer);
  yield takeLatest(StockTransferActionTypes.RECEIVE_STOCK_TRANSFER_LOADING, receiveStockTransfer);
  yield takeLatest(StockTransferActionTypes.GET_STOCK_TRANSFER_COURIER_LIST_LOADING, getStockTransferCourierAsync);
  yield takeLatest(StockTransferActionTypes.GET_STOCK_TRANSFER_DELIVERABLE_USER_LIST_LOADING, getStockTransferDeliverablePersonAsync);
  yield takeLatest(StockTransferActionTypes.POST_COURIER_STOCK_TRANSFER_LOADING, postCourierStockTransferAsync)
  yield takeLatest(StockTransferActionTypes.PARTS_VALIDATE, partSkuValidateAsync)
  yield takeLatest(StockTransferActionTypes.PRINT_STOCK_TRANSFER, printStockTransferAsync)
  yield takeLatest(StockTransferActionTypes.EMAIL_STOCK_TRANSFER, emailStockTransferAsync)

}

export default StockTransferSaga;
