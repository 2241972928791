
import { VoucherStateModel } from "src/models/pages/voucherModel";
import { VoucherTypes } from "./actionTypes";




const initialState: VoucherStateModel = {
  error: "",
  loading: false,
  message: "",
  paginatorCount: 0,
  voucherList: {
    values: [],
    totalRecords: 0
  },
  voucherDetails:null,
  voucherTypes:[],
  employeeList:[],
  franchiseList:[],
  dealerList:[],
  supplierList:[],
  customerList:[],
  paymentTypeList:[]
};


const Voucher = (state = initialState, action: any) => {
  switch (action.type) {

    case VoucherTypes.GET_VOUCHER_LIST:
    case VoucherTypes.GET_CUSTOMER_DROPDOWN_LIST:
    case VoucherTypes.GET_DEALER_DROPDOWN_LIST:
    case VoucherTypes.GET_EMPLOYEE_DROPDOWN_LIST:
    case VoucherTypes.GET_FRANCHISE_DROPDOWN_LIST:
    case VoucherTypes.GET_SUPPLIER_DROPDOWN_LIST:
    case VoucherTypes.CREATE_VOUCHER_LOADING:
    case VoucherTypes.GET_VOUCHER_DETAILS:
    case VoucherTypes.UPDATE_VOUCHER_LOADING:
    case VoucherTypes.GET_PAYMENT_TYPE:
    case VoucherTypes.DELETE_VOUCHER_DETAILS:
    case VoucherTypes.PRINT_VOUCHER_DETAILS_BY_ID_LOADING:
      return {
        ...state,
        error: "",
        message: "",
        loading: true,
        paginatorCount: 0
      };

    case VoucherTypes.GET_VOUCHER_LIST_SUCCESS:
      return {
        ...state,
        loading: false,
        voucherList: {
          values: action.payload.data.items,
          totalRecords: action.payload.data.totalCount,
        },
        paginatorCount: action.payload.data.items?.length
      };

    case VoucherTypes.GET_VOUCHER_TYPE_SUCCESS:
      return {
        ...state,
        loading: false,
        voucherTypes:action.payload.data
      };

    case VoucherTypes.GET_EMPLOYEE_DROPDOWN_LIST_SUCCESS:
        let employeeData = action.payload.data?.map((val:any)=>({
            label:val.name,
            value:val.id
        }))
      return {
        ...state,
        loading: false,
        employeeList:employeeData
      };

    case VoucherTypes.GET_CUSTOMER_DROPDOWN_LIST_SUCCESS:
        let customerOptions = action.payload.data?.map((val:any)=>({
            label:val.name,
            value:val.id
        }))
      return {
        ...state,
        loading: false,
        customerList:customerOptions
      };

    case VoucherTypes.GET_FRANCHISE_DROPDOWN_LIST_SUCCESS:
        let franchiseOptions = action.payload.data?.map((val:any)=>({
            label:val.name,
            value:val.id
        }))
      return {
        ...state,
        loading: false,
        franchiseList:franchiseOptions
      };

    case VoucherTypes.GET_DEALER_DROPDOWN_LIST_SUCCESS:
        let dealerOptions = action.payload.data?.map((val:any)=>({
            label:val.name,
            value:val.id
        }))
      return {
        ...state,
        loading: false,
        dealerList:dealerOptions
      };

    case VoucherTypes.GET_SUPPLIER_DROPDOWN_LIST_SUCCESS:
        let supplierOptions = action.payload.data?.map((val:any)=>({
            label:val.name,
            value:val.id
        }))
      return {
        ...state,
        loading: false,
        supplierList:supplierOptions
      };

    case VoucherTypes.GET_PAYMENT_TYPE_SUCCESS:
      return {
        ...state,
        loading: false,
        paymentTypeList:action.payload.data
      };

    case VoucherTypes.CREATE_VOUCHER_SUCCESS:

      return {
        ...state,
        loading: false,
        message:"Voucher Created Successfully!"
      };

    case VoucherTypes.UPDATE_VOUCHER_SUCCESS:

      return {
        ...state,
        loading: false,
        message:"Voucher Updated Successfully!"
      };
      
      case VoucherTypes.DELETE_VOUCHER_DETAILS_SUCCESS:
        let msg = 'Voucher deleted Successfully!'
        if(action.payload.undo){
          msg = "Voucher Restored Successfully"
        } 
        debugger
        return {
          ...state,
          loading: false,
          message:msg
        };
        
      case VoucherTypes.PRINT_VOUCHER_DETAILS_BY_ID_SUCCESS:
        return {
          ...state,
          loading: false,
        };

    case VoucherTypes.GET_VOUCHER_DETAILS_SUCCESS:
      return {
        ...state,
        loading: false,
        voucherDetails:action.payload
      };

   

    case VoucherTypes.GET_VOUCHER_LIST_ERROR:
    case VoucherTypes.GET_EMPLOYEE_DROPDOWN_LIST_ERROR:
    case VoucherTypes.GET_FRANCHISE_DROPDOWN_LIST_ERROR:
    case VoucherTypes.GET_DEALER_DROPDOWN_LIST_ERROR:
    case VoucherTypes.GET_SUPPLIER_DROPDOWN_LIST_ERROR:
    case VoucherTypes.GET_CUSTOMER_DROPDOWN_LIST:
    case VoucherTypes.CREATE_VOUCHER_ERROR:
    case VoucherTypes.GET_VOUCHER_DETAILS_ERROR:
    case VoucherTypes.UPDATE_VOUCHER_ERROR:
    case VoucherTypes.GET_PAYMENT_TYPE_ERROR:    
    case VoucherTypes.DELETE_VOUCHER_DETAILS_ERROR:
    case VoucherTypes.PRINT_VOUCHER_DETAILS_BY_ID_ERROR:
      return {
        ...state,
        loading: false,
        message: "",
        error: action.payload
      };

    case VoucherTypes.RESET_VOUCHER:
      return {
        ...state,
        error: "",
        loading: false,
        message: "",
      }

    case VoucherTypes.CLEAR_VOUCHER:
      return {
        ...state,
        ...initialState
      }

    default:
      return { ...state };
  }
};

export default Voucher;
