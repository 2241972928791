import { BulkEmailInitialState } from "src/models/pages/BulkEmailModel";
import { messages } from "src/common/data";
import { WhatsappTypes } from "./actionTypes";
import { WhatsAppInitialState } from "src/models/pages/massWhatsAppModel";




const initialState: WhatsAppInitialState = {
  error: "",
  loading: false,
  message: "",
  paginatorCount: 0,
  whatsAppList:[],
  totalRecords: 0,
  employeeNum:"",
  dealerNum:"",
  customerNum:"",
  franchiseNum:"",
  supplierNum:"",
  whatsAppDetails:"",
  statusList:[],
  whatsAppDocumentLink:""
};



const WhatsappReducer = (state = initialState, action: any) => {
  switch (action.type) {
    case WhatsappTypes.GET_WHATSAPP_LIST_LOADING:
    case WhatsappTypes.CREATE_WHATSAPP_MESSAGE_LOADING:
    case WhatsappTypes.DELETE_WHATSAPP_MESSAGE_LOADING:
    case WhatsappTypes.GET_BY_ID_WHATSAPP_MESSAGE_LOADING:
      return {
        ...state,
        loading: true,
        paginatorCount: 0,
        message: "",
        error: ""
      };


    case WhatsappTypes.GET_WHATSAPP_LIST_SUCCESS:
      return {
        ...state,
        loading: false,
        whatsAppList:action.payload.data.items,
        totalRecords : action.payload.data.totalCount,
        paginatorCount:action.payload.data.items.length
      };
    case WhatsappTypes.GET_BY_ID_WHATSAPP_MESSAGE_SUCCESS:
      return {
        ...state,
        loading: false,
        whatsAppDetails:action.payload.data,
      };

    case WhatsappTypes.GET_WHATSAPPDOCUMENT_LINK_SUCCESS:
      return {
        ...state,
        loading: false,
        whatsAppDocumentLink:[...state.whatsAppDocumentLink,{id:action.payload.id,link:action.payload.response.data}]
      };

    case WhatsappTypes.GET_EMPLOYEE_NUM_SUCCESS:
      return {
        ...state,
        loading: false,
        employeeNum: action.payload.data?.filter((item:any) => !item.isDeleted).map((item:any) => ({name:item.name,phone:item.phoneNumber}))
      };
    case WhatsappTypes.GET_DEALER_NUM_SUCCESS:
      return {
        ...state,
        loading: false,
        dealerNum:action.payload.data?.filter((item:any) => !item.isDeleted).map((item:any) => ({name:item.name,phone:item.phoneNumber}))
      };

    case WhatsappTypes.GET_CUSTOMER_NUM_SUCCESS:
      return {
        ...state,
        loading: false,
        customerNum:action.payload.data?.filter((item:any) => !item.isDeleted).map((item:any) => ({name:item.name,phone:item.phoneNumber}))
      };

    case WhatsappTypes.GET_FRANCHISE_NUM_SUCCESS:
      return {
        ...state,
        loading: false,
        franchiseNum:action.payload.data?.filter((item:any) => !item.isDeleted).map((item:any) => ({name:item.name,phone:item.phoneNumber}))
      };

    case WhatsappTypes.GET_SUPPLIER_NUM_SUCCESS:
      return {
        ...state,
        loading: false,
        supplierNum:action.payload.data?.filter((item:any) => !item.isDeleted).map((item:any) => ({name:item.name,phone:item.mobileNumber}))
      };
    case WhatsappTypes.CREATE_WHATSAPP_MESSAGE_SUCCESS:
      return {
        ...state,
        loading: false,
        message:"Chat sent Successfully"
      };
    case WhatsappTypes.GET_WHATSAPP_MESSAGE_STATUS_SUCCESS:
      return {
        ...state,
        loading: false,
        statusList:action.payload.data
      };
    case WhatsappTypes.DELETE_WHATSAPP_MESSAGE_SUCCESS:
      return {
        ...state,
        loading: false,
        message:"Chat Sending Cancelled Successfully"
      };
    case WhatsappTypes.CLEAR_WHATSAPP_MESSAGE_MESSAGE:
      return {
        ...state,
        loading: false,
        error:"",
        message:""
      };
    case WhatsappTypes.RESET_WHATSAPP_MESSAGE_MESSAGE:
      return {
        ...state,
        loading: false,
        error:"",
        message:"",
        whatsAppDetails:"",
        whatsAppDocumentLink:""
      };

    case WhatsappTypes.GET_WHATSAPP_LIST_ERROR:
    case WhatsappTypes.CREATE_WHATSAPP_MESSAGE_ERROR:
    case WhatsappTypes.DELETE_WHATSAPP_MESSAGE_ERROR:
    case WhatsappTypes.GET_BY_ID_WHATSAPP_MESSAGE_ERROR:
    case WhatsappTypes.GET_WHATSAPP_MESSAGE_STATUS_ERROR:
    case WhatsappTypes.GET_EMPLOYEE_NUM_ERROR:
    case WhatsappTypes.GET_DEALER_NUM_ERROR:
    case WhatsappTypes.GET_CUSTOMER_NUM_ERROR:
    case WhatsappTypes.GET_FRANCHISE_NUM_ERROR:
    case WhatsappTypes.GET_SUPPLIER_NUM_ERROR:
      return {
        ...state,
        loading: false,
        error: action.payload
      };


    default:
      return { ...state };
  }
};

export default WhatsappReducer;
