import { VoucherTypes } from "./actionTypes";

export const getVoucherList = (lazyState: any) => {
    return {
      type: VoucherTypes.GET_VOUCHER_LIST,
      lazyState,
    };
  };
  
  export const getVoucherListSuccess = (Voucher: any) => {
    return {
      type: VoucherTypes.GET_VOUCHER_LIST_SUCCESS,
      payload: Voucher,
    };
  };
  
  export const getVoucherListError = (error: any) => {
    return {
      type: VoucherTypes.GET_VOUCHER_LIST_ERROR,
      payload: error,
    };
  };

export const getVoucherType = () => {
    return {
      type: VoucherTypes.GET_VOUCHER_TYPE,
    };
  };
  
  export const getVoucherTypeSuccess = (VoucherType: any) => {
    return {
      type: VoucherTypes.GET_VOUCHER_TYPE_SUCCESS,
      payload: VoucherType,
    };
  };
  
  export const getVoucherTypeError = (error: any) => {
    return {
      type: VoucherTypes.GET_VOUCHER_TYPE_ERROR,
      payload: error,
    };
  };

export const getPaymentType = () => {
    return {
      type: VoucherTypes.GET_PAYMENT_TYPE,
    };
  };
  
  export const getPaymentTypeSuccess = (VoucherType: any) => {
    return {
      type: VoucherTypes.GET_PAYMENT_TYPE_SUCCESS,
      payload: VoucherType,
    };
  };
  
  export const getPaymentTypeError = (error: any) => {
    return {
      type: VoucherTypes.GET_PAYMENT_TYPE_ERROR,
      payload: error,
    };
  };

  export const getEmployeeDropdownList = () => {
    return {
      type: VoucherTypes.GET_EMPLOYEE_DROPDOWN_LIST,
    };
  };
  
  export const getEmployeeDropdownListSuccess = (attendance: any) => {
    return {
      type: VoucherTypes.GET_EMPLOYEE_DROPDOWN_LIST_SUCCESS,
      payload: attendance,
    };
  };
  
  export const getEmployeeDropdownListError = (error: any) => {
    return {
      type: VoucherTypes.GET_EMPLOYEE_DROPDOWN_LIST_ERROR,
      payload: error,
    };
  };
  
export const getFranchiseDropdownList = () => {
    return {
      type: VoucherTypes.GET_FRANCHISE_DROPDOWN_LIST,
    };
  };
  
  export const getFranchiseDropdownListSuccess = (attendance: any) => {
    return {
      type: VoucherTypes.GET_FRANCHISE_DROPDOWN_LIST_SUCCESS,
      payload: attendance,
    };
  };
  
  export const getFranchiseDropdownListError = (error: any) => {
    return {
      type: VoucherTypes.GET_FRANCHISE_DROPDOWN_LIST_ERROR,
      payload: error,
    };
  };
  
export const getDealerDropdownList = () => {
    return {
      type: VoucherTypes.GET_DEALER_DROPDOWN_LIST,
    };
  };
  
  export const getDealerDropdownListSuccess = (attendance: any) => {
    return {
      type: VoucherTypes.GET_DEALER_DROPDOWN_LIST_SUCCESS,
      payload: attendance,
    };
  };
  
  export const getDealerDropdownListError = (error: any) => {
    return {
      type: VoucherTypes.GET_DEALER_DROPDOWN_LIST_ERROR,
      payload: error,
    };
  };
  
export const getCustomerDropdownList = () => {
    return {
      type: VoucherTypes.GET_CUSTOMER_DROPDOWN_LIST,
    };
  };
  
  export const getCustomerDropdownListSuccess = (attendance: any) => {
    return {
      type: VoucherTypes.GET_CUSTOMER_DROPDOWN_LIST_SUCCESS,
      payload: attendance,
    };
  };
  
  export const getCustomerDropdownListError = (error: any) => {
    return {
      type: VoucherTypes.GET_CUSTOMER_DROPDOWN_LIST_ERROR,
      payload: error,
    };
  };
  
export const getSupplierDropdownList = () => {
    return {
      type: VoucherTypes.GET_SUPPLIER_DROPDOWN_LIST,
    };
  };
  
  export const getSupplierDropdownListSuccess = (attendance: any) => {
    return {
      type: VoucherTypes.GET_SUPPLIER_DROPDOWN_LIST_SUCCESS,
      payload: attendance,
    };
  };
  
  export const getSupplierDropdownListError = (error: any) => {
    return {
      type: VoucherTypes.GET_SUPPLIER_DROPDOWN_LIST_ERROR,
      payload: error,
    };
  };
  
export const createVoucherLoading = (voucherData:any) => {
    return {
      type: VoucherTypes.CREATE_VOUCHER_LOADING,
      payload:voucherData
    };
  };
  
  export const createVoucherSuccess = (attendance: any) => {
    return {
      type: VoucherTypes.CREATE_VOUCHER_SUCCESS,
      payload: attendance,
    };
  };
  
  export const createVoucherError = (error: any) => {
    return {
      type: VoucherTypes.CREATE_VOUCHER_ERROR,
      payload: error,
    };
  };
  
export const getVoucherDetailsById = (id:any) => {
    return {
      type: VoucherTypes.GET_VOUCHER_DETAILS,
      id:id
    };
  };
  
  export const getVoucherDetailsByIdSuccess = (data: any) => {
    return {
      type: VoucherTypes.GET_VOUCHER_DETAILS_SUCCESS,
      payload: data,
    };
  };
  
  export const getVoucherDetailsByIdError = (error: any) => {
    return {
      type: VoucherTypes.GET_VOUCHER_DETAILS_ERROR,
      payload: error,
    };
  };
  
    
export const updateVoucherLoading = (voucherData:any,id:any) => {
  debugger
  return {
    type: VoucherTypes.UPDATE_VOUCHER_LOADING,
    payload:{voucherData,id}
  };
};

export const updateVoucherSuccess = (data: any) => {
  return {
    type: VoucherTypes.UPDATE_VOUCHER_SUCCESS,
    payload: data,
  };
};

export const updateVoucherError = (error: any) => {
  return {
    type: VoucherTypes.UPDATE_VOUCHER_ERROR,
    payload: error,
  };
};
  
export const deleteVoucherLoading = (payload:any) => {
  return {
    type: VoucherTypes.DELETE_VOUCHER_DETAILS,
    payload:payload
  };
};

export const deleteVoucherSuccess = (data: any) => {
  return {
    type: VoucherTypes.DELETE_VOUCHER_DETAILS_SUCCESS,
    payload: data,
  };
};

export const deleteVoucherError = (error: any) => {
  return {
    type: VoucherTypes.DELETE_VOUCHER_DETAILS_ERROR,
    payload: error,
  };
};
export const printVoucherByIdLoading = (payload:any) => {
  return {
    type: VoucherTypes.PRINT_VOUCHER_DETAILS_BY_ID_LOADING,
    payload:payload
  };
};

export const printVoucherByIdSuccess = (data: any) => {
  return {
    type: VoucherTypes.PRINT_VOUCHER_DETAILS_BY_ID_SUCCESS,
    payload: data,
  };
};

export const printVoucherByIdError = (error: any) => {
  return {
    type: VoucherTypes.PRINT_VOUCHER_DETAILS_BY_ID_ERROR,
    payload: error,
  };
};

  export const clearVoucher = () => {
    debugger
    return {
      type: VoucherTypes.CLEAR_VOUCHER,
    };
  };

  export const resetVoucher = () => {
    debugger
    return {
      type: VoucherTypes.RESET_VOUCHER,
    };
  };