//grid

import { DefectivePartType } from "./actionTypes"

export const getDefectivePartListLoading = (lazyState: any) => {
    return {
      type: DefectivePartType.GET_DEFECTIVE_PART_LIST_LOADING,
      lazyState,
    }
  }
  
  export const geDefectivePartListSuccess = (resp: any) => {
    return {
      type: DefectivePartType.GET_DEFECTIVE_PART_LIST_SUCCESS,
      payload: resp
    }
  }
  
  export const getDefectivePartListError = (error: any) => {
    return {
      type: DefectivePartType.GET_DEFECTIVE_PART_LIST_ERROR,
      payload: error
    }
  }