export enum PartsInboundTypes {

  // Grid
  GET_PARTS_INBOUND_LIST = "@@PARTS_inbound/GET_PARTS_INBOUND_LIST",
  GET_PARTS_INBOUND_LIST_SUCCESS = "@@PARTS_inbound/GET_PARTS_INBOUND_LIST_SUCCESS",
  GET_PARTS_INBOUND_LIST_FAIL = "@@PARTS_inbound/GET_PARTS_INBOUND_LIST_FAIL",


  GET_PARTS_INBOUND_BY_ID = "@@PARTS_inbound/GET_PARTS_INBOUND_BY_ID",
  GET_PARTS_INBOUND_BY_ID_SUCCESS = "@@PARTS_inbound/GET_PARTS_INBOUND_BY_ID_SUCCESS",
  GET_PARTS_INBOUND_BY_ID_ERROR = "@@PARTS_inbound/GET_PARTS_INBOUND_BY_ID_ERROR",

  DELETE_PARTS_INBOUND = "@@PARTS_inbound/DELETE_PARTS_INBOUND",
  DELETE_PARTS_INBOUND_SUCCESS = "@@PARTS_inbound/DELETE_PARTS_INBOUND_SUCCESS",
  DELETE_PARTS_INBOUND_FAIL = "@@PARTS_inbound/DELETE_PARTS_INBOUND_FAIL",

  // //reset
  RESET_PARTS_INBOUND = "@@PARTS_inbound/RESET_PARTS_INBOUND",

  //clearMessage

  CLEAR_PARTS_INBOUND_MESSAGE = "@@PARTS_inbound/CLEAR_PARTS_INBOUND_MESSAGE",

  // //update PARTS_INBOUND
  UPDATE_PARTS_INBOUND = "@@PARTS_inbound/UPDATE_PARTS_INBOUND",
  UPDATE_PARTS_INBOUND_SUCCESS = "@@PARTS_inbound/UPDATE_PARTS_INBOUND_SUCCESS",
  UPDATE_PARTS_INBOUND_ERROR = "@@PARTS_inbound/UPDATE_PARTS_INBOUND_ERROR",

  //create
  CREATE_PARTS_INBOUND = "@@PARTS_inbound/CREATE_PARTS_INBOUND",
  CREATE_PARTS_INBOUND_SUCCESS = "@@PARTS_inbound/CREATE_PARTS_INBOUND_SUCCESS",
  CREATE_PARTS_INBOUND_ERROR = "@@PARTS_inbound/CREATE_PARTS_INBOUND_ERROR",

  //supplier
  GET_SUPPLIER_DROPDOWN = '@@PARTS_inbound/GET_SUPPLIER_DROPDOWN',
  GET_SUPPLIER_DROPDOWN_SUCCESS = '@@PARTS_inbound/GET_SUPPLIER_DROPDOWN_SUCCESS',
  GET_SUPPLIER_DROPDOWN_ERROR = '@@PARTS_inbound/GET_SUPPLIER_DROPDOWN_ERROR',

  //stock location
  GET_STOCK_LOCATION_DROPDOWN = '@@PARTS_inbound/GET_STOCK_LOCATION_DROPDOWN',
  GET_STOCK_LOCATION_DROPDOWN_SUCCESS = '@@PARTS_inbound/GET_STOCK_LOCATION_DROPDOWN_SUCCESS',
  GET_STOCK_LOCATION_DROPDOWN_ERROR = '@@PARTS_inbound/GET_STOCK_LOCATION_DROPDOWN_ERROR',

  //purchase order
  GET_PURCHASE_ORDER_DROPDOWN = '@@PARTS_inbound/GET_PURCHASE_ORDER_DROPDOWN',
  GET_PURCHASE_ORDER_DROPDOWN_SUCCESS = '@@PARTS_inbound/GET_PURCHASE_ORDER_DROPDOWN_SUCCESS',
  GET_PURCHASE_ORDER_DROPDOWN_ERROR = '@@PARTS_inbound/GET_PURCHASE_ORDER_DROPDOWN_ERROR',

  //parts
  GET_PARTS_DROPDOWN = '@@PARTS_inbound/GET_PARTS_DROPDOWN',
  GET_PARTS_DROPDOWN_SUCCESS = '@@PARTS_inbound/GET_PARTS_DROPDOWN_SUCCESS',
  GET_PARTS_DROPDOWN_ERROR = '@@PARTS_inbound/GET_PARTS_DROPDOWN_ERROR',

  //parts sku generated

  PARTS_INBOUND_SKU_GENERATED_LOADING = "@@PARTS_inbound/PARTS_INBOUND_SKU_GENERATED_LOADING",
  PARTS_INBOUND_SKU_GENERATED_SUCCESS = "@@PARTS_inbound/PARTS_INBOUND_SKU_GENERATED_SUCCESS",
  PARTS_INBOUND_SKU_GENERATED_ERROR = "@@PARTS_inbound/PARTS_INBOUND_SKU_GENERATED_ERROR",

  //purpose
  GET_PURPOSE_DROPDOWN = '@@PARTS_inbound/GET_PURPOSE_DROPDOWN',
  GET_PURPOSE_DROPDOWN_SUCCESS = '@@PARTS_inbound/GET_PURPOSE_DROPDOWN_SUCCESS',
  GET_PURPOSE_DROPDOWN_ERROR = '@@PARTS_inbound/GET_PURPOSE_DROPDOWN_ERROR',
}