import { call, put, takeEvery, takeLatest } from "redux-saga/effects";
import { API } from "src/util/api";
import { BulkSmsTypes } from "./actionTypes";
import { createBulkSmsError, createBulkSmsSuccess, deleteBulkSmsError, deleteBulkSmsSuccess, getBulkSmsByIdError, getBulkSmsByIdSuccess, getBulkSmsListError, getBulkSmsListSuccess, getBulkSmsStatusError, getBulkSmsStatusSuccess, getCustomerNumError, getCustomerNumSuccess, getDealerNumError, getDealerNumSuccess, getEmployeeNumError, getEmployeeNumSuccess, getFranchiseNumError, getFranchiseNumSuccess, getManufacturerNumError, getManufacturerNumSuccess, getNumberbyPrefixError, getNumberbyPrefixSuccess, getSupplierNumError, getSupplierNumSuccess } from "./action";

function* getBulkSmsListAsync(action : any) {

    try {
      const response : Promise<any> = yield call(
          API.getBulkSmsListAPI,{...action.lazyState}
      )
      yield put(getBulkSmsListSuccess(response));
    } catch (error) {
      yield put(getBulkSmsListError(error));
    }
  }

  
function* getEmployeeDropdownListAsync() {
    
  try {
      const response: Promise<any> = yield call( API.getEmployeeDrpFromIdentity )        
      yield put(getEmployeeNumSuccess(response));
  } catch (error) {
      yield put(getEmployeeNumError(error));
  }
}


function* getDealerListAsync() {
  try {
    const response: Promise<any> = yield call(API.getDealerDropdownApi);
    yield put(getDealerNumSuccess(response));
  } catch (error) {
    yield put(getDealerNumError(error));
  }
}

function* getcustomerDropdownListAsync() {
  try {
    // const response: Promise<any> = yield call(API.getCustomerDropForJobAPI);
    const response: Promise<any> = yield call(API.getCustomerDrpFromIdentity);
    yield put(getCustomerNumSuccess(response));
  } catch (error) {
    yield put(getCustomerNumError(error));
  }
}

function* getFranchiseDropdownAsync() {
  try {
    const response : Promise<any> = yield call(API.getFranchiseDrpFromIdentity);    
    yield put(getFranchiseNumSuccess(response));

  } catch (error) {
    yield put(getFranchiseNumError(error));
  }
}

function* getSupplierDropdownAsync() {
  try {
    const response : Promise<any> = yield call(API.getSupplierDropdown);    
    yield put(getSupplierNumSuccess(response));

  } catch (error) {
    yield put(getSupplierNumError(error));
  }
}

function* getManufacturerDropdownAsync() {
  try {
    const response : Promise<any> = yield call(API.getManufacturerDropdown);    
    yield put(getManufacturerNumSuccess(response));

  } catch (error) {
    yield put(getManufacturerNumError(error));
  }
}

function* craeteBulkSmsAsync(action:any) {
  try {
    const response : Promise<any> = yield call(API.createBulkSmsApi,action.payload);    
    yield put(createBulkSmsSuccess(response));

  } catch (error) {
    yield put(createBulkSmsError(error));
  }
}

function* deleteBulkSmsAsync(action:any) {
  try {
    const response : Promise<any> = yield call(API.deleteBulkSmsApi,action.id);    
    yield put(deleteBulkSmsSuccess(response));

  } catch (error) {
    yield put(deleteBulkSmsError(error));
  }
}

function* getBulkSmsByIdAsync(action:any) {
  try {
    const response : Promise<any> = yield call(API.getBulkSmsByIdApi,action.id);    
    yield put(getBulkSmsByIdSuccess(response));

  } catch (error) {
    yield put(getBulkSmsByIdError(error));
  }
}

function* getBulkSmsStatusAsync() {
  try {
    const response : Promise<any> = yield call(API.getBulkSmsStatusApi);    
    yield put(getBulkSmsStatusSuccess(response));

  } catch (error) {
    yield put(getBulkSmsStatusError(error));
  }
}

function* getNumberByPrefix(action:any) {
  try {
    if(action.payload.userType=="Customer"){

      const response : Promise<any> = yield call(API.getEmailByCustomer,action.payload.jobPrefix);    
      yield put(getNumberbyPrefixSuccess(response));
    }
    if(action.payload.userType=="Dealer"){

      const response : Promise<any> = yield call(API.getEmailByDealer,action.payload.jobPrefix);    
      yield put(getNumberbyPrefixSuccess(response));
    }
    if(action.payload.userType=="Manufacturer"){

      const response : Promise<any> = yield call(API.getEmailByManufacturer,action.payload.jobPrefix);    
      yield put(getNumberbyPrefixSuccess(response));
    }
  } catch (error) {
    yield put(getNumberbyPrefixError(error));
  }
}

  function* BulkSmsSaga() {
    yield takeLatest(BulkSmsTypes.GET_BULK_SMS_LIST_LOADING, getBulkSmsListAsync);
    yield takeLatest(BulkSmsTypes.GET_EMPLOYEE_NUM_LOADING, getEmployeeDropdownListAsync);
    yield takeLatest(BulkSmsTypes.GET_DEALER_NUM_LOADING, getDealerListAsync);
    yield takeLatest(BulkSmsTypes.GET_CUSTOMER_NUM_LOADING, getcustomerDropdownListAsync);
    yield takeLatest(BulkSmsTypes.GET_FRANCHISE_NUM_LOADING, getFranchiseDropdownAsync);
    yield takeLatest(BulkSmsTypes.GET_SUPPLIER_NUM_LOADING, getSupplierDropdownAsync);
    yield takeLatest(BulkSmsTypes.GET_MANUFACTURER_NUM_LOADING, getManufacturerDropdownAsync);
    yield takeLatest(BulkSmsTypes.CREATE_BULK_SMS_LOADING, craeteBulkSmsAsync);
    yield takeEvery(BulkSmsTypes.DELETE_BULK_SMS_LOADING, deleteBulkSmsAsync);
    yield takeLatest(BulkSmsTypes.GET_BY_ID_BULK_SMS_LOADING, getBulkSmsByIdAsync);
    yield takeLatest(BulkSmsTypes.GET_BULK_SMS_STATUS_LOADING, getBulkSmsStatusAsync);
    yield takeLatest(BulkSmsTypes.GET_GET_NUMBER_BY_PREFIX_LOADING, getNumberByPrefix);
  }
  
  export default BulkSmsSaga;