import { FieldArray, Form, Formik } from "formik";
import React, { useEffect, useMemo, useState } from "react";
import { useNavigate, useParams } from "react-router";
import { Card, CardBody, Col, Container, Row } from "reactstrap";
import Button from 'src/components/UI/Button';
import Breadcrumb from "src/components/Common/Breadcrumb";
import InputCom from "src/components/Common/Input";
import CheckboxInput from "src/components/UI/CheckboxInput";
import { useDispatch, useSelector } from "react-redux";
import { RootReducerState } from "src/store/reducers";
import { useToast } from "src/components/Common/ReactToaster";
import * as Yup from "yup";
import { ROUTER } from "src/constants/routes";
import { formatDateTOYYYYMMDD } from "src/helpers/common_helpers";
import ValidationPopup from "src/components/Common/ValidationMessage/ValidationPopup";
import TooltipMEL from "src/components/UI/Tooltip";
import { resetPurchaseOrder } from "src/store/purchaseOrders/action";
import DropdownInput from "src/components/UI/DropdownInput";
import { clearVoucher, createVoucherLoading, getCustomerDropdownList, getDealerDropdownList, getEmployeeDropdownList, getFranchiseDropdownList, getPaymentType, getSupplierDropdownList, getVoucherDetailsById, getVoucherType, resetVoucher, updateVoucherLoading } from "src/store/voucher/action";

const AddEditVoucher = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const { showToast } = useToast();
  const formValue = useSelector(
    (state: RootReducerState) => state.VoucherReducer.voucherDetails 
  );
  const { error, message, loading, voucherTypes } = useSelector(
    (state: RootReducerState) => state.VoucherReducer
  );
  const { employeeList,franchiseList,dealerList,customerList,supplierList,paymentTypeList } = useSelector(
    (state: RootReducerState) => state.VoucherReducer
  );
  const [canRedirect, setCanRedirect] = useState(true);
  
  useEffect(() => {
    const preloader: any = document.getElementById("preloader");
    if (loading) {
      preloader.style.display = "block";
    } else {
      preloader.style.display = "none";
    }
  }, [loading]);

  

  const dispatch = useDispatch();
  const [visible, setVisible] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false)

  const[voucherType,setVoucherType]=useState<any>([])
  const[userOption,setUserOption]=useState<any>([])
  
  const bindSubType = (id:any,setFieldValue:any)=>{
    setFieldValue('voucherSubTypeId',null)
      let types = voucherTypes?.filter((val:any)=>val.vocherCategorieTypeId ==id)
      let options = types.map((val:any)=>({
          label:val.name,
          value:val.id
      }))
      setVoucherType(options)
  }
  
  const bindSubTypeForInitial = (id:any)=>{
      let types = voucherTypes?.filter((val:any)=>val.vocherCategorieTypeId ==id)
      let options = types.map((val:any)=>({
          label:val.name,
          value:val.id
      }))
      setVoucherType(options)
  }
  let paymentOptions = paymentTypeList?.map((val:any)=>({
    label:val.name,
    value:val.id
  }))
  const assignUser = (type:any,setFieldValue:any)=>{
    setFieldValue('userId',null)
    switch(type){
        case "Employees":
            setUserOption(employeeList)
            break
        case "Dealer":
            setUserOption(dealerList)
            break
        case "Customer":
            setUserOption(customerList)
            break
        case "Franchise":
            setUserOption(franchiseList)
            break
        case "Supplier":
            setUserOption(supplierList)
            break
        default:
            break
    }
}
  const assignUserForInitial = (type:any)=>{
    switch(type){
        case "Employees":
            setUserOption(employeeList)
            break
        case "Dealer":
            setUserOption(dealerList)
            break
        case "Customer":
            setUserOption(customerList)
            break
        case "Franchise":
            setUserOption(franchiseList)
            break
        case "Supplier":
            setUserOption(supplierList)
            break
        default:
            break
    }
}

  useEffect(() => {
    if (error) {
      setVisible(true);
      setIsSubmitting(false)
      // showToast(error, { type: "error" })
    }
    if (message) {
      showToast(message, { type: "success" });
      setTimeout(() => {
        setIsSubmitting(false)
        navigate(ROUTER.VOUCHER_GRID);
      }, 500)
      dispatch(resetVoucher())

    }
  }, [error, message]);

  const hidePopup = () => {
    setVisible(false);
    dispatch(clearVoucher())
  };

  const dialogContainer = (error: any) => {
    return (
      <>
        <ValidationPopup error={error} visible={visible} onHide={hidePopup} />
      </>
    );
  };

  const post = (formValue: any) => {
    dispatch(createVoucherLoading(formValue));
  };



  const update = (formValue: any) => {
    dispatch(updateVoucherLoading(formValue, id));
  };


  const initialvalue = useMemo(()=>{
    let voucherId = voucherTypes?.find((val:any)=>val.id == formValue?.voucherTypeId)?.vocherCategorieTypeId
debugger
    bindSubTypeForInitial(voucherId)
    let userType = ""
    let userId = null
    if(formValue?.employeeId !="" && formValue?.employeeId != null){
      userType = "Employees"
      userId = formValue?.employeeId
    }
    if(formValue?.franchiseId !="" && formValue?.franchiseId != null){
      userType = "Franchise"
      userId = formValue?.franchiseId
    }
    if(formValue?.dealerId !="" && formValue?.dealerId != null){
      userType = "Dealer"
      userId = formValue?.dealerId
    }
    if(formValue?.supplierId !="" && formValue?.supplierId != null){
      userType = "Supplier"
      userId = formValue?.supplierId
    }
    if(formValue?.customerId !="" && formValue?.customerId != null){
      userType = "Customer"
      userId = formValue?.customerId
    }
    assignUserForInitial(userType)
    return{
      voucherTypeId: voucherId,
      voucherSubTypeId: formValue?.voucherTypeId,
      voucherNumber: formValue?.voucherNumber,
      vocherDate: formValue?.vocherDate  ? formatDateTOYYYYMMDD(formValue?.vocherDate) : "",
      userType:userType,
      userId:userId,
      initiatedName: formValue?.initiatedName,
      approvedByName: formValue?.approvedByName,
      attachedBills: formValue?.attachedBills ? formValue?.attachedBills : false,
      approvedDate: formValue?.approvedDate  ? formatDateTOYYYYMMDD(formValue?.approvedDate) : "",
      approvedReceiverNumber: formValue?.approvedReceiverNumber,
      total: formValue?.total,
      lessTDS: formValue?.lessTDS,
      roundOff: formValue?.roundOff,
      grandTotal: formValue?.grandTotal,
      remittanceDetails: formValue?.remittanceDetails,
      paymentPaidBy: formValue?.paymentPaidBy,
      payoutDate: formValue?.payoutDate  ? formatDateTOYYYYMMDD(formValue?.payoutDate) : "",
      dealerId: formValue?.dealerId,
      customerId: formValue?.customerId,
      franchiseId: formValue?.franchiseId,
      supplierId: formValue?.supplierId,
      employeeId: formValue?.employeeId,
      voucherDetailsDto: formValue?.voucherDetailsDto?.filter((val:any)=>val.isDeleted != true)?.map((data:any)=>({
          id: data?.id,
          voucherMasterId: data?.voucherMasterId,
          subject: data?.subject,
          rateRs: data?.rateRs,
          qty: data?.qty,
          amount: data?.amount,
          isDeleted: data?.isDeleted
      }))
  }
},[formValue,voucherTypes]);

  const initialvaluecreate = {
    id: 0,
    voucherTypeId: null,
    voucherSubTypeId: null,
    voucherNumber: "",
    vocherDate: "",
    userType:"",
    userId:"",
    initiatedName: "",
    attachedBills: false,
    approvedByName: "",
    approvedDate: "",
    approvedReceiverNumber: "",
    total: null,
    lessTDS: null,
    roundOff: null,
    grandTotal: null,
    remittanceDetails: "",
    paymentPaidBy: "",
    payoutDate: "",
    dealerId: null,
    customerId: null,
    franchiseId: null,
    supplierId: null,
    employeeId: null,
    voucherDetailsDto: [
        {
          id: 0,
          voucherMasterId: 0,
          subject: "",
          rateRs: "",
          qty: "",
          amount: "",
          isDeleted: false
        }
      ]
  };









  const handleSubmit = async (values: any) => {
    if (isSubmitting) return
    const submittedData: any = {
        "id": id ? id :0,
        "voucherTypeId": values.voucherSubTypeId,
        "voucherNumber": values.voucherNumber,
        "vocherDate": values.vocherDate,
        "initiatedName": values.initiatedName,
        "attachedBills": values.attachedBills,
        "approvedByName": values.approvedByName,
        "approvedDate": values.approvedDate,
        "approvedReceiverNumber": values.approvedReceiverNumber,
        "total": values.total,
        "lessTDS": values.lessTDS ? values.lessTDS : 0,
        "roundOff": values.roundOff ? values.roundOff : 0,
        "grandTotal": values.grandTotal,
        "remittanceDetails": values.remittanceDetails,
        "paymentPaidBy":values.paymentPaidBy,
        "payoutDate": values.payoutDate,  
        "dealerId": null,
        "customerId": null,
        "franchiseId": null,
        "supplierId": null,
        "employeeId": null,
      "voucherDetailsDto": values.voucherDetailsDto.map((eachValue: any) => {
        let data: any = { };
        data['id'] = eachValue?.id ? eachValue?.id : 0
        data['voucherMasterId'] = eachValue?.voucherMasterId ? eachValue?.voucherMasterId : 0
        data['subject'] = eachValue?.subject ? eachValue?.subject : ""
        data['rateRs'] = eachValue?.rateRs ? eachValue?.rateRs : 0
        data['qty'] = eachValue?.qty ? eachValue?.qty : 0
        data['amount'] = eachValue?.amount ? eachValue?.amount : 0
        data['isDeleted'] = eachValue?.isDeleted ? eachValue?.isDeleted : false
        return data
      })

    };
    if(values.userType == "Employees"){
        submittedData['employeeId'] = values.userId
    }
    if(values.userType == "Dealer"){
        submittedData['dealerId'] = values.userId
    }
    if(values.userType == "Customer"){
        submittedData['customerId'] = values.userId
    }
    if(values.userType == "Franchise"){
        submittedData['franchiseId'] = values.userId
    }
    if(values.userType == "Supplier"){
        submittedData['supplierId'] = values.userId
    }
    id ? update(submittedData) : post(submittedData);
    setIsSubmitting(true)
  };

  const validateWeddingDate = (dateOfBirth: any, weddingDate: any) => {
    // alert(123);
  };

  const validationSchema = Yup.object().shape({
    voucherTypeId: Yup.string().required("Voucher Type is required"),
    voucherSubTypeId: Yup.string().required("Voucher Sub Type is required"),
    voucherNumber: Yup.string().required("Voucher Number is required"),
    vocherDate: Yup.string().required("Voucher Date is required"),
    userType: Yup.string().required("User Type is required"),
    userId: Yup.string().required("User is required"),
    initiatedName: Yup.string().required("Initiator Name is required"),
    attachedBills: Yup.boolean(),
    approvedDate: Yup.string()
        .nullable()
        .test(
            "approvedDate-after-vocherDate",
            "Approved Date cannot be less than Voucher Date",
            function (approvedDate) {
                const { vocherDate } = this.parent;
                if (!approvedDate || !vocherDate) return true; // Skip validation if either date is not provided
                return new Date(approvedDate) >= new Date(vocherDate);
            }
        ),
    approvedReceiverNumber: Yup.string().nullable(),
    total: Yup.number().required("Total is required"),
    lessTDS: Yup.number()
    .nullable()
    .min(0, "Less TDS cannot be a negative number")
    .test("lessTDS-validation", "Less TDS cannot be more than the total", function (lessTDS) {
        const total = this.parent.total; // Accessing the current form value for total
        if (lessTDS && total !== undefined && lessTDS > total) {
            return false; // Return false if lessTDS is greater than total
        }
        return true;
    }),
    roundOff: Yup.number().nullable(),
    grandTotal: Yup.number().required("Grand Total is required"),
    remittanceDetails: Yup.string().nullable(),
    paymentPaidBy: Yup.string().required("Payment Paid By is required"),
    payoutDate: Yup.string().nullable(),
    dealerId: Yup.number().nullable(),
    customerId: Yup.number().nullable(),
    franchiseId: Yup.number().nullable(),
    supplierId: Yup.number().nullable(),
    employeeId: Yup.number().nullable(),
    voucherDetailsDto: Yup.array().of(
        Yup.object().shape({
            subject: Yup.string().required("Subject is required"),
            rateRs: Yup.number().required("Rate is required").positive("Rate must be positive"),
            qty: Yup.number().required("Quantity is required")
              .positive("Quantity must be positive")
              .integer("Quantity must be a whole number"),
            amount: Yup.number().required("Amount is required").positive("Amount must be positive"),
        })
    ).min(1, "At least one voucher detail is required"),
});



  const handleAddContactDetails = (values: any, push: any, setFieldTouched: any) => {
    if (isContactDetailFilled(values)) {
      push({
        subject: "",
        rateRs: null,
        qty: null,
        amount: null,
      });
    } else {
      values.voucherDetailsDto?.forEach((_: any, index: number) => {
        setFieldTouched(`voucherDetailsDto[${index}].subject`, true);
        setFieldTouched(`voucherDetailsDto[${index}].rateRs`, true);
        setFieldTouched(`voucherDetailsDto[${index}].qty`, true);
        setFieldTouched(`voucherDetailsDto[${index}].amount`, true);
      })

    }

  };

  const isContactDetailFilled = (values: any) => {
    const voucherDetails = values.voucherDetailsDto || [];
    return voucherDetails.every((contact: any) => {
      const { id,voucherMasterId,isDeleted, ...fieldsExceptReOrderLevel } = contact;
      return Object.values(fieldsExceptReOrderLevel).every(
        (value: any) => value !== undefined && value !== null && value !== ""
      );
    });
  };




  useEffect(() => {
    const preloader: any = document.getElementById("preloader");
    if (loading) {
      preloader.style.display = "block";
    } else {
      preloader.style.display = "none";
    }
  }, [loading]);

  useEffect(()=>{
    dispatch(getVoucherType())
    dispatch(getEmployeeDropdownList())
    dispatch(getCustomerDropdownList())
    dispatch(getFranchiseDropdownList())
    dispatch(getDealerDropdownList())
    dispatch(getSupplierDropdownList())
    dispatch(getPaymentType())
    if(id){
        dispatch(getVoucherDetailsById(id))
    }
    return()=>{
        dispatch(resetVoucher())
    }
  },[])

  const preventSpecialChar = (event: any) => {

    if (
      (event.which >= 106 && event.which <= 111) ||
      (event.which >= 187 && event.which <= 191) ||
      event.which === 69
    ) {
      event.preventDefault();
    }
  };

const VoucherTypeOption = [
    {label:"Expense",value:0},
    {label:"Purchase",value:1}
]



const toUserTypesOptions = [
    { label: "Employees", value: "Employees" },
    { label: "Dealer", value: "Dealer" },
    { label: "Customer", value: "Customer" },
    { label: "Franchise", value: "Franchise" },
    { label: "Supplier", value: "Supplier" },
  ];
  
const calculateAmount = (values:any,index:any,setFieldValue:any,rate:any,qty:any)=>{
    debugger
    let rateval = rate > 0 ? rate : 0
    let qtyVal = qty > 0 ? qty : 0
    let amtVal = rateval * qtyVal
    let less = values.lessTDS ? values.lessTDS : 0
    setFieldValue(`voucherDetailsDto[${index}].amount`,amtVal)
    
    let total = values.voucherDetailsDto?.reduce((acc: number, curr: any, currIndex: number) => {
        // If current index matches the index passed to the function, use amtVal
        if (currIndex === index) {
            return acc + amtVal;
        } else {
            return acc + (curr.amount || 0); // Add amount if it exists, otherwise add 0
        }
    }, 0);
    setFieldValue('total',total)
    if(total > less){
        let tot = total - less
        setFieldValue('roundOff',Math.round(tot))
        setFieldValue('grandTotal',Math.round(tot))
    }
}

const totalCalculation = (values:any,less:any,setFieldValue:any)=>{
    let total = values.voucherDetailsDto?.reduce((acc: number, curr: any) => {
        return acc + (curr.amount || 0); // Add amount if it exists, otherwise add 0
    }, 0);
    if(total > less){
        let tot = total - less
        setFieldValue('roundOff',Math.round(tot))
        setFieldValue('grandTotal',Math.round(tot))
    }else{
        debugger
        showToast("Less TDS Cannot be more that Total", { type: "error" });
    }
}

const removeTotalCalculation = (values:any,less:any,setFieldValue:any,remove:any,index:any)=>{
    debugger
    let total = values.voucherDetailsDto?.reduce((acc: number, curr: any, currIndex: number) => {
        // If current index matches the index passed to the function, use amtVal
        if (currIndex === index) {
            return acc + 0;
        } else {
            return acc + (curr.amount || 0); // Add amount if it exists, otherwise add 0
        }
    }, 0);
    if(total > less){
        let tot = total - less
        setFieldValue('roundOff',Math.round(tot))
        setFieldValue('grandTotal',Math.round(tot))
    }else{
        debugger
        showToast("Less TDS Cannot be more that Total", { type: "error" });
    }
    remove(index)
}
  return (
    <div id="view-dealer">
      {visible && dialogContainer(error)}
      
      <div className="page-content">
        <Container fluid>
          <Breadcrumb
            title="Voucher"
            titlePath={ROUTER.VOUCHER_GRID}
            breadcrumbItem={id ? "Edit Voucher" : "Create Voucher"}
          />
          <Row>
            <Card>
              <CardBody>
                <React.Fragment>
                  <Formik onSubmit={handleSubmit} initialValues={id ? initialvalue : initialvaluecreate} validationSchema={validationSchema} enableReinitialize >
                    {({ values, handleChange, touched, errors, handleBlur, resetForm, reset, setFieldValue, setFieldTouched, ...rest }: any) => {
                      return (
                        <Form onChange={handleChange}>
                          <Row>
                            <Col lg={3} className="mb-2">
                            <DropdownInput
                                options={VoucherTypeOption}
                                name="voucherTypeId"
                                label="Voucher Type"
                                onChange={(e)=>{
                                    handleChange(e);
                                    bindSubType(e.value,setFieldValue)
                                }}
                                onBlur={handleBlur}
                                required={true}
                                value={values.voucherTypeId}
                                invalid={
                                  touched["voucherTypeId"] && errors["voucherTypeId"]
                                    ? true
                                    : false
                                } />
                            </Col>
                            <Col lg={3} className="mb-2">
                            <DropdownInput
                                options={voucherType}
                                name="voucherSubTypeId"
                                label="Voucher Sub Type"
                                onChange={handleChange}
                                onBlur={handleBlur}
                                required={true}
                                disabled={!(values.voucherTypeId != null)}
                                value={values.voucherSubTypeId}
                                invalid={
                                  touched["voucherSubTypeId"] && errors["voucherSubTypeId"]
                                    ? true
                                    : false
                                } />
                            </Col>
                            <Col lg={3} className="mb-2">
                            <DropdownInput
                                options={toUserTypesOptions}
                                name="userType"
                                label="User Type"
                                onChange={(e:any)=>{
                                    assignUser(e.value,setFieldValue)
                                    handleChange(e)
                                }}
                                onBlur={handleBlur}
                                lazy={false}
                                required={true}
                                disabled={false}
                                value={values.userType}
                                invalid={
                                  touched["userType"] && errors["userType"]
                                    ? true
                                    : false
                                } />
                            </Col>
                            <Col lg={3} className="mb-2">
                            <DropdownInput
                                options={userOption}
                                name="userId"
                                label="User"
                                onChange={handleChange}
                                onBlur={handleBlur}
                                required={true}
                                disabled={!values.userType}
                                value={values.userId}
                                invalid={
                                  touched["userId"] && errors["userId"]
                                    ? true
                                    : false
                                } />
                            </Col>
                            <Col lg={3} className="mb-2">
                              <InputCom
                                labelName="Voucher Number"
                                inputclassName="form-control"
                                labelClassName="form-Label"
                                type="text"
                                name="voucherNumber"
                                onBlur={handleBlur}
                                value={values.voucherNumber}
                                onChange={handleChange}
                                isRequired={true}
                                invalid={
                                  touched["voucherNumber"] && errors["voucherNumber"]
                                    ? true
                                    : false
                                }
                              />
                            </Col>
                            <Col lg={3} className="mb-2">
                                <InputCom
                                    labelName="Voucher Date"
                                    inputclassName="form-control"
                                    labelClassName="form-Label"
                                    type="date"
                                    name="vocherDate"
                                    onBlur={handleBlur}
                                    value={values.vocherDate}
                                    onChange={handleChange}
                                    isRequired={true}
                                    invalid={
                                    touched["vocherDate"] && errors["vocherDate"]
                                        ? true
                                        : false
                                    }
                                />
                            </Col>
                            <Col lg={3} className="mb-2">
                                <InputCom
                                    labelName="Initiator Name"
                                    inputclassName="form-control"
                                    labelClassName="form-Label"
                                    type="text"
                                    name="initiatedName"
                                    onBlur={handleBlur}
                                    value={values.initiatedName}
                                    onChange={handleChange}
                                    isRequired={true}
                                    invalid={
                                    touched["initiatedName"] && errors["initiatedName"]
                                        ? true
                                        : false
                                    }
                                />
                            </Col>
                            <Col lg={3} className="mb-2">
                                <InputCom
                                    labelName="Approved By"
                                    inputclassName="form-control"
                                    labelClassName="form-Label"
                                    type="text"
                                    name="approvedByName"
                                    onBlur={handleBlur}
                                    value={values.approvedByName}
                                    onChange={handleChange}
                                    isRequired={true}
                                    invalid={
                                    touched["approvedByName"] && errors["approvedByName"]
                                        ? true
                                        : false
                                    }
                                />
                            </Col>
                            <Col lg={3} className="mb-2">
                                <InputCom
                                    labelName="Approved Date"
                                    inputclassName="form-control"
                                    labelClassName="form-Label"
                                    type="date"
                                    name="approvedDate"
                                    onBlur={handleBlur}
                                    min={values.vocherDate}
                                    value={values.approvedDate}
                                    onChange={handleChange}
                                    isRequired={false}
                                    invalid={
                                    touched["approvedDate"] && errors["approvedDate"]
                                        ? true
                                        : false
                                    }
                                />
                            </Col>
                            <Col lg={3} className="mb-2">
                                <InputCom
                                    labelName="Approved Received Number"
                                    inputclassName="form-control"
                                    labelClassName="form-Label"
                                    type="text"
                                    name="approvedReceiverNumber"
                                    onBlur={handleBlur}
                                    value={values.approvedReceiverNumber}
                                    onChange={handleChange}
                                    isRequired={false}
                                    invalid={
                                    touched["approvedReceiverNumber"] && errors["approvedReceiverNumber"]
                                        ? true
                                        : false
                                    }
                                />
                            </Col>
                            <Col lg={3} className="mb-2">
                                <InputCom
                                    labelName="Remittance Details"
                                    inputclassName="form-control"
                                    labelClassName="form-Label"
                                    type="text"
                                    name="remittanceDetails"
                                    onBlur={handleBlur}
                                    value={values.remittanceDetails}
                                    onChange={handleChange}
                                    isRequired={false}
                                    invalid={
                                    touched["remittanceDetails"] && errors["remittanceDetails"]
                                        ? true
                                        : false
                                    }
                                />
                            </Col>
                            <Col lg={3} className="mb-2">
                            <DropdownInput
                                options={paymentOptions}
                                name="paymentPaidBy"
                                label="Payment Type"
                                onChange={handleChange}
                                onBlur={handleBlur}
                                required={true}
                                value={values.paymentPaidBy}
                                invalid={
                                  touched["paymentPaidBy"] && errors["paymentPaidBy"]
                                    ? true
                                    : false
                                } />
                            </Col>
                            <Col lg={3} className="mb-2">
                                <InputCom
                                    labelName="Payout Date"
                                    inputclassName="form-control"
                                    labelClassName="form-Label"
                                    type="date"
                                    name="payoutDate"
                                    onBlur={handleBlur}
                                    value={values.payoutDate}
                                    onChange={handleChange}
                                    isRequired={false}
                                    invalid={
                                    touched["payoutDate"] && errors["payoutDate"]
                                        ? true
                                        : false
                                    }
                                />
                            </Col>
                            <Col lg={3} className="mb-2">
                                <InputCom
                                    labelName="Total"
                                    inputclassName="form-control"
                                    labelClassName="form-Label"
                                    type="number"
                                    name="total"
                                    onBlur={handleBlur}
                                    value={values.total}
                                    onChange={handleChange}
                                    disabled={true}
                                    isRequired={true}
                                    invalid={
                                    touched["total"] && errors["total"]
                                        ? true
                                        : false
                                    }
                                />
                            </Col>
                            <Col lg={3} className="mb-2">
                                <InputCom
                                    labelName="Less TDS"
                                    inputclassName="form-control"
                                    labelClassName="form-Label"
                                    type="number"
                                    name="lessTDS"
                                    onBlur={handleBlur}
                                    value={values.lessTDS}
                                    onChange={(e:any)=>{
                                        handleChange(e)
                                        totalCalculation(values,e.target.value,setFieldValue)
                                    }}
                                    isRequired={false}
                                    onKeyDown={preventSpecialChar}
                                    invalid={
                                    touched["lessTDS"] && errors["lessTDS"]
                                        ? true
                                        : false
                                    }
                                />
                            </Col>
                            <Col lg={3} className="mb-2">
                                <InputCom
                                    labelName="Round Off"
                                    inputclassName="form-control"
                                    labelClassName="form-Label"
                                    type="number"
                                    name="roundOff"
                                    onBlur={handleBlur}
                                    value={values.roundOff}
                                    onChange={handleChange}
                                    disabled={true}
                                    isRequired={false}
                                    invalid={
                                    touched["roundOff"] && errors["roundOff"]
                                        ? true
                                        : false
                                    }
                                />
                            </Col>
                            <Col lg={3} className="mb-2">
                                <InputCom
                                    labelName="Grand Total"
                                    inputclassName="form-control"
                                    labelClassName="form-Label"
                                    type="number"
                                    name="grandTotal"
                                    onBlur={handleBlur}
                                    value={values.grandTotal}
                                    onChange={handleChange}
                                    disabled={true}
                                    isRequired={true}
                                    invalid={
                                    touched["grandTotal"] && errors["grandTotal"]
                                        ? true
                                        : false
                                    }
                                />
                            </Col>
                            
                            <Col lg={3} className="mb-2 d-flex align-items-center">
                              <CheckboxInput
                                name="attachedBills"
                                label="Attached Bills"
                                value={values.attachedBills}
                                onChange={handleChange}
                                {...rest}
                              />
                            </Col>
                          </Row>

                          <Row>
                            <hr />
                            <Col lg={12} className="dealer-title-header">
                              <div>Add Voucher</div>
                            </Col>
                            <FieldArray name="voucherDetailsDto">
                              {({ insert, remove, push }) => (
                                <div >
                                  {values.voucherDetailsDto?.length > 0 &&
                                    values.voucherDetailsDto.map(
                                      (value: any, index: any) => {
                                        const isLastContact = index === values.voucherDetailsDto?.length - 1;
                                        const isFirstContact = index === 0;
                                        const displayIndex = index + 1;
                                        return <div key={index}>

                                          <Row>
                                            <Col lg={3} className="mb-2">
                                             
                                            <InputCom
                                                labelName="Subject"
                                                inputclassName="form-control"
                                                labelClassName={`form-Label ${index == 0 ? "" : "d-sm-none"}`}
                                                type="text"
                                                min={0}
                                                name={`voucherDetailsDto[${index}].subject`}
                                                onBlur={handleBlur}
                                                onChange={(e) => { handleChange(e);}}
                                                isRequired={true}
                                                value={value.subject || ""}
                                                invalid={
                                                  touched.voucherDetailsDto?.[
                                                    index
                                                  ]?.subject &&
                                                    errors.voucherDetailsDto?.[index]
                                                      ?.subject
                                                    ? true
                                                    : false
                                                }
                                              />
                                              
                                            </Col>
                                            <Col lg={2} className="mb-2">
                                              <InputCom
                                                labelName="Rate"
                                                inputclassName="form-control"
                                                labelClassName={`form-Label ${index == 0 ? "" : "d-sm-none"}`}
                                                type="number"
                                                name={`voucherDetailsDto[${index}].rateRs`}
                                                onBlur={handleBlur}
                                                onChange={(e) => { handleChange(e);
                                                calculateAmount(values,index,setFieldValue,e.target.value,value.qty)
                                                }
                                                }
                                                isRequired={true}
                                                value={value.rateRs || ""}
                                                invalid={
                                                  touched.voucherDetailsDto?.[
                                                    index
                                                  ]?.rateRs &&
                                                    errors.voucherDetailsDto?.[index]
                                                      ?.rateRs
                                                    ? true
                                                    : false
                                                }
                                              />
                                            </Col>
                                            <Col lg={2} className="mb-2">
                                              <InputCom
                                                labelName="Quantity"
                                                inputclassName="form-control"
                                                labelClassName={`form-Label ${index == 0 ? "" : "d-sm-none"}`}
                                                type="number"
                                                min={0}
                                                name={`voucherDetailsDto[${index}].qty`}
                                                onBlur={handleBlur}
                                                onChange={(e) => { 
                                                    handleChange(e);
                                                    calculateAmount(values,index,setFieldValue,value.rateRs,e.target.value)
                                                }}
                                                isRequired={true}
                                                value={value.qty || ""}
                                                invalid={
                                                  touched.voucherDetailsDto?.[
                                                    index
                                                  ]?.qty &&
                                                    errors.voucherDetailsDto?.[index]
                                                      ?.qty
                                                    ? true
                                                    : false
                                                }
                                              />
                                            </Col>

                                            <Col lg={2} className="mb-2 d-flex">
                                            <div className="">
                                              <InputCom
                                                labelName="Amount"
                                                inputclassName="form-control"
                                                labelClassName={`form-Label ${index == 0 ? "" : "d-sm-none"}`}
                                                type="number"
                                                min={0}
                                                name={`voucherDetailsDto[${index}].amount`}
                                                onBlur={handleBlur}
                                                onChange={handleChange}
                                                isRequired={true}
                                                disabled={true}
                                                value={value.amount}
                                                invalid={
                                                  touched.voucherDetailsDto?.[
                                                    index
                                                  ]?.amount &&
                                                    errors.voucherDetailsDto?.[index]
                                                      ?.amount
                                                    ? true
                                                    : false
                                                }
                                              />
                                              </div>
                                               <div className={`justify-content-center remove-tooltip-margin ${index==0 ? 'margin-top-4' : 'mt-2'}`}>
                                                  {values.voucherDetailsDto.length > 1 && <TooltipMEL title="Remove Voucher" position="bottom">


                                                    <i className="fas fa-times-circle color-red"
                                                      onClick={() => {
                                                        calculateAmount(values,index,setFieldValue,0,0)
                                                        removeTotalCalculation(values,values.lessTDS,setFieldValue,remove,index)
                                                      }
                                                      }>
                                                    </i>

                                                  </TooltipMEL>}
                                                </div>
                                            </Col>

                                            
                                          </Row>
                                          <div className="multiadd-container">
                                            {isLastContact &&
                                              <div className="add-btn-container" onClick={() => { handleAddContactDetails(values, push, setFieldTouched); }}>
                                                <TooltipMEL title="Add Parts" position='bottom'>
                                                  <i className="fas fa-plus-circle"></i><span>Add</span>
                                                </TooltipMEL>
                                              </div>
                                            }
                                          </div>
                                        </div>;
                                      }
                                    )}
                                  <hr className="mt-1" />
                                </div>
                              )}
                            </FieldArray>
                          </Row>
                          <div className="d-flex justify-content-end mt-2">
                            {/* <TooltipMEL title={id ? "Cancel" : "Reset The Form"} position='top'> */}
                            <Button
                              className="secondary-btn me-2"
                              label={id ? "Cancel" : "Reset"}
                              type="button"
                              onClick={() =>resetForm()}
                            />
                            {/* </TooltipMEL> */}
                            {/* <TooltipMEL title={id ? "Update Purchase Order" : "Create Purchase Order"} position='top'> */}
                            <Button
                              className="btn-primary me-2 btn-primary-shadow"
                              label={id ? "Update Voucher" : "Create Voucher"}
                              type="submit"
                            />
                            {/* </TooltipMEL> */}
                          </div>
                        </Form>
                      );
                    }}
                  </Formik>
                </React.Fragment>
              </CardBody>
            </Card>
          </Row>
        </Container>
      </div>
    </div>
  );
};

export default AddEditVoucher;
