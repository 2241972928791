import { StockTransferActionTypes } from "./actionTypes";




// get StockTransfer by id

export const getStockTransferDetail = (id: string) => {
  return { type: StockTransferActionTypes.GET_STOCK_TRANSFER_BY_ID_LOADING, payload: id };
};

export const getStockTransferDetailSuccess = (stockTransfer: any) => {
  return { type: StockTransferActionTypes.GET_STOCK_TRANSFER_BY_ID_SUCCESS, payload: stockTransfer };
};

export const getStockTransferDetailError = (error: any) => {
    return { type: StockTransferActionTypes.GET_STOCK_TRANSFER_BY_ID_ERROR, payload: error };
  };





// update Dealer
export const updateStockTransfer = (stockTransfer: any, id: string = "") => {
  return { type: StockTransferActionTypes.UPDATE_STOCK_TRANSFER_LOADING, payload: { stockTransfer, id } };
};

export const updateStockTransferSuccess = (stockTransfer: any) => {
  return { type: StockTransferActionTypes.UPDATE_STOCK_TRANSFER_SUCCESS, payload: stockTransfer };
};

export const updateStockTransferError = (error: any) => {
  return { type: StockTransferActionTypes.UPDATE_STOCK_TRANSFER_ERROR, payload: error };
};


// post Dealer
export const createStockTransfer = (stockTransfer: any) => {
  return { type: StockTransferActionTypes.CREATE_STOCK_TRANSFER_LOADING, payload: stockTransfer };
};

export const createStockTransferSuccess = (stockTransfer: any) => {
  return { type: StockTransferActionTypes.CREATE_STOCK_TRANSFER_SUCCESS, payload: stockTransfer };
};

export const createStockTransferError = (error: any) => {
  return { type: StockTransferActionTypes.CREATE_STOCK_TRANSFER_ERROR, payload: error };
};

//  delete

export const deleteStockTransferList = (payload:any) => {
    return {
      type: StockTransferActionTypes.DELETE_STOCK_TRANSFER_LIST_LOADING,
      payload: payload,
    }
  }
  
  export const deleteStockTransferListSuccess = (response:any) => {
    return {
      type: StockTransferActionTypes.DELETE_STOCK_TRANSFER_LIST_SUCCESS,
      payload: response,
    }
  }
  
  export const deleteStockTransferListError = (error:any) => {
    return {
      type: StockTransferActionTypes.DELETE_STOCK_TRANSFER_LIST_ERROR,
      payload: error,
    }
  }



// For Grid

// get StockTransfer list

export const getStockTransferList = (lazyState: any) => {
    return { type: StockTransferActionTypes.GET_STOCK_TRANSFER_LIST_LOADING, lazyState, };
  };
  
  export const getStockTransferListSuccess = (stockTransfers: any) => {
    return { type: StockTransferActionTypes.GET_STOCK_TRANSFER_LIST_SUCCESS, payload: stockTransfers };
  };
  
  export const getStockTransferListError = (error: any) => {
    return { type: StockTransferActionTypes.GET_STOCK_TRANSFER_LIST_FAIL, payload: error };
  };
  


// Dealer Tax Type

export const franchiseListforStockTransfer=()=>{
    return {
      type : StockTransferActionTypes.GET_FRANCHISE_LIST_FOR_STOCK_TRANSFER_LOADING,
    }
  }
  
  export const franchiseListforStockTransferSuccess=(franchiseList : any)=>{
    return {
      type : StockTransferActionTypes.GET_FRANCHISE_LIST_FOR_STOCK_TRANSFER_SUCCESS,
      payload : franchiseList
    }
  }
  
  export const franchiseListforStockTransferError=(error : any)=>{
    return {
      type : StockTransferActionTypes.GET_FRANCHISE_LIST_FOR_STOCK_TRANSFER_ERROR,
      payload : error
    }
  }

export const employeeListforStockTransfer=()=>{
    return {
      type : StockTransferActionTypes.GET_EMPLOYEE_LIST_FOR_STOCK_TRANSFER_LOADING,
    }
  }
  
  export const employeeListforStockTransferSuccess=(employeeList : any)=>{
    return {
      type : StockTransferActionTypes.GET_EMPLOYEE_LIST_FOR_STOCK_TRANSFER_SUCCESS,
      payload : employeeList
    }
  }
  
  export const employeeListforStockTransferError=(error : any)=>{
    return {
      type : StockTransferActionTypes.GET_EMPLOYEE_LIST_FOR_STOCK_TRANSFER_ERROR,
      payload : error
    }
  }


  //  Parts autocomplete

export const partsAutoCompleteForStockTransferList = (params: any) => {
  return {
    type: StockTransferActionTypes.GET_PART_LIST_FOR_STOCK_TRANSFER_LOADING,
    params,
  };
};


export const partsAutoCompleteForStockTransferListSuccess = (payload: any) => {
  return {
    type: StockTransferActionTypes.GET_PART_LIST_FOR_STOCK_TRANSFER_SUCCESS,
    payload: payload,
  };
};

export const partsAutoCompleteForStockTransferListError = (error: any) => {
  return {
    type: StockTransferActionTypes.GET_PART_LIST_FOR_STOCK_TRANSFER_ERROR,
    payload: error,
  };
};


export const approveStockTransfer = (params: any) => {
  return {
    type: StockTransferActionTypes.APPROVE_STOCK_TRANSFER_LOADING,
    params,
  };
};


export const approveStockTransferSuccess = (payload: any) => {
  return {
    type: StockTransferActionTypes.APPROVE_STOCK_TRANSFER_SUCCESS,
    payload: payload,
  };
};

export const approveStockTransferError = (error: any) => {
  return {
    type: StockTransferActionTypes.APPROVE_STOCK_TRANSFER_ERROR,
    payload: error,
  };
};



export const rejectStockTransfer = (params: any) => {
  return {
    type: StockTransferActionTypes.REJECT_STOCK_TRANSFER_LOADING,
    params,
  };
};


export const rejectStockTransferSuccess = (payload: any) => {
  return {
    type: StockTransferActionTypes.REJECT_STOCK_TRANSFER_SUCCESS,
    payload: payload,
  };
};

export const rejectStockTransferError = (error: any) => {
  return {
    type: StockTransferActionTypes.REJECT_STOCK_TRANSFER_ERROR,
    payload: error,
  };
};


export const dispatchStockTransfer = (params: any) => {
  return {
    type: StockTransferActionTypes.DISPATCH_STOCK_TRANSFER_LOADING,
    params,
  };
};


export const dispatchStockTransferSuccess = (payload: any) => {
  return {
    type: StockTransferActionTypes.DISPATCH_STOCK_TRANSFER_SUCCESS,
    payload: payload,
  };
};

export const dispatchStockTransferError = (error: any) => {
  return {
    type: StockTransferActionTypes.DISPATCH_STOCK_TRANSFER_ERROR,
    payload: error,
  };
};

export const receiveStockTransfer = (params: any) => {
  return {
    type: StockTransferActionTypes.RECEIVE_STOCK_TRANSFER_LOADING,
    params,
  };
};


export const receiveStockTransferSuccess = (payload: any) => {
  return {
    type: StockTransferActionTypes.RECEIVE_STOCK_TRANSFER_SUCCESS,
    payload: payload,
  };
};

export const receiveStockTransferError = (error: any) => {
  return {
    type: StockTransferActionTypes.RECEIVE_STOCK_TRANSFER_ERROR,
    payload: error,
  };
};





export const clearStockTransferMessageData = ()=>{
  return {
    type: StockTransferActionTypes.CLEAR_STOCK_TRANSFER_MESSAGE,
    
  }
}


export const resetStockTransferValue = ()=>{
  return{
    type: StockTransferActionTypes.RESET_STOCK_TRANSFER_VALUE,
  }
}


//get courierList dropdown

export const getStockTransferCourierListDropdownLoading=()=>{
  return {
    type : StockTransferActionTypes.GET_STOCK_TRANSFER_COURIER_LIST_LOADING
  }
}

export const getStockTransferCourierListDropdownSuccess=(courierResponse : any)=>{
  return {
    type : StockTransferActionTypes.GET_STOCK_TRANSFER_COURIER_LIST_SUCCESS,
    payload : courierResponse
  }
}

export const getStockTransferCourierListDropdownError=(error : any)=>{
  return {
    type : StockTransferActionTypes.GET_STOCK_TRANSFER_COURIER_LIST_ERROR,
    payload : error
  }
}

//get stockTransfer delieverd person dropdown

export const getStockTransferDeliverablePersonDropdownLoading=()=>{
  return {
    type : StockTransferActionTypes.GET_STOCK_TRANSFER_DELIVERABLE_USER_LIST_LOADING
  }
}
export const getStockTransferDeliverablePersonDropdownSuccess=(deliverablePerson : any)=>{
  return {
    type : StockTransferActionTypes.GET_STOCK_TRANSFER_DELIVERABLE_USER_LIST_SUCCESS,
    payload : deliverablePerson
  }
}
export const getStockTransferDeliverablePersonDropdownError=(error : any)=>{
  return {
    type : StockTransferActionTypes.GET_STOCK_TRANSFER_DELIVERABLE_USER_LIST_ERROR,
    payload : error
  }
}


//post deliverable courier stockTransfer

export const postDeliverableCourierStockTransferLoading=(postStockTransferDetails : any,id:any)=>{
  return {
    type : StockTransferActionTypes.POST_COURIER_STOCK_TRANSFER_LOADING,
    payload : postStockTransferDetails,
    id
  }
}

export const postDeliverableCourierStockTransferSuccess=(resp : any)=>{
  return {
    type : StockTransferActionTypes.POST_COURIER_STOCK_TRANSFER_SUCCESS,
    payload : resp
  }
}
export const postDeliverableCourierStockTransferError=(error : any)=>{
  return {
    type : StockTransferActionTypes.POST_COURIER_STOCK_TRANSFER_ERROR,
    payload : error
  }
}
export const partSkuValidate=(payload : any)=>{
  return {
    type : StockTransferActionTypes.PARTS_VALIDATE,
    payload : payload
  }
}
export const partSkuValidateSuccess=(resp : any)=>{
  return {
    type : StockTransferActionTypes.PARTS_VALIDATE_SUCCESS,
    payload : resp
  }
}
export const partSkuValidateError=(resp : any)=>{
  return {
    type : StockTransferActionTypes.PARTS_VALIDATE_ERROR,
    payload : resp
  }
}

export const printStockTransferLoading=(payload : any)=>{
  return {
    type : StockTransferActionTypes.PRINT_STOCK_TRANSFER,
    payload : payload
  }
}
export const printStockTransferSuccess=(resp : any)=>{
  return {
    type : StockTransferActionTypes.PRINT_STOCK_TRANSFER_SUCCESS,
    payload : resp
  }
}
export const printStockTransferError=(resp : any)=>{
  return {
    type : StockTransferActionTypes.PRINT_STOCK_TRANSFER_ERROR,
    payload : resp
  }
}

export const emailStockTransferLoading=(payload : any)=>{
  return {
    type : StockTransferActionTypes.EMAIL_STOCK_TRANSFER,
    payload : payload
  }
}
export const emailStockTransferSuccess=(resp : any)=>{
  return {
    type : StockTransferActionTypes.EMAIL_STOCK_TRANSFER_SUCCESS,
    payload : resp
  }
}
export const emailStockTransferError=(resp : any)=>{
  return {
    type : StockTransferActionTypes.EMAIL_STOCK_TRANSFER_ERROR,
    payload : resp
  }
}







