export enum StockTransferActionTypes {

  GET_STOCK_TRANSFER_LIST_LOADING = '@@stock_transfer/GET_STOCK_TRANSFER_LIST_LOADING',
  GET_STOCK_TRANSFER_LIST_SUCCESS = '@@stock_transfer/GET_STOCK_TRANSFER_LIST_SUCCESS',
  GET_STOCK_TRANSFER_LIST_FAIL = '@@stock_transfer/GET_STOCK_TRANSFER_LIST_FAIL',

  GET_FRANCHISE_LIST_FOR_STOCK_TRANSFER_LOADING = "@@stock_transfer/GET_FRANCHISE_LIST_FOR_STOCK_TRANSFER_LOADING",
  GET_FRANCHISE_LIST_FOR_STOCK_TRANSFER_SUCCESS = "@@stock_transfer/GET_FRANCHISE_LIST_FOR_STOCK_TRANSFER_SUCCESS",
  GET_FRANCHISE_LIST_FOR_STOCK_TRANSFER_ERROR = "@@stock_transfer/GET_FRANCHISE_LIST_FOR_STOCK_TRANSFER_ERROR",

  GET_EMPLOYEE_LIST_FOR_STOCK_TRANSFER_LOADING = "@@stock_transfer/GET_EMPLOYEE_LIST_FOR_STOCK_TRANSFER_LOADING",
  GET_EMPLOYEE_LIST_FOR_STOCK_TRANSFER_SUCCESS = "@@stock_transfer/GET_EMPLOYEE_LIST_FOR_STOCK_TRANSFER_SUCCESS",
  GET_EMPLOYEE_LIST_FOR_STOCK_TRANSFER_ERROR = "@@stock_transfer/GET_EMPLOYEE_LIST_FOR_STOCK_TRANSFER_ERROR",

  DELETE_STOCK_TRANSFER_LIST_LOADING = "@@stock_transfer/DELETE_STOCK_TRANSFER_LIST_LOADING",
  DELETE_STOCK_TRANSFER_LIST_SUCCESS = "@@stock_transfer/DELETE_STOCK_TRANSFER_LIST_SUCCESS",
  DELETE_STOCK_TRANSFER_LIST_ERROR = "@@stock_transfer/DELETE_STOCK_TRANSFER_LIST_ERROR",

  UPDATE_STOCK_TRANSFER_LOADING = "@@stock_transfer/UPDATE_STOCK_TRANSFER_LOADING",
  UPDATE_STOCK_TRANSFER_SUCCESS = "@@stock_transfer/UPDATE_STOCK_TRANSFER_SUCCESS",
  UPDATE_STOCK_TRANSFER_ERROR = "@@stock_transfer/UPDATE_STOCK_TRANSFER_ERROR",

  CREATE_STOCK_TRANSFER_LOADING = "@@stock_transfer/CREATE_STOCK_TRANSFER_LOADING",
  CREATE_STOCK_TRANSFER_SUCCESS = "@@stock_transfer/CREATE_STOCK_TRANSFER_SUCCESS",
  CREATE_STOCK_TRANSFER_ERROR = "@@stock_transfer/CREATE_STOCK_TRANSFER_ERROR",

  GET_STOCK_TRANSFER_BY_ID_LOADING = "@@stock_transfer/GET_STOCK_TRANSFER_BY_ID_LOADING",
  GET_STOCK_TRANSFER_BY_ID_SUCCESS = "@@stock_transfer/GET_STOCK_TRANSFER_BY_ID_SUCCESS",
  GET_STOCK_TRANSFER_BY_ID_ERROR = "@@stock_transfer/GET_STOCK_TRANSFER_BY_ID_ERROR",

  GET_PART_LIST_FOR_STOCK_TRANSFER_LOADING = "@@stock_transfer/GET_PART_LIST_FOR_STOCK_TRANSFER_LOADING",
  GET_PART_LIST_FOR_STOCK_TRANSFER_SUCCESS = "@@stock_transfer/GET_PART_LIST_FOR_STOCK_TRANSFER_SUCCESS",
  GET_PART_LIST_FOR_STOCK_TRANSFER_ERROR = "@@stock_transfer/GET_PART_LIST_FOR_STOCK_TRANSFER_ERROR",

  APPROVE_STOCK_TRANSFER_LOADING = "@@stock_transfer/APPROVE_STOCK_TRANSFER_LOADING",
  APPROVE_STOCK_TRANSFER_SUCCESS = "@@stock_transfer/APPROVE_STOCK_TRANSFER_SUCCESS",
  APPROVE_STOCK_TRANSFER_ERROR = "@@stock_transfer/APPROVE_STOCK_TRANSFER_ERROR",

  REJECT_STOCK_TRANSFER_LOADING = "@@stock_transfer/REJECT_STOCK_TRANSFER_LOADING",
  REJECT_STOCK_TRANSFER_SUCCESS = "@@stock_transfer/REJECT_STOCK_TRANSFER_SUCCESS",
  REJECT_STOCK_TRANSFER_ERROR = "@@stock_transfer/REJECT_STOCK_TRANSFER_ERROR",

  DISPATCH_STOCK_TRANSFER_LOADING = "@@stock_transfer/DISPATCH_STOCK_TRANSFER_LOADING",
  DISPATCH_STOCK_TRANSFER_SUCCESS = "@@stock_transfer/DISPATCH_STOCK_TRANSFER_SUCCESS",
  DISPATCH_STOCK_TRANSFER_ERROR = "@@stock_transfer/DISPATCH_STOCK_TRANSFER_ERROR",

  RECEIVE_STOCK_TRANSFER_LOADING = "@@stock_transfer/RECEIVE_STOCK_TRANSFER_LOADING",
  RECEIVE_STOCK_TRANSFER_SUCCESS = "@@stock_transfer/RECEIVE_STOCK_TRANSFER_SUCCESS",
  RECEIVE_STOCK_TRANSFER_ERROR = "@@stock_transfer/RECEIVE_STOCK_TRANSFER_ERROR",

  CLEAR_STOCK_TRANSFER_MESSAGE = "@@stock_transfer/CLEAR_STOCK_TRANSFER_MESSAGE",
  RESET_STOCK_TRANSFER_VALUE = "@@stock_transfer/RESET_STOCK_TRANSFER_VALUE",

  //get courierList
  GET_STOCK_TRANSFER_COURIER_LIST_LOADING = "@@stock_transfer/GET_STOCK_TRANSFER_COURIER_LIST_LOADING",
  GET_STOCK_TRANSFER_COURIER_LIST_SUCCESS = "@@stock_transfer/GET_STOCK_TRANSFER_COURIER_LIST_SUCCESS",
  GET_STOCK_TRANSFER_COURIER_LIST_ERROR = "@@stock_transfer/GET_STOCK_TRANSFER_COURIER_LIST_ERROR",

  //get deliverableuserList
  GET_STOCK_TRANSFER_DELIVERABLE_USER_LIST_LOADING = "@@stock_transfer/GET_STOCK_TRANSFER_DELIVERABLE_USER_LIST_LOADING",
  GET_STOCK_TRANSFER_DELIVERABLE_USER_LIST_SUCCESS = "@@stock_transfer/GET_STOCK_TRANSFER_DELIVERABLE_USER_LIST_SUCCESS",
  GET_STOCK_TRANSFER_DELIVERABLE_USER_LIST_ERROR = "@@stock_transfer/GET_STOCK_TRANSFER_DELIVERABLE_USER_LIST_ERROR",

  //post deliverable courier stock_transfer
  POST_COURIER_STOCK_TRANSFER_LOADING = "@@stock_transfer/POST_COURIER_STOCK_TRANSFER_LOADING",
  POST_COURIER_STOCK_TRANSFER_SUCCESS = "@@stock_transfer/POST_COURIER_STOCK_TRANSFER_SUCCESS",
  POST_COURIER_STOCK_TRANSFER_ERROR = "@@stock_transfer/POST_COURIER_STOCK_TRANSFER_ERROR",

  PARTS_VALIDATE="@@stock_transfer/PARTS_VALIDATE",
  PARTS_VALIDATE_SUCCESS="@@stock_transfer/PARTS_VALIDATE_SUCCESS",
  PARTS_VALIDATE_ERROR="@@stock_transfer/PARTS_VALIDATE_ERROR",

  PRINT_STOCK_TRANSFER="@@stock_transfer/PRINT_STOCK_TRANSFER",
  PRINT_STOCK_TRANSFER_SUCCESS="@@stock_transfer/PRINT_STOCK_TRANSFER_SUCCESS",
  PRINT_STOCK_TRANSFER_ERROR="@@stock_transfer/PRINT_STOCK_TRANSFER_ERROR",

  EMAIL_STOCK_TRANSFER="@@stock_transfer/EMAIL_STOCK_TRANSFER",
  EMAIL_STOCK_TRANSFER_SUCCESS="@@stock_transfer/EMAIL_STOCK_TRANSFER_SUCCESS",
  EMAIL_STOCK_TRANSFER_ERROR="@@stock_transfer/EMAIL_STOCK_TRANSFER_ERROR",
}
