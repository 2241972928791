import React, { useEffect, useRef, useState } from "react";
import { Row, Col, Button, UncontrolledTooltip, Input } from "reactstrap";
import { Form, Formik } from "formik";
import * as Yup from "yup";
import { Option } from "src/models/components/inputWrapperModel";
import {
  Columns,
  LazyTableState,
} from "src/models/components/dataTableWrapperModel";
import DataTableWrapper from "src/components/Grid/DataTableWrapper";
import { DataTable, DataTableFilterMeta } from "primereact/datatable";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import DeleteModal from "../../Calendar/DeleteModal";
import ArchiveModal from "../../Calendar/DeleteModal";
import { useToast } from "src/components/Common/ReactToaster";
import Select from "src/components/Common/Select";
import StatusHghlighter from "src/components/Common/StatusHighlighter/StatusHighlighter";
import { CurrencyFormatter, formatDateMEL, formatDateMELForExcel, saveAsExcelFile } from "src/helpers/common_helpers";
import { deletePartsFromList } from "src/store/parts/action";
import { ROUTER } from "src/constants/routes";
import { MultiSelectChangeEvent } from "primereact/multiselect";
import FeatherIcon from "feather-icons-react";
import CustomizeTable from "src/components/Common/CustomizeTable/CustomizeTable";
import SortIcon from "src/components/Common/CustomFilterandSortIcon/SortIcon";
import { ACTIVE, DELETED } from "src/constants/commonConstants";
import ExportFile from "src/components/Common/ExcelPdf Export/ExportFile";
import FilterIcon from "src/components/Common/CustomFilterandSortIcon/FilterIcon";
import { invoiceItemWiseRegisters } from "src/models/pages/invoiceItemWiseRegistersModel";
import { getInvoiceItemWiseRegistersList, resetInvoiceItemWiseRegistersList } from "src/store/invoiceItemWiseRegisters/action";
import ValidationPopup from "src/components/Common/ValidationMessage/ValidationPopup";
import { ColumnFilterElementTemplateOptions } from "primereact/column";
import { API } from "src/util/api";
import { customizeTableColumns } from "src/models/components/CustomizeTableColumns";

const invoiceItemWiseRegistersListConversion = (values: any[]) => {
  const modifeidValue = values.map((eachValue) => {

    let data: any = {};
    data['invoiceNumber'] = eachValue?.invoiceNumber;
    data['invoiceDate'] = eachValue?.invoiceDate;
    data['JobNumber'] = eachValue?.jobNumber;
    data['customer'] = eachValue?.name;
    data['address'] = eachValue?.address;
    data['name'] = eachValue?.taxTypeName ? eachValue?.taxTypeName : "-";
    data['gstNumber'] = eachValue?.gstNumber ? eachValue?.gstNumber : "-";
    data['hsnCode'] = eachValue?.hsnCodeOrSacCode;
    data['invociePartOrServiceDetail'] = eachValue?.invociePartOrServiceDetail;
    data['quantity'] = eachValue?.quantity;
    data['taxableValue'] = eachValue?.taxableValue;
    data['taxRate'] = eachValue?.taxRate;
    data['sgst'] = eachValue?.sgst;
    data['cgst'] = eachValue?.cgst;
    data['igst'] = eachValue?.igst;
    data['utgst'] = eachValue?.utgst;
    data['totalValue'] = eachValue?.totalValue;
    return data;
  });
  return modifeidValue;
};

export default function InvoiceItemWiseRegisters() {
  const navigate = useNavigate();
  const [filter, setFilter] = useState(false);
  // const [gstNumber, setGstNumber] = useState([]);
  const { values, totalRecords } = useSelector((state: any) => state.invoiceItemWiseRgisterReducer.invoiceItemList);
  const modifiedValues: invoiceItemWiseRegisters[] = invoiceItemWiseRegistersListConversion(values);
  const { showToast } = useToast();
  const loading = useSelector((state: any) => state.invoiceItemWiseRgisterReducer.loading);
  const [pdfLoading, setPdfLoading] = useState<boolean>(false);

  const { error, message, paginatorCount, gstList } = useSelector((state: any) => state.invoiceItemWiseRgisterReducer);
  const dispatch = useDispatch();
  const dt = useRef<DataTable<any>>(null);
  // for delete invoice
  const [deleteModal, setDeleteModal] = useState(false);
  const [archiveModal, setArchiveModal] = useState(false);
  const [rowData, setRowData] = useState<invoiceItemWiseRegisters | null>(null);
  const filterDetails: DataTableFilterMeta = {
    invoiceNumber: { value: "", matchMode: "contains" },
    invoiceDate: { value: "", matchMode: "contains" },
    LockedStartDate: { value: "", matchMode: "contains" },
    LockedEndDate: { value: "", matchMode: "contains" },
    JobNumber: { value: "", matchMode: "contains" },
    customer: { value: "", matchMode: "contains" },
    PartNumber: { value: "", matchMode: "contains" },
    HsnNumber: { value: "", matchMode: "contains" },
    gstNumber: { value: "", matchMode: "contains" },
    Description: { value: "", matchMode: "contains" },
    ReorderLevel: { value: "", matchMode: "contains" },
    IsDeleted: { value: "false", matchMode: "contains" },
  };
  let initialLazyState: LazyTableState = {
    first: 0,
    rows: 10,
    page: 1,
    sortField: "",  //CreationTime
    sortOrder: 1,
    filters: filterDetails,
  };
  const [lazyState, setLazyState] = useState(initialLazyState);

  const initialValues = {
    status: "",
  };

  const onFilterClickHandler = () => {
    setFilter((preState) => !preState);
  };

  const onCreateClickHandler = () => {
    navigate(ROUTER.PARTS_CREATE);
  };

  const viewHandler = (rowData: invoiceItemWiseRegisters) => {
    navigate(`${ROUTER.PARTS_VIEW}/${rowData["id"]}`);
  };

  const editHandler = (rowData: invoiceItemWiseRegisters) => {
    navigate(`${ROUTER.PARTS_EDIT}/${rowData["id"]}`);
  };

  const deleteHandler = (rowData: invoiceItemWiseRegisters) => {
    setDeleteModal(true);
    setRowData(rowData);
  };

  const actionBodyTemplate = (rowData: invoiceItemWiseRegisters, field: string) => {
    return (
      <>
        {rowData[field] as string}
        {/* {
          rowData.isDeleted == false ? (<ColumnAction
            displayData={rowData[field] as string}
            isDelete={true}
            isView={true}
            onViewClick={() => viewHandler(rowData)}
            onDeleteClick={() => deleteHandler(rowData)}
          />
          ) : (
            <ColumnAction
              displayData={rowData[field] as string}

            />
          )
        } */}
      </>
    );
  };

  const [visible, setVisible] = useState(false);
  useEffect(() => {
    if (error) {
      setVisible(true);
      // showToast(error, { type: "error" })
    }
    if (message) {
      showToast(message, { type: "success" });
      dispatch(resetInvoiceItemWiseRegistersList());
    }
  }, [error, message]);

  const hidePopup = () => {
    setVisible(false);
  };

  const dialogContainer = (error: any) => {
    return (
      <>
        <ValidationPopup error={error} visible={visible} onHide={hidePopup} />
      </>
    );
  };


  const percentageBodyTemplate = (rowData: invoiceItemWiseRegisters, field: string) => {
    return (
      <div>{rowData[field]}</div>
    );
  };

  const statusBodyTemplate = (rowData: invoiceItemWiseRegisters, field: string) => {
    let status = rowData[field] as string;
    return (
      <StatusHghlighter status={status} />
    );

  };

  const options: Option[] = [
    { label: "Active", value: "Active" },
    { label: "Deleted", value: "Deleted" },
  ];

  const currencyBodyTemplate = (rowData: invoiceItemWiseRegisters, field: string) => {
    let amount = rowData[field];
    return (<>{CurrencyFormatter({ value: amount })}</>);
  };
  const dateFilterTemplate = (options: ColumnFilterElementTemplateOptions) => {
    return (<>
      <label htmlFor="">Start Date</label>
      <Input type="date" required value={options?.value?.startDate || ""} max={options?.value?.endDate ? options?.value?.endDate : ""} onChange={(e: any) => {
        options;
        let modifiedDate: any = options.value || { startDate: "", endDate: "" };
        modifiedDate['startDate'] = e.target.value;
        options.filterCallback(modifiedDate, options.index);
      }} />
      <br />
      <label htmlFor="">End Date</label>
      <Input type="date" required value={options?.value?.endDate || ""} min={options?.value?.startDate ? options?.value?.startDate : ""} onChange={(e: any) => {
        options;
        let modifiedDate: any = options.value || { startDate: "", endDate: "" };
        modifiedDate['endDate'] = e.target.value;
        options.filterCallback(modifiedDate, options.index);
      }} />
    </>);
  };





  // const gstFilterTemplate = (options: ColumnFilterElementTemplateOptions) => {
  //   return (<>
  //     <MultiSelect
  //       value={gstNumber}
  //       options={gstList?.length > 0 ? (gstList.filter((data:any)=>data.gstNumner != null && data.gstNumner != "").map((gst: any) => {
  //         let data: any = {};
  //         data['value'] = gst.gstNumner;
  //         data['label'] = gst.gstNumner;
  //         return data;
  //       })).sort((a: any, b: any) => {
  //         const labelA = a.label.toUpperCase().trim();
  //         const labelB = b.label.toUpperCase().trim();
  //         if (labelA < labelB) {
  //           return -1;
  //         }
  //         if (labelA > labelB) {
  //           return 1;
  //         }
  //         return 0;
  //       }) : []}
  //       onChange={(e) => {
  //         debugger
  //         setGstNumber(e.value);
         
  //       }}
  //       filter={true}
  //       selectAll={false}
  //       virtualScrollerOptions={{ itemSize: 15 }}
  //       maxLength={5}
  //       maxSelectedLabels={2}
  //       placeholder="Select Items"
  //       className="w-full md:w-20rem"
  //     />
  //   </>);
  // };

  const columns: Columns[] = [

    {
      field: "invoiceNumber",
      sortable: true,
      header: "Invoice Number",
      filter: true,
      showFilterMenu: true,
      isDefault: true,
      frozen: true,
      body: (rowData) => actionBodyTemplate(rowData, "invoiceNumber"),
      headerStyle: { whiteSpace: "nowrap", width: "250px" },
      isStatic: true,
    },
    {
      field: "invoiceDate",
      sortable: true,
      header: "Invoice Date",
      filter: true,
      showFilterMenu: true,
      isDefault: true,
      headerStyle: { whiteSpace: "nowrap" },
      isStatic: false,
      body: (rowData) => formatDateMEL(rowData.invoiceDate),
      filterElement: dateFilterTemplate
    },
    {
      field: "JobNumber",
      sortable: true,
      header: "Job Sheet Number",
      filter: true,
      showFilterMenu: true,
      isDefault: true,
      headerStyle: { whiteSpace: "nowrap" },
      isStatic: false,
    },
    {
      field: "customer",
      sortable: false,
      header: "Customer",
      filter: false,
      showFilterMenu: false,
      isDefault: true,
      headerStyle: { whiteSpace: "nowrap" },
      isStatic: false,
    },

    {
      field: "address",
      sortable: true,
      header: "State Of Supply",
      filter: false,
      showFilterMenu: false,
      isDefault: true,
      headerStyle: { whiteSpace: "nowrap" },
      isStatic: false,
    },
    {
      field: "name",
      sortable: true,
      header: "Name",
      filter: false,
      showFilterMenu: false,
      isDefault: true,
      headerStyle: { whiteSpace: "nowrap" },
      isStatic: false,
    },
    {
      field: "gstNumber",
      sortable: false,
      header: "Gstin No",
      filter: false,
      showFilterMenu: false,
      isDefault: true,
      headerStyle: { whiteSpace: "nowrap" },
      isStatic: false,
      // filterElement: gstFilterTemplate
    },
    {
      field: "hsnCode",
      sortable: false,
      header: "Hsn Code Or Sac Code",
      filter: false,
      showFilterMenu: false,
      isDefault: true,
      headerStyle: { whiteSpace: "nowrap" },
      isStatic: false,
    },
    {
      field: "invociePartOrServiceDetail",
      sortable: true,
      header: "Invoice Part Or Service Detail",
      filter: false,
      showFilterMenu: false,
      isDefault: true,
      headerStyle: { whiteSpace: "nowrap" },
      isStatic: false,
    },
    {
      field: "quantity",
      sortable: true,
      header: "Quantity",
      filter: false,
      showFilterMenu: false,
      isDefault: true,
      headerStyle: { whiteSpace: "nowrap", textAlign: "right" },
      isStatic: false,
      className: "align-right"
    },
    {
      field: "taxableValue",
      sortable: true,
      header: "Taxable Value",
      filter: false,
      showFilterMenu: false,
      isDefault: true,
      headerStyle: { whiteSpace: "nowrap" },
      isStatic: false,
      className: "align-right"
    },
    {
      field: "taxRate",
      sortable: true,
      header: "Tax rate (%)",
      filter: false,
      showFilterMenu: false,
      isDefault: true,
      headerStyle: { whiteSpace: "nowrap" },
      isStatic: false,
      className: "align-right"
    },
    {
      field: "sgst",
      sortable: true,
      header: "Sgst",
      filter: false,
      showFilterMenu: false,
      isDefault: true,
      headerStyle: { whiteSpace: "nowrap" },
      isStatic: false,
      className: "align-right"
    },
    {
      field: "cgst",
      sortable: true,
      header: "Cgst",
      filter: false,
      showFilterMenu: false,
      isDefault: true,
      headerStyle: { whiteSpace: "nowrap" },
      isStatic: false,
      className: "align-right"
    },
    {
      field: "igst",
      sortable: true,
      header: "Igst",
      filter: false,
      showFilterMenu: false,
      isDefault: true,
      headerStyle: { whiteSpace: "nowrap" },
      isStatic: false,
      className: "align-right"
    },
    {
      field: "utgst",
      sortable: true,
      header: "Utgst",
      filter: false,
      showFilterMenu: false,
      isDefault: true,
      headerStyle: { whiteSpace: "nowrap" },
      isStatic: false,
      className: "align-right"
    },
    {
      field: "totalValue",
      sortable: true,
      header: "Total Value",
      filter: false,
      showFilterMenu: false,
      isDefault: true,
      headerStyle: { whiteSpace: "nowrap" },
      isStatic: false,
      className: "align-right"
    },

  ];

  const [visibleColumns, setVisibleColumns] = useState(columns.filter((eachColumn: Columns) => eachColumn.isDefault));

  // useEffect(() => {
  //   setVisibleColumns(columns.filter((eachColumn: Columns) => eachColumn.isDefault));
  // }, [gstList,gstNumber]);

  const changeColumnDetails = (values: any[]) => {
    let modifiedColumnDetails = values.map((eachValue) => {
      let data: any = {};
      data['invoiceNumber'] = eachValue?.invoiceNumber;
      data['invoiceDate'] = formatDateMELForExcel(eachValue?.invoiceDate);
      data['JobNumber'] = eachValue?.JobNumber;
      data['customer'] = eachValue?.customer;
      data['address'] = eachValue?.address;
      data['name'] = eachValue?.name ? eachValue?.name : "-";
      data['gstNumber'] = eachValue?.gstNumber ? eachValue?.gstNumber : "-";
      data['hsnCode'] = eachValue?.hsnCode;
      data['invociePartOrServiceDetail'] = eachValue?.invociePartOrServiceDetail;
      data['quantity'] = eachValue?.quantity;
      data['taxableValue'] = eachValue?.taxableValue;
      data['taxRate'] = eachValue?.taxRate;
      data['sgst'] = eachValue?.sgst;
      data['cgst'] = eachValue?.cgst;
      data['igst'] = eachValue?.igst;
      data['utgst'] = eachValue?.utgst;
      data['totalValue'] = eachValue?.totalValue;
      return { ...data };
    });
    return modifiedColumnDetails;
  };

  const saveDataAsExcel = (values: any[], fileName: string) => {
    const modifiedColumnDetails = changeColumnDetails(values);
    import('xlsx').then((xlsx) => {
      const worksheet = xlsx.utils.json_to_sheet(modifiedColumnDetails);
      const workbook = { Sheets: { data: worksheet }, SheetNames: ['data'] };
      const excelBuffer = xlsx.write(workbook, {
        bookType: 'xlsx',
        type: 'array'
      });
      saveAsExcelFile(excelBuffer, fileName);
    });
  };

  const saveDataAsPdf = (values: any[], columns: any[], fileName: string) => {

    const destructureedData = values.map((eachValue) => {
      let datas = {
        id: eachValue.id,
        invoiceNumber: eachValue?.invoiceNumber,
        invoiceDate: formatDateMEL(eachValue?.invoiceDate),
        JobNumber: eachValue?.JobNumber,
        customer: eachValue?.customer,
        address: eachValue?.address,
        name: eachValue?.name ? eachValue?.name : "-",
        gstNumber: eachValue?.gstNumber ? eachValue?.gstNumber : "-",
        hsnCode: eachValue?.hsnCode,
        invociePartOrServiceDetail: eachValue?.invociePartOrServiceDetail,
        quantity: eachValue?.quantity,
        taxableValue: eachValue?.taxableValue,
        taxRate: eachValue?.taxRate,
        sgst: eachValue?.sgst,
        cgst: eachValue?.cgst,
        igst: eachValue?.igst,
        utgst: eachValue?.utgst,
        totalValue: eachValue?.totalValue
      };
      return datas;
    });
    import("jspdf").then((jsPDF) => {
      import("jspdf-autotable").then(() => {
        const exportColumns = columns.map((col) => ({
          title: col.header,
          dataKey: col.field,
        }));
        const doc = new (jsPDF as any).default({
          orientation: 'landscape',
          format: 'a2',
        });
        doc.autoTable(exportColumns, destructureedData);
        doc.save(`${fileName}.pdf`);
      });
    });
  };
  const fetchData = async (isPdf: boolean) => {
    let lazy: any = {
      ...lazyState,
      rows: totalRecords > 5000 ? 5000 : totalRecords,
      filters: {
        ...lazyState.filters,
      },
    };

    try {
      setPdfLoading(true);
      const response = await API.getInvoiceItemWiseRegistersListAPI(lazy);
      if (!response) {
        throw new Error('Network response was not ok');
      }
      const modifeidValue = response?.data?.items?.map((eachValue: any) => {

        let data: any = {};
        data['invoiceNumber'] = eachValue?.invoiceNumber;
        data['invoiceDate'] = eachValue?.invoiceDate;
        data['JobNumber'] = eachValue?.jobNumber;
        data['customer'] = eachValue?.name;
        data['address'] = eachValue?.address;
        data['name'] = eachValue?.name ? eachValue?.name : "-";
        data['gstNumber'] = eachValue?.gstNumber ? eachValue?.gstNumber : "-";
        data['hsnCode'] = eachValue?.hsnCodeOrSacCode;
        data['invociePartOrServiceDetail'] = eachValue?.invociePartOrServiceDetail;
        data['quantity'] = eachValue?.quantity;
        data['taxableValue'] = eachValue?.taxableValue;
        data['taxRate'] = eachValue?.taxRate;
        data['sgst'] = eachValue?.sgst;
        data['cgst'] = eachValue?.cgst;
        data['igst'] = eachValue?.igst;
        data['utgst'] = eachValue?.utgst;
        data['totalValue'] = eachValue?.totalValue;
        return data;
      });
      // let resp=await invoiceItemWiseRegistersListConversion(response?.data?.items)
      if (isPdf) {

        saveDataAsPdf(modifeidValue, columns, "InvoiceItemWiseRegisters");
      } else {
        saveDataAsExcel(modifeidValue, "InvoiceItemWiseRegisters ");
      }
      setPdfLoading(false);
    } catch (error: any) {
      showToast(error.message, { type: "error" });
      setPdfLoading(false);

    } finally {
      setPdfLoading(false);
    }
  };


  const csvClickHandler = () => {
    // saveDataAsExcel(modifiedValues, "InvoiceItemWiseRegisters ");
    fetchData(false);
  };

  const pdfClickHandler = () => {
    // saveDataAsPdf(modifiedValues, columns, "InvoiceItemWiseRegisters");
    fetchData(true);
  };

  const validationSchema = Yup.object({});

  const callbackFunction = (lazyState: LazyTableState) => {
    let modifiedLazyState: any = { ...lazyState };
    if (modifiedLazyState.filters && modifiedLazyState.filters.invoiceDate) {
      modifiedLazyState.filters.invoiceDate.value = "";
    }
    dispatch(getInvoiceItemWiseRegistersList(lazyState));
  };

  // useEffect(() => {
  //   dispatch(getGstNoList());
  // }, []);

  const [selectAll, setSelectAll] = useState(false);
  const [selectedParts, setSelectedParts] = useState<any>([]);

  const onSelectionChange = (event: any) => {
    const value = event.value;
    setSelectedParts(value);
    // setSelectAll(value.length === totalRecords);
    setSelectAll(value.length === paginatorCount);
  };

  const onSelectAllChange = (event: any) => {
    const selectAll = event.checked;

    if (selectAll) {
      setSelectAll(true);
      setSelectedParts(modifiedValues);
    } else {
      setSelectAll(false);
      setSelectedParts([]);
    }
  };

  const handleStatusBlur = () => { };

  const onPage = (event: any) => {
    setLazyState(event);
  };

  const onSort = (event: any) => {
    setLazyState(event);
  };


  const onFilter = (event: any) => {    
    event['filters']['LockedStartDate']['value'] = event?.filters?.invoiceDate?.value?.startDate ? event?.filters?.invoiceDate?.value?.startDate : '';
    event['filters']['LockedEndDate']['value'] = event?.filters?.invoiceDate?.value?.endDate ? event?.filters?.invoiceDate?.value?.endDate : '';
    event["first"] = 0;
    setLazyState(event);
  };

  const handleSubmit = (formValue: any) => {
    setselectedStatus(formValue);
    if (formValue == ACTIVE) {
      setLazyState((state) => ({
        ...state,
        filters: {
          ...state.filters,
          IsDeleted: { value: "false", matchMode: "contains" },
        },
      }));
    }
    else if (formValue == DELETED) {
      setLazyState((state) => ({
        ...state,
        filters: {
          ...state.filters,
          IsDeleted: { value: "true", matchMode: "contains" },
        },
      }));
    }
    else {
      setLazyState((state) => ({
        ...state,
        filters: {
          ...state.filters,
          IsDeleted: { value: null, matchMode: "contains" },
        },
      }));
    }
  };

  const handleReset = (formValue: any) => {
    setLazyState((state) => ({
      ...state,
      filters: {
        ...state.filters,
        status: {
          ...state.filters.status,
          value: "",
        }
      },
    }));
  };

  useEffect(() => {
    debugger;
    let data: any = { ...lazyState };
    if (data.sortField == "eventDate") {

    }
    callbackFunction(lazyState);
  }, [lazyState]);

  useEffect(() => {
    const preloader: any = document.getElementById("preloader");
    if (loading || pdfLoading) {
      preloader.style.display = "block";
    } else {
      preloader.style.display = "none";
    }
  }, [loading, pdfLoading]);


  const handleDeleteUsers = (rowData: invoiceItemWiseRegisters, length: number = 0, index: number = 0) => {
    let payload = {
      id: rowData["id"],
      lazyState,
    };
    dispatch(deletePartsFromList(payload));
    setDeleteModal(false);
    setRowData(null);



    if (index + 1 === length || index === length) {
      setSelectAll(false);
      setSelectedParts([]);
      showToast("Part Deleted Successfully", { type: "success" });
      setTimeout(() => {
        dispatch(getInvoiceItemWiseRegistersList(lazyState));
      }, 500);
    }
  };

  const archiveHandler = () => {
    setSelectAll(false);
    setSelectedParts([]);
    setArchiveModal(false);
  };

  const defaultColumns = {
    ...columns[0]
  };

  const onColumnToggle = (event: MultiSelectChangeEvent) => {
    let selectedColumns = event.value;
    let orderedSelectedColumns = columns.filter((col) => selectedColumns.some((sCol: any) => sCol.field === col.field));

    setVisibleColumns([{ ...defaultColumns }, ...orderedSelectedColumns]);
  };

  const [showCustomizeTable, setShowCustomizeTable] = useState(false);
  const customizeTableClickHandler = () => {
    setShowCustomizeTable(true);
  };


  const [tooltipOpen, setTooltipOpen] = useState(false);

  const toggleTooltip = () => setTooltipOpen(!tooltipOpen);
  const [selectedStatus, setselectedStatus] = useState(ACTIVE);

  const bulkActionClickHandler = () => {
    if (modifiedValues.length > 0) {
      setSelectAll(true);
      setSelectedParts(modifiedValues);
    }
  };

  const cancelBulkActionHadler = () => {
    setSelectAll(false);
    setSelectedParts([]);
  };
  const customizeTableColumns: customizeTableColumns[] = columns.map((eachColumns: Columns) => {
    let data: any = {};
    data['field'] = eachColumns.field;
    data['value'] = visibleColumns.some((sCol: any) => sCol.field === eachColumns.field);
    data['isDisable'] = eachColumns.isStatic;
    data['header'] = eachColumns.header;
    return data;
  }).filter((eachColumns: customizeTableColumns) => eachColumns.field != 'select');
  const customizeTableCancelHandler = () => {
    setShowCustomizeTable(false);
  };
  const customizeColumnSubmitHandler = (customizeColumnObject: any[]) => {
    let orderedSelectedColumns = columns.filter((col) => customizeColumnObject.some((sCol: any) => sCol === col.field));

    setVisibleColumns([...orderedSelectedColumns]);
    setShowCustomizeTable(false);
  };

  const clearFilter = () => {
    setLazyState(initialLazyState);
  };
  const header =
    <div className="table-header-container">
      <div>Invoice Item Wise Registers</div>

      <div className="flex-grow-1"></div>
      <div className="table-header-container-item">
        <ExportFile exportExcel={csvClickHandler} exportPdf={pdfClickHandler} clearFilter={clearFilter} isFilter={true} />

        <Button type="button" className="customize-table-button mt-1" id="ScheduleUpdateTooltip"
          onMouseEnter={() => setTooltipOpen(true)}
          onMouseLeave={() => setTooltipOpen(false)} data-btn="btn" onClick={customizeTableClickHandler} style={{ paddingLeft: "0px" }}>
          <FeatherIcon icon="sliders" />
        </Button>
        <UncontrolledTooltip
          placement="bottom"
          target="ScheduleUpdateTooltip"
          isOpen={tooltipOpen}
          toggle={toggleTooltip}
          innerClassName="tooltip-color"
        >
          Choose fields you want to see in the table
        </UncontrolledTooltip>
      </div>

    </div>;

  return (
    <>
      <div className="page-content">
        {visible && dialogContainer(error)}
        <DeleteModal
          show={deleteModal}
          onDeleteClick={() => {
            if (rowData) {
              return handleDeleteUsers(rowData);
            }
            if (selectedParts?.length > 10) {
              showToast("Cannot delete more than 10 items at once", { type: "error" });
              setSelectedParts([]);
              setDeleteModal(false);
              setSelectAll(false);
              return setRowData(null);
            }

            if (selectedParts.length > 0) {
              for (let i = 0; i < selectedParts.length; i++) {
                handleDeleteUsers(selectedParts[i], selectedParts.length, i);
              }
            }
            // if (rowData) {
            //   handleDeleteUsers(rowData);
            // }
          }}
          onCloseClick={() => {
            setDeleteModal(false);
            setRowData(null);
          }}
        />
        <ArchiveModal
          show={archiveModal}
          message="you want to archieve these parts?"
          deleteLabel="Yes"
          closeLabel="No"
          onDeleteClick={() => {
            archiveHandler();
          }}
          onCloseClick={() => {
            setArchiveModal(false);
            setSelectAll(false);
            setSelectedParts([]);
          }}
        />
        {showCustomizeTable && <CustomizeTable onCancelHandler={customizeTableCancelHandler} onCustomizeColumnSubmitHandler={customizeColumnSubmitHandler} columns={customizeTableColumns} />}

        {filter && (
          <div className="card">
            <div className="card-body">
              <Formik
                initialValues={initialValues}
                validationSchema={validationSchema}
                onSubmit={handleSubmit}
                onReset={handleReset}
                enableReinitialize
              >
                {({ values, handleChange }) => {
                  return (
                    <Form onChange={handleChange}>
                      <Row>
                        <Col xs={12} lg={7} id="export-search-clear">

                        </Col>
                        <Col xs={12} lg={5} id="export-search-clear d-flex">
                          <Select
                            label=""
                            name="status"
                            onBlur={handleStatusBlur}
                            value={values.status}
                            onChange={handleChange}
                            options={options}
                            invalid={false}
                          />
                          <Button className="btn btn-primary ms-2" color="primary" type="reset">
                            Clear </Button>
                          <Button className="btn btn-primary ms-2" color="primary" type="submit">
                            Search </Button>
                        </Col>


                      </Row>
                    </Form>
                  );
                }}
              </Formik>
            </div>
          </div>
        )}
        <DataTableWrapper
          ref={dt}
          columnDetails={visibleColumns}
          header={header}
          value={modifiedValues}
          lazy
          paginator={true}
          stripedRows={true}
          filterIcon={FilterIcon}
          rowHover={true}
          // filterDisplay="row"
          sortIcon={(setOrder) => <SortIcon order={setOrder} />}
          rowsPerPageOptions={[5, 10, 25, 50]}
          totalRecords={totalRecords}
          loading={loading}
          emptyMessage="No invoice item wise registers found."
          paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
          currentPageReportTemplate="Displaying Invoice item wise register  {last} records of {totalRecords} in total"
          selection={selectedParts}
          onSelectionChange={onSelectionChange}
          selectAll={selectAll}
          onSelectAllChange={onSelectAllChange}
          first={lazyState.first}
          onSort={onSort}
          sortField={lazyState.sortField}
          sortOrder={lazyState.sortOrder}
          onFilter={onFilter}
          filters={lazyState.filters}
          onPage={onPage}
          rows={lazyState.rows}
        ></DataTableWrapper>
      </div>
    </>
  );
}
