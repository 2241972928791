import { FieldArray, Form, Formik } from 'formik';
import React, { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router';
import { Card, CardBody, Col, Container, Row } from 'reactstrap';
import Breadcrumb from 'src/components/Common/Breadcrumb';
import InputCom from 'src/components/Common/Input';
import { useToast } from 'src/components/Common/ReactToaster';
import ValidationPopup from 'src/components/Common/ValidationMessage/ValidationPopup';
import Button from 'src/components/UI/Button';
import CheckboxInput from 'src/components/UI/CheckboxInput';
import DropdownInput from 'src/components/UI/DropdownInput';
import TooltipMEL from 'src/components/UI/Tooltip';
import { ROUTER } from 'src/constants/routes';
import {
  clearCourierMessage,
  createCourierLoading,
  dealerListLoading,
  employeeDropdownForJobLoading,
  getContactDetailsByRecievedUserLoading,
  getContactDetailsByUSerIDLoading,
  getCustomerDropForInvoiceLoading,
  getCustomerReferenceNumber,
  getDealerDropForInvoiceLoading,
  getMelEmployeeAddressLoading,
  getTechnicianDropdownLoading,
  getbyIdCourierDetailsLoading,
  resetCourierState,
  resetMelEmployeeAddress,
  resetSenderData,
  updateCourierLoading,
  viewCourierLoading,
} from 'src/store/actions';
import { RootReducerState } from 'src/store/reducers';
import * as Yup from "yup";
import { convertToDateTimeLocalMEL } from 'src/helpers/common_helpers';

const AddEditCourier = () => {
  const [visible, setVisible] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [senderDropdown, setSenderDropdown] = useState<any>([]);
  const [receiverDropdown, setReceiverDropdown] = useState<any>([]);

  const { error, message, loading, courierInformation, companyList, contactDetailsList, contactSetFieldValue, senderType, currentSender, currentReceiver, receivercontactDetailsList, receivercontactSetFieldValue, receiverType, melEmployeeAddress, melEmplyeesetFieldValue, currentTypeSelected,customerReferenceNumberList } = useSelector((state: RootReducerState) => state.courierReducer);

  
  //dealerList

  //franchise
  // const { franchiseList } = useSelector((state: RootReducerState) => state.intentReducer)
  //customer
  const { customerList, dealerList } = useSelector((state: RootReducerState) => state.invoiceReducer);
  //employee
  const { employeeList, technicianList } = useSelector((state: RootReducerState) => state.jobsheetReducer);

  const { id } = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { showToast } = useToast();

  const receivercustomer = useMemo(() => {
    if (customerList?.length > 0) {
      return customerList
        .filter((val: any) => !val.isDeleted)
        .map((item: any) => ({
          label: item.name,
          value: item.id,
        }))
        .sort((a: any, b: any) => a.label.toUpperCase().trim().localeCompare(b.label.toUpperCase().trim()));
    }
    return [];
  }, [customerList]);

  const receiverdealer = useMemo(() => {
    if (dealerList?.length > 0) {
      return dealerList
        .filter((val: any) => !val.isDeleted)
        .map((item: any) => ({
          label: item.name,
          value: item.id,
        }))
        .sort((a: any, b: any) => a.label.toUpperCase().trim().localeCompare(b.label.toUpperCase().trim()));
    }
    return [];
  }, [dealerList]);

  const receiverfranchise = useMemo(() => {
    if (technicianList?.length > 0) {
      return technicianList
        .filter((val: any) => !val.isDeleted)
        .map((item: any) => ({
          label: item.name,
          value: item.franchiseId,
        }))
        .sort((a: any, b: any) => a.label.toUpperCase().trim().localeCompare(b.label.toUpperCase().trim()));
    }
    return [];
  }, [technicianList]);

  const AssigntheValue = (receivertype: string) => {
    if (receivertype === "customer") {
      setReceiverDropdown(receivercustomer);
    } else if (receivertype === "dealer") {
      setReceiverDropdown(receiverdealer);
    } else if (receivertype === "franchise") {
      setReceiverDropdown(receiverfranchise);
    }
  };

  const AsssignSenderDropGetValue = (receivertype: string) => {
    debugger
    if (receivertype === "customer") {
      setSenderDropdown(receivercustomer);
    } else if (receivertype === "dealer") {
      setSenderDropdown(receiverdealer);
    } else if (receivertype === "franchise") {
      setSenderDropdown(receiverfranchise);
    }
  };


  useEffect(() => {
    debugger
    // if(courierInformation?.["packageReceiverId"] && courierInformation?.["consigneeUserType"] && courierInformation?.["shipperUserType"]){
      AssigntheValue(courierInformation?.["consigneeUserType"]);
      AsssignSenderDropGetValue(courierInformation?.["shipperUserType"]);
    // }
  }, [courierInformation,customerList,dealerList,technicianList]);

  useEffect(() => {
    dispatch(dealerListLoading());
    dispatch(getCustomerDropForInvoiceLoading());
    dispatch(getDealerDropForInvoiceLoading());
    dispatch(employeeDropdownForJobLoading());
    dispatch(getTechnicianDropdownLoading());
    dispatch(getCustomerReferenceNumber());
    if (id) {
      dispatch(viewCourierLoading(id));
    }
  }, []);


  const productCode = [
    { value: "A", label: "A" },
    { value: "D", label: "D" },
    { value: "E", label: "E" },
  ];

  const packType = [
    { value: "N", label: "N" },
    { value: "T", label: "T" },
  ];

  const smartRProductCode = [
    { value: "ACC", label: "ACC" },
    { value: "ACP", label: "ACP" },
    { value: "ACR", label: "ACR" },
    { value: "APF", label: "APF - Documents" },
    { value: "APP", label: "APP - Non Documents" },
    { value: "AKP", label: "AKP - Air Cargo commercial shipments" },
    { value: "WKO", label: "WKO" },
  ];

  const NAProductCode = [
    { value: "N/A", label: "N/A" },
  ];

  const SendAndRecieverType = [
    { label: "Mel", value: "employee" },
    { label: "Customer", value: "customer" },
    { label: "Dealer", value: "dealer" },
    { label: "franchise", value: "franchise" },
  ];

  const companyDropdown = companyList?.map((item: any) => ({
    label: item.name,
    value: item.name
  })).sort((a: any, b: any) => {
    const labelA = a.label.toUpperCase().trim();
    const labelB = b.label.toUpperCase().trim();
    if (labelA < labelB) {
      return -1;
    }
    if (labelA > labelB) {
      return 1;
    }
    return 0;
  });

  const customerReferenceNumberOption = customerReferenceNumberList?.map((item:any)=>({
    label:item.customerCode,
    value:item.customerCode
  }))


  // const AssigntheValue = (receivertype: any): any => {
  //   if (receivertype == "customer" && customerList?.length > 0) {
  //     const customer = customerList?.filter((val: any) => val.isDeleted != true)?.map((item: any) => ({
  //       label: item.name,
  //       value: item.id
  //     })).sort((a: any, b: any) => {
  //       const labelA = a.label.toUpperCase().trim();
  //       const labelB = b.label.toUpperCase().trim();
  //       if (labelA < labelB) {
  //         return -1;
  //       }
  //       if (labelA > labelB) {
  //         return 1;
  //       }
  //       return 0;
  //     });
  //     setReceiverDropdown(customer);
  //   } else if (receivertype == "dealer" && dealerList?.length > 0) {
  //     const dealer = dealerList?.filter((val: any) => val.isDeleted != true)?.map((item: any) => ({
  //       label: item.name,
  //       value: item.id
  //     })).sort((a: any, b: any) => {
  //       const labelA = a.label.toUpperCase().trim();
  //       const labelB = b.label.toUpperCase().trim();
  //       if (labelA < labelB) {
  //         return -1;
  //       }
  //       if (labelA > labelB) {
  //         return 1;
  //       }
  //       return 0;
  //     });
  //     setReceiverDropdown(dealer);
  //   } else if (receivertype == "franchise" && technicianList?.length > 0) {
  //     const franchise = technicianList?.filter((val: any) => val.isDeleted != true)?.map((item: any) => ({
  //       label: item.name,
  //       value: item.franchiseId
  //     })).sort((a: any, b: any) => {
  //       const labelA = a.label.toUpperCase().trim();
  //       const labelB = b.label.toUpperCase().trim();
  //       if (labelA < labelB) {
  //         return -1;
  //       }
  //       if (labelA > labelB) {
  //         return 1;
  //       }
  //       return 0;
  //     });
  //     setReceiverDropdown(franchise);
  //   }
  // };

  // const AsssignSenderDropGetValue = (receivertype: any): any => {
  //   debugger
  //   if (receivertype == "customer" && customerList?.length > 0) {
  //     const customer = customerList?.filter((val: any) => val.isDeleted != true)?.map((item: any) => ({
  //       label: item.name,
  //       value: item.id
  //     })).sort((a: any, b: any) => {
  //       const labelA = a.label.toUpperCase().trim();
  //       const labelB = b.label.toUpperCase().trim();
  //       if (labelA < labelB) {
  //         return -1;
  //       }
  //       if (labelA > labelB) {
  //         return 1;
  //       }
  //       return 0;
  //     }).sort((a: any, b: any) => {
  //       const labelA = a.label.toUpperCase().trim();
  //       const labelB = b.label.toUpperCase().trim();
  //       if (labelA < labelB) {
  //         return -1;
  //       }
  //       if (labelA > labelB) {
  //         return 1;
  //       }
  //       return 0;
  //     });
  //     setSenderDropdown(customer);
  //   } else if (receivertype == "dealer" && dealerList?.length > 0) {
  //     const dealer = dealerList?.filter((val: any) => val.isDeleted != true)?.map((item: any) => ({
  //       label: item.name,
  //       value: item.id
  //     })).sort((a: any, b: any) => {
  //       const labelA = a.label.toUpperCase().trim();
  //       const labelB = b.label.toUpperCase().trim();
  //       if (labelA < labelB) {
  //         return -1;
  //       }
  //       if (labelA > labelB) {
  //         return 1;
  //       }
  //       return 0;
  //     });
  //     setSenderDropdown(dealer);
  //   } else if (receivertype == "franchise" && technicianList?.length > 0) {
  //     const franchise = technicianList?.filter((val: any) => val.isDeleted != true)?.map((item: any) => ({
  //       label: item.name,
  //       value: item.franchiseId
  //     })).sort((a: any, b: any) => {
  //       const labelA = a.label.toUpperCase().trim();
  //       const labelB = b.label.toUpperCase().trim();
  //       if (labelA < labelB) {
  //         return -1;
  //       }
  //       if (labelA > labelB) {
  //         return 1;
  //       }
  //       return 0;
  //     });
  //     setSenderDropdown(franchise);
  //   }
  // };



 


  const courierDetailFiltered = (values: any) => {
    return values.filter((value: any) => value.isDeleted !== true);
  };
  const initialvalue = useMemo(()=>{
    debugger
     return {
      courierMasterId: courierInformation?.["company"] || "",
      otherCompany: courierInformation?.["otherCompany"] || "",
      senderType: courierInformation?.["shipperUserType"] || "",
      recieverType: courierInformation?.["consigneeUserType"] || "",
      senderAddress: courierInformation?.["shipperAddress"] || "",
      senderName: "",
      reciverAddress: courierInformation?.["consigneeAddress"] || "",
      receiverName: "",
      packageSentAt: courierInformation?.["packageSentAt"] ? convertToDateTimeLocalMEL(courierInformation?.["packageSentAt"]) : "",
      packageSenderId: senderDropdown ? courierInformation?.["packageSenderId"] :   "",
      packageReceiverId: receiverDropdown ? courierInformation?.["packageReceiverId"] :   "",
      pickupRequestAt: courierInformation?.["pickupRequestAt"] ? convertToDateTimeLocalMEL(courierInformation?.["pickupRequestAt"]) : "",
      creditReferenceNumber: courierInformation?.["creditReferenceNumber"] || "",
      shipperKindAttention: courierInformation?.["shipperKindAttention"] ?? "",
      shipperEdlPinCode: courierInformation?.["shipperEdlPinCode"] ?? "",
      shipperContactMobile: courierInformation?.["shipperContactMobile"] ?? "",
      kindAttention: courierInformation?.["kindAttention"] ?? "",
      edlPinCode: courierInformation?.["edlPinCode"] ?? "",
      contactMobile: courierInformation?.["contactMobile"] ?? "",
      productCode: courierInformation?.["productCode"] || "",
      shipmentWeight: courierInformation?.["shipmentWeight"] || "",
      declaredValue: courierInformation?.["declaredValue"] || "",
      awbNumber: courierInformation?.["awbNumber"] || "",
      tokenNumber: courierInformation?.["tokenNumber"] || "",
      commodity: courierInformation?.["commodity"] || "Electronic Goods",
      specialInstruction: courierInformation?.["specialInstruction"] || "",
      isToPayCustomer: courierInformation?.["isToPayCustomer"] || false,
      courierPackageDetails: courierInformation?.["courierPackageDetails"] ? courierDetailFiltered(courierInformation?.["courierPackageDetails"]) : [{
        length: "",
        breadth: "",
        height: "",
        weight: "",
        numberOfPkgs: "",
      }],
      consigneeCity: courierInformation?.["consigneeCity"] || "",
      consigneeState: courierInformation?.["consigneeState"] || "",
      shipperState: courierInformation?.["shipperCity"] || "",
      shipperCity: courierInformation?.["shipperCity"] || "",
      packType: courierInformation?.["packType"] || "",
      ewayBillNo: courierInformation?.["ewayBillNo"] || "",
      shipperGSTNo: courierInformation?.["shipperGSTNo"] || "",
      isSurface: courierInformation?.["isSurface"] || false,
      customerCode: courierInformation?.["customerCode"] || "",
    };
  },[courierInformation,senderDropdown,receiverDropdown])
  
  const smartrCompany = "Smartr";
  const blueDartCompany = "Blue Dart";
  const blueDartEComCompany = "Blue Dart eCOM";

  const validationSchema = Yup.object().shape({
    courierMasterId: Yup.string().required('Company is required'),
    otherCompany: Yup.string(),
    senderType: Yup.string(),
    consigneeCity: Yup.string(),
    consigneeState: Yup.string(),
    shipperState: Yup.string(),
    shipperCity: Yup.string(),
    reciverAddress: Yup.string(),
    shipperContactMobile: Yup.string().required("Shipper Contact Mobile is required").matches(/^\d{10}$/, "Shipper Contact Mobile must be 10 digits"),
    contactMobile: Yup.string().required("Consignee Contact Mobile is required").matches(/^\d{10}$/, "Consignee Contact Mobile must be 10 digits"),
    // recieverType: Yup.string().notOneOf([Yup.ref("senderType")], "Receiver type must not be the same as sender type"),
    pickupRequestAt: Yup.string().required('Pickup Request At is required'),
    shipmentWeight: Yup.number().required('Shipment Weight is required').positive('Shipment Weight must be a positive number').max(9999, "Shipment weight must consist of four digits").test(
      'is-decimal',
      'Shipment Weight must have no more than two decimal places',
      (value:any) => /^\d+(\.\d{1,2})?$/.test(value)
  ),
    edlPinCode: Yup.number(),
    creditReferenceNumber: Yup.string()
      .required('Credit Reference Number is required')
      .matches(/^[a-zA-Z0-9]*$/, 'Credit Reference Number must not contain special characters'),
    productCode: Yup.string().required('Product Code is required'),
    declaredValue: Yup.number().required('Declared Value is required').max(99999, "Declare value must consist of five digits"),
    ewayBillNo: Yup.string().when(['courierMasterId', 'productCode'], {
      is: (courierMasterId:any, productCode:any) => 
        courierMasterId === smartrCompany && (productCode === 'AKP' || productCode === 'WKO'),
      then: schema => schema.required('Eway Bill Number is required'),
      otherwise: schema => schema.notRequired(),
    }),
    customerCode: Yup.string().when(['courierMasterId'], {
      is: (courierMasterId:any) => 
        courierMasterId === blueDartCompany || courierMasterId ==blueDartEComCompany,
      then: schema => schema.required('Customer Code is required'),
      otherwise: schema => schema.notRequired(),
    }),
    
    shipperGSTNo: Yup.string().when(['courierMasterId', 'productCode'], {
      is: (courierMasterId:any, productCode:any) =>
        courierMasterId === smartrCompany && (productCode === 'AKP' || productCode === 'WKO'),
      then: schema =>
        schema
          .required('Shipper GST Number is required')
          .matches(
            /^[0-9]{2}[A-Z]{5}[0-9]{4}[A-Z]{1}[1-9A-Z]{1}[Z]{1}[0-9A-Z]{1}$/,
            'Invalid GST number format'
          ),
      otherwise: schema => schema.notRequired(),
    }),
    shipperEdlPinCode: Yup.number(),
    senderAddress: Yup.string(),
    courierPackageDetails: Yup.array().of(
      Yup.object().shape({
        length: Yup.number().required('Length is required').positive('length must be a positive number').max(9999, "Length value must consist of four digits"),
        breadth: Yup.number().required('Breadth is required').positive('Breadth must be a positive number').max(9999, "breadth value must consist of four digits"),
        height: Yup.number().required('Height is required').positive('Height must be a positive number').max(9999, "height value must consist of four digits"),
        numberOfPkgs: Yup.number().required('Number Of Pkgs is required').positive('Number Of Pkgs must be a positive number').max(9999, "Number of package value must consist of four digits"),
        weight: Yup.number().required('weight is required').min(0,'weight must be a positive number').max(9999, "weight must consist of four digits")
      }))
  })
  // .test({
  //   name: 'one-field-required',
  //   test: function (value: any) {
  //     const { senderType, recieverType } = value;
  //     if (((senderType != "employee") && (recieverType != "employee"))) {
  //       return this.createError({
  //         path: 'recieverType',
  //         message: 'Either sender name or receiver type must be the mel employee',
  //       });
  //     }
  //     return true;
  //   },
  // })
  .test({
    name: "change-field-required Shipper EDL PinCode",
    test: function (value: any) {
      const { packageSenderId, senderType, shipperEdlPinCode } = value;
      if (senderType == "customer") {
        const customer = customerList.filter((data) => data.id == packageSenderId);
        const pincodeRegex = /\b\d{6}\b/;
        const address = customer[0]?.address?.match(pincodeRegex);
        if (address == null && !shipperEdlPinCode) {
          return this.createError({
            path: 'shipperEdlPinCode',
            message: 'Required Shipper EDL PinCode',
          });
        }
      } else if (senderType == "dealer") {
        const dealers = dealerList.filter((data) => data.id == packageSenderId);
        const pincodeRegex = /\b\d{6}\b/;
        const address = dealers[0]?.address?.match(pincodeRegex);
        if (address == null && !shipperEdlPinCode) {
          return this.createError({
            path: 'shipperEdlPinCode',
            message: 'Required Shipper EDL PinCode',
          });
        }
      } else if (senderType == "franchise") {
        const franchisee = technicianList.filter((data) => data.franchiseId == packageSenderId);
        const pincodeRegex = /\b\d{6}\b/;
        const address = franchisee[0]?.address?.match(pincodeRegex);
        if (address == null && !shipperEdlPinCode) {
          return this.createError({
            path: 'shipperEdlPinCode',
            message: 'Required Shipper EDL PinCode',
          });
        }
      } else if (senderType == "employee") {
        const employees = employeeList.filter((data) => data.id == packageSenderId);
        const pincodeRegex = /\b\d{6}\b/;
        const address = employees[0]?.address?.match(pincodeRegex);
        if (address == null && !shipperEdlPinCode) {
          return this.createError({
            path: 'shipperEdlPinCode',
            message: 'Required Shipper EDL PinCode',
          });
        }
      }
    }
  }).test({
    name: "change-field-required Sender Address",
    test: function (value: any) {
      const { packageSenderId, senderType, senderAddress } = value;
      if (senderType == "customer") {
        const customer = customerList.filter((data) => data.id == packageSenderId);
        const address = customer[0]?.address;
        if ((address == null || address == "") && !senderAddress) {
          return this.createError({
            path: 'senderAddress',
            message: 'Required Sender Address field',
          });
        }
      } else if (senderType == "dealer") {
        const dealer = dealerList.filter((data) => data.id == packageSenderId);
        const address = dealer[0]?.address;
        if ((address == null || address == "") && !senderAddress) {
          return this.createError({
            path: 'senderAddress',
            message: 'Required Sender Address field',
          });
        }
      } else if (senderType == "franchise") {
        const franchisee = technicianList.filter((data) => data.franchiseId == packageSenderId);
        const address = franchisee[0]?.address;
        if ((address == null || address == "") && !senderAddress) {
          return this.createError({
            path: 'senderAddress',
            message: 'Required Sender Address field',
          });
        }
      } else if (senderType == "employee") {
        const employees = employeeList.filter((data) => data.id == packageSenderId);
        const address = employees[0]?.address;
        if ((address == null || address == "") && !senderAddress) {
          return this.createError({
            path: 'senderAddress',
            message: 'Required Sender Address field',
          });
        }
      }
    }
  }).test({
    name: "change-field-required edlPinCode",
    test: function (value: any) {
      const { packageReceiverId, recieverType, edlPinCode } = value;
      if (recieverType == "customer") {
        const customer = customerList.filter((data) => data.id == packageReceiverId);
        const pincodeRegex = /\b\d{6}\b/;
        const address = customer[0]?.address?.match(pincodeRegex);
        if (address == null && !edlPinCode) {
          return this.createError({
            path: 'edlPinCode',
            message: 'Required Consignee EDL Pin Code field',
          });
        }
      } else if (recieverType == "dealer") {
        const dealers = dealerList.filter((data) => data.id == packageReceiverId);
        const pincodeRegex = /\b\d{6}\b/;
        const address = dealers[0]?.address?.match(pincodeRegex);
        if (address == null && !edlPinCode) {
          return this.createError({
            path: 'edlPinCode',
            message: 'Required Consignee EDL Pin Code field',
          });
        }
      } else if (recieverType == "franchise") {
        const franchisee = technicianList.filter((data) => data.franchiseId == packageReceiverId);
        const pincodeRegex = /\b\d{6}\b/;
        const address = franchisee[0]?.address?.match(pincodeRegex);
        if (address == null && !edlPinCode) {
          return this.createError({
            path: 'edlPinCode',
            message: 'Required Consignee EDL Pin Code field',
          });
        }
      } else if (recieverType == "employee") {
        const employees = employeeList.filter((data) => data.id == packageReceiverId);
        const pincodeRegex = /\b\d{6}\b/;
        const address = employees[0]?.address?.match(pincodeRegex);
        if (address == null && !edlPinCode) {
          return this.createError({
            path: 'edlPinCode',
            message: 'Required Consignee EDL Pin Code field',
          });
        }
      }
    }
  }).test({
    // reciverAddress
    name: "change-field-required Receiver Address ",
    test: function (value: any) {
      const { packageReceiverId, recieverType, reciverAddress } = value;
      if (recieverType == "customer") {
        const customer = customerList.filter((data) => data.id == packageReceiverId);
        const address = customer[0]?.address;
        if ((address == null || address == "") && !reciverAddress) {
          return this.createError({
            path: 'reciverAddress',
            message: 'Required receiver Address field',
          });
        }
      } else if (recieverType == "dealer") {
        const dealer = dealerList.filter((data) => data.id == packageReceiverId);
        const address = dealer[0]?.address;
        if ((address == null || address == "") && !reciverAddress) {
          return this.createError({
            path: 'reciverAddress',
            message: 'Required receiver Address field',
          });
        }
      } else if (recieverType == "franchise") {
        const franchisee = technicianList.filter((data) => data.franchiseId == packageReceiverId);
        const address = franchisee[0]?.address;
        if ((address == null || address == "") && !reciverAddress) {
          return this.createError({
            path: 'reciverAddress',
            message: 'Required receiver Address field',
          });
        }
      } else if (recieverType == "employee") {
        const employees = employeeList.filter((data) => data.id == packageReceiverId);
        const address = employees[0]?.address;
        if ((address == null || address == "") && !reciverAddress) {
          return this.createError({
            path: 'reciverAddress',
            message: 'Required receiver Address field',
          });
        }
      }
    }
  }).test({
    name: "change-field-required Other Company field is required",
    test: function (value: any) {
      const { courierMasterId, otherCompany } = value;
      const isOtherCompanyrequired = companyDropdown?.filter((data: any) => data.value == courierMasterId && data.label == "Other").length > 0;
      if (isOtherCompanyrequired && !otherCompany) {
        return this.createError({
          path: 'otherCompany',
          message: 'Other Company field is required',
        });
      }
    }
  }).test({
    name: "change-field-required receiver city field is required",
    test: function (value: any) {
      const { courierMasterId, consigneeCity, packageSenderId, senderType, senderAddress } = value;
      // const isOtherCompanyrequired = companyDropdown?.filter((data: any) => data.value == courierMasterId && data.label == "Other").length > 0;
      const isOtherCompanyrequired = companyDropdown?.filter((data: any) => data.value == courierMasterId && (data.label == "Blue Dart" || data.label == "Smartr")).length > 0;
      if (isOtherCompanyrequired && !consigneeCity) {
        return this.createError({
          path: 'consigneeCity',
          message: 'Receiver city field is required',
        });
      }
    }
  }).test({
    name: "change-field-required receiver state field is required",
    test: function (value: any) {
      const { courierMasterId, consigneeState } = value;
      // const isOtherCompanyrequired = companyDropdown?.filter((data: any) => data.value == courierMasterId && data.label == "Other").length > 0
      const isOtherCompanyrequired = companyDropdown?.filter((data: any) => data.value == courierMasterId && (data.label == "Blue Dart" || data.label == "Smartr")).length > 0;
      if (isOtherCompanyrequired && !consigneeState) {
        return this.createError({
          path: 'consigneeState',
          message: 'Receiver state field is required',
        });
      }
    }
  }).test({
    name: "change-field-required sender city field is required",
    test: function (value: any) {
      const { courierMasterId, shipperCity } = value;
      // const isOtherCompanyrequired = companyDropdown?.filter((data: any) => data.value == courierMasterId && data.label == "Other").length > 0
      const isOtherCompanyrequired = companyDropdown?.filter((data: any) => data.value == courierMasterId && (data.label == "Blue Dart" || data.label == "Smartr")).length > 0;
      if (isOtherCompanyrequired && !shipperCity) {
        return this.createError({
          path: 'shipperCity',
          message: 'Sender city field is required',
        });
      }
    }
  }).test({
    name: "change-field-required receiver state field is required",
    test: function (value: any) {
      const { courierMasterId, shipperState } = value;
      // const isOtherCompanyrequired = companyDropdown?.filter((data: any) => data.value == courierMasterId && data.label == "Other").length > 0
      const isOtherCompanyrequired = companyDropdown?.filter((data: any) => data.value == courierMasterId && (data.label == "Blue Dart" || data.label == "Smartr")).length > 0;
      if (isOtherCompanyrequired && !shipperState) {
        return this.createError({
          path: 'shipperState',
          message: 'Sender state field is required',
        });
      }
    }
  });


  const hidePopup = () => {
    setVisible(false);
    setIsSubmitting(false);
    dispatch(clearCourierMessage());
  };

  const dialogContainer = (error: any) => {
    return (
      <>
        <ValidationPopup error={error} visible={visible} onHide={hidePopup} />
      </>
    );
  };

  const handleSubmit = async (values: any) => {    
    const { contactMobile, shipperContactMobile, courierMasterId, senderType, recieverType, senderAddress, reciverAddress, awbNumber, tokenNumber, packageSentAt, pickupRequestAt, senderName, receiverName, edlPinCode, shipperEdlPinCode,isSurface,ewayBillNo,shipperGSTNo,customerCode, ...value } = values;

    let name: string | any = "";
    if (recieverType === "customer") {
      const customer = customerList.filter((data) => data.id == values.packageReceiverId);
      name = customer[0]?.name;
    } else if (recieverType === "employee") {
      const employee = employeeList.filter((data) => data.id == values.packageReceiverId);
      name = employee[0]?.name;
    } else if (recieverType === "dealer") {
      const dealer = dealerList.filter((data) => data.id == values.packageReceiverId);
      name = dealer[0]?.name;
    } else if (recieverType === "franchise") {
      const franchise = technicianList.filter((data) => data.franchiseId == values.packageReceiverId);
      name = franchise[0]?.name;

    }

    let stringContactMobile = String(contactMobile);
    let shipperContact = String(shipperContactMobile);
    debugger
    let obj = {
      contactMobile: stringContactMobile?.replace(/\s/g, ""),
      shipperContactMobile: shipperContact?.replace(/\s/g, ""),
      company: courierMasterId,
      packageSenderName: (senderType == "employee") ? "Mount Electronics" : senderName,
      packageReceiverName: (recieverType == "employee") ? "Mount Electronics" : name,
      shipperUserType: senderType,
      shipperAddress: senderAddress,
      consigneeUserType: recieverType,
      consigneeAddress: reciverAddress,
      awbPrintContent: "",
      awbNumber: awbNumber ? awbNumber : null,
      tokenNumber: tokenNumber ? tokenNumber : null,
      packageSentAt: packageSentAt ? packageSentAt : null,
      pickupRequestAt: pickupRequestAt ? pickupRequestAt : null,
      edlPinCode: Array.isArray(edlPinCode) ? edlPinCode[0]?.toString() : edlPinCode?.toString(),
      shipperEdlPinCode: Array.isArray(shipperEdlPinCode) ? shipperEdlPinCode[0]?.toString() : shipperEdlPinCode?.toString(),
      shipperGSTNo:shipperGSTNo,
      ewayBillNo:ewayBillNo,
      ...value
    };
    if((courierMasterId==blueDartCompany || courierMasterId == blueDartEComCompany)){
      obj.packType=values.packType
      obj.customerCode=customerCode
    }else{
      delete obj.packType
      delete obj.customerCode
    }
    if(courierMasterId==smartrCompany){
      obj.isSurface=isSurface
    }
    if (isSubmitting) return;
    if (!id) {
      dispatch(createCourierLoading(obj));
    } else {
      dispatch(updateCourierLoading(obj, id));
    }
    setIsSubmitting(true);
  };

  const handleReset = (resetForm: any) => {
    resetForm();
    if (id) {
      navigate(ROUTER.COURIER_LIST);
    }
  };

  const handleAddCourierDetails = (values: any, push: any) => {
    if (isCourierDetailFilled(values)) {
      push({
        length: "",
        breadth: "",
        height: "",
        numberOfPkgs: ""
      });
    }
  };

  const isCourierDetailFilled = (values: any) => {
    const courierDetails = values.courierPackageDetails || [];
    return courierDetails.every((courier: any) => {
      const { courierId, creationTime, creatorId, deleterId, deletionTime, id, isDeleted, lastModificationTime, lastModifierId, ...fieldsExceptAlternativeNumber } = courier;
      return Object.values(fieldsExceptAlternativeNumber).every(
        (value: any) => value !== null && value !== ""
      );
    });
  };

  useEffect(() => {
    const preloader: any = document.getElementById("preloader");
    if (loading) {
      preloader.style.display = "block";
    } else {
      preloader.style.display = "none";
    }
  }, [loading]);

  useEffect(() => {
    if (error) {
      setVisible(true);
      setIsSubmitting(false);
    }
    if (message) {
      showToast(message, { type: "success" });
      setTimeout(() => {
        setIsSubmitting(false);
        navigate(ROUTER.COURIER_LIST);
      }, 500);
      dispatch(clearCourierMessage());
      // dispatch(resetPurchaseOrder())
    }
  }, [error, message]);

  useEffect(() => {
    if (id) {
      dispatch(getbyIdCourierDetailsLoading(id));
    }
    return () => {
      dispatch(resetCourierState());
    };
  }, [id]);


  const resetPreviousValue = (setFieldValue: any) => {
    setFieldValue("senderName", "");
    setFieldValue("shipperContactMobile", "");
    setFieldValue("shipperEdlPinCode", "");
    setFieldValue("senderAddress", "");
    setFieldValue("shipperKindAttention", "");
    // setFieldValue("packageSenderId", "")
  };
  const resetReceiverPreviousValue = (setFieldValue: any) => {
    setFieldValue("kindAttention", "");
    setFieldValue("receiverName", "");
    setFieldValue("contactMobile", "");
    setFieldValue("edlPinCode", "");
    setFieldValue("reciverAddress", "");

  };


  const assignSenderDrop = (currentSelect: any, setFieldValue: any) => {
    
    if (currentSelect === "customer") {
      const customer = customerList?.filter((val: any) => val.isDeleted != true)?.map((item: any) => ({
        label: item.name,
        value: item.id
      })).sort((a: any, b: any) => {
        const labelA = a.label.toUpperCase().trim();
        const labelB = b.label.toUpperCase().trim();
        if (labelA < labelB) {
          return -1;
        }
        if (labelA > labelB) {
          return 1;
        }
        return 0;
      });
      setSenderDropdown(customer);
      resetPreviousValue(setFieldValue);
    } else if (currentSelect === "employee") {
      resetPreviousValue(setFieldValue);
      dispatch(getMelEmployeeAddressLoading(setFieldValue, "sender"));
      setFieldValue("packageSenderId", 0);
    } else if (currentSelect == "dealer") {
      const dealer = dealerList?.map((item: any) => ({
        label: item.name,
        value: item.id
      })).sort((a: any, b: any) => {
        const labelA = a.label.toUpperCase().trim();
        const labelB = b.label.toUpperCase().trim();
        if (labelA < labelB) {
          return -1;
        }
        if (labelA > labelB) {
          return 1;
        }
        return 0;
      });
      setSenderDropdown(dealer);
      resetPreviousValue(setFieldValue);
    } else if (currentSelect == "franchise") {
      const franchise = technicianList?.filter((val: any) => val.isDeleted != true)?.map((item: any) => ({
        label: item.name,
        value: item.franchiseId
      })).sort((a: any, b: any) => {
        const labelA = a.label.toUpperCase().trim();
        const labelB = b.label.toUpperCase().trim();
        if (labelA < labelB) {
          return -1;
        }
        if (labelA > labelB) {
          return 1;
        }
        return 0;
      });
      setSenderDropdown(franchise);
      resetPreviousValue(setFieldValue);
    }
  };

  const assignReceiverrDrop = (currentSelect: any, setFieldValue: any) => {
    
    if (currentSelect === "customer") {
      const customer = customerList?.filter((val: any) => val.isDeleted != true)?.map((item: any) => ({
        label: item.name,
        value: item.id
      })).sort((a: any, b: any) => {
        const labelA = a.label.toUpperCase().trim();
        const labelB = b.label.toUpperCase().trim();
        if (labelA < labelB) {
          return -1;
        }
        if (labelA > labelB) {
          return 1;
        }
        return 0;
      });
      resetReceiverPreviousValue(setFieldValue);
      setReceiverDropdown(customer);
    } else if (currentSelect === "employee") {
      resetReceiverPreviousValue(setFieldValue);
      setFieldValue("packageReceiverId", 0);
      dispatch(getMelEmployeeAddressLoading(setFieldValue, "receiver"));
    } else if (currentSelect == "dealer") {
      const dealer = dealerList?.filter((val: any) => val.isDeleted != true)?.map((item: any) => ({
        label: item.name,
        value: item.id
      })).sort((a: any, b: any) => {
        const labelA = a.label.toUpperCase().trim();
        const labelB = b.label.toUpperCase().trim();
        if (labelA < labelB) {
          return -1;
        }
        if (labelA > labelB) {
          return 1;
        }
        return 0;
      });
      resetReceiverPreviousValue(setFieldValue);
      setReceiverDropdown(dealer);
    } else if (currentSelect == "franchise") {
      const franchise = technicianList?.filter((val: any) => val.isDeleted != true)?.map((item: any) => ({
        label: item.name,
        value: item.franchiseId
      })).sort((a: any, b: any) => {
        const labelA = a.label.toUpperCase().trim();
        const labelB = b.label.toUpperCase().trim();
        if (labelA < labelB) {
          return -1;
        }
        if (labelA > labelB) {
          return 1;
        }
        return 0;
      });
      resetReceiverPreviousValue(setFieldValue);
      setReceiverDropdown(franchise);
    }
  };



  const checkWhichuserselected = (currentSenderType: any, selectedVaue: any, setFieldValue: any) => {
    let userId: string | any = "";
    if (currentSenderType === "customer") {
      const customer = customerList.filter((data) => data.id == selectedVaue);
      userId = customer[0]?.userId;
    } else if (currentSenderType === "employee") {
      // const employee = employeeList.filter((data) => data.id == selectedVaue);
      // userId = employee[0]?.userId
    } else if (currentSenderType === "dealer") {
      const dealer = dealerList.filter((data) => data.id == selectedVaue);
      userId = dealer[0]?.userId;
    } else if (currentSenderType === "franchise") {
      const franchise = technicianList.filter((data) => data.franchiseId == selectedVaue);
      userId = franchise[0]?.userId;

    }
    dispatch(getContactDetailsByUSerIDLoading(userId, setFieldValue, currentSenderType, selectedVaue));
  };

  // receiver api call  
  const checkWhichuserselectedReceiverType = (currentRecieverType: any, selectedVaue: any, setFieldValue: any) => {
    let userId: string | any = "";
    if (currentRecieverType === "customer") {
      const customer = customerList.filter((data) => data.id == selectedVaue);
      userId = customer[0]?.userId;
    } else if (currentRecieverType === "employee") {
      const employee = employeeList.filter((data) => data.id == selectedVaue);
      userId = employee[0]?.userId;
    } else if (currentRecieverType === "dealer") {
      const dealer = dealerList.filter((data) => data.id == selectedVaue);
      userId = dealer[0]?.userId;
    } else if (currentRecieverType === "franchise") {
      const franchise = technicianList.filter((data) => data.franchiseId == selectedVaue);
      userId = franchise[0]?.userId;

    }
    dispatch(getContactDetailsByRecievedUserLoading(userId, setFieldValue, currentRecieverType, selectedVaue));
  };

  // sender value assign without employee select
  useEffect(() => {
    if (contactDetailsList?.length == 0) {
      if (senderType == "customer") {
        const customer = customerList.filter((data) => data.id == currentSender);
        contactSetFieldValue("senderName", customer[0]?.name);
        contactSetFieldValue("shipperContactMobile", customer[0]?.phoneNumber ? customer[0]?.phoneNumber : "");
        const pincodeRegex = /\b\d{6}\b/;
        const address = customer[0]?.address?.match(pincodeRegex);
        if (address != null) {
          contactSetFieldValue("shipperEdlPinCode", address);
        } else {
          contactSetFieldValue("shipperEdlPinCode", "");
        }
        if (customer[0]?.address != "" && customer[0]?.address != null) {
          contactSetFieldValue("senderAddress", customer[0]?.address);
        } else {
          contactSetFieldValue("senderAddress", "");
        }
        contactSetFieldValue("shipperKindAttention", customer[0]?.name);
        dispatch(resetSenderData());
      } else if (senderType == "dealer") {
        const dealer = dealerList.filter((data) => data.id == currentSender);
        contactSetFieldValue("senderName", dealer[0]?.name);
        contactSetFieldValue("shipperContactMobile", dealer[0]?.phoneNumber ? dealer[0]?.phoneNumber : "");
        const pincodeRegex = /\b\d{6}\b/;
        const address = dealer[0]?.address?.match(pincodeRegex);
        if (address != null) {
          contactSetFieldValue("shipperEdlPinCode", address);
        } else {
          contactSetFieldValue("shipperEdlPinCode", "");
        }
        if (dealer[0]?.address != "" && dealer[0]?.address != null) {
          contactSetFieldValue("senderAddress", dealer[0]?.address);
        } else {
          contactSetFieldValue("senderAddress", "");
        }
        contactSetFieldValue("shipperKindAttention", dealer[0]?.name);
        dispatch(resetSenderData());
      } else if (senderType == "franchise") {
        const franchise = technicianList.filter((data) => data.franchiseId == currentSender);
        contactSetFieldValue("senderName", franchise[0]?.name);
        contactSetFieldValue("shipperContactMobile", franchise[0]?.phoneNumber ? franchise[0]?.phoneNumber : "");
        const pincodeRegex = /\b\d{6}\b/;
        const address = franchise[0]?.address?.match(pincodeRegex);
        if (address != null) {
          contactSetFieldValue("shipperEdlPinCode", address);
        } else {
          contactSetFieldValue("shipperEdlPinCode", "");
        }
        if (franchise[0]?.address != "" && franchise[0]?.address != null) {
          contactSetFieldValue("senderAddress", franchise[0]?.address);
        } else {
          contactSetFieldValue("senderAddress", "");
        }

        contactSetFieldValue("shipperKindAttention", franchise[0]?.name);
        dispatch(resetSenderData());
      }
    }
    else {
      if (senderType == "customer") {
        const customer = customerList.filter((data) => data.id == currentSender);
        contactSetFieldValue("senderName", customer[0]?.name);
        contactSetFieldValue("shipperContactMobile", contactDetailsList[0]?.mobileNumber ? contactDetailsList[0]?.mobileNumber : "");
        const pincodeRegex = /\b\d{6}\b/;
        const address = customer[0]?.address?.match(pincodeRegex);
        if (address != null) {
          contactSetFieldValue("shipperEdlPinCode", address);
        } else {
          contactSetFieldValue("shipperEdlPinCode", "");
        }
        if (customer[0]?.address != "" && customer[0]?.address != null) {
          contactSetFieldValue("senderAddress", customer[0]?.address);
        } else {
          contactSetFieldValue("senderAddress", "");
        }
        contactSetFieldValue("shipperKindAttention", contactDetailsList[0]?.name);
        dispatch(resetSenderData());
      } else if (senderType == "dealer") {
        const dealer = dealerList.filter((data) => data.id == currentSender);
        contactSetFieldValue("senderName", dealer[0]?.name);
        const pincodeRegex = /\b\d{6}\b/;
        const address = dealer[0]?.address?.match(pincodeRegex);
        if (address != null) {
          contactSetFieldValue("shipperEdlPinCode", address);
        } else {
          contactSetFieldValue("shipperEdlPinCode", "");
        }
        if (dealer[0]?.address != "" && dealer[0]?.address != null) {
          contactSetFieldValue("senderAddress", dealer[0]?.address);
        } else {
          contactSetFieldValue("senderAddress", "");
        }
        contactSetFieldValue("shipperContactMobile", contactDetailsList[0]?.mobileNumber ? contactDetailsList[0]?.mobileNumber : "");
        contactSetFieldValue("shipperKindAttention", contactDetailsList[0]?.name);
        dispatch(resetSenderData());
      } else if (senderType == "franchise") {
        const franchise = technicianList.filter((data) => data.franchiseId == currentSender);
        contactSetFieldValue("senderName", franchise[0]?.name);
        const pincodeRegex = /\b\d{6}\b/;
        const address = franchise[0]?.address?.match(pincodeRegex);
        if (address != null) {
          contactSetFieldValue("shipperEdlPinCode", address);
        } else {
          contactSetFieldValue("shipperEdlPinCode", "");
        }
        if (franchise[0]?.address != "" && franchise[0]?.address != null) {
          contactSetFieldValue("senderAddress", franchise[0]?.address);
        } else {
          contactSetFieldValue("senderAddress", "");
        }
        contactSetFieldValue("shipperContactMobile", contactDetailsList[0]?.mobileNumber ? contactDetailsList[0]?.mobileNumber : "");
        contactSetFieldValue("shipperKindAttention", contactDetailsList[0]?.name);
        dispatch(resetSenderData());
      }
    }
  }, [contactDetailsList]);





  //mel employee address bind
  useEffect(() => {
    if (currentTypeSelected == "sender") {
      melEmplyeesetFieldValue("senderAddress", melEmployeeAddress?.melAddress);
      melEmplyeesetFieldValue("shipperEdlPinCode", melEmployeeAddress?.melAddressPinCode);
      melEmplyeesetFieldValue("shipperContactMobile", melEmployeeAddress?.melMobileNumber?.split(' ')?.join(''));
      melEmplyeesetFieldValue("shipperKindAttention", "Mel");
      dispatch(resetMelEmployeeAddress());
    } else if (currentTypeSelected == "receiver") {
      melEmplyeesetFieldValue("reciverAddress", melEmployeeAddress?.melAddress);
      melEmplyeesetFieldValue("edlPinCode", melEmployeeAddress?.melAddressPinCode);
      melEmplyeesetFieldValue("contactMobile", melEmployeeAddress?.melMobileNumber?.split(' ')?.join(''));
      melEmplyeesetFieldValue("kindAttention", "Mel");
      dispatch(resetMelEmployeeAddress());
    }
  }, [melEmployeeAddress]);





  //receiver value assign
  useEffect(() => {
    if (receivercontactDetailsList?.length == 0) {
      if (receiverType == "customer") {
        const customer = customerList.filter((data) => data.id == currentReceiver);
        receivercontactSetFieldValue("kindAttention", customer[0]?.name);
        receivercontactSetFieldValue("receiverName", customer[0]?.name);
        receivercontactSetFieldValue("contactMobile", customer[0]?.phoneNumber);
        const pincodeRegex = /\b\d{6}\b/;
        const address = customer[0]?.address?.match(pincodeRegex);
        if (address != null) {
          receivercontactSetFieldValue("edlPinCode", address);
        } else {
          receivercontactSetFieldValue("edlPinCode", "");
        }
        if (customer[0]?.address != "" && customer[0]?.address != null) {
          receivercontactSetFieldValue("reciverAddress", customer[0]?.address);
        } else {
          receivercontactSetFieldValue("reciverAddress", "");
        }

        dispatch(resetSenderData());
      } else if (receiverType == "dealer") {
        const dealer = dealerList.filter((data) => data.id == currentReceiver);
        receivercontactSetFieldValue("kindAttention", dealer[0]?.name);
        receivercontactSetFieldValue("receiverName", dealer[0]?.name);
        receivercontactSetFieldValue("contactMobile", dealer[0]?.phoneNumber);
        const pincodeRegex = /\b\d{6}\b/;
        const address = dealer[0]?.address?.match(pincodeRegex);
        if (address != null) {
          receivercontactSetFieldValue("edlPinCode", address);
        } else {
          receivercontactSetFieldValue("edlPinCode", "");
        }
        if (dealer[0]?.address != "" && dealer[0]?.address != null) {
          receivercontactSetFieldValue("reciverAddress", dealer[0]?.address);
        } else {
          receivercontactSetFieldValue("reciverAddress", "");
        }

        dispatch(resetSenderData());
      } else if (receiverType == "franchise") {
        debugger;
        const franchise = technicianList.filter((data) => data.franchiseId == currentReceiver);
        receivercontactSetFieldValue("kindAttention", franchise[0]?.name);
        receivercontactSetFieldValue("receiverName", franchise[0]?.name);
        receivercontactSetFieldValue("contactMobile", franchise[0]?.phoneNumber);
        const pincodeRegex = /\b\d{6}\b/;
        const address = franchise[0]?.address?.match(pincodeRegex);
        if (address != null) {
          receivercontactSetFieldValue("edlPinCode", address);
        } else {
          receivercontactSetFieldValue("edlPinCode", "");
        }
        if (franchise[0]?.address != "" && franchise[0]?.address != null) {
          receivercontactSetFieldValue("reciverAddress", franchise[0]?.address);
        } else {
          receivercontactSetFieldValue("reciverAddress", "");
        }


        dispatch(resetSenderData());
      }
    } else {
      if (receiverType == "customer") {
        const customer = customerList.filter((data) => data.id == currentReceiver);
        receivercontactSetFieldValue("kindAttention", receivercontactDetailsList[0]?.name);
        receivercontactSetFieldValue("receiverName", customer[0]?.name);
        receivercontactSetFieldValue("contactMobile", receivercontactDetailsList[0]?.mobileNumber);
        const pincodeRegex = /\b\d{6}\b/;
        const address = customer[0]?.address?.match(pincodeRegex);
        if (address != null) {
          receivercontactSetFieldValue("edlPinCode", address);
        } else {
          receivercontactSetFieldValue("edlPinCode", "");
        }
        if (customer[0]?.address != "" && customer[0]?.address != null) {
          receivercontactSetFieldValue("reciverAddress", customer[0]?.address);
        } else {
          receivercontactSetFieldValue("reciverAddress", "");
        }

        dispatch(resetSenderData());
      } else if (receiverType == "dealer") {
        const dealer = dealerList.filter((data) => data.id == currentReceiver);
        receivercontactSetFieldValue("kindAttention", receivercontactDetailsList[0]?.name);
        receivercontactSetFieldValue("receiverName", dealer[0]?.name);
        receivercontactSetFieldValue("contactMobile", receivercontactDetailsList[0]?.mobileNumber);
        const pincodeRegex = /\b\d{6}\b/;
        const address = dealer[0]?.address?.match(pincodeRegex);
        if (address != null) {
          receivercontactSetFieldValue("edlPinCode", address);
        } else {
          receivercontactSetFieldValue("edlPinCode", "");
        }
        if (dealer[0]?.address != "" && dealer[0]?.address != null) {
          receivercontactSetFieldValue("reciverAddress", dealer[0]?.address);
        } else {
          receivercontactSetFieldValue("reciverAddress", "");
        }
        dispatch(resetSenderData());
      } else if (receiverType == "franchise") {
        const franchise = technicianList.filter((data) => data.franchiseId == currentReceiver);
        receivercontactSetFieldValue("kindAttention", receivercontactDetailsList[0]?.name);
        receivercontactSetFieldValue("receiverName", franchise[0]?.name);
        receivercontactSetFieldValue("contactMobile", receivercontactDetailsList[0]?.mobileNumber);
        const pincodeRegex = /\b\d{6}\b/;
        const address = franchise[0]?.address?.match(pincodeRegex);
        if (address != null) {
          receivercontactSetFieldValue("edlPinCode", address);
        } else {
          receivercontactSetFieldValue("edlPinCode", "");
        }
        if (franchise[0]?.address != "" && franchise[0]?.address != null) {
          receivercontactSetFieldValue("reciverAddress", franchise[0]?.address);
        } else {
          receivercontactSetFieldValue("reciverAddress", "");
        }
        dispatch(resetSenderData());
      }
    }
  }, [receivercontactDetailsList]);

  const dynamicallyChangeRequired = (sender: any, currentCValue: any) => {
    if (sender == "customer") {
      const customer = customerList.filter((data) => data.id == currentCValue);
      if (customer[0]?.address == "" || customer[0]?.address == null) {
        return true;
      } else {
        return false;
      }
    } else if (sender == "dealer") {
      const dealer = dealerList.filter((data) => data.id == currentCValue);
      if (dealer[0]?.address == "" || dealer[0]?.address == null) {
        return true;
      } else {
        return false;
      }
    } else if (sender == "franchise") {
      const franchise = technicianList.filter((data) => data.franchiseId == currentCValue);
      if (franchise[0]?.address == "" || franchise[0]?.address == null) {
        return true;
      } else {
        return false;
      }
    }
    return false;
  };

  const dynamicallyChangeedlpinRequired = (sender: any, currentCValue: any) => {
    if (sender == "customer") {
      const customer = customerList.filter((data) => data.id == currentCValue);
      const pincodeRegex = /\b\d{6}\b/;
      const address = customer[0]?.address?.match(pincodeRegex);
      if (address != null) {
        return false;
      } else {
        return true;
      }
    } else if (sender == "dealer") {
      const dealer = dealerList.filter((data) => data.id == currentCValue);
      const pincodeRegex = /\b\d{6}\b/;
      const address = dealer[0]?.address?.match(pincodeRegex);
      if (address != null) {
        return false;
      } else {
        return true;
      }
    } else if (sender == "franchise") {
      const franchise = technicianList.filter((data) => data.franchiseId == currentCValue);
      const pincodeRegex = /\b\d{6}\b/;
      const address = franchise[0]?.address?.match(pincodeRegex);
      if (address != null) {
        return false;
      } else {
        return true;
      }
    }
    return false;
  };

  const dynamicAddressRequired = (recevier: any, currentValue: any) => {
    if (recevier == "customer") {
      const customer = customerList.filter((data) => data.id == currentValue);
      if (customer[0]?.address != "" && customer[0]?.address != null) {
        return false;
      } else {
        return true;
      }
    } else if (recevier == "dealer") {
      const dealer = dealerList.filter((data) => data.id == currentValue);
      if (dealer[0]?.address != "" && dealer[0]?.address != null) {
        return false;
      } else {
        return true;
      }
    } else if (recevier == "franchise") {
      const franchise = technicianList.filter((data) => data.franchiseId == currentValue);
      if (franchise[0]?.address != "" && franchise[0]?.address != null) {
        return false;
      } else {
        return true;
      }
    }
    return false;
  };

  const dynamicRecevierEDLPinRequired = (recevier: any, currentValue: any) => {
    if (recevier == "customer") {
      const customer = customerList.filter((data) => data.id == currentValue);
      const pincodeRegex = /\b\d{6}\b/;
      const address = customer[0]?.address?.match(pincodeRegex);
      if (address != null) {
        return false;
      } else {
        return true;
      }
    } else if (recevier == "dealer") {
      const dealer = dealerList.filter((data) => data.id == currentValue);
      const pincodeRegex = /\b\d{6}\b/;
      const address = dealer[0]?.address?.match(pincodeRegex);
      if (address != null) {
        return false;
      } else {
        return true;
      }
    } else if (recevier == "franchise") {
      const franchisee = technicianList.filter((data) => data.franchiseId == currentValue);
      const pincodeRegex = /\b\d{6}\b/;
      const address = franchisee[0]?.address?.match(pincodeRegex);
      if (address != null) {
        return false;
      } else {
        return true;
      }
    }
    return false;
  };


  const changeDisable = (values: any) => {

    if (values != "Other" && values != "" && values != undefined) {
      return true;
    }
    return false;
  };

  const changeDisablecorresponseCompany = (values: any) => {
    if (values != "Other" && values != "" && values != undefined) {
      return true;
    }
    return false;
  };

  const dynamicallyChangerequiredField = (companyType: any) => {
    // if (companyType == "Other") {
    //   return true
    // }
    if (companyType == "Blue Dart" || companyType == "Smartr") {
      return true;
    }
  };

  return (
    <React.Fragment>
      <div className="page-content">
        {visible && dialogContainer(error)}
        <Container fluid>
          <Breadcrumb
            title="Courier"
            titlePath={ROUTER.COURIER_LIST}
            breadcrumbItem={id ? "Edit Courier" : "New Courier"}
          />
          <Row>
            <Card>
              <CardBody>
                <React.Fragment>
                  <Formik
                    onSubmit={handleSubmit}
                    initialValues={initialvalue}
                    validationSchema={validationSchema}
                    enableReinitialize >
                    {({ values, handleChange, touched, errors, handleBlur, resetForm, reset, setFieldValue, ...rest }: any) => {
                      return (
                        <Form>
                          <Row>
                            <Col lg={6}>
                              <Row>
                                <Col lg={6} className="mb-2">
                                  <DropdownInput
                                    options={companyDropdown}
                                    name="courierMasterId"
                                    label="Company"
                                    // onChange={handleChange}
                                    onChange={(e) => {
                                      if (e.target.value == "Other") {
                                        setFieldValue("productCode", 'N/A');
                                      } else if (e.target.value == "Gati") {
                                        setFieldValue("productCode", 'N/A');
                                      }
                                      handleChange(e);
                                    }}
                                    onBlur={handleBlur}
                                    required={true}
                                    value={values.courierMasterId}
                                    invalid={touched["courierMasterId"] && errors["courierMasterId"] ? true : false} />
                                </Col>
                                {(values.courierMasterId==blueDartCompany || values.courierMasterId==blueDartEComCompany) && <><Col lg={6} className="mb-2">
                                  <DropdownInput
                                    options={packType}
                                    name="packType"
                                    label="Pack Type"
                                    // onChange={handleChange}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    required={false}
                                    value={values.packType}
                                    invalid={touched["packType"] && errors["packType"] ? true : false} />
                                </Col>
                                <Col lg={6} className="mb-2">
                                  <DropdownInput
                                    options={customerReferenceNumberOption}
                                    name="customerCode"
                                    label="Customer Code"
                                    // onChange={handleChange}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    required={false}
                                    value={values.customerCode}
                                    invalid={touched["customerCode"] && errors["customerCode"] ? true : false} />
                                </Col>
                                </>
                                }
                                <Col lg={6} className="mb-2">
                                  <InputCom
                                    labelName="Other Company"
                                    inputclassName="form-control"
                                    labelClassName="form-Label"
                                    type="text"
                                    name="otherCompany"
                                    onBlur={handleBlur}
                                    value={values.otherCompany}
                                    onChange={handleChange}
                                    isRequired={companyDropdown?.filter((data: any) => data.value === values.courierMasterId && data.label === "Other").length > 0 ? true : false}
                                    invalid={touched["otherCompany"] && errors["otherCompany"] ? true : false}
                                  />
                                </Col>
                              </Row>
                              <Col lg={12} className="mb-2">
                                <DropdownInput
                                  options={SendAndRecieverType}
                                  name="senderType"
                                  label="Sender Type"
                                  onChange={(e) => {
                                    handleChange(e);
                                    assignSenderDrop(e.target.value, setFieldValue);
                                  }}
                                  onBlur={handleBlur}
                                  panelClassName='newCourierDropDown'
                                  required={true}
                                  value={values.senderType}
                                  invalid={touched["senderType"] && errors["senderType"] ? true : false} />
                              </Col>
                              <Row>
                                <Col lg={6} className="mb-2">
                                  <DropdownInput
                                    options={senderDropdown}
                                    name="packageSenderId"
                                    label="Package Sender"
                                    onChange={(e) => {
                                      handleChange(e);
                                      checkWhichuserselected(values.senderType, e.target.value, setFieldValue);
                                    }}
                                    disabled={values.senderType == "employee" ? true : false}
                                    onBlur={handleBlur}
                                    required={true}
                                    value={values.packageSenderId}
                                    invalid={touched["packageSenderId"] && errors["packageSenderId"] ? true : false}
                                  />
                                </Col>
                                <Col lg={6} className="mb-2">
                                  <InputCom
                                    labelName="Shipper Kind Attention to"
                                    inputclassName="form-control"
                                    labelClassName="form-Label"
                                    type="text"
                                    name="shipperKindAttention"
                                    onBlur={handleBlur}
                                    value={values.shipperKindAttention}
                                    onChange={handleChange}
                                    isRequired={false}
                                    invalid={touched["shipperKindAttention"] && errors["shipperKindAttention"] ? true : false}
                                  />
                                </Col>
                                <Col lg={6} className="mb-2">
                                  <InputCom
                                    labelName="Shipper Contact Mobile"
                                    inputclassName="form-control"
                                    labelClassName="form-Label"
                                    type="number"
                                    name="shipperContactMobile"
                                    onBlur={handleBlur}
                                    value={values.shipperContactMobile}
                                    onChange={handleChange}
                                    isRequired={true}
                                    invalid={touched["shipperContactMobile"] && errors["shipperContactMobile"] ? true : false}
                                  />
                                </Col>
                                <Col lg={6} className="mb-2">
                                  <InputCom
                                    labelName="Shipper EDL Pin Code"
                                    inputclassName="form-control"
                                    labelClassName="form-Label"
                                    type="text"
                                    name="shipperEdlPinCode"
                                    onBlur={handleBlur}
                                    value={values.shipperEdlPinCode}
                                    onChange={handleChange}
                                    isRequired={dynamicallyChangeedlpinRequired(values.senderType, values.packageSenderId)}
                                    invalid={touched["shipperEdlPinCode"] && errors["shipperEdlPinCode"] ? true : false}
                                  />
                                </Col>
                                <Col lg={6} className="mb-2">
                                  <InputCom
                                    labelName="Sender City"
                                    inputclassName="form-control"
                                    labelClassName="form-Label"
                                    type="text"
                                    name="shipperCity"
                                    onBlur={handleBlur}
                                    value={values.shipperCity}
                                    onChange={handleChange}
                                    isRequired={dynamicallyChangerequiredField(values.courierMasterId)}
                                    invalid={touched["shipperCity"] && errors["shipperCity"] ? true : false}
                                  />
                                </Col>
                                <Col lg={6} className="mb-2">
                                  <InputCom
                                    labelName="Sender State"
                                    inputclassName="form-control"
                                    labelClassName="form-Label"
                                    type="text"
                                    name="shipperState"
                                    onBlur={handleBlur}
                                    value={values.shipperState}
                                    onChange={handleChange}
                                    isRequired={dynamicallyChangerequiredField(values.courierMasterId)}
                                    invalid={touched["shipperState"] && errors["shipperState"] ? true : false}
                                  />
                                </Col>
                                <Col lg={12} className="mb-2">
                                  <InputCom
                                    labelName="Sender Address"
                                    inputclassName="form-control"
                                    labelClassName="form-Label"
                                    type="textarea"
                                    name="senderAddress"
                                    onBlur={handleBlur}
                                    value={values.senderAddress}
                                    onChange={handleChange}
                                    isRequired={dynamicallyChangeRequired(values.senderType, values.packageSenderId)}
                                    invalid={touched["senderAddress"] && errors["senderAddress"] ? true : false}
                                  />
                                </Col>
                              </Row>
                            </Col>
                            <Col lg={6}>
                              <Row>
                                <Col lg={6} className="mb-2">
                                  <InputCom
                                    labelName="Awb number"
                                    inputclassName="form-control"
                                    labelClassName="form-Label"
                                    type="text"
                                    name="awbNumber"
                                    onBlur={handleBlur}
                                    disabled={changeDisablecorresponseCompany(values.courierMasterId)}
                                    value={values.awbNumber}
                                    onChange={handleChange}
                                    isRequired={false}
                                    invalid={touched["awbNumber"] && errors["awbNumber"] ? true : false}
                                  />
                                </Col>
                                <Col lg={6} className="mb-2">
                                  <InputCom
                                    labelName="Token number"
                                    inputclassName="form-control"
                                    labelClassName="form-Label"
                                    type="text"
                                    name="tokenNumber"
                                    onBlur={handleBlur}
                                    disabled={changeDisablecorresponseCompany(values.courierMasterId)}
                                    value={values.tokenNumber}
                                    onChange={handleChange}
                                    isRequired={false}
                                    invalid={touched["tokenNumber"] && errors["tokenNumber"] ? true : false}
                                  />
                                </Col>
                              </Row>
                              <Col lg={12} className="mb-2">
                                <DropdownInput
                                  options={SendAndRecieverType}
                                  name="recieverType"
                                  label="Receiver Type"
                                  panelClassName='newCourierDropDown'
                                  onBlur={handleBlur}
                                  onChange={(e) => {
                                    handleChange(e);
                                    assignReceiverrDrop(e.target.value, setFieldValue);
                                  }}
                                  required={true}
                                  value={values.recieverType}
                                  invalid={touched["recieverType"] && errors["recieverType"] ? true : false} />
                              </Col>
                              <Row>
                                <Col lg={6} className="mb-2">
                                  <DropdownInput
                                    options={receiverDropdown}
                                    name="packageReceiverId"
                                    label="Package Receiver"
                                    // onChange={handleChange}
                                    onChange={(e) => {
                                      handleChange(e);
                                      checkWhichuserselectedReceiverType(values.recieverType, e.target.value, setFieldValue);
                                    }}
                                    disabled={values.recieverType == "employee" ? true : false}
                                    onBlur={handleBlur}
                                    required={true}
                                    value={values.packageReceiverId}
                                    invalid={touched["packageReceiverId"] && errors["packageReceiverId"] ? true : false} />
                                </Col>
                                <Col lg={6} className="mb-2">
                                  <InputCom
                                    labelName="Consignee Kind Attention to"
                                    inputclassName="form-control"
                                    labelClassName="form-Label"
                                    type="text"
                                    name="kindAttention"
                                    onBlur={handleBlur}
                                    value={values.kindAttention}
                                    onChange={handleChange}
                                    isRequired={false}
                                    invalid={touched["kindAttention"] && errors["kindAttention"] ? true : false}
                                  />
                                </Col>
                                <Col lg={6} className="mb-2">
                                  <InputCom
                                    labelName="Consignee Contact Mobile"
                                    inputclassName="form-control"
                                    labelClassName="form-Label"
                                    type="number"
                                    name="contactMobile"
                                    onBlur={handleBlur}
                                    value={values.contactMobile}
                                    onChange={handleChange}
                                    isRequired={true}
                                    invalid={touched["contactMobile"] && errors["contactMobile"] ? true : false}
                                  />
                                </Col>
                                <Col lg={6} className="mb-2">
                                  <InputCom
                                    labelName="Consignee EDL Pin Code"
                                    inputclassName="form-control"
                                    labelClassName="form-Label"
                                    type="number"
                                    name="edlPinCode"
                                    onBlur={handleBlur}
                                    value={values.edlPinCode}
                                    onChange={handleChange}
                                    isRequired={dynamicRecevierEDLPinRequired(values.recieverType, values.packageReceiverId)}
                                    invalid={touched["edlPinCode"] && errors["edlPinCode"] ? true : false}
                                  />
                                </Col>
                                <Col lg={6} className="mb-2">
                                  <InputCom
                                    labelName="Receiver City"
                                    inputclassName="form-control"
                                    labelClassName="form-Label"
                                    type="text"
                                    name="consigneeCity"
                                    onBlur={handleBlur}
                                    value={values.consigneeCity}
                                    onChange={handleChange}
                                    isRequired={dynamicallyChangerequiredField(values.courierMasterId)}
                                    invalid={touched["consigneeCity"] && errors["consigneeCity"] ? true : false}
                                  />
                                </Col>
                                <Col lg={6} className="mb-2">
                                  <InputCom
                                    labelName="Receiver State"
                                    inputclassName="form-control"
                                    labelClassName="form-Label"
                                    type="text"
                                    name="consigneeState"
                                    onBlur={handleBlur}
                                    value={values.consigneeState}
                                    onChange={handleChange}
                                    isRequired={dynamicallyChangerequiredField(values.courierMasterId)}
                                    invalid={touched["consigneeState"] && errors["consigneeState"] ? true : false}
                                  />
                                </Col>
                                <Col lg={12} className="mb-2">
                                  <InputCom
                                    labelName="Receiver Address"
                                    inputclassName="form-control"
                                    labelClassName="form-Label"
                                    type="textarea"
                                    name="reciverAddress"
                                    onBlur={handleBlur}
                                    value={values.reciverAddress}
                                    onChange={handleChange}
                                    isRequired={dynamicAddressRequired(values.recieverType, values.packageReceiverId)}
                                    invalid={touched["reciverAddress"] && errors["reciverAddress"] ? true : false}
                                  />
                                </Col>
                              </Row>
                            </Col>
                            <Col lg={3} className="mb-2">
                              <DropdownInput
                                options={values.courierMasterId == "Smartr" ? smartRProductCode : values.courierMasterId == "Other" ? NAProductCode : values.courierMasterId == "Gati" ? NAProductCode : productCode}
                                name="productCode"
                                label="Product code"
                                onChange={handleChange}
                                onBlur={handleBlur}
                                required={true}
                                value={values.productCode}
                                invalid={touched["productCode"] && errors["productCode"] ? true : false} />
                            </Col>
                            <Col lg={3} className="mb-2">
                              <InputCom
                                labelName="Package sent at"
                                inputclassName="form-control"
                                labelClassName="form-Label"
                                type="datetime-local"
                                name="packageSentAt"
                                onBlur={handleBlur}
                                // disabled={changeDisable(values.courierMasterId)}
                                disabled={false}
                                min={values.pickupRequestAt}
                                value={values.packageSentAt}
                                onChange={handleChange}
                                isRequired={false}
                                invalid={touched["packageSentAt"] && errors["packageSentAt"] ? true : false}
                              />
                            </Col>
                            <Col lg={3}>
                              <InputCom
                                labelName="Pickup request at"
                                inputclassName="form-control"
                                labelClassName="form-Label"
                                type="datetime-local"
                                name="pickupRequestAt"
                                onBlur={handleBlur}
                                value={values.pickupRequestAt}
                                onChange={handleChange}
                                max={values.packageSentAt}
                                isRequired={true}
                                invalid={touched["pickupRequestAt"] && errors["pickupRequestAt"] ? true : false}
                              />
                            </Col>
                            <Col lg={3}>
                              <InputCom
                                labelName="Shipment weight"
                                inputclassName="form-control"
                                labelClassName="form-Label"
                                type="number"
                                name="shipmentWeight"
                                onBlur={handleBlur}
                                value={values.shipmentWeight}
                                onChange={handleChange}
                                isRequired={true}
                                invalid={touched["shipmentWeight"] && errors["shipmentWeight"] ? true : false}
                              />
                            </Col>
                            <Col lg={3} className="mb-2">
                              <InputCom
                                labelName="Declared value"
                                inputclassName="form-control"
                                labelClassName="form-Label"
                                type="number"
                                name="declaredValue"
                                onBlur={handleBlur}
                                value={values.declaredValue}
                                onChange={handleChange}
                                isRequired={true}
                                invalid={touched["declaredValue"] && errors["declaredValue"] ? true : false}
                              />
                            </Col>
                            <Col lg={3} className="mb-2">
                              <InputCom
                                labelName="Commodity"
                                inputclassName="form-control"
                                labelClassName="form-Label"
                                type="text"
                                name="commodity"
                                onBlur={handleBlur}
                                value={values.commodity}
                                onChange={handleChange}
                                isRequired={false}
                                invalid={touched["commodity"] && errors["commodity"] ? true : false}
                              />
                            </Col>
                            <Col lg={3} className="mb-2">
                              <InputCom
                                labelName="Special instruction"
                                inputclassName="form-control"
                                labelClassName="form-Label"
                                type="text"
                                name="specialInstruction"
                                onBlur={handleBlur}
                                value={values.specialInstruction}
                                onChange={handleChange}
                                isRequired={false}
                                invalid={touched["specialInstruction"] && errors["specialInstruction"] ? true : false}
                              />
                            </Col>
                            <Col lg={3} className="mb-2">
                              <InputCom
                                labelName="Credit Reference Number"
                                inputclassName="form-control"
                                labelClassName="form-Label"
                                type="text"
                                name="creditReferenceNumber"
                                onBlur={handleBlur}
                                value={values.creditReferenceNumber}
                                onChange={handleChange}
                                isRequired={true}
                                invalid={touched["creditReferenceNumber"] && errors["creditReferenceNumber"] ? true : false}
                              />
                            </Col>
                                <Col lg={3} className="mb-2">
                                  <InputCom
                                    labelName="E-Way Bill No"
                                    inputclassName="form-control"
                                    labelClassName="form-Label"
                                    type="text"
                                    name="ewayBillNo"
                                    onBlur={handleBlur}
                                    value={values.ewayBillNo}
                                    onChange={handleChange}
                                    isRequired={(values.courierMasterId == smartrCompany && (values.productCode == "AKP" || values.productCode == "WKO"))}
                                    invalid={touched["ewayBillNo"] && errors["ewayBillNo"] ? true : false}
                                  />
                                </Col>
                                <Col lg={3} className="mb-2">
                                  <InputCom
                                    labelName="Shipper GST Number"
                                    inputclassName="form-control"
                                    labelClassName="form-Label"
                                    type="text"
                                    name="shipperGSTNo"
                                    onBlur={handleBlur}
                                    value={values.shipperGSTNo}
                                    onChange={handleChange}
                                    isRequired={(values.courierMasterId == smartrCompany && (values.productCode == "AKP" || values.productCode == "WKO"))}
                                    invalid={touched["shipperGSTNo"] && errors["shipperGSTNo"] ? true : false}
                                  />
                                </Col>
                              
                            <Col lg={3} className="mb-2 d-flex align-items-center">
                              <CheckboxInput
                                name="isToPayCustomer"
                                label="Is To Pay Customer"
                                value={values.isToPayCustomer}
                                onChange={handleChange}
                                {...rest}
                              />
                            </Col>
                            {values.courierMasterId == smartrCompany && <Col lg={3} className="mb-2 d-flex align-items-center">
                              <CheckboxInput
                                name="isSurface"
                                label="Is Surface"
                                value={values.isSurface}
                                onChange={handleChange}
                                {...rest}
                              />
                            </Col>}
                          </Row>
                          <hr />
                          <Col lg={12} className="dealer-title-header">
                            <div>Courier Packages</div>
                          </Col>

                          <FieldArray name='courierPackageDetails'>
                            {({ insert, remove, push }) => (
                              <div>
                                {values.courierPackageDetails?.length > 0 && values.courierPackageDetails.map((value: any, index: any) => {
                                  const isFirstContact = index === 0;
                                  return <div key={index}>
                                    <div className="dynamic-form-container">
                                      <div className="space-equally">
                                        <InputCom
                                          labelName="Length"
                                          inputclassName="form-control"
                                          labelClassName={`form-Label ${index == 0 ? "" : "d-sm-none"}`}
                                          type="number"
                                          name={`courierPackageDetails[${index}].length`}
                                          onBlur={handleBlur}
                                          onChange={handleChange}
                                          isRequired={true}
                                          value={value.length}
                                          invalid={touched.courierPackageDetails?.[index]?.length && errors.courierPackageDetails?.[index]?.length ? true : false}
                                        />
                                      </div>
                                      <div className="space-equally">
                                        <InputCom
                                          labelName="Breadth"
                                          inputclassName="form-control"
                                          labelClassName={`form-Label ${index == 0 ? "" : "d-sm-none"}`}
                                          type="number"
                                          name={`courierPackageDetails[${index}].breadth`}
                                          onBlur={handleBlur}
                                          onChange={handleChange}
                                          isRequired={true}
                                          value={value.breadth}
                                          invalid={touched.courierPackageDetails?.[index]?.breadth && errors.courierPackageDetails?.[index]?.breadth ? true : false}
                                        />
                                      </div>
                                      <div className="space-equally">
                                        <InputCom
                                          labelName="Height"
                                          inputclassName="form-control"
                                          labelClassName={`form-Label ${index == 0 ? "" : "d-sm-none"}`}
                                          type="number"
                                          name={`courierPackageDetails[${index}].height`}
                                          onBlur={handleBlur}
                                          onChange={handleChange}
                                          isRequired={true}
                                          value={value.height}
                                          invalid={touched.courierPackageDetails?.[index]?.height && errors.courierPackageDetails?.[index]?.height ? true : false}
                                        />
                                      </div>
                                      <div className="space-equally">
                                        <InputCom
                                          labelName="Weight"
                                          inputclassName="form-control"
                                          labelClassName={`form-Label ${index == 0 ? "" : "d-sm-none"}`}
                                          type="number"
                                          name={`courierPackageDetails[${index}].weight`}
                                          onBlur={handleBlur}
                                          onChange={handleChange}
                                          isRequired={true}
                                          value={value.weight}
                                          invalid={touched.courierPackageDetails?.[index]?.weight && errors.courierPackageDetails?.[index]?.weight ? true : false}
                                        />
                                      </div>
                                      <div className="space-equally">
                                        <InputCom
                                          labelName="Number of pkgs"
                                          inputclassName="form-control"
                                          labelClassName={`form-Label ${index == 0 ? "" : "d-sm-none"}`}
                                          type="number"
                                          name={`courierPackageDetails[${index}].numberOfPkgs`}
                                          onBlur={handleBlur}
                                          onChange={handleChange}
                                          isRequired={true}
                                          value={value.numberOfPkgs}
                                          invalid={touched.courierPackageDetails?.[index]?.numberOfPkgs && errors.courierPackageDetails?.[index]?.numberOfPkgs ? true : false}
                                        />
                                      </div>
                                      <div className="space-equally-last">
                                        <div className="space-equally-2 add-btn-container">
                                          <TooltipMEL title="Remove Courier Details" position='left'>
                                            {!isFirstContact &&
                                              <i className="fas fa-times-circle color-red" style={{ fontSize: "16px" }}
                                                onClick={() => {
                                                  // if (!value.id) {
                                                  remove(index);
                                                  // }
                                                }}>
                                              </i>
                                            }
                                          </TooltipMEL>
                                        </div>
                                      </div>
                                    </div>
                                  </div>;
                                })}

                                <div className="multiadd-container">
                                  {<div className="add-btn-container" onClick={() => { handleAddCourierDetails(values, push); }}>
                                    <TooltipMEL title="Add Courier Details" position='bottom'>
                                      <i className="fas fa-plus-circle"></i><span>Add</span>
                                    </TooltipMEL>
                                  </div>
                                  }
                                </div>
                              </div>
                            )}
                          </FieldArray>

                          <div className="d-flex justify-content-end mt-2">
                            <Button
                              className="secondary-btn me-2"
                              label={id ? "Cancel" : "Reset"}
                              type="button"
                              onClick={() => handleReset(resetForm)}
                            />
                            <Button
                              className="btn-primary me-2 btn-primary-shadow"
                              label={id ? "Update Courier" : "Create Courier"}
                              type="submit"
                            />
                          </div>
                        </Form>
                      );
                    }}
                  </Formik>
                </React.Fragment>
              </CardBody>
            </Card>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default AddEditCourier;