import { taxesInitialState } from "src/models/pages/taxes";
import { PartSkuTypes } from "./actionTypes";
import { partSkuInitialState } from "src/models/pages/partSkusModel";




const initialState: partSkuInitialState = {
  error: "",
  loading: false,
  partSkuList: {
    values: [],
    totalRecords: 0
  },
  locationList:"",
  partList:"",
  partSkuNumberList:""
};



const PartSku = (state = initialState, action: any) => {
  switch (action.type) {

    // Grid

    case PartSkuTypes.GET_PART_SKU_LIST:
    case PartSkuTypes.GET_PART_AUTOCOMPLETE:
    case PartSkuTypes.GET_PART_SKU_AUTOCOMPLETE:
      return {
        ...state,
        loading: true,
      };

    case PartSkuTypes.GET_PART_SKU_SUCCESS:
      return {
        ...state,
        loading: false,
        partSkuList: {
          ...state.partSkuList,
          // values: action.payload.data.values,
          values: action.payload.data.items,
          totalRecords: action.payload.data.totalCount,
        }
      };

    case PartSkuTypes.GET_PART_AUTOCOMPLETE_SUCCESS:
      return {
        ...state,
        loading: false,
        partList:action.payload.data
      };

    case PartSkuTypes.GET_PART_SKU_AUTOCOMPLETE_SUCCESS:
      return {
        ...state,
        loading: false,
        partSkuNumberList:action.payload.data
      };

    case PartSkuTypes.GET_LOCATION_SUCCESS:
      return {
        ...state,
        loading: false,
        locationList:action.payload.data
      };

    case PartSkuTypes.RESET_PART_SKU_MESSAGE :
      return {
        ...state,
        loading : false ,
        error : ""
      }  

    case PartSkuTypes.GET_PART_SKU_FAIL:
    case PartSkuTypes.GET_PART_AUTOCOMPLETE_FAIL:
    case PartSkuTypes.GET_PART_SKU_AUTOCOMPLETE_FAIL:
    case PartSkuTypes.GET_LOCATION_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload
      };

    default:
      return { ...state };
  }
};

export default PartSku;
