import React, { KeyboardEventHandler, useState } from 'react';
import { InputText } from 'primereact/inputtext';
import { InputTextarea } from 'primereact/inputtextarea';
import { Dropdown } from 'primereact/dropdown';
import { Calendar } from 'primereact/calendar';
import { InputNumber } from 'primereact/inputnumber';
import ErrorText from "../UI/ErrorText";
import { ErrorMessage } from "formik";
import './FloatInput.scss'
import { Button } from 'primereact/button';
import { Input, Label } from 'reactstrap';
import FeatherIcon from "feather-icons-react";
import { FileUpload } from 'primereact/fileupload';
import { Link } from 'react-router-dom';
import { Tooltip } from 'primereact/tooltip';
import JobTooltipMEL from '../UI/JobTooltip';

interface FloatLabelProps {
  type: 'text' | 'number' | 'date' | 'dropdown' | 'textarea' | 'datetime' | 'split' | 'file';
  label: string;
  value?: any;
  onChange?: (value: any) => void;
  options?: any;
  invalid?: boolean;
  onBlur?: (value: any) => void;
  name: string;
  disabled: boolean;
  required: boolean;
  error?: string;
  today?: any;
  className?: any;
  onClick?: any;
  placeholder?: any;
  map?: boolean;
  concatinateLatandLng?: string
  onKeyDown?:KeyboardEventHandler<HTMLInputElement> | undefined;
}

const FloatInput: React.FC<FloatLabelProps> = ({
  type,
  label,
  value,
  onChange,
  options,
  invalid = false,
  onBlur,
  name,
  disabled,
  required = false,
  error,
  today,
  className = "",
  onKeyDown=()=>{},
  onClick = () => { },
  placeholder,
  map,
  concatinateLatandLng,
}) => {
  const getToday = () => {
    const today = new Date();
    today.setHours(0, 0, 0, 0);
    return today;
  };

  return (
    <>

      <div className={`p-float-label ${value ? 'p-input-filled' : ''} ${className} ${disabled ? 'float-disabled' : ''}`}>
        {(type === 'text' || type === 'number') && (
          <InputText
            name={name}
            type={type}
            id={label}
            value={value}
            autoComplete='off'
            placeholder={placeholder}
            onChange={onChange}
            onKeyDown={onKeyDown}
            onBlur={onBlur}
            className={`${invalid ? 'form-control is-invalid' : ''} ${className}`}
            disabled={disabled}
          />
        )}


        {(type === 'date' || type === "datetime") && (

          <Calendar
            name={name}
            inputId={label}
            id={label}
            value={value}
            onChange={onChange}
            onBlur={onBlur}
            className={`p-inputfield ${invalid ? 'p-invalid' : ''} ${disabled ? 'float-disabled' : ''}`}
            disabled={disabled}
            showIcon={true}
            dateFormat="dd-mm-yy"
            showTime={type === 'datetime'}
            hourFormat="24"
            showButtonBar
            clearButtonClassName="calendar_button"
            todayButtonClassName="calendar_button"
            // minDate = {today && new Date() }
          />
        )}

        {type === 'dropdown' && (
          <Dropdown
            resetFilterOnHide={true}
            filter
            virtualScrollerOptions={{ itemSize: 35 }}
            name={name}
            id={label}
            title={options?.find((data:any)=>data.value == value)?.label}
            value={value}
            options={options}
            onChange={onChange}
            onBlur={onBlur}
            className={`${invalid ? 'form-control is-invalid' : ''} ${disabled ? 'float-disabled' : ''}`}
            disabled={disabled}
            showClear={value ? true : false}
            itemTemplate={(option: any) => (
              <div
                title={option.label} // Tooltip content comes from the `tooltip` field in the option
                style={{ display: 'flex', alignItems: 'center' }}
              >
                {option.label}
              </div>
            )}
 
 
          />
        )}
        {type === 'textarea' && (
          <>
            <InputTextarea
              name={name}
              id={label}
              value={value}
              onChange={onChange}
              onBlur={onBlur}
              className={`${invalid ? 'form-control is-invalid' : ''} ${disabled ? 'float-disabled' : ''}`}
              disabled={disabled} />
            {
              map && <div className='TextArea_With_Map'>
                <Link to={`https://www.google.com/maps/place/${concatinateLatandLng}`} target="_blank"><FeatherIcon icon="map-pin" /></Link>
              </div>
            }

          </>

        )}
        <label htmlFor={label}>
          {label} {required && <span className="text-danger">*</span>}
        </label>

      </div>

      <ErrorMessage name={name || error || ''} component={ErrorText} />

    </>
  );
};

export default FloatInput;