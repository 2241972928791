import React, { useEffect, useState } from 'react';
import { Html5QrcodeScanner } from "html5-qrcode";
import { Html5Qrcode } from "html5-qrcode";
import { Dialog } from 'primereact/dialog';
import { getPartSkuWithLocation, getPostPartSku } from 'src/store/stockInspection/action';
import { useDispatch } from 'react-redux';
import Button from 'src/components/UI/Button';
import { useToast } from "src/components/Common/ReactToaster";
import { hasFranchiseRoleHandler } from 'src/helpers/common_helpers';
import { partSkuValidate } from 'src/store/actions';


interface ScannerPops {
  visible: boolean;
  onHide: () => void;
  scanParts:any;
  setScanParts:any;
}

const JobsheetScanner = ({ visible, onHide, scanParts,setScanParts }: ScannerPops) => {

  const [qrCodeScanner, setQrCodeScanner] = useState<Html5QrcodeScanner | null>(null);
  const [paused, setPaused] = useState<boolean>(false);

  // const [inspectionValues, setInspectionValues] = useState<any>(null);
  // useEffect(() => {
  //   setInspectionValues(values)
  // }, [values])


  const clearScanner = () => {
    qrCodeScanner?.clear();
    onHide();
    // setQrCodeScanner(null);
  };

  useEffect(() => {
    if (paused) {
      setTimeout(() => {
        qrCodeScanner?.resume();
        setPaused(false)
      }, 2000);
    }
  }, [paused]);

  const resumeScanner = () => {
    qrCodeScanner?.resume();
    setPaused(false);
  };

  const scan = () => {

    const html5QrcodeScanner = new Html5QrcodeScanner(
      "reader",
      { fps: 10, qrbox: { width: 400, height: 230, },supportedScanTypes:[0], showZoomSliderIfSupported: true, showTorchButtonIfSupported: true, defaultZoomValueIfSupported: 5, useBarCodeDetectorIfSupported: true, formatsToSupport: [0, 5] }, false);

    setQrCodeScanner(html5QrcodeScanner);

    const onScanSuccess = (decodedText: any, decodedResult: any) => {
        if(decodedText){
            setScanParts(decodedResult)
        }
     

      if (html5QrcodeScanner) {
        html5QrcodeScanner.pause();
      }

      if (html5QrcodeScanner.getState() === 3) {
        setPaused(true);
      }
    };

    const onScanFailure = (error: any) => {
    };


    html5QrcodeScanner.render(onScanSuccess, onScanFailure);

  };


  return (
    <div>
      {visible && <React.Fragment>
        <Dialog header="Scan Sku here" visible={visible} maximizable onHide={clearScanner} onShow={scan} style={{ width: '33%' }} >
          <div style={{ display: 'grid', placeItems: 'center' }} className={paused ? 'scannerContainer' : ''}>
            <div id="reader" style={{ width: '30vw', height: 'auto', textAlign: 'center' }} ></div>
            <div className='d-flex justify-content-center'></div>
            {/* {paused && <Button className="btn-primary me-2 resumeBtn" label="Resume scanner" type="submit" onClick={resumeScanner} />} */}
          </div>
        </Dialog>
      </React.Fragment>}

    </div>
  );
};

export default JobsheetScanner;
