import React from 'react';
import "./commonUI.styles.scss"
import FeatherIcon from "feather-icons-react";

interface ButtonProps {
  className: string;
  label?: string;
  type: 'button' | 'submit' | 'reset';
  onClick? :()=>void | any;
  icon?:any;
  disabled?: boolean
}
const style={
  marginRight: "10px",
}

const Button = (props: ButtonProps) => {
  const {label, type, className,onClick,icon, disabled=false} = props
  return (
      <button disabled={disabled}  onClick={onClick} className={icon == "whatsapp" ? `btn btn-success ms-2 minimize-button` : className ? `btn ${className}` : 'btn'} type={type}>
        {icon && label &&(icon == "whatsapp" ?<i className='bx bxl-whatsapp'></i> : <FeatherIcon icon={icon} style={style} />)}
        {!label && icon && <FeatherIcon icon={icon} />}
        {label}
      </button>
  )
};

export default Button;
