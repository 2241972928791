import React, { useEffect, useRef, useState } from "react";
import { Row, Col, Button, UncontrolledTooltip } from "reactstrap";
import { Form, Formik } from "formik";
import * as Yup from "yup";
import { Option } from "src/models/components/inputWrapperModel";
import {
  Columns,
  LazyTableState,
} from "src/models/components/dataTableWrapperModel";
import DataTableWrapper from "src/components/Grid/DataTableWrapper";
import { DataTable, DataTableFilterMeta } from "primereact/datatable";
import { useDispatch, useSelector } from "react-redux";
import ColumnAction from "src/components/Common/ColumnAction/ColumnAction";
import { useNavigate } from "react-router-dom";
import DeleteModal from "../../Calendar/DeleteModal";
import ArchiveModal from "../../Calendar/DeleteModal";
import { useToast } from "src/components/Common/ReactToaster";
import Select from "src/components/Common/Select";
import StatusHghlighter from "src/components/Common/StatusHighlighter/StatusHighlighter";
import {
  CurrencyFormatter,
  formatDateMEL,
  hasAdminOrEmployeeRoleHandler,
  hasFranchiseRoleHandler,
  jobLatModifiedResponse,
  saveAsExcelFile,
} from "src/helpers/common_helpers";
import { parts } from "src/models/pages/partsModel";
import { ROUTER } from "src/constants/routes";
import { MultiSelectChangeEvent } from "primereact/multiselect";
import { Dropdown, DropdownChangeEvent } from "primereact/dropdown";
import { AnimatePresence, motion } from "framer-motion";
import { BUTTON_VARIANT } from "src/constants/bulkActionVariant";
import FeatherIcon from "feather-icons-react";
import CustomizeTable from "src/components/Common/CustomizeTable/CustomizeTable";
import TooltipMEL from "src/components/UI/Tooltip";
import BulkActionStatus from "src/components/Common/BulkActionStatus/BulkActionStatus";
import SortIcon from "src/components/Common/CustomFilterandSortIcon/SortIcon";
import { ACTIVE, DELETED, INTENT_STATUS } from "src/constants/commonConstants";
import ExportFile from "src/components/Common/ExcelPdf Export/ExportFile";
import FilterIcon from "src/components/Common/CustomFilterandSortIcon/FilterIcon";
import { approveIntent, clearIntentMessageData, deleteIntentList, franchiseListforIntent, getIntentList, rejectIntent } from "src/store/actions";
import { ColumnFilterElementTemplateOptions } from "primereact/column";
import ValidationPopup from "src/components/Common/ValidationMessage/ValidationPopup";
import { Link } from "react-router-dom";
import ConfirmationDialog from "src/components/Common/ConfirmationDialog/ConfirmationDialog";
import { API } from "src/util/api";
import { customizeTableColumns } from "src/models/components/CustomizeTableColumns";


const partsListConverson = (values: any[]) => {
  const modifeidValue = values?.map((eachValue) => {
    let data: any = {};
    data['id'] = eachValue.id;
    data['intentNumber'] = eachValue?.intentNumber;
    data['franchiseId'] = eachValue?.franchise;
    data['approvedUser'] = eachValue?.approvedUser;
    data['status'] = eachValue?.status;
    data['FranchiseId']=eachValue?.franchiseId;
    data['approvedOn']=eachValue?.approvedOn;
    data['isDeleted'] = eachValue?.isDeleted;
    return data;
  });
  return modifeidValue;
}

export default function FloatingStockManagement() {
  const navigate = useNavigate();
  const [filter, setFilter] = useState(false);
  const { values, totalRecords } = useSelector((state: any) => state.intentReducer.intentList);

  const role: any = localStorage.getItem("role");
  const parsedRole = JSON.parse(role);

  const hasAdminOrEmployeeRole = parsedRole == "admin" || parsedRole == "Employee"

  const modifiedValues: parts[] = partsListConverson(values);
  const { showToast } = useToast();
  const loading = useSelector((state: any) => state.intentReducer.loading);
  const[pdfLoading,setPdfLoading]=useState<boolean>(false)

  const { error, message, paginatorCount, franchiseList, } = useSelector((state: any) => state.intentReducer);

  const dispatch = useDispatch();
  const dt = useRef<DataTable<any>>(null);
  // for delete invoice
  const [deleteModal, setDeleteModal] = useState(false);
  const [archiveModal, setArchiveModal] = useState(false);
  const [rowData, setRowData] = useState<parts | null>(null);
  const [isTooltipVisible, setIsTooltipVisible] = useState<boolean>(false);
  const [hasRole, setHasRole] = useState(false)
  const filterDetails: DataTableFilterMeta = {
    intentNumber: { value: "", matchMode: "contains" },
    franchiseId: { value: "", matchMode: "contains" },
    approvedBy: { value: "", matchMode: "contains" },
    status: { value: "", matchMode: "contains" },
    IsDeleted: { value: "false", matchMode: "contains" },
  };
  let initialLazyState: LazyTableState = {
    first: 0,
    rows: 10,
    page: 1,
    // sortField: "",
    // sortOrder: 1,
    sortField: "CreationTime",
    sortOrder: -1,
    filters: filterDetails,
  };
  const [lazyState, setLazyState] = useState(initialLazyState);

  const initialValues = {
    status: "",
  };
  // useEffect(()=>{
  // dispatch(getIntentList(lazyState))
  // },[])
  const onFilterClickHandler = () => {
    setFilter((preState) => !preState);
  };

  const onCreateClickHandler = () => {
    navigate(ROUTER.INTENT_CREATE);
  };

  const viewHandler = (rowData: parts) => {
    navigate(`${ROUTER.INTENT_VIEW}/${rowData["id"]}`);
  };

  const editHandler = (rowData: parts) => {
    navigate(`${ROUTER.INTENT_EDIT}/${rowData["id"]}`);
  };

  const deleteHandler = (rowData: parts) => {
    setDeleteModal(true);
    setRowData(rowData);
  };

  useEffect(() => {
    dispatch(franchiseListforIntent());
  }, [dispatch])

  const actionBodyTemplate = (rowData: any, value: string) => {
    let isApprovedStatus = true;
    let isAdmin = hasAdminOrEmployeeRoleHandler();

    isApprovedStatus = ((isAdmin && (rowData.status == INTENT_STATUS.REQUESTED || rowData.status == INTENT_STATUS.APPROVED || rowData.status == INTENT_STATUS.PENDING_DISPATCH)) || (!isAdmin && (rowData.status == INTENT_STATUS.REQUESTED || rowData.status == INTENT_STATUS.COURIERED || rowData.status == INTENT_STATUS.PENDING_RECEIVED ))) ? true : false
    let isRequest = (isAdmin && rowData.status==INTENT_STATUS.REQUESTED)

    const isDeleteStatus = (rowData.status == INTENT_STATUS.REQUESTED || rowData.status == INTENT_STATUS.REJECTED) ? true : false;
    return (
      <>
        {
          (!rowData.isDeleted) ? (
            <ColumnAction
              displayData={rowData[value] as string}
              isEdit={isApprovedStatus}
              isDelete={isDeleteStatus}
              isView={true}
              isApprove={isRequest}
              isReject={isRequest}
              onViewClick={() => viewHandler(rowData)}
              onEditClick={() => editHandler(rowData)}
              onDeleteClick={() => deleteHandler(rowData)}
              approveClick={()=>{statusBtnHandler(INTENT_STATUS.APPROVED,rowData)}}
              rejectClick={()=>{statusBtnHandler(INTENT_STATUS.REJECTED,rowData)}}
            />
          ) : (
            <ColumnAction
              displayData={rowData[value] as string}
              undo={rowData.isDeleted ? true : false}
              onDeleteClick={() => deleteHandler(rowData)}
            />
          )
        }
      </>
    );
  };
  const [status, setStatus] = useState<any>("");
  const [rowDatas, setRowDatas] = useState<any>("");
  const statusBtnHandler = (status: string,rowData:any) => {
    setStatus(status)
    setRowDatas(rowData)
  }

  const approveConfirmHandler = (value: any) => {

    let data: any = {};
    data['id'] = rowDatas.id;
    // data['approvedNote'] = value?.note ? value?.note : "";
    data['approvedNote'] = value?.franchiseId ? value?.franchiseId : "";
    setStatus("")
    setRowDatas("")
    
    
    dispatch(approveIntent(data))
    dispatch(getIntentList(lazyState))
  }

  const rejectConfirmHandler = (value: any) => {
    let data: any = {};
    data['id'] = rowDatas.id;
    // data['rejectedNote'] = value?.note ? value?.note : "";
    data['rejectedNote'] = value?.franchiseId ? value?.franchiseId : "";
    setStatus("")
    setRowDatas("")
    dispatch(rejectIntent(data))
    dispatch(getIntentList(lazyState))
  }
  const confirmationHideHandler = () => {
    setStatus("")
    setRowDatas("")
  }


  useEffect(() => {
    const preloader: any = document.getElementById("preloader");
    if (loading || pdfLoading) {
      preloader.style.display = "block";
    } else {
      preloader.style.display = "none";
    }
  }, [loading,pdfLoading]);

  const [visible, setVisible] = useState(false);

  useEffect(() => {
    if (error) {
      setVisible(true);
    }
    if (message) {
      showToast(message, { type: "success" });
      dispatch(clearIntentMessageData())
      setTimeout(() => {
        dispatch(clearIntentMessageData())
      }, 2000)
    }
  }, [error, message]);

  const hidePopup = () => {
    setVisible(false);
    dispatch(clearIntentMessageData())
  };

  const dialogContainer = (error: any) => {
    return (
      <>
        <ValidationPopup error={error} visible={visible} onHide={hidePopup} />
      </>
    );
  };


  const percentageBodyTemplate = (rowData: parts, field: string) => {
    return (
      <div>{rowData[field] + ' %'}</div>
    );
  };
  const [statusList, setStatusList] = useState([{ name: "Requested", id: "0" }, { name: "Approved", id: 1 }, { name: "Rejected", id: 2 }, { name: "PendingDispatch", id: 3 }, { name: "Couriered", id: 4 }, { name: "Received", id: 5 }, { name: "PendingReceived", id: 6 }, { name: "Miscellaneous", id: 7 }])
  const statusFilterTemplate = (options: ColumnFilterElementTemplateOptions) => {
    return (<>
      <Dropdown
        options={(statusList?.length > 0) ? (statusList?.map((eachStatus: any) => {
          let data: any = {};
          data['label'] = eachStatus?.name.toUpperCase();
          data['value'] = eachStatus?.id;
          return data;
        })).sort((a:any, b:any) => {
          const labelA =a.label.toUpperCase().trim();
          const labelB = b.label.toUpperCase().trim();
          if (labelA < labelB) {
            return -1;
          }
          if (labelA > labelB) {
            return 1;
          }
          return 0;
        }) : []}
        name="supplierId"
        optionLabel="label"
        filter
        onChange={(e: DropdownChangeEvent) => {
          options.filterCallback(e.value, options.index)
          return {};
        }}
        required={true}
        value={options.value}
      />
    </>)
  };
  const filteredFranchiselist = franchiseList.filter((item: any) => item.isDeleted === false)

  const FranchiseFilterTemplate = (options: ColumnFilterElementTemplateOptions) => {
    return (<>
      <Dropdown
        options={(filteredFranchiselist?.length > 0) ? (filteredFranchiselist?.map((eachStatus: any) => {
          let data: any = {};
          data['label'] = eachStatus?.name;
          data['value'] = eachStatus?.id;
          return data;
        })).sort((a:any, b:any) => {
          const labelA =a.label.toUpperCase().trim();
          const labelB = b.label.toUpperCase().trim();
          if (labelA < labelB) {
            return -1;
          }
          if (labelA > labelB) {
            return 1;
          }
          return 0;
        }) : []}
        name="supplierId"
        optionLabel="label"
        filter
        onChange={(e: DropdownChangeEvent) => {
          options.filterCallback(e.value, options.index)
          return {};
        }}
        required={true}
        value={options.value}
      />
    </>)
  };

  const statusBodyTemplate = (rowData: parts, field: string) => {
    let status = rowData[field] as string;
    return (
      <StatusHghlighter status={status} />
    );

  };

  const options: Option[] = [
    { label: "All", value: "All" },
    { label: "Active", value: "Active" },
    { label: "Deleted", value: "Deleted" },
  ];

  const currencyBodyTemplate = (rowData: parts, field: string) => {
    let amount = rowData[field];
    return (<>{CurrencyFormatter({ value: amount })}</>);
  };
  const ToUpperCase = (rowData: any, field: string) => {
    let data = rowData[field]
    if (rowData[field] == INTENT_STATUS.PENDING_DISPATCH) {
      data = "Pending Dispatch"
    }
    return data.toUpperCase()
  }
  
  const FranchiseTemplate = (rowData: any, field: string) => {

    return (
      <>
      {hasAdminOrEmployeeRoleHandler() ? 
      <Link to={`${ROUTER.FRANCHISEE_VIEW}/${rowData['FranchiseId']}`}>{rowData[field]}</Link>
      :
      <div>{rowData[field]}</div>
      }
      </>
    );
  };
  
  const StatusHighliter=(data:any)=>{
    switch(data){
      case "Requested":
        return(<div  className="info-status common-status-intend">{data}</div>)
      case "Approved":
        return(<div  className="success-status common-status-intend">{data}</div>)
      case "Rejected":
        return(<div  className="danger-status common-status-intend">{data}</div>)
      case "PendingDispatch":
        return(<div  className="warning-status common-status-intend">{'Pending Dispatch'}</div>)
      case "Couriered":
        return(<div  className="couriered-status common-status-intend">{data}</div>)
      case "Received":
        return(<div  className="received-status common-status-intend">{data}</div>)
      case "Miscellaneous":
        return(<div  className="miscellaneous-status common-status-intend">{data}</div>)
      case "PendingReceived":
        return(<div  className="miscellaneous-status common-status-intend">{'Pending Received'}</div>)

      default:
        return(<div  className="info-status common-status-intend">{data}</div>)
    }
  }

  const columns: Columns[] = [
    {
      field: "select",
      sortable: false,
      header: " ",
      filter: false,
      showFilterMenu: false,
      selectionMode: "multiple",
      isDefault: true,
      headerStyle: { width: "3rem", whiteSpace: "nowrap" },
      isStatic: false,
      frozen : true,
    },
    {
      field: "intentNumber",
      sortable: true,
      header: "Indent Number",
      filter: true,
      showFilterMenu: true,
      isDefault: true,
      frozen : true,
      body: (rowData) => actionBodyTemplate(rowData, "intentNumber"),
      headerStyle: { whiteSpace: "nowrap", width: "250px" },
      isStatic: true,
    },
    {
      field: "franchiseId",
      sortable: !hasFranchiseRoleHandler()? true :false,
      header: "Franchise",
      filter: !hasFranchiseRoleHandler()? true :false,
      showFilterMenu: !hasFranchiseRoleHandler()? true :false,
      isDefault: true,
      headerStyle: { whiteSpace: "nowrap" },
      body:(rowData:any)=>FranchiseTemplate(rowData,'franchiseId'),
      isStatic: false,
      className: "captialize",
      filterElement: FranchiseFilterTemplate,
    },
    {
      field: "approvedUser",
      sortable: false,
      header: "Approved By",
      filter: true,
      showFilterMenu: false,
      isDefault: true,
      headerStyle: { whiteSpace: "nowrap" },
      isStatic: false,
      className: "captialize",
    },
    {
      field: "approvedOn",
      sortable: false,
      header: "Approved On",
      filter: true,
      showFilterMenu: false,
      isDefault: true,
      headerStyle: { whiteSpace: "nowrap" },
      isStatic: false,
      body: (rowData) => jobLatModifiedResponse(rowData.approvedOn)
      // className: "captialize",
    },
    {
      field: "status",
      sortable: false,
      header: "Status",
      filter: true,
      showFilterMenu: true,
      isDefault: true,
      headerStyle: { whiteSpace: "nowrap" },
      body: (rowData: any) => StatusHighliter(rowData['status']),
      isStatic: false,
      filterElement: statusFilterTemplate
    },
  ];

  const [visibleColumns, setVisibleColumns] = useState(columns.filter((eachColumn: Columns) => eachColumn.isDefault));

  useEffect(() => {
    setVisibleColumns(columns.filter((eachColumn: Columns) => eachColumn.isDefault))
  }, [franchiseList])


  const changeColumnDetails = (values: any[]) => {
    let modifiedColumnDetails = values.map((eachDealer) => {
      const { id, intentNumber, franchiseId, approvedUser, status,approvedOn} = eachDealer;
      let data: any = {};
      data['Indent Number'] = intentNumber;
      data['Franchisee'] = franchiseId;
      data['Approved By'] = approvedUser;
      data['Approved On'] = jobLatModifiedResponse(approvedOn);
      data['Status'] = status;
      return { ...data };
    });

    return modifiedColumnDetails;
  };

  const saveDataAsExcel = (values: any[], fileName: string) => {
    const modifiedColumnDetails = changeColumnDetails(values)
    import('xlsx').then((xlsx) => {
      const worksheet = xlsx.utils.json_to_sheet(modifiedColumnDetails);
      const workbook = { Sheets: { data: worksheet }, SheetNames: ['data'] };
      const excelBuffer = xlsx.write(workbook, {
        bookType: 'xlsx',
        type: 'array'
      });
      saveAsExcelFile(excelBuffer, fileName);
    });
  };

  const saveDataAsPdf = (values: any[], columns: any[], fileName: string) => {
    const destructureedData = values.map((data) => {
      let datas = {
        id: data.id,
        intentNumber: data.intentNumber,
        franchiseId: data.franchiseId,
        approvedUser: data?.approvedUser,
        approvedOn : jobLatModifiedResponse(data?.approvedOn),
        status: data?.status,
      };
      return datas;
    });
    import("jspdf").then((jsPDF) => {
      import("jspdf-autotable").then(() => {
        const exportColumns = columns.map((col) => ({
          title: col.header,
          dataKey: col.field,
        }));
        const doc = new (jsPDF as any).default(0, 0);
        doc.autoTable(exportColumns, destructureedData);
        doc.save(`${fileName}.pdf`);
      });
    });
  };
  const fetchData = async (isPdf:boolean) => {
    let state:any= {
      ...lazyState,
      rows:totalRecords>5000 ? 5000 : totalRecords<1 ? 1 : totalRecords,
      first:0,
      filters: {
        ...lazyState.filters,
      },
    };
    
    
    try {
      setPdfLoading(true)
      const response = await API.getIntentListAPI(state);
      if (!response) {
        throw new Error('Network response was not ok');
      }
      let resp=await partsListConverson(response?.data?.items)
     if(isPdf){

       saveDataAsPdf(resp, columns, "Indent");
     }else{
      saveDataAsExcel(resp, "Indent");
     }
     setPdfLoading(false)
    } catch (error:any) {
      showToast(error?.message,{type:"error"})
      setPdfLoading(false)
      
    } finally {
      setPdfLoading(false)
    }
  };

  const csvClickHandler = () => {
    // saveDataAsExcel(modifiedValues, "intent");
    fetchData(false)
  };

  const pdfClickHandler = () => {
    // saveDataAsPdf(modifiedValues, columns, "intent");
    fetchData(true)
  };

  const validationSchema = Yup.object({});

  const callbackFunction = (lazyState: LazyTableState) => {
    dispatch(getIntentList(lazyState));
  };

  const [selectAll, setSelectAll] = useState(false);
  const [selectedParts, setSelectedParts] = useState<any>([]);

  const onSelectionChange = (event: any) => {
    const value = event.value;
    setSelectedParts(value.filter((eachValue: any) => ((eachValue.status == INTENT_STATUS.REQUESTED || eachValue.status == INTENT_STATUS.REJECTED) ? true : false)));
    // setSelectAll(value.length === totalRecords);
    setSelectAll(value.length === paginatorCount);
  };

  const onSelectAllChange = (event: any) => {
    const selectAll = event.checked;
    let filterValue = modifiedValues.filter((eachValue: any) => ((eachValue.status == INTENT_STATUS.REQUESTED || eachValue.status == INTENT_STATUS.REJECTED) ? true : false))
    if (selectAll) {
      setSelectAll((filterValue.length == modifiedValues.length) ? true : false);
      setSelectedParts(filterValue);
    } else {
      setSelectAll(false);
      setSelectedParts([]);
    }
  };

  const handleStatusBlur = () => { };

  const onPage = (event: any) => {
    setLazyState(event);
  };

  const onSort = (event: any) => {
    setSelectAll(false);
    setSelectedParts([]);
    setLazyState(event);
  };


  const onFilter = (event: any) => {
    event["first"] = 0;
    setLazyState(event);
  };

  const handleSubmit = (formValue: any) => {
    setselectedStatus(formValue);
    if (formValue == ACTIVE) {
      setLazyState((state) => ({
        ...state,
        filters: {
          ...state.filters,
          IsDeleted: { value: "false", matchMode: "contains" },
        },
      }));
    }
    else if (formValue == DELETED) {
      setLazyState((state) => ({
        ...state,
        filters: {
          ...state.filters,
          IsDeleted: { value: "true", matchMode: "contains" },
        },
      }));
    }
    else {
      setLazyState((state) => ({
        ...state,
        filters: {
          ...state.filters,
          IsDeleted: { value: null, matchMode: "contains" },
        },
      }));
    }
    setIsTooltipVisible(false)
    setVisibleColumns(visibleColumns)
  };

  const handleReset = (formValue: any) => {
    setLazyState((state) => ({
      ...state,
      filters: {
        ...state.filters,
        status: {
          ...state.filters.status,
          value: "",
        }
      },
    }));
  };

  useEffect(() => {
    callbackFunction(lazyState);
    return () => {
      dispatch(clearIntentMessageData())
    }
  }, [lazyState]);



  const handleDeleteUsers = (rowData: any, length: number = 0, index: number = 0) => {
    let payload = {
      id: rowData["id"] ? rowData["id"] : rowData[0]?.id,
      undo: rowData['isDeleted'] ? true : (rowData[0]?.isDeleted ? true : false),
      lazyState,
    };

    dispatch(deleteIntentList(payload));
    setDeleteModal(false);
    setDeleteModal(false);
    setRowData(null);



    if (index + 1 === length || index === length) {
      setSelectAll(false);
      setSelectedParts([]);
      setTimeout(() => {
        dispatch(getIntentList(lazyState));
      }, 500);
    }
  };

  const archiveHandler = () => {
    setSelectAll(false);
    setSelectedParts([]);
    setArchiveModal(false);
  };

  const defaultColumns = {
    ...columns[0]
  }

  const onColumnToggle = (event: MultiSelectChangeEvent) => {
    let selectedColumns = event.value;
    let orderedSelectedColumns = columns.filter((col) => selectedColumns.some((sCol: any) => sCol.field === col.field));

    setVisibleColumns([{ ...defaultColumns }, ...orderedSelectedColumns]);
  };

  const [showCustomizeTable, setShowCustomizeTable] = useState(false);
  const customizeTableClickHandler = () => {
    setShowCustomizeTable(true);
  };


  const [tooltipOpen, setTooltipOpen] = useState(false);

  const toggleTooltip = () => setTooltipOpen(!tooltipOpen);
  const [selectedStatus, setselectedStatus] = useState(ACTIVE);

  const bulkActionClickHandler = () => {
    let filterValue = modifiedValues.filter((eachValue: any) => ((eachValue.status == INTENT_STATUS.REQUESTED || eachValue.status == INTENT_STATUS.REJECTED) ? true : false))
    if (modifiedValues.length > 0) {
      setSelectAll((filterValue.length == modifiedValues.length) ? true : false);
      setSelectedParts(filterValue);
    }
  };

  const cancelBulkActionHadler = () => {
    setSelectAll(false);
    setSelectedParts([]);
  };
  const clearFilter=()=>{
    setLazyState(initialLazyState)
    setselectedStatus(ACTIVE)
  }

  const customizeTableColumns: customizeTableColumns[] = columns.map((eachColumns: Columns) => {
    let data: any = {};
    data['field'] = eachColumns.field;
    data['value'] = visibleColumns.some((sCol: any) => sCol.field === eachColumns.field);
    data['isDisable'] = eachColumns.isStatic;
    data['header'] = eachColumns.header;
    return data;
  }).filter((eachColumns: customizeTableColumns) => eachColumns.field != 'select');
  const customizeTableCancelHandler = () => {
    setShowCustomizeTable(false);
  };
  const customizeColumnSubmitHandler = (customizeColumnObject: any[]) => {
    let orderedSelectedColumns = columns.filter((col) => customizeColumnObject.some((sCol: any) => sCol === col.field));

    setVisibleColumns([{ ...defaultColumns }, ...orderedSelectedColumns]);
    setShowCustomizeTable(false);
  };


  const header =
    <div className="table-header-container">
      <div>
        Indent List
      </div>
      {/* <BreadcrumbWithFilter
          title="Admin"
          breadcrumbItem="Parts"
          isShowFilter={false}
          isShowCreate={false}
          selectedCustomers={selectedParts}
          bulkActionSatusTitle="Parts"
          showBorderRight={true}
        />  */}
      <div className="table-header-container-item table-header-container-item-special">
        {(selectedParts.length == 0&& selectedStatus!="All") && <Button type="button" className="customize-table-button mr-1 " onClick={bulkActionClickHandler}>
          <FeatherIcon icon="layers" className="mr-1" />
          Bulk actions
        </Button>}
        <AnimatePresence>
          {selectedParts.length > 0 &&
            <>
              {/* <motion.div initial="initial" animate="animate" exit="exit" variants={BUTTON_VARIANT}>
            <Button type="button" className="secondary-btn  mr-1" onClick={() => { setArchiveModal(true); }}>
              <FeatherIcon icon="archive" className="mr-1" />
              Archive
            </Button>
          </motion.div> */}
              <motion.div initial="initial" animate="animate" exit="exit" variants={BUTTON_VARIANT}>
                <Button type="button" className="secondary-btn  mr-1 delete-space btn_space" onClick={() => { setDeleteModal(true); }}>
                  <FeatherIcon icon="trash-2" className="mr-1" />
                  {selectedStatus == DELETED ? (<span className="remove_lables">Restore</span>):(<span className="remove_lables">Delete</span>)}
                </Button>
              </motion.div>
              <motion.div initial="initial" animate="animate" exit="exit" variants={BUTTON_VARIANT}>
                <Button type="button" className="customize-table-button btn_space mr-1 ml-1" onClick={cancelBulkActionHadler} > <FeatherIcon icon="x" className="" /> <span className="remove_lables">Cancel selection</span></Button>
              </motion.div>
              <motion.div initial="initial" animate="animate" exit="exit" variants={BUTTON_VARIANT}>
                {selectedParts.length > 0 && <BulkActionStatus count={selectedParts.length} title="Indents"></BulkActionStatus>}
              </motion.div>


            </>
          }
        </AnimatePresence>

      </div>
      <div className="flex-grow-1"></div>
      <div className="table-header-container-item">
        <ExportFile exportExcel={csvClickHandler} exportPdf={pdfClickHandler} clearFilter={clearFilter} isFilter={true} />
        <TooltipMEL title="Select Status" position="bottom" tooltipVisible={isTooltipVisible} setTooltipVisible={setIsTooltipVisible}>
          <Dropdown value={selectedStatus} onChange={(e) => handleSubmit(e.value)} options={options} optionLabel="label" placeholder="Select status .."
            filter className="w-full md:w-14rem" />
        </TooltipMEL>

        <Button type="button" className="customize-table-button" id="ScheduleUpdateTooltip"
          onMouseEnter={() => setTooltipOpen(true)}
          onMouseLeave={() => setTooltipOpen(false)} data-btn="btn" onClick={customizeTableClickHandler}>
          <FeatherIcon icon="sliders" className="mr-1" />
        </Button>
        {/* <TooltipMEL title="Add Parts" position="bottom"> */}
        <Button type="button" className="btn btn-primary add_btn" onClick={onCreateClickHandler}>
          <FeatherIcon icon="plus-circle" className={"feathet-icon"} />
          Add Indent
        </Button>
        {/* </TooltipMEL> */}
        <UncontrolledTooltip
          placement="bottom"
          target="ScheduleUpdateTooltip"
          isOpen={tooltipOpen}
          toggle={toggleTooltip}
          innerClassName="tooltip-color"
        >
          Choose fields you want to see in the table
        </UncontrolledTooltip>
      </div>

    </div>;
  const confirmMessage = `Are you sure? You want to ${(status == INTENT_STATUS.APPROVED) ? "approve" : "reject"}!`
  return (
    <>
      <div className="page-content">
        {visible && dialogContainer(error)}
        <DeleteModal
          show={deleteModal}
          undo={selectedStatus == DELETED ? true : false}
          onDeleteClick={() => {
            if (rowData) {
              return handleDeleteUsers(rowData);
            }
            if (selectedParts?.length > 10) {
              showToast("Cannot delete more than 10 items at once", { type: "error" });
              setSelectedParts([]);
              setDeleteModal(false);
              setSelectAll(false);
              return setRowData(null);
            }
            if (rowData || selectedParts.length === 1) {
              handleDeleteUsers(rowData || selectedParts);
            } else if (selectedParts.length > 0) {
              for (let i = 0; i < selectedParts.length; i++) {
                handleDeleteUsers(selectedParts[i], selectedParts.length, i);
              }
            }
            // if (rowData) {
            //   handleDeleteUsers(rowData);
            // }
          }}
          onCloseClick={() => {
            setDeleteModal(false);
            setRowData(null);
          }}
        />
        <ArchiveModal
          show={archiveModal}
          message="you want to archieve these parts?"
          deleteLabel="Yes"
          closeLabel="No"
          onDeleteClick={() => {
            archiveHandler();
          }}
          onCloseClick={() => {
            setArchiveModal(false);
            setSelectAll(false);
            setSelectedParts([]);
          }}
        />
        {showCustomizeTable && <CustomizeTable onCancelHandler={customizeTableCancelHandler} onCustomizeColumnSubmitHandler={customizeColumnSubmitHandler} columns={customizeTableColumns} />}

        {filter && (
          <div className="card">
            <div className="card-body">
              <Formik
                initialValues={initialValues}
                validationSchema={validationSchema}
                onSubmit={handleSubmit}
                onReset={handleReset}
                enableReinitialize
              >
                {({ values, handleChange }) => {
                  return (
                    <Form onChange={handleChange}>
                      <Row>
                        <Col xs={12} lg={7} id="export-search-clear">

                        </Col>
                        <Col xs={12} lg={5} id="export-search-clear d-flex">
                          <Select
                            label=""
                            name="status"
                            onBlur={handleStatusBlur}
                            value={values.status}
                            onChange={handleChange}
                            options={options}
                            invalid={false}
                          />
                          <Button className="btn btn-primary ms-2" color="primary" type="reset">
                            Clear </Button>
                          <Button className="btn btn-primary ms-2" color="primary" type="submit">
                            Search </Button>
                        </Col>


                      </Row>
                    </Form>
                  );
                }}
              </Formik>
            </div>
          </div>
        )}
              <ConfirmationDialog message={confirmMessage} visible={status ? true : false} status={status} onHide={confirmationHideHandler} onSubmit={(status == INTENT_STATUS.APPROVED) ? approveConfirmHandler : rejectConfirmHandler} />
        <DataTableWrapper
          ref={dt}
          columnDetails={selectedStatus=="All"? [...visibleColumns]?.filter((items:any)=>items.field!="select") : visibleColumns}
          header={header}
          value={modifiedValues}
          lazy
          paginator={true}
          stripedRows={true}
          filterIcon={FilterIcon}
          rowHover={true}
          // filterDisplay="row"
          sortIcon={(setOrder) => <SortIcon order={setOrder} />}
          rowsPerPageOptions={[5, 10, 25, 50]}
          totalRecords={totalRecords}
          loading={loading}
          emptyMessage="No details found."
          paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
          currentPageReportTemplate="Displaying Indents  {last} records of {totalRecords} in total"
          selection={selectedParts}
          onSelectionChange={onSelectionChange}
          selectAll={selectAll}
          onSelectAllChange={onSelectAllChange}
          first={lazyState.first}
          onSort={onSort}
          sortField={lazyState.sortField}
          sortOrder={lazyState.sortOrder}
          onFilter={onFilter}
          filters={lazyState.filters}
          onPage={onPage}
          rows={lazyState.rows}
        ></DataTableWrapper>
      </div>
    </>
  );
}
