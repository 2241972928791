import { PendingJobsheetTatReportsReducerState } from "src/models/pages/PendingJobsheetTatReportsModel"
import { pendingJobsheetTatReportsActions } from "./actionTypes"



const initialState: PendingJobsheetTatReportsReducerState = {
  loading: false,
  error: "",
  message: "",
  pendingJobsheetTatReportList: {
    items: [],
    totalCount: 0
  }
}


const pendingJobsheetTatReportReducer = (state = initialState, action: any) => {
  switch (action.type) {
    case pendingJobsheetTatReportsActions.GET_PENDING_JOBSHEETS_TAT_REPORTS_LOADING:
      return {
        ...state,
        loading: true,
        error: "",
        message: ""
      }

    case pendingJobsheetTatReportsActions.GET_PENDING_JOBSHEETS_TAT_REPORTS_SUCCESS:

      return {
        ...state,
        loading : false,
        pendingJobsheetTatReportList : {
           ...state.pendingJobsheetTatReportList,
           items : action.payload.data.items,
           totalCount : action.payload.data.totalCount
        }
      }  

    case pendingJobsheetTatReportsActions.GET_PENDING_JOBSHEETS_TAT_REPORTS_ERROR :
      return {
        ...state,
        loading : false,
        error : action.payload
      }  

    default:
      return { ...state }
  }
}

export default pendingJobsheetTatReportReducer ;