import React from 'react';
import Barcode from 'react-barcode';
 
const BarcodeLabel = ({ number,width,height ,description,Col,partNumber}:any) => {
  return (
    <>
    {/* <p>Mount Electronics</p> */}
      <p style={{position:"absolute",zIndex:"99",fontSize:"12px",top:"-4px",textWrap:"wrap",left:"100px"}}><b>Mount Electronics</b></p>
      <Barcode value={number} width={width} height={height} displayValue={false} marginBottom={30}/>
      <p style={{position:"absolute",zIndex:"99",fontSize:"16px",bottom:"-25px",textWrap:"wrap",left:"20px"}}><b>{number}  : {partNumber} - {description.substring(0,20)}</b></p>
 
      </>
 
  );
};
 
 
export default BarcodeLabel;