import React, { useEffect, useRef, useState } from 'react';
import { Card, CardBody, Col, Row } from 'reactstrap';
import { FieldArray } from "formik";
import CheckboxInput from "src/components/UI/CheckboxInput";
import TooltipMEL from "src/components/UI/Tooltip";
import FloatInput from 'src/components/Common/FloatInput';
import { useDispatch, useSelector } from 'react-redux';
import { deletePendingCommand, getPendingReasonLoading } from 'src/store/actions';
import { RootReducerState } from 'src/store/reducers';
import { ConvertIsoStringWithTime, formatDateTimeFormServerView } from 'src/helpers/common_helpers';
import TextEditor from 'src/components/atoms/TextEditor';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import FeatherIcon from "feather-icons-react";
import { useToast } from 'src/components/Common/ReactToaster';
import JobsheetScanner from 'src/components/Common/Scanner/JobsheetScanner';

interface TabProps {
  activeTab: string | number;
  status: string;
  initialValues: any;
  touch: any;
  error: any;
  handleChange?: any;
  onBlur?: () => void;
  changeFlow: any;
  setFieldError: any;
  setFieldTouched: any;
  setFieldValue: any;
  jobId?:any;
  scanParts?:any;
  setScanParts?:any;
  values?:any;
}

const DefectivePart = (props: TabProps) => {
  const dispatch = useDispatch();
  const {showToast} = useToast();
  const [openScanner, setOpenScanner] = useState<boolean>(false);

  

  useEffect(() => {
    dispatch(getPendingReasonLoading())
  }, [])

  const openScannerHandler = () => {
    setOpenScanner(true)
  }
  const handleKeyPress = (e: any) => {
      e?.preventDefault();

    let toScan = numRef?.current ? numRef?.current?.value : "";
    if (!toScan) return;
    debugger
      let alreadyScanned = props.scanParts?.some((val:any)=>val.number == toScan);
      debugger
      if (alreadyScanned) {
        numRef.current.value = ""; 

        return showToast('SKU number already scanned', { type: "error" });
      }
      props.setScanParts((prev:any)=>[...prev,{number:toScan,id:0}]);
      numRef.current.value = ""; 
  };

  const setScanFromParent = (text:any) => {
    let alreadyScanned = props.scanParts?.some((val:any)=>val.number == text);
    if (alreadyScanned) {
      numRef.current.value = ""; 

      return showToast('SKU number already scanned', { type: "error" });
    }
    props.setScanParts((prev:any)=>[...prev,{number:text,id:0}]);
  }

    const numRef = useRef<any>(null)

    const scannerContainer = () => {
        return <JobsheetScanner visible={openScanner} onHide={() => setOpenScanner(false)} scanParts={props.scanParts} setScanParts={setScanFromParent}/>
      }

      const handleRemoveParts = (id: number) => {
        if(props.values.status == "Assigned To Technician"){
          const RemoveParts = props.scanParts.filter((item: any, index: number) => index !== id);
          props.setScanParts(RemoveParts);
        }
      };
  return (
    <div
      className={`job-sheet-tab-content  ${
        props.activeTab === "Defective Part" ? "" : "d-none"
      }`}
    >
      {openScanner && scannerContainer()}
      <Card className="pe-0 ps-0 mb-0 border-leftrigh-none">
        <div className="dealer-title-header font-size-15 border-bottom">
          Add Defective Part
        </div>

        <CardBody className="p-0">
          <div className="px-3 pb-3">
            <Row>
              <div className="custom-scan-input" style={{ width: "300px" }}>
                <div className="inp-icon scan-here">
                  <FeatherIcon icon="search" className="icon-lg left-icon" />
                  <input
                    className="icon-input"
                    type="text"
                    placeholder="Scan SKU here"
                    disabled={props.values.status != "Assigned To Technician"}
                    required={false}
                    ref={numRef}
                    onKeyDown={(e) => {
                      if (e.key === "Enter" || e.key === "Tab") {
                        handleKeyPress(e);
                      }
                    }}
                  />
                  {props.values.status == "Assigned To Technician" &&
                  <FeatherIcon
                    icon="camera"
                    className="icon-lg right-icon pointer"
                    onClick={openScannerHandler}
                  />
}
                </div>
              </div>
            </Row>
            <Row>{props.scanParts.length>0 &&
            <>
                <Card className='mt-3'>
                <div className="mt-2 text-bold">Scanned Sku</div>
                    <CardBody>
                    <div className="p-chips p-component p-inputwrapper p-inputwrapper-filled custom-chip">
                    <ul className="p-inputtext p-chips-multiple-container p-variant-filled">
                        {
                        props.scanParts.length > 0 && (
                        props.scanParts.map((item: any, index: number) => (
                          <li key={index} className="p-chips-token">
                            <span className="p-chips-token-label">{item.number}</span>
                           {props.values.status == "Assigned To Technician" && <FeatherIcon icon="x-circle" className="p-icon p-chips-token-icon" onClick={() => handleRemoveParts(index)} />}
                          </li>
                        ))
                    )}
                    </ul>
                        </div>
                    </CardBody>
                </Card>
                </>
}
            </Row>
            <Row>
              <div></div>
            </Row>
          </div>
        </CardBody>
      </Card>
    </div>
  );
}

export default DefectivePart