import { APIError } from "../components/errorModel";

export interface attendance {
  creationTime?: any;
  creatorId?: string;
  currentLocation?: string;
  deleterId?: any;
  deletionTime?: any;
  id?: number;
  isDeleted?: boolean;
  lastModificationTime?: any;
  lastModifierId?: any;
  logInLocation?: string;
  logInSource?: string;
  logInTime?: any;
  logOutLocation?: string;
  logOutTime?: string;
  status?: string;
  totalHours?: number;
  userId?: string;

  [key: string | number]: string | number | boolean | undefined,
}

export interface attendanceList {
  items: attendance[];
  totalCount: number;
}

export interface individualAttendanceDetails {
  userId: string
  logInTime: string
  logOutTime: string
  status: string
  totalHours: number
  currentLocation: string
  logInLocation: string
  logOutLocation: string
  logInSource: string
  userType: string
  name: string
  attendanceStatus: any
  userList: any
  day: number
  isDeleted: boolean
  deleterId: any
  deletionTime: any
  lastModificationTime: any
  lastModifierId: any
  creationTime: string
  creatorId: any
  id: number
}




export interface attendanceInitialState {
  error: APIError| string;
  message: string;
  loading: boolean;
  attendanceList: attendanceList;
  paginatorCount: number;
  attendance: attendance | null;
  workingHours: null;
  reloadData: boolean;
  isLogout: boolean;
  attendanceDetail:individualAttendanceDetails[]
}



export enum LogInSource {
  Mobile = "Mobile",
  Web = "Web",
}
export const attendanceApplicableUsers: any = [
  { value: "Employee", label: "MEL Employee" },
  { value: "Franchise", label: "Franchise" },
  // { value: "Dealer", label: "Dealer" },
  // { value: "DealerSubUser", label: "Dealer SubUser" },
  { value: "FranchiseSubUser", label: "FranchiseSubUser" },
];
//{ value: "FranchiseSubUser", label: "Franchise SubUser" },