export enum PartSkuTypes {

  // Grid
  GET_PART_SKU_LIST = "@@partSku/GET_PART_SKU_LIST",
  GET_PART_SKU_SUCCESS = "@@partSku/GET_PART_SKU_SUCCESS",
  GET_PART_SKU_FAIL = "@@partSku/GET_PART_SKU_FAIL",

  // location
  GET_LOCATION_LIST = "@@partSku/GET_LOCATION_LIST",
  GET_LOCATION_SUCCESS = "@@partSku/GET_LOCATION_SUCCESS",
  GET_LOCATION_FAIL = "@@partSku/GET_LOCATION_FAIL",

  GET_PART_AUTOCOMPLETE = "@@partSku/GET_PART_AUTOCOMPLETE",
  GET_PART_AUTOCOMPLETE_SUCCESS = "@@partSku/GET_PART_AUTOCOMPLETE_SUCCESS",
  GET_PART_AUTOCOMPLETE_FAIL = "@@partSku/GET_PART_AUTOCOMPLETE_FAIL",

  GET_PART_SKU_AUTOCOMPLETE = "@@partSku/GET_PART_SKU_AUTOCOMPLETE",
  GET_PART_SKU_AUTOCOMPLETE_SUCCESS = "@@partSku/GET_PART_SKU_AUTOCOMPLETE_SUCCESS",
  GET_PART_SKU_AUTOCOMPLETE_FAIL = "@@partSku/GET_PART_SKU_AUTOCOMPLETE_FAIL",

  //Reset

  RESET_PART_SKU_MESSAGE = "@@partSku/RESET_PART_SKU_MESSAGE",

  // DELETE_PART_SKU = "@@partSku/DELETE_PART_SKU",
  // DELETE_PART_SKU_SUCCESS = "@@partSku/DELETE_PART_SKU_SUCCESS",
  // DELETE_PART_SKU_FAIL = "@@partSku/DELETE_PART_SKU_FAIL",
  // //reset
  // RESET_USER = "RESET_USER",
  // //create user
  // CREATE_USER_LOADING = "CREATE_USER_LOADING",
  // CREATE_USER_SUCCESS = "CREATE_USER_SUCCESS",
  // CREATE_USER_ERROR = "CREATE_USER_ERROR",

  // //update user
  // UPDATE_USER_LOADING = "UPDATE_USER_LOADING",
  // UPDATE_USER_SUCCESS = "UPDATE_USER_SUCCESS",
  // UPDATE_USER_ERROR = "UPDATE_USER_ERROR"
}
