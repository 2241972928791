import React from 'react';
import "./file.scss";
import TooltipMEL from 'src/components/UI/Tooltip';
import { Button } from 'reactstrap';
import FeatherIcon from "feather-icons-react";
import filter from 'src/assets/images/clear-filter.png';
import { InputSwitch } from 'primereact/inputswitch';
interface ExportFileModel {
    exportPdf?: () => void;
    exportExcel?: () => void;
    isShowPdf?: boolean;
    isShowCsv?: boolean;
    TooltipPosition?: "top" | "right" | "bottom" | "left" | undefined;
    isFilter?: boolean;
    clearFilter?: () => void;
    toggle?: boolean;
    checkedValue?: any;
    setChecked?: any
}

const ExportFile = ({ exportExcel = () => { }, exportPdf = () => { }, isShowPdf = true, isShowCsv = true, TooltipPosition = "bottom", isFilter = false, clearFilter = () => { }, toggle, checkedValue, setChecked }: ExportFileModel) => {
    return (
        <React.Fragment>
            <div className="ms-2 mt-2 d-flex align-items-center" id="export-btn">
                {toggle &&
                    <>
                        <div>Franchise Assigned Job List</div> &nbsp;
                        <InputSwitch checked={checkedValue} onChange={(e) => setChecked(e.value)} />
                    </>
                }
                {isFilter && <TooltipMEL title="Clear All Filter" position={TooltipPosition}>
                    <img src={filter} className={"feathet-icon  clear-filter-icon"} onClick={clearFilter} />
                </TooltipMEL>}
                {isShowCsv && <TooltipMEL title="Download as Excel File" position={TooltipPosition}><i className="fas fa-file-excel icon-style-csv-pdf csv-icon" onClick={exportExcel}></i></TooltipMEL>}
                {isShowPdf && <TooltipMEL title="Download as Pdf File" position={TooltipPosition}><i className="far fa-file-pdf icon-style-csv-pdf pdf-icon" onClick={exportPdf}></i></TooltipMEL>}
            </div>
        </React.Fragment>
    )
}

export default ExportFile