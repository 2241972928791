import { PartTransactionTypes } from "./actionTypes";

export const getPartTransactionList = (lazyState: any) => {
    return {
      type: PartTransactionTypes.GET_DATE_WISE_PART_TRANSACTION_LIST,
      lazyState,
    };
};

export const getPartTransactionListSuccess = (resp: any) => {
    return {
      type: PartTransactionTypes.GET_DATE_WISE_PART_TRANSACTION_LIST_SUCCESS,
      payload:resp,
    };
};

export const getPartTransactionListError = (error: any) => {
    return {
      type: PartTransactionTypes.GET_DATE_WISE_PART_TRANSACTION_LIST_FAIL,
      payload:error,
    };
};