import React, { useEffect, useRef, useState } from "react";
import { Row, Col, Button, UncontrolledTooltip } from "reactstrap";
import { Form, Formik } from "formik";
import * as Yup from "yup";
import { Option } from "src/models/components/inputWrapperModel";
import {
  Columns,
  LazyTableState,
} from "src/models/components/dataTableWrapperModel";
import DataTableWrapper from "src/components/Grid/DataTableWrapper";
import { DataTable, DataTableFilterMeta } from "primereact/datatable";
import { useDispatch, useSelector } from "react-redux";
import ColumnAction from "src/components/Common/ColumnAction/ColumnAction";
import { useNavigate } from "react-router-dom";
import DeleteModal from "../../Calendar/DeleteModal";
import ArchiveModal from "../../Calendar/DeleteModal";
import { useToast } from "src/components/Common/ReactToaster";
import Select from "src/components/Common/Select";
import StatusHghlighter from "src/components/Common/StatusHighlighter/StatusHighlighter";
import { audioModels } from "src/models/pages/audioModelsModel";
import { deleteAudioModelsFromList, getAudioBrandDropDownListLoading, getAudioModelAutoComplete, getAudioModelsList, resetAudioModelState } from "src/store/actions";
import { CurrencyFormatter, saveAsExcelFile } from "src/helpers/common_helpers";
import { ROUTER } from "src/constants/routes";
import { MultiSelectChangeEvent } from "primereact/multiselect";
import { Dropdown, DropdownChangeEvent } from "primereact/dropdown";
import { AnimatePresence, motion } from "framer-motion";
import { BUTTON_VARIANT } from "src/constants/bulkActionVariant";
import FeatherIcon from "feather-icons-react";
import CustomizeTable from "src/components/Common/CustomizeTable/CustomizeTable";
import TooltipMEL from "src/components/UI/Tooltip";
import BulkActionStatus from "src/components/Common/BulkActionStatus/BulkActionStatus";
import SortIcon from "src/components/Common/CustomFilterandSortIcon/SortIcon";
import { RootReducerState } from "src/store/reducers";
import ExportFile from "src/components/Common/ExcelPdf Export/ExportFile";
import FilterIcon from "src/components/Common/CustomFilterandSortIcon/FilterIcon";
import { ColumnFilterElementTemplateOptions } from "primereact/column";
import { ACTIVE, DELETED } from "src/constants/commonConstants";
import ValidationPopup from "src/components/Common/ValidationMessage/ValidationPopup";
import { Link } from "react-router-dom";
import { API } from "src/util/api";
import { AutoComplete } from "primereact/autocomplete";
import { customizeTableColumns } from "src/models/components/CustomizeTableColumns";
// import { Audio, Bars } from 'react-loader-spinner'

export default function AudioModels() {
  const navigate = useNavigate();
  const [filter, setFilter] = useState(false);
  const [audioModel, setAudioModel] = useState<any[]>([])
  const { error, paginatorCount, message,audioModelAudoCompleteList } = useSelector((state: RootReducerState) => state.audioModelsReducer);
  const { values, totalRecords } = useSelector((state: RootReducerState) => state.audioModelsReducer.audioModelsList) || {};
  const { audioBrandList } = useSelector((state: RootReducerState) => state.audioModelsReducer.audioBrandNames) || {};
  const { showToast } = useToast();
  const loading = useSelector((state: any) => state.audioModelsReducer.loading);
  const[pdfLoading,setPdfLoading]=useState<boolean>(false)

  const dispatch = useDispatch();
  const dt = useRef<DataTable<any>>(null);
  // for delete invoice
  const [deleteModal, setDeleteModal] = useState(false);
  const [archiveModal, setArchiveModal] = useState(false);
  const [visible, setVisible] = useState(false);
  const [rowData, setRowData] = useState<audioModels | null>(null);
  const filterDetails: DataTableFilterMeta = {
    Number: { value: "", matchMode: "contains" },
    audioPartNumber: { value: "", matchMode: "contains" },
    AudioBrandId: { value: "", matchMode: "contains" },
    status: { value: "", matchMode: "contains" },
    IsDeleted: { value: "false", matchMode: "contains" },
  };
  let initialLazyState: LazyTableState = {
    first: 0,
    rows: 10,
    page: 1,
    // sortField: "Number",
    // sortOrder: 1,
    // sortField: "CreationTime",
    // sortOrder: -1,
    sortField: "CreationTime",
    sortOrder: -1,
    filters: filterDetails,
  };
  const [lazyState, setLazyState] = useState(initialLazyState);
  const [isTooltipVisible, setIsTooltipVisible] = useState<boolean>(false);

  const initialValues = {
    status: "",
  };
  const onFilterClickHandler = () => {
    setFilter((preState) => !preState);
  };
  const onCreateClickHandler = () => {
    navigate(ROUTER.AUDIO_MODELS_CREATE);
  };

  const viewHandler = (rowData: audioModels) => {
    navigate(`${ROUTER.AUDIO_MODELS_VIEW}/${rowData["id"]}`);
  };
  const editHandler = (rowData: audioModels) => {
    navigate(`${ROUTER.AUDIO_MODELS_EDIT}/${rowData["id"]}`);
  };
  const deleteHandler = (rowData: audioModels) => {
    setDeleteModal(true);
    setRowData(rowData);
  };

  const actionBodyTemplate = (rowData: any, field: string) => {
    return (
      <>
        {
          rowData.isDeleted == false ? (<ColumnAction
            displayData={rowData[field] as string}
            isEdit={true}
            isDelete={true}
            isView={true}
            onViewClick={() => viewHandler(rowData)}
            onEditClick={() => editHandler(rowData)}
            onDeleteClick={() => deleteHandler(rowData)}
          />
          ) : (
            <ColumnAction
              displayData={rowData[field] as string}
              undo={rowData.isDeleted ? true : false}
              onDeleteClick={() => deleteHandler(rowData)}
            />
          )
        }
      </>
    );
  };

  const statusBodyTemplate = (rowData: audioModels, field: string) => {
    let status = rowData[field] as string
    return (
      <StatusHghlighter status={status} />
    )

  }


  const audioModelListConverson = (values: any) => {

    const modifeidValue = values?.map((eachValue: any) => {
      let data: any = {};
      data['Number'] = eachValue.number;
      data['description'] = eachValue.description;
      data['serviceCharge'] = eachValue.serviceCharge;
      data['exchangeFixedFee'] = eachValue.exchangeFixedFee;
      data['sacNumber'] = eachValue.sacNumber;
      data['taxpercentage'] = eachValue.taxpercentage;
      data['sacNumber'] = eachValue.sacNumber;
      data['taxpercentage'] = eachValue.taxpercentage;
      data['audioBrandName'] = eachValue.audioBrandName;
      data['audioPartNumber'] = eachValue.audioPartNumber;
      return data;
    });
    // setAudioModel(modifeidValue)
    return modifeidValue;
  }

  const modifiedValues: any = audioModelListConverson(values);

  const options: Option[] = [
    { label: "All", value: "All" },
    { label: "Active", value: "Active" },
    { label: "Deleted", value: "Deleted" },
  ];



  const currencyBodyTemplate = (rowData: audioModels, field: string) => {
    let amount = rowData[field]

    return (<>{CurrencyFormatter({ value: amount })}</>)
  }



  const AudioBrandFliter = (options: ColumnFilterElementTemplateOptions) => {

    return (<>
      <Dropdown
        options={(audioBrandList?.length > 0) ? (audioBrandList?.map((eachSupplier: any) => {
          let data: any = {};
          data['label'] = eachSupplier?.brandName;
          data['value'] = eachSupplier?.id;
          return data;
        })).sort((a:any, b:any) => {
          const labelA =a.label.toUpperCase().trim();
          const labelB = b.label.toUpperCase().trim();
          if (labelA < labelB) {
            return -1;
          }
          if (labelA > labelB) {
            return 1;
          }
          return 0;
        }) : []}
        name="audiobrandId"
        optionLabel="label"
        filter
        onChange={(e: DropdownChangeEvent) => {
          options.filterCallback(e.value, options.index)
          return {};
        }}
        required={true}
        value={options.value}
      />
    </>)
  };


  const AudioBrandTemplate = (rowData: any, field: any) => {
    
    
    return (
      <Link to={`${ROUTER.AUDIO_BRANDS_VIEW}/${rowData['audioBrandId']}`}>{rowData[field]}</Link>
    )
  }
  const searchParts = (event: any) => {

    dispatch(getAudioModelAutoComplete(event.query));
  };
  const[optionsList,setOptionsList]=useState([])

  const audioModelFilterTemplate = (options: ColumnFilterElementTemplateOptions) => {
    return (<div className="autoCompleteFilter">
      <AutoComplete value={options.value} suggestions={optionsList} completeMethod={searchParts} onChange={(e: DropdownChangeEvent) => {
          options.filterCallback(e.value, options.index)
          return {};
        }} className="w-full md:w-20rem padding-none" />
    </div>)
  };


  const headerTemplate = (val: any) => {
    return (
      <div className="d-flex justify-content-start">
        <div className="d-flex justify-content-end" style={{ width: "100px" }}  >
          <div className="align-right">
            {val}
          </div>
        </div>
      </div>
    );
  };

  const columns: Columns[] = [
    {
      field: "select",
      sortable: false,
      header: "",
      filter: false,
      showFilterMenu: false,
      selectionMode: "multiple",
      headerStyle: { width: "3rem", whiteSpace: "nowrap" },
      isDefault: true,
      frozen : true,
      isStatic: false,
    },
    {
      field: "Number",
      sortable: true,
      header: "Model Number",
      filter: true,
      showFilterMenu: true,
      frozen : true,
      headerStyle: { whiteSpace: "nowrap", width: "250px" },
      body: (rowData) => actionBodyTemplate(rowData, "Number"),
      isDefault: true,
      isStatic: true,
      filterElement:audioModelFilterTemplate,
    },
    {
      field: "AudioBrandId",
      sortable: true,
      header: "Audio Brand",
      filter: true,
      showFilterMenu: true,
      headerStyle: { whiteSpace: "nowrap" },
      body:(rowData:any)=>AudioBrandTemplate(rowData,'AudioBrandId'),
      isDefault: true,
      isStatic: false,
      filterElement: AudioBrandFliter
    },
    {
      field: "audioPartNumber",
      sortable: true,
      header: "Audio Part Number",
      filter: true,
      showFilterMenu: true,
      headerStyle: { whiteSpace: "nowrap" },
      isDefault: true,
      isStatic: false,
    },
    {
      field: "description",
      sortable: true,
      header: "Description",
      filter: false,
      showFilterMenu: false,
      headerStyle: { whiteSpace: "nowrap" },
      isDefault: true,
      isStatic: false,
    },
    {
      field: "sacNumber",
      sortable: true,
      header: "SAC Number",
      filter: false,
      showFilterMenu: false,
      headerStyle: { whiteSpace: "nowrap" },
      isDefault: true,
      isStatic: false,
    },
    {
      field: "taxpercentage",
      sortable: true,
      header: "SAC Tax %",
      filter: false,
      showFilterMenu: false,
      headerStyle: { whiteSpace: "nowrap" },
      isDefault: true,
      body: (rowData) => headerTemplate(rowData.taxpercentage),
      isStatic: false,
      className: "align-right"
    },
    {
      field: "serviceCharge",
      sortable: true,
      header: "Service Charge",
      filter: false,
      showFilterMenu: false,
      body: (rowData) => headerTemplate(currencyBodyTemplate(rowData, "serviceCharge")),
      headerStyle: { whiteSpace: "nowrap" },
      isDefault: true,
      isStatic: false,
      className: "align-right"
    },
    {
      field: "exchangeFixedFee",
      sortable: true,
      header: "Exchange Fixed Fee",
      filter: false,
      showFilterMenu: false,
      body: (rowData) => headerTemplate(currencyBodyTemplate(rowData, "exchangeFixedFee")),
      headerStyle: { whiteSpace: "nowrap" },
      isDefault: true,
      isStatic: false,
      className: "align-right"
    }
  ];

  const [visibleColumns, setVisibleColumns] = useState(columns.filter((eachColumn: Columns) => eachColumn.isDefault));
  useEffect(()=>{
  if(audioModelAudoCompleteList){
    setOptionsList(audioModelAudoCompleteList?.map((item:any)=>item.number))
    
  }
  },[audioModelAudoCompleteList])
  useEffect(()=>{
    setVisibleColumns(columns.filter((eachColumn: Columns) => eachColumn.isDefault))
  },[optionsList])
  const changeColumnDetails = (values: any[]) => {
    let modifiedColumnDetails = values.map((eachDealer) => {
      const { Number, description, serviceCharge, exchangeFixedFee, sacNumber, taxpercentage, audioBrandName, audioPartNumber } = eachDealer;
      let data: any = {};
      data['Model Number'] = Number;
      data['Description'] = description;
      data['Service Charge'] = serviceCharge;
      data['Exchange Fixed Fee'] = exchangeFixedFee;
      data['SAC Number'] = sacNumber;
      data['SAC Tax'] = taxpercentage;
      data['Audio Brand'] = audioBrandName;
      data['Audio Part Number'] = audioPartNumber;
      return { ...data };
    });
    return modifiedColumnDetails;
  };


  const hidePopup = () => {
    setVisible(false);
    dispatch(resetAudioModelState())
  };

  const dialogContainer = (error: any) => {
    return (
      <>
        {/* <Dialog header="Validation Error" visible={visible} maximizable className="vw30" onHide={() => { setVisible(false); dispatch(resetAudioModelState()); }}>
          <div className="m-0 validation-text">
            {error?.split(".,").map((eachLine: string, index: number) => {
              return (<div key={index}><span className="validation-error-index">{index + 1}</span><span className="validation-error-line">{eachLine}</span></div>);
            })}
          </div>
        </Dialog> */}
        <ValidationPopup error={error} visible={visible} onHide={hidePopup} />
      </>
    );
  };


  const saveDataAsExcel = (values: any[], fileName: string) => {
    const modifiedColumnDetails = changeColumnDetails(values)
    import('xlsx').then((xlsx) => {
      const worksheet = xlsx.utils.json_to_sheet(modifiedColumnDetails);
      const workbook = { Sheets: { data: worksheet }, SheetNames: ['data'] };
      const excelBuffer = xlsx.write(workbook, {
        bookType: 'xlsx',
        type: 'array'
      });
      saveAsExcelFile(excelBuffer, fileName);
    });
  };
  const fetchData = async (isPdf:boolean) => {
    let state:any= {
      ...lazyState,
      rows:totalRecords>5000 ? 5000 : totalRecords<1 ? 1 : totalRecords,
      first:0,
      filters: {
        ...lazyState.filters,
      },
    };
    try {
      setPdfLoading(true)
      const response = await API.getAudioModelsListAPI(state);
      if (!response) {
        throw new Error('Network response was not ok');
      }
      const modifeidValue = response?.data?.items?.map((eachValue: any) => {
        let data: any = {};
        data['Number'] = eachValue.number;
        data['description'] = eachValue.description;
        data['serviceCharge'] = eachValue.serviceCharge;
        data['exchangeFixedFee'] = eachValue.exchangeFixedFee;
        data['sacNumber'] = eachValue.sacNumber;
        data['taxpercentage'] = eachValue.taxpercentage;
        data['sacNumber'] = eachValue.sacNumber;
        data['taxpercentage'] = eachValue.taxpercentage;
        data['audioBrandName'] = eachValue.audioBrandName;
        data['audioPartNumber'] = eachValue.audioPartNumber;
        return data;
      });
      // let resp=await audioModelListConverson(response?.data?.items)
     if(isPdf){

       saveDataAsPdf(modifeidValue, columns, "Audio Models");
     }else{
      saveDataAsExcel(modifeidValue, "Audio Models");
     }
     setPdfLoading(false)
    } catch (error:any) {
      // console.log(error);
	  showToast(error.message,{type:"error"})
      setPdfLoading(false)
      
    } finally {
      setPdfLoading(false)
    }
  };
  const csvClickHandler = () => {
    // saveDataAsExcel(modifiedValues, "audioModels");
    fetchData(false)
  };

  const saveDataAsPdf = (values: any[], columns: any[], fileName: string) => {
    const destructureedData = values.map((eachValue) => {
      let data: any = {};
      data['Number'] = eachValue.Number;
      data['description'] = eachValue.description;
      data['serviceCharge'] = eachValue.serviceCharge;
      data['exchangeFixedFee'] = eachValue.exchangeFixedFee;
      data['sacNumber'] = eachValue.sacNumber;
      data['taxpercentage'] = eachValue.taxpercentage;
      data['AudioBrandId'] = eachValue.audioBrandName;
      data['audioPartNumber'] = eachValue.audioPartNumber;
      return data
    });
    import("jspdf").then((jsPDF) => {
      import("jspdf-autotable").then(() => {
        const exportColumns = columns.map((col) => ({
          title: col.header,
          dataKey: col.field,
        }));
        const doc = new (jsPDF as any).default(0, 0);
        doc.autoTable(exportColumns, destructureedData);
        doc.save(`${fileName}.pdf`);
      });
    });
  };

  const pdfClickHandler = () => {
    // saveDataAsPdf(values, columns, "audioModels");
    fetchData(true)
  };

  const validationSchema = Yup.object({});

  const callbackFunction = (lazyState: LazyTableState) => {
    dispatch(getAudioBrandDropDownListLoading())
    dispatch(getAudioModelsList(lazyState));
  };

  const [selectAll, setSelectAll] = useState(false);
  const [selectedAudioModels, setSelectedAudioModels] = useState<any>([]);

  const onSelectionChange = (event: any) => {
    if(selectedStatus=="All"){
      const value = event.value;
      let filteredData=value?.filter((items:any)=>items?.isDeleted!=true)
      setSelectedAudioModels(filteredData);
      setSelectAll(value.length==paginatorCount)
    
    }else{
      const value = event.value;
      setSelectedAudioModels(value);
      setSelectAll(value.length === paginatorCount);
    }
  };

  const onSelectAllChange = (event: any) => {
    const selectAll = event.checked;
    if(selectedStatus=="All"){
      const selectAll = event.checked;
      if (selectAll) {
      let filteredData=audioModel?.filter((items:any)=>items?.isDeleted!=true)
      setSelectedAudioModels(filteredData);
      setSelectAll(audioModel.length==filteredData.length)
      }else{
        setSelectAll(false);
        setSelectedAudioModels([]);
      }
    }else{
    if (selectAll) {
      setSelectAll(true);
      setSelectedAudioModels(audioModel);
    } else {
      setSelectAll(false);
      setSelectedAudioModels([]);
    }
  }
  };

  const handleStatusBlur = () => { };

  const onPage = (event: any) => {
    setLazyState(event);
  };

  const onSort = (event: any) => {
    setSelectAll(false);
    setSelectedAudioModels([]);
    setLazyState(event);
  };
  const onFilter = (event: any) => {
    event["first"] = 0;
    setLazyState(event);
  };

  const handleSubmit = (formValue: any) => {
    setselectedStatus(formValue);
    if (formValue == ACTIVE) {
      setLazyState((state) => ({
        ...state,
        filters: {
          ...state.filters,
          IsDeleted: { value: "false", matchMode: "contains" },
        },
      }));
    }
    else if (formValue == DELETED) {
      setLazyState((state) => ({
        ...state,
        filters: {
          ...state.filters,
          IsDeleted: { value: "true", matchMode: "contains" },
        },
      }));
    }
    else {
      setLazyState((state) => ({
        ...state,
        filters: {
          ...state.filters,
          IsDeleted: { value: null, matchMode: "contains" },
        },
      }));
    }
    setIsTooltipVisible(false)
    setVisibleColumns(visibleColumns)
  };

  const handleReset = (formValue: any) => {
    setLazyState((state) => ({
      ...state,
      filters: {
        ...state.filters,
        status: {
          ...state.filters.status,
          value: "",
        }
      },
    }));
  };

  useEffect(() => {
    setSelectAll(false);
    setSelectedAudioModels([]);
    callbackFunction(lazyState);
  }, [lazyState]);

  useEffect(() => {
    setTimeout(() => {
      if (error) {
        // showToast(error.message, { type: "error" })
        setVisible(true);
        // dispatch(resetAudioModelState())
      }
    }, 500)
    if (message) {
      showToast(message, { type: "success" });
      dispatch(resetAudioModelState());
      dispatch(getAudioModelsList(lazyState));

    }
  }, [error, message])

  useEffect(() => {
    if (values?.length > 0) {
      const dataStructre = values.map((data: any) => {
        let { number, audioBrandName, ...remain } = data;
        let modified = {
          Number: number,
          AudioBrandId: audioBrandName,
          ...remain
        };
        return modified;
      });
      setAudioModel(dataStructre)
    } else {
      setAudioModel(values)
    }
  }, [values])

  useEffect(() => {
    // return () => {
    //   dispatch(resetAudioModelState())
    // }
  }, [])

  useEffect(() => {
    setVisibleColumns(columns.filter((eachColumn: Columns) => eachColumn.isDefault))
  }, [audioBrandList])

  useEffect(() => {
    const preloader: any = document.getElementById("preloader");
    if (loading||pdfLoading) {
      preloader.style.display = "block";
    } else {
      preloader.style.display = "none";
    }
  }, [loading,pdfLoading]);


  const handleDeleteUsers = (rowData: any, length: number = 0, index: number = 0) => {
    let payload = {
      id: rowData["id"] ? rowData["id"] : rowData[0]?.id,
      undo: rowData?.isDeleted == true ? true : rowData[0]?.isDeleted == true ? true : false,
      lazyState,
    };
    dispatch(deleteAudioModelsFromList(payload));
    setDeleteModal(false);
    setRowData(null);
    if (index + 1 === length || index === length) {
      setSelectAll(false);
      setSelectedAudioModels([]);
    }
  };

  const archiveHandler = () => {
    setSelectAll(false)
    setSelectedAudioModels([])
    setArchiveModal(false)
  }

  const defaultColumns = {
    ...columns[0]
  }

  const onColumnToggle = (event: MultiSelectChangeEvent) => {
    let selectedColumns = event.value;
    let orderedSelectedColumns = columns.filter((col) => selectedColumns.some((sCol: any) => sCol.field === col.field));

    setVisibleColumns([{ ...defaultColumns }, ...orderedSelectedColumns]);
  };

  const [showCustomizeTable, setShowCustomizeTable] = useState(false);
  const customizeTableClickHandler = () => {
    setShowCustomizeTable(true);
  };


  const [tooltipOpen, setTooltipOpen] = useState(false);

  const toggleTooltip = () => setTooltipOpen(!tooltipOpen);
  const [selectedStatus, setselectedStatus] = useState(ACTIVE);

  const bulkActionClickHandler = () => {
    if(selectedStatus=="All"){

      let filteredData=audioModel?.filter((items:any)=>items?.isDeleted!=true)
      setSelectedAudioModels(filteredData);
      setSelectAll(audioModel.length==filteredData.length)
      
    }else{
    if (values.length > 0) {
      setSelectAll(true);
      setSelectedAudioModels(audioModel);
    }
  }
  };

  const cancelBulkActionHadler = () => {
    setSelectAll(false);
    setSelectedAudioModels([]);
  };
  const customizeTableColumns: customizeTableColumns[] = columns.map((eachColumns: Columns) => {
    let data: any = {};
    data['field'] = eachColumns.field;
    data['value'] = visibleColumns.some((sCol: any) => sCol.field === eachColumns.field);
    data['isDisable'] = eachColumns.isStatic;
    data['header'] = eachColumns.header;
    return data;
  }).filter((eachColumns: customizeTableColumns) => eachColumns.field != 'select');

  const customizeTableCancelHandler = () => {
    setShowCustomizeTable(false);
  };
  const customizeColumnSubmitHandler = (customizeColumnObject: any[]) => {


    let orderedSelectedColumns = columns.filter((col) => customizeColumnObject.some((sCol: any) => sCol === col.field));

    setVisibleColumns([{ ...defaultColumns }, ...orderedSelectedColumns]);
    setShowCustomizeTable(false);
  };
  const clearFilter=()=>{
    setLazyState(initialLazyState)
    setselectedStatus(ACTIVE)
  }

  const header =
    <div className="table-header-container">
      <div>
        Audio Models List
      </div>
      {/* <BreadcrumbWithFilter
        title="Admin"
        breadcrumbItem="Audio Models"
        isShowFilter={false}
        isShowCreate={false}
        selectedCustomers={selectedAudioModels}
        bulkActionSatusTitle="Audio Models"
        showBorderRight={true}
      /> */}
      <div className="table-header-container-item table-header-container-item-special">
        {(selectedAudioModels.length == 0 && selectedStatus!="All") &&
          // <TooltipMEL title="Bulk Action" position="right">
          <Button type="button" className="customize-table-button mr-1 " onClick={bulkActionClickHandler}>
            <FeatherIcon icon="layers" className="mr-1" />
            Bulk actions
          </Button>
          // </TooltipMEL>
        }
        <AnimatePresence>
          {selectedAudioModels.length > 0 &&
            <>
              {/* <motion.div initial="initial" animate="animate" exit="exit" variants={BUTTON_VARIANT}>
            <Button type="button" className="secondary-btn  mr-1" onClick={() => { setArchiveModal(true); }}>
              <FeatherIcon icon="archive" className="mr-1" />
              Archive
            </Button>
          </motion.div> */}
              <motion.div initial="initial" animate="animate" exit="exit" variants={BUTTON_VARIANT}>
                <Button type="button" className="secondary-btn  mr-1 delete-space btn_space" onClick={() => { setDeleteModal(true); }}>
                  {selectedStatus == DELETED ? <><i className="fa fa-undo option-icons special"></i><span className="remove_lables">Restore</span> </> : <><FeatherIcon  icon="trash-2" className="mr-1" /> <span className="remove_lables">Delete</span></> }
                </Button>
              </motion.div>
              <motion.div initial="initial" animate="animate" exit="exit" variants={BUTTON_VARIANT}>
                <Button type="button" className="customize-table-button btn_space mr-1" onClick={cancelBulkActionHadler} > <FeatherIcon icon="x" className="" /> <span className="remove_lables">Cancel selection</span></Button>
              </motion.div>

              <motion.div initial="initial" animate="animate" exit="exit" variants={BUTTON_VARIANT}>
                {selectedAudioModels.length > 0 && <BulkActionStatus count={selectedAudioModels.length} title="Audio Models"></BulkActionStatus>}
              </motion.div>

            </>
          }
        </AnimatePresence>

      </div>
      <div className="flex-grow-1"></div>
      <div className="table-header-container-item">
        <div style={{ display: "flex" }}>
          <ExportFile exportExcel={csvClickHandler} exportPdf={pdfClickHandler} clearFilter={clearFilter} isFilter={true}/>
          <TooltipMEL title="Select Status" position="bottom" tooltipVisible={isTooltipVisible} setTooltipVisible={setIsTooltipVisible}>
            <Dropdown value={selectedStatus} onChange={(e) => handleSubmit(e.value)} options={options} optionLabel="label" placeholder="Select status .."
              filter className="w-full md:w-14rem" />
          </TooltipMEL>
          <Button type="button" className="customize-table-button" id="ScheduleUpdateTooltip"
            onMouseEnter={() => setTooltipOpen(true)}
            onMouseLeave={() => setTooltipOpen(false)} data-btn="btn" onClick={customizeTableClickHandler}>
            <FeatherIcon icon="sliders" className="" />
          </Button>
        </div>
        <div className="reponsive_position">
          {/* <TooltipMEL title="Add Audio Model" position="bottom"> */}
          <Button type="button" className="btn btn-primary add_btn" onClick={onCreateClickHandler}>
            <FeatherIcon icon="plus-circle" className={"feathet-icon"} />
            Add Audio Model
          </Button>
        </div>
        {/* </TooltipMEL> */}

        <UncontrolledTooltip
          placement="bottom"
          target="ScheduleUpdateTooltip"
          isOpen={tooltipOpen}
          toggle={toggleTooltip}
          innerClassName="tooltip-color"
        >
          Choose fields you want to see in the table
        </UncontrolledTooltip>
      </div>

    </div>;

  



  return (
    <>
      <div className="page-content">
        {/* <div className={loading ? `loading-container` : `d-none`}>
          <Bars
            height="80"
            width="80"
            color="#5156BE"
            ariaLabel="bars-loading"
            wrapperStyle={{}}
            wrapperClass=""
            visible={loading}
          />
        </div> */}
        {visible && dialogContainer(error)}
        <DeleteModal
          show={deleteModal}
          undo={selectedStatus == DELETED ? true : false}
          onDeleteClick={() => {
            if (selectedAudioModels?.length > 10) {
              showToast("Cannot delete more than 10 items at once", { type: "error" });
              setSelectedAudioModels([])
              setDeleteModal(false);
              setSelectAll(false)
              return setRowData(null);
            }

            if (rowData || selectedAudioModels.length === 1) {
              handleDeleteUsers(rowData || selectedAudioModels);
            } else if (selectedAudioModels.length > 1) {
              for (let i = 0; i < selectedAudioModels.length; i++) {
                handleDeleteUsers(selectedAudioModels[i], selectedAudioModels.length, i);
              }
            }
          }}
          onCloseClick={() => {
            setDeleteModal(false);
            setRowData(null);
          }}
        />
        <ArchiveModal
          show={archiveModal}
          message="you want to archieve these audio models?"
          deleteLabel="Yes"
          closeLabel="No"
          onDeleteClick={() => {
            archiveHandler();
          }}
          onCloseClick={() => {
            setArchiveModal(false);
            setSelectAll(false);
            setSelectedAudioModels([]);
          }}
        />

        {showCustomizeTable && <CustomizeTable onCancelHandler={customizeTableCancelHandler} onCustomizeColumnSubmitHandler={customizeColumnSubmitHandler} columns={customizeTableColumns} />}
        {filter && (
          <div className="card">
            <div className="card-body">
              <Formik
                initialValues={initialValues}
                validationSchema={validationSchema}
                onSubmit={handleSubmit}
                onReset={handleReset}
                enableReinitialize
              >
                {({ values, handleChange }) => {
                  return (
                    <Form onChange={handleChange}>
                      <Row>
                        <Col xs={12} lg={7} id="export-search-clear">

                        </Col>
                        <Col xs={12} lg={5} id="export-search-clear d-flex">
                          <Select
                            label=""
                            name="status"
                            onBlur={handleStatusBlur}
                            value={values.status}
                            onChange={handleChange}
                            options={options}
                            invalid={false}
                          />
                          <Button className="btn btn-primary ms-2" color="primary" type="reset">
                            Clear </Button>
                          <Button className="btn btn-primary ms-2" color="primary" type="submit">
                            Search </Button>
                        </Col>


                      </Row>
                    </Form>
                  );
                }}
              </Formik>
            </div>
          </div>
        )}
        <DataTableWrapper
          ref={dt}
          header={header}
          columnDetails={selectedStatus=="All"? [...visibleColumns]?.filter((items:any)=>items.field!="select") : visibleColumns}
          value={audioModel}
          // value={modifiedValues}
          lazy
          paginator={true}
          stripedRows={true}
          filterIcon={FilterIcon}
          rowHover={true}
          // filterDisplay="row"
          sortIcon={(setOrder) => <SortIcon order={setOrder} />}
          rowsPerPageOptions={[5, 10, 25, 50]}
          totalRecords={totalRecords}
          loading={loading}
          emptyMessage="No details found."
          paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
          currentPageReportTemplate="Displaying Audio Models  {last} records of {totalRecords} in total"
          selection={selectedAudioModels}
          onSelectionChange={onSelectionChange}
          selectAll={selectAll}
          onSelectAllChange={onSelectAllChange}
          first={lazyState.first}
          onSort={onSort}
          sortField={lazyState.sortField}
          sortOrder={lazyState.sortOrder}
          onFilter={onFilter}
          filters={lazyState.filters}
          onPage={onPage}
          rows={lazyState.rows}
        ></DataTableWrapper>
      </div>
    </>
  );
}
