import React, { useEffect, useRef, useState } from 'react';
// import DeleteModal from '../Calendar/DeleteModal';
// import ArchiveModal from "../Calendar/DeleteModal";
// import { franchisee } from 'src/models/pages/franchiseeModel';
import { LazyTableState } from 'src/models/components/dataTableWrapperModel';
import { DataTable, DataTableFilterMeta } from 'primereact/datatable';
import { Form, Formik } from 'formik';
import { Button, Col, Row } from 'reactstrap';
import Select from 'src/components/Common/Select';
import { Option } from 'src/models/components/inputWrapperModel';
import DataTableWrapper from 'src/components/Grid/DataTableWrapper';
import ColumnAction from "src/components/Common/ColumnAction/ColumnAction";
import { ROUTER } from 'src/constants/routes';
import { useNavigate } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
import { RootReducerState } from 'src/store/reducers';
import { useToast } from 'src/components/Common/ReactToaster';
import { Dropdown } from 'primereact/dropdown';
import FeatherIcon from "feather-icons-react";
import { AnimatePresence, motion } from "framer-motion";
import { BUTTON_VARIANT } from 'src/constants/bulkActionVariant';
import ExportFile from 'src/components/Common/ExcelPdf Export/ExportFile';
import {
  convertToMonthYear,
  formatDateMEL,
  formatFirstDayOfMonthToYYYYMMDD,
  formatLastDayOfMonthToYYYYMMDD,
  hasAdminOrEmployeeRoleHandler,
  hasDealerRoleHandler,
  hasDealerSubuserRoleHandler,
  hasFranchiseRoleHandler,
  hasFranchiseSubuserRoleHandler,
  saveAsExcelFile,
} from 'src/helpers/common_helpers';
import FilterIcon from 'src/components/Common/CustomFilterandSortIcon/FilterIcon';
import SortIcon from 'src/components/Common/CustomFilterandSortIcon/SortIcon';
import BulkActionStatus from 'src/components/Common/BulkActionStatus/BulkActionStatus';
import ValidationPopup from 'src/components/Common/ValidationMessage/ValidationPopup';
import ArchiveModal from 'src/pages/Calendar/DeleteModal';
import { OverlayPanel } from 'primereact/overlaypanel';
import { ClearAttendanceReportMessage, ResetAttendanceReport, getAttendanceReportList, getDealerDropdownList, getDealerSubuserDropdownList, getEmployeeDropdownList, getFranchiseDropdownList, getFranchiseSubuserDropdownList } from 'src/store/AttendanceReport/action';
import { API } from 'src/util/api';

const ACTIVE = "Active";
const DELETED = "Deleted";
const EMAIL_VERIFIED = "Email verified";
const monthConverter = (dates: any) => {

  const data = new Date(dates)
  const year = data.getFullYear()
  const month = data.getMonth() + 1
  const monthFormat = month > 9 ? month : `0${month}`
  return `${year}-${monthFormat}`


}
const AttendanceReports: React.FC = () => {
  const [dates, setDates] = useState<any>(monthConverter(new Date()))
  const [showdates, setShowDate] = useState<any>(monthConverter(new Date()))
  const [showUser, setShowUser] = useState<any>(hasAdminOrEmployeeRoleHandler() ? "Employee" : "")
  const [showUserName, setShowUserName] = useState<any>()

  const [userId, setUserId] = useState<any>("")
  const [userType, setUserType] = useState<any>(hasAdminOrEmployeeRoleHandler() ? "Employee" : "")
  const [dealerId, setDealerId] = useState()
  const [franchiseId, setFranchiseId] = useState()
  useEffect(() => {
    dispatch(getEmployeeDropdownList())
    dispatch(getFranchiseDropdownList())
    dispatch(getFranchiseSubuserDropdownList())
    dispatch(getDealerDropdownList())
    dispatch(getDealerSubuserDropdownList())
    return () => {
      dispatch(ResetAttendanceReport());
    };
  }, [])
  const { attendanceReportList, error, message, totalCount, employeeDropdownList, loading, franchiseDropdownList, franchiseSubuserDropdownList, dealerDropdownList, dealerSubuserDropdownList } = useSelector((state: RootReducerState) => state.AttendanceReportReducer);
  const[pdfLoading,setPdfLoading]=useState<boolean>(false)
  const { reloadData } = useSelector((state: any) => state.attendanceReducer);
  const auth: any = localStorage.getItem('authUser')
  const parsed = JSON.parse(auth)
  

  useEffect(() => {


    if (hasDealerRoleHandler()) {
      const id = dealerDropdownList?.find((item: any) => item.userId == parsed.uid)?.id;
      setDealerId(id)
    }
    if (hasFranchiseRoleHandler()) {
      const id = franchiseDropdownList?.find((item: any) => item.userId == parsed.uid)?.id;
      setFranchiseId(id)

    }
  }, [dealerDropdownList, franchiseDropdownList])
  const initialusertype = hasAdminOrEmployeeRoleHandler() ? "Employee" : "";

  const filterDetails: DataTableFilterMeta = {
    startDate: { value: formatFirstDayOfMonthToYYYYMMDD(new Date()), matchMode: "contains" },
    endDate: { value: formatLastDayOfMonthToYYYYMMDD(new Date()), matchMode: "contains" },
    UserId: { value: userId, matchMode: "contains" },
    UserType: { value: initialusertype, matchMode: "contains" },
    FranchiseId: { value: franchiseId, matchMode: "contains" },
    DealerId: { value: dealerId, matchMode: "contains" },
  };

  let initialLazyState: LazyTableState = {
    first: 0,
    rows: 10,
    page: 1,
    sortField: "",  //CreationTime
    sortOrder: 1,
    filters: filterDetails,
  };
  const [deleteModal, setDeleteModal] = useState(false);
  const [archiveModal, setArchiveModal] = useState(false);
  const [selectAll, setSelectAll] = useState(false);
  const [tooltipOpen, setTooltipOpen] = useState(false);
  const [selectedFranchisee, setSelectedFranchisee] = useState<any>([]);
  const [rowData, setRowData] = useState<any | null>(null);
  const [lazyState, setLazyState] = useState(initialLazyState);
  const [showCustomizeTable, setShowCustomizeTable] = useState(false);
  const [isTooltipVisible, setIsTooltipVisible] = useState<boolean>(false);
  const [filter, setFilter] = useState(false);
  const [selectedStatus, setselectedStatus] = useState(ACTIVE);
  const [franchiseeData, setFranchiseeData] = useState<any>([]);
  const toggleTooltip = () => setTooltipOpen(!tooltipOpen);
  const initialValues = {
    status: "",
  };
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { showToast } = useToast();
  const dt = useRef<DataTable<any>>(null);






  const hidePopup = () => {
    setVisible(false);
    dispatch(ClearAttendanceReportMessage());
  }

  const dialogContainer = (error: any) => {
    return (
      <>
        <ValidationPopup error={error} visible={visible} onHide={hidePopup} />
        {/* <Dialog header="Validation Error" visible={visible} maximizable className='vw30' onHide={() => { setVisible(false); dispatch(resetFranchisee()); }}>
          <div className="m-0 validation-text">
            {error}
          </div>
        </Dialog> */}
      </>
    );
  };


  const dayNames = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];

  // Function to get the day name for a given date
  function getDayName(dateString: any) {
    const date = new Date(dateString);
    return dayNames[date.getDay()];
  }

  const AttendanceBodyTemplate = (rowData: any, date: any) => {
    const hours = Math.floor(rowData);
    const decimalMinutes = (rowData - hours) * 60;
    const minutes = Math.round(decimalMinutes);
    const currentDate = new Date(date);
    const today = new Date();
    if (currentDate > today) {
      return "";
    }

    const formattedHours = hours < 10 ? `${hours}` : `${hours}`;
    const formattedMinutes = minutes < 10 ? `${minutes}` : `${minutes}`;
    if (rowData == 0) {
      return `0:0`
    } else {
      return `${formattedHours}:${formattedMinutes}`;
    }
  }


  const [datas, setDatas] = useState<any>([])
  useEffect(() => {
    let data = generateMissingDatesAndAssignZeroHours(attendanceReportList)
    if (hasAdminOrEmployeeRoleHandler()) {
      setDatas(data)
    }
    else if (hasDealerRoleHandler()) {
      

      // if(dealerId){
      //   const dataForDealer = dealerSubuserDropdownList?.filter((item:any)=>item.id==dealerId);

      //   const dealerUserIds = dataForDealer.map((item:any) => item.userId)
      //   let totalId=[...dealerUserIds,parsed?.uid]
      //   console.log(totalId)
      //   const filteredData=data.filter((item:any)=>totalId.includes(item.userId))

      //   setDatas(filteredData)
      // }
      setDatas(data)
    } else if (hasFranchiseRoleHandler()) {

      // if(franchiseId){
      //   const dataForFranchise = franchiseSubuserDropdownList?.filter((item:any)=>item.id==franchiseId);
      //   const franchiseUserIds = dataForFranchise.map((item:any) => item.userId)
      //   let totalId=[...franchiseUserIds,parsed?.uid]
      //   const filteredData=data.filter((item:any)=>totalId.includes(item.userId))

      //   setDatas(filteredData)
      // }
      setDatas(data)
    } else if (hasFranchiseSubuserRoleHandler()) {
      // const filteredData=data.filter((item:any)=>item.userId==parsed.uid)
      // console.log(filteredData);

      // setDatas(filteredData)
      setDatas(data)
    } else if (hasDealerSubuserRoleHandler()) {
      // const filteredData=data.filter((item:any)=>item.userId==parsed.uid)
      // console.log(filteredData);

      // setDatas(filteredData)
      setDatas(data)
    }
  }, [attendanceReportList])

  // const uniqueDates:any = [];


  // datas?.forEach((item:any) => {
  //       item?.reportData?.forEach((d:any) => {
  //           const date = new Date(d.date);

  //           const formattedDate = `${date.getFullYear()}-${(date.getMonth() + 1).toString().padStart(2, '0')}-${date.getDate().toString().padStart(2, '0')}`;
  //           if (!uniqueDates.includes(formattedDate)) {
  //               uniqueDates.push(formattedDate);
  //           }
  //       });
  //   });

  const uniqueDates: any = [];

  const sampleDate = new Date(showdates);

  const year = sampleDate.getFullYear();
  const month = sampleDate.getMonth();

  function getLastDayOfMonth(year: number, month: number): number {
    return new Date(year, month + 1, 0).getDate();
  }

  const daysInMonth = getLastDayOfMonth(year, month);

  for (let day = 1; day <= daysInMonth; day++) {
    const currentDate = new Date(year, month, day);
    const formattedDate = `${currentDate.getFullYear()}-${(currentDate.getMonth() + 1).toString().padStart(2, '0')}-${currentDate.getDate().toString().padStart(2, '0')}`;

    if (!uniqueDates.includes(formattedDate)) {
      uniqueDates.push(formattedDate);
    }
  }
  let sorted = uniqueDates.sort((a: string, b: any) => {
    const dateA: any = new Date(a);
    const dateB: any = new Date(b);
    return dateA - dateB;
  });


  function generateMissingDatesAndAssignZeroHours(data: any) {
    data?.forEach((item: any) => {
      const datesMap = new Map();

      item?.reportData?.forEach((report: any) => {
        const date = new Date(report.date).getDate();
        datesMap.set(date, report.totalHours);
      });

      const currentDate = new Date(dates);
      const currentYear = currentDate.getFullYear();
      const currentMonth = currentDate.getMonth();

      const daysInMonth = new Date(currentYear, currentMonth + 1, 0).getDate();

      for (let i = 1; i <= daysInMonth; i++) {
        const newDate = new Date(Date.UTC(currentYear, currentMonth, i));
        const isoDateString = newDate.toISOString().split('T')[0];
        if (!datesMap.has(i)) {
          item?.reportData?.push({ totalHours: 0, day: i, date: isoDateString });
        }
      }
    });

    return data;
  }

  const data = [
    {
      "userName": "sakthi",
      "totalHours": null,
      "date": "2024-02-01T00:00:00+00:00", // February
      "day": 0,
      "userType": "Employee",
      "name": "sakthi",
      "userId": "3a113f1e-10a9-4b4e-d6a4-7d3d898c9398",
      "reportData": [
        {
          "totalHours": 0.38471137383333337,
          "day": 1,
          "date": "2024-02-01T00:00:00Z"
        },
        {
          "totalHours": 0.001974556777777773,
          "day": 2,
          "date": "2024-02-02T00:00:00Z"
        },
        // Other report data for February...
      ]
    }
  ];
  const headerTemplate = (day: any) => {
    return (
      <div>
        <div className='text-center'>{getDayName(day)}</div>
        <div className='text-center'>{day.split('-')[2]}</div>
      </div>
    )
  }
  const columns = [
    {
      field: 'name',
      header: 'Name',
      sortable: false,
      filter: false,
      showFilterMenu: false,
      headerStyle: { whiteSpace: "nowrap", width: "100px" },
      isDefault: true,
      frozen : true,
      isStatic: true,
      body: (item: any) => item.name
    },
    // {
    //     field: 'userType',
    //     header: 'User Type',
    //     sortable: true,
    //     filter: false,
    //     showFilterMenu: false,
    //     headerStyle: { whiteSpace: "nowrap", width: "100px" },
    //     isDefault: true,
    //     isStatic: true,
    //     body: (item:any) => item.userType
    // },
    ...sorted.map((day: any) => ({
      field: day,
      sortable: false,
      // header: `${getDayName(day)} ${day.split('-')[2]}`,
      header: headerTemplate(day),
      filter: false,
      showFilterMenu: false,
      headerStyle: { whiteSpace: "wrap", textAlign: "center", width: "7px" },
      body: (item: any) => {

        const userData = item?.reportData?.find((d: any) => (d.date.split('T')[0]) === day);
        return userData ? AttendanceBodyTemplate(userData.totalHours, day) : null;
      },
      isDefault: true,
      isStatic: true,
    }))
  ];





  useEffect(() => {
    const preloader: any = document.getElementById("preloader");
    if (loading||pdfLoading) {
      preloader.style.display = "block";
    } else {
      preloader.style.display = "none";
    }
  }, [loading,pdfLoading]);



  const actionBodyTemplate = (rowData: any, field: string) => {
    return (
      <>
        {
          rowData.isDeleted == false ?
            (
              <ColumnAction
                displayData={rowData[field] as string}
                isEdit={true}
                isDelete={true}
                isView={true}
                franchiseSubUser={true}
                onViewClick={() => viewHandler(rowData)}
                onEditClick={() => editHandler(rowData)}
                onDeleteClick={() => deleteHandler(rowData)}
                onFranchiseSubuserClick={() => franchiseSubUSer(rowData)}
              />
            ) : (
              <ColumnAction
                displayData={rowData[field] as string}
                undo={rowData.isDeleted ? true : false}
                onDeleteClick={() => deleteHandler(rowData)}
              />
            )

        }
      </>

    );
  };

  const options: Option[] = [
    { label: "All", value: "" },
    { label: "Employee", value: "Employee" },
    // { label: "Dealer", value: "Dealer" },
    { label: "Franchise", value: "Franchise" },
    // { label: "DealerSubUser", value: "DealerSubUser" },
    { label: "FranchiseSubUser", value: "FranchiseSubUser" },
  ];

  const archiveHandler = () => {
    setSelectAll(false);
    setSelectedFranchisee([]);
    setArchiveModal(false);
  };

  const onFilterClickHandler = () => {
    setFilter((preState) => !preState);
  };



  const handleReset = (formValue: any) => {
    setLazyState((state) => ({
      ...state,
      filters: {
        ...state.filters,
        status: {
          ...state.filters.status,
          value: "",
        }
      },
    }));
  };

  // const viewHandler = (rowData: franchisee) => {
  const viewHandler = (rowData: any) => {
    navigate(`${ROUTER.FRANCHISEE_VIEW}/${rowData["id"]}`);
  };

  // const editHandler = (rowData: franchisee) => {
  const editHandler = (rowData: any) => {
    navigate(`${ROUTER.FRANCHISEE_EDIT}/${rowData["id"]}`);
  };

  // const deleteHandler = (rowData: franchisee) => {
  const deleteHandler = (rowData: any) => {
    setDeleteModal(true);
    setRowData(rowData);
  };

  const franchiseSubUSer = (rowData: any) => {
    navigate(ROUTER.SUB_USER_CREATE_ID + '/' + rowData['id'], { state: rowData })
  }

  const onSelectionChange = (event: any) => {
    const value = event.value;
    setSelectedFranchisee(value);
    // setSelectAll(value.length === totalCount);
    // setSelectAll(value.length === paginatorCount);
  };

  const onSelectAllChange = (event: any) => {
    const selectAll = event.checked;
    if (selectAll) {
      setSelectAll(true);
      setSelectedFranchisee(franchiseeData);
    } else {
      setSelectAll(false);
      setSelectedFranchisee([]);
    }
  };
  const defaultColumns = {
    ...columns[0]
  };

  //   const customizeTableColumns: customizeTableColumns[] = columns.map((eachColumns: Columns) => {
  //     let data: any = {};
  //     data['field'] = eachColumns.field;
  //     data['value'] = visibleColumns.some((sCol: any) => sCol.field === eachColumns.field);
  //     data['isDisable'] = eachColumns.isStatic;
  //     data['header'] = eachColumns.header;
  //     return data;
  //   }).filter((eachColumns: customizeTableColumns) => eachColumns.field != 'select');

  const onSort = (event: any) => {
    setSelectAll(false);
    setSelectedFranchisee([]);
    setLazyState(event);
  };
  const onFilter = (event: any) => {
    event["first"] = 0;
    setLazyState(event);
  };

  const [visible, setVisible] = useState(false);
  useEffect(() => {

    if (error) {
      setVisible(true);
      // showToast(error, { type: "error" })
    }
    if (message) {
      showToast(message, { type: "success" });
      dispatch(ClearAttendanceReportMessage());
      dispatch(getAttendanceReportList(lazyState));
    }
  }, [error, message]);

  const onPage = (event: any) => {
    setLazyState(event);
  };
  const onCreateClickHandler = () => {
    navigate(ROUTER.FRANCHISEE_CREATE);
  };

  const callbackFunction = (lazyState: any) => {
    dispatch(getAttendanceReportList(lazyState));
  };


  const bulkActionClickHandler = () => {
    if (franchiseeData.length > 0) {
      setSelectAll(true);
      setSelectedFranchisee(franchiseeData);
    }
  };

  const cancelBulkActionHadler = () => {
    setSelectAll(false);
    setSelectedFranchisee([]);
  };


  useEffect(() => {
    if ((dealerId || franchiseId) && (hasDealerRoleHandler() || hasFranchiseRoleHandler())) {
      let lazy = {
        ...lazyState,
        filters: {
          ...lazyState.filters,
          FranchiseId: { value: franchiseId, matchMode: "contains" },
          DealerId: { value: dealerId, matchMode: "contains" },
        },
      }
      callbackFunction(lazy);
    } else if (hasFranchiseSubuserRoleHandler() || hasDealerSubuserRoleHandler()) {
      let lazy = {
        ...lazyState,
        filters: {
          ...lazyState.filters,
          UserId: { value: parsed.uid, matchMode: "contains" },
        },
      }
      callbackFunction(lazy)
    }
    else if (hasAdminOrEmployeeRoleHandler()) {
      callbackFunction(lazyState)
    }
  }, [lazyState, dealerId, franchiseId, reloadData])


  const fetchData = async (isPdf:boolean) => {
    let lazy:any= {
      ...lazyState,
      rows:totalCount>5000 ? 5000 : totalCount<1 ? 1 : totalCount,
      first:0,
      filters: {
        ...lazyState.filters,
      },
    };
    if ((dealerId || franchiseId) && (hasDealerRoleHandler() || hasFranchiseRoleHandler())) {
      lazy = {
        ...lazy,
        filters: {
          ...lazyState.filters,
          FranchiseId: { value: franchiseId, matchMode: "contains" },
          DealerId: { value: dealerId, matchMode: "contains" },
        },
      }
    } else if (hasFranchiseSubuserRoleHandler() || hasDealerSubuserRoleHandler()) {
      lazy = {
        ...lazy,
        filters: {
          ...lazyState.filters,
          UserId: { value: parsed.uid, matchMode: "contains" },
        },
      }
    }
    else if (hasAdminOrEmployeeRoleHandler()) {
      lazy = lazy
    }
    try {
      setPdfLoading(true)
      const response = await API.getAttendanceReportListAPI(lazy);
      if (!response) {
        throw new Error('Network response was not ok');
      }
      let data = generateMissingDatesAndAssignZeroHours(response?.data?.items)
     if(isPdf){

       saveDataAsPdf(data, columns, "Attendance Report");
     }else{
      saveDataAsExcel(data, "Attendance Report");
     }
     setPdfLoading(false)
    } catch (error:any) {
	  showToast(error.message,{type:"error"})
      setPdfLoading(false)
      
    } finally {
      setPdfLoading(false)
    }
  };
  const csvClickHandler = () => {
    // saveDataAsExcel(datas, "Attendance Report");
    fetchData(false)
  };

  const pdfClickHandler = () => {
    // saveDataAsPdf(franchiseeData, columns, "franchise");
    fetchData(true)
  };

  const setInitialValue = () => {
    if (hasAdminOrEmployeeRoleHandler()) {
      return [...employeeDropdownList]
    }
    else if (hasDealerRoleHandler()) {
      return [...dealerDropdownList, ...dealerSubuserDropdownList]
    }
    else if (hasFranchiseRoleHandler()) {
      return [...franchiseDropdownList, ...franchiseSubuserDropdownList]
    }
  }
  const initialUserList = setInitialValue()
  const [compinedUserList, setCombinedUserList] = useState(initialUserList)

  useEffect(() => {
    const userDropdownList = () => {
      if (hasAdminOrEmployeeRoleHandler()) {
        if (userType == "Employee") {
          return [...employeeDropdownList]
        }
        else if (userType == "Dealer") {
          return [...dealerDropdownList]
        }
        else if (userType == "Franchise") {
          return [...franchiseDropdownList]
        }
        else if (userType == "DealerSubUser") {
          return [...dealerSubuserDropdownList]
        }
        else if (userType == "FranchiseSubUser") {
          return [...franchiseSubuserDropdownList]
        }
        else if (userType == "") {
          return [...employeeDropdownList, ...franchiseDropdownList, ...franchiseSubuserDropdownList, ...dealerDropdownList, ...dealerSubuserDropdownList]
        }
      }
    }

    const combinedUserList: any = userDropdownList();
    setCombinedUserList(combinedUserList)

  }, [userType])

  const userOptions: any = compinedUserList?.map((item: any) => ({
    label: item?.name,
    value: item?.userId
  })).sort((a: any, b: any) => {
    const labelA = a.label.toUpperCase().trim();
    const labelB = b.label.toUpperCase().trim();
    if (labelA < labelB) {
      return -1;
    }
    if (labelA > labelB) {
      return 1;
    }
    return 0;
  })

  useEffect(() => {
    if (hasAdminOrEmployeeRoleHandler()) {
      setCombinedUserList(employeeDropdownList)
    }
    if (hasDealerRoleHandler()) {
      let usersSubUser = dealerSubuserDropdownList.filter((item: any) => item.id == dealerId)
      setCombinedUserList([...usersSubUser, dealerDropdownList.find((user: any) => user.id == dealerId)])
    }
    if (hasFranchiseRoleHandler()) {
      let usersSubUser = franchiseSubuserDropdownList.filter((item: any) => item.id == franchiseId)
      setCombinedUserList([...usersSubUser, franchiseDropdownList.find((user: any) => user.id == franchiseId)])
    }
  }, [dealerDropdownList, dealerSubuserDropdownList, franchiseDropdownList, franchiseSubuserDropdownList, dealerId, franchiseId])

  const calendarRef = useRef<any>(null);


  const clearCustomCalendar = (e: any) => {
    calendarRef.current.toggle(e)
    setShowDate(new Date())
    setUserId("")
    setShowUser(hasAdminOrEmployeeRoleHandler() ? "Employee" : "")
    setShowUserName("")
    if (hasAdminOrEmployeeRoleHandler()) {
      setUserType("Employee")
      setLazyState((state) => ({
        ...state,
        filters: {
          ...state.filters,
          startDate: { value: formatFirstDayOfMonthToYYYYMMDD(new Date()), matchMode: "contains" },
          endDate: { value: formatLastDayOfMonthToYYYYMMDD(new Date()), matchMode: "contains" },
          UserId: { value: "", matchMode: "contains" },
          UserType: { value: "Employee", matchMode: "contains" },
          // FranchiseId:{value:franchiseId,matchMode:"contains"},
          // DealerId:{value:dealerId,matchMode:"contains"},
        },
      }));
    } else if (hasDealerRoleHandler() || hasFranchiseRoleHandler()) {
      setLazyState((state) => ({
        ...state,
        filters: {
          ...state.filters,
          startDate: { value: formatFirstDayOfMonthToYYYYMMDD(new Date()), matchMode: "contains" },
          endDate: { value: formatLastDayOfMonthToYYYYMMDD(new Date()), matchMode: "contains" },
          UserId: { value: "", matchMode: "contains" },
          // UserType:{value:userType,matchMode:"contains"},
          FranchiseId: { value: franchiseId, matchMode: "contains" },
          DealerId: { value: dealerId, matchMode: "contains" },
        },
      }));
    } else {
      setLazyState((state) => ({
        ...state,
        filters: {
          ...state.filters,
          startDate: { value: formatFirstDayOfMonthToYYYYMMDD(new Date()), matchMode: "contains" },
          endDate: { value: formatLastDayOfMonthToYYYYMMDD(new Date()), matchMode: "contains" },
          UserId: { value: parsed.uid, matchMode: "contains" },
          // UserType:{value:userType,matchMode:"contains"},
          // FranchiseId:{value:franchiseId,matchMode:"contains"},
          // DealerId:{value:dealerId,matchMode:"contains"},
        },
      }));
    }

    setDates(monthConverter(new Date()))
  }

  const searchCustomCalendar = (event: any) => {
    calendarRef.current.toggle(event)
    setShowDate(dates);
    if (userOptions.length > 0) {
      setShowUserName(userOptions?.find((item: any) => item.value == userId)?.label)
    }
    setShowUser(userType)
    setLazyState((state) => ({
      ...state,
      filters: {
        ...state.filters,
        startDate: { value: formatFirstDayOfMonthToYYYYMMDD(dates), matchMode: "contains" },
        endDate: { value: formatLastDayOfMonthToYYYYMMDD(dates), matchMode: "contains" },
        UserId: { value: userId, matchMode: "contains" },
        UserType: { value: userType, matchMode: "contains" },
      },
    }));
  }

  const josheetDetails = [
    { name: "Brand", value: convertToMonthYear(showdates) ?? "-", color: "#dff0ff", border: "#749be0" },
    { name: "Status", value: showUser, color: "#e0f5f1", border: "#2d97a6" },
    { name: "Created", value: showUserName ? showUser : "", color: "#ccf0f7", border: "#628bda" },
  ]
  const clearFilter=()=>{
    setShowDate(new Date())
    setUserId("")
    setShowUser(hasAdminOrEmployeeRoleHandler() ? "Employee" : "")
    setShowUserName("")
    if (hasAdminOrEmployeeRoleHandler()) {
      setUserType("Employee")
      setLazyState((state) => ({
        ...state,
        filters: {
          ...state.filters,
          startDate: { value: formatFirstDayOfMonthToYYYYMMDD(new Date()), matchMode: "contains" },
          endDate: { value: formatLastDayOfMonthToYYYYMMDD(new Date()), matchMode: "contains" },
          UserId: { value: "", matchMode: "contains" },
          UserType: { value: "Employee", matchMode: "contains" },
          // FranchiseId:{value:franchiseId,matchMode:"contains"},
          // DealerId:{value:dealerId,matchMode:"contains"},
        },
      }));
    } else if (hasDealerRoleHandler() || hasFranchiseRoleHandler()) {
      setLazyState((state) => ({
        ...state,
        filters: {
          ...state.filters,
          startDate: { value: formatFirstDayOfMonthToYYYYMMDD(new Date()), matchMode: "contains" },
          endDate: { value: formatLastDayOfMonthToYYYYMMDD(new Date()), matchMode: "contains" },
          UserId: { value: "", matchMode: "contains" },
          // UserType:{value:userType,matchMode:"contains"},
          FranchiseId: { value: franchiseId, matchMode: "contains" },
          DealerId: { value: dealerId, matchMode: "contains" },
        },
      }));
    } else {
      setLazyState((state) => ({
        ...state,
        filters: {
          ...state.filters,
          startDate: { value: formatFirstDayOfMonthToYYYYMMDD(new Date()), matchMode: "contains" },
          endDate: { value: formatLastDayOfMonthToYYYYMMDD(new Date()), matchMode: "contains" },
          UserId: { value: parsed.uid, matchMode: "contains" },
          // UserType:{value:userType,matchMode:"contains"},
          // FranchiseId:{value:franchiseId,matchMode:"contains"},
          // DealerId:{value:dealerId,matchMode:"contains"},
        },
      }));
    }

    setDates(monthConverter(new Date()))
  }

  const header =
    <div className="table-header-container custom_align">
      <div className="label_margin">
        Attendance Report
      </div>
      <div id="calendar" className="calendar_btns" style={{ marginLeft: "10px" }}>
        <div className="table-header-container-item">
          <div className="btn-group">
            <button
              className="btn btn-soft-primary waves-effect waves-light"
              // onClick={toggleCalendar}
              onClick={(e) => calendarRef.current.toggle(e)}
            >
              {/* <i className="bx bx-calendar font-size-16 align-middle"></i> */}
              <FeatherIcon icon="search" style={{ Width: "6px" }} />
            </button>
          </div>
        </div>
      </div>
      <OverlayPanel ref={calendarRef}>
        <div className="mb-2">
          <label htmlFor="">Select Month</label>
          {/* <Row>     */}
          <input
            className='form-control'
            type="month"
            name="month"
            value={dates}
            onChange={(e) => setDates(e.target.value)}
          />
          {/* </Row> */}
        </div>
        {hasAdminOrEmployeeRoleHandler() &&
          <div className="mb-2">
            <label htmlFor="">Select User Type</label>
            <Dropdown value={userType} onChange={(e) => setUserType(e.value)} options={options} optionLabel="label" placeholder="Select User" filter className="w-full md:w-14rem" />
          </div>
        }
        {(hasAdminOrEmployeeRoleHandler() || hasFranchiseRoleHandler() || hasDealerRoleHandler()) &&
          <div className="mb-2">
            <label htmlFor="">Select User</label>
            <Dropdown value={userId} onChange={(e) => setUserId(e.value)} options={userOptions} optionLabel="label" placeholder="Select User" filter className="w-full md:w-14rem" />
          </div>
        }

        <div className="p-column-filter-buttonbar">
          <button aria-label="Clear" className="p-button p-component p-button-outlined p-button-sm" type="button" onClick={clearCustomCalendar}>
            <span className="p-button-label p-c">Clear</span>
          </button>
          <button aria-label="Apply" className="p-button p-component p-button-sm" type="button" onClick={searchCustomCalendar}>
            <span className="p-button-label p-c">Apply</span>
          </button>
        </div>
      </OverlayPanel>

      <div className="table-header-container-item align_width">
        <div className="m-2 d-flex flex-wrap gap-1 align_justify">
          <div className='job-basic-info'>
            <div className='title text-center' style={{ background: "#dff0ff", color: "#749be0", width: "auto", whiteSpace: "nowrap" }}>Date</div>
            <b className='m-auto text-center font-size-13' style={{ minWidth: "120px", whiteSpace: "nowrap" }}>{convertToMonthYear(showdates)}</b>
          </div>
          {showUser &&
            <div className='job-basic-info'>
              <div className='title text-center' style={{ background: "#e0f5f1", color: "#2d97a6", width: "auto", whiteSpace: "nowrap" }}>User Type</div>
              <b className='m-auto text-center font-size-13' style={{ minWidth: "150px", whiteSpace: "nowrap" }}>{showUser}</b>
            </div>
          }
          {showUserName &&
            <div className='job-basic-info'>
              <div className='title text-center' style={{ background: "#ccf0f7", color: "#628bda", width: "auto", whiteSpace: "nowrap" }}>User Name</div>
              <b className='m-auto text-center font-size-13' style={{ minWidth: "150px", whiteSpace: "nowrap" }}>{showUserName}</b>
            </div>
          }</div>
        {/* <b style={{marginLeft:"10px"}}>{showUser}</b>
      <b style={{marginLeft:"10px"}}>{showUserName}</b> */}

        {false && selectedFranchisee.length == 0 && <Button type="button" className="customize-table-button mr-1 " onClick={bulkActionClickHandler}>
          <FeatherIcon icon="layers" className="mr-1" />
          Bulk actions
        </Button>}
        <AnimatePresence>
          {false && selectedFranchisee.length > 0 &&
            <>
              {/* <motion.div initial="initial" animate="animate" exit="exit" variants={BUTTON_VARIANT}>
          <Button type="button" className="secondary-btn  mr-1" onClick={() => { setArchiveModal(true); }}>
            <FeatherIcon icon="archive" className="mr-1" />
            Archive
          </Button>
        </motion.div> */}
              <motion.div initial="initial" animate="animate" exit="exit" variants={BUTTON_VARIANT}>
                <Button type="button" className="secondary-btn  mr-1 delete-space" onClick={() => { setDeleteModal(true); }}>
                  {selectedStatus == DELETED ? <><i className="fa fa-undo option-icons special"></i> Restore</> : <><FeatherIcon icon="trash-2" className="mr-1" /> Delete</>}
                </Button>
              </motion.div>
              <motion.div initial="initial" animate="animate" exit="exit" variants={BUTTON_VARIANT}>
                <Button type="button" className="customize-table-button mr-1" onClick={cancelBulkActionHadler} > <FeatherIcon icon="x" className="" /> Cancel selection</Button>
              </motion.div>
              <motion.div initial="initial" animate="animate" exit="exit" variants={BUTTON_VARIANT}>
                {selectedFranchisee.length > 0 && <BulkActionStatus count={selectedFranchisee.length} title="Franchise"></BulkActionStatus>}
              </motion.div>


            </>
          }
        </AnimatePresence>

      </div>
      <div className="flex-grow-1"></div>
      <div className="table-header-container-item export_btns">
        <div style={{ display: "flex" }}>
          <ExportFile exportExcel={csvClickHandler} isShowPdf={true} exportPdf={pdfClickHandler} clearFilter={clearFilter} isFilter={true}/>

          <>
            {/* <TooltipMEL title="Select User" position="bottom" tooltipVisible={isTooltipVisible} setTooltipVisible={setIsTooltipVisible}>
          <Dropdown value={userId} onChange={(e) => setUserId(e.value)} options={options} optionLabel="label" placeholder="Select status .."
            filter className="w-full md:w-14rem" /> 
        </TooltipMEL> */}
            {/* <Button type="button" className="customize-table-button mr-1 ml-1" id="ScheduleUpdateTooltip"
          onMouseEnter={() => setTooltipOpen(true)}
          onMouseLeave={() => setTooltipOpen(false)} data-btn="btn" onClick={customizeTableClickHandler}>
          <FeatherIcon icon="sliders" className="" />
        </Button> */}
          </>

        </div>


        {/* </TooltipMEL> */}

        {/* <UncontrolledTooltip
          placement="bottom"
          target="ScheduleUpdateTooltip"
          isOpen={tooltipOpen}
          toggle={toggleTooltip}
          innerClassName="tooltip-color"
        >
          Choose fields you want to see in the table
        </UncontrolledTooltip> */}
      </div>

    </div>;


  const changeColumnDetails = (values: any[],) => {
    let modifiedColumnDetails: any[] = [];

    values.forEach((a) => {
      let data: any = {};
      data['Name'] = a.name;
      data['User Type'] = a.userType;

      sorted.forEach((item: any) => {
        const userData = a.reportData.find((d: any) => d.date.split('T')[0] === item);
        data[formatDateMEL(item)] = userData ? AttendanceBodyTemplate(userData.totalHours, item) : 0;
      });

      modifiedColumnDetails.push(data);
    });

    return modifiedColumnDetails;
  };

  const saveDataAsExcel = async (values: any[], fileName: string) => {
    let modifiedColumnDetails = await changeColumnDetails(values);
    import('xlsx').then((xlsx) => {
      const worksheet = xlsx.utils.json_to_sheet(modifiedColumnDetails);
      const workbook = { Sheets: { data: worksheet }, SheetNames: ['data'] };
      const excelBuffer = xlsx.write(workbook, {
        bookType: 'xlsx',
        type: 'array'
      });
      saveAsExcelFile(excelBuffer, fileName);
    });
  };
  const changeColumnDetailsPdf = (values: any[],) => {
    let modifiedColumnDetails: any[] = [];

    values.forEach((a) => {
      let data: any = {};
      data['name'] = a.name;
      data['userType'] = a.userType;

      sorted.forEach((item: any) => {
        const userData = a.reportData.find((d: any) => d.date.split('T')[0] === item);
        let currentDate=new Date(item)        
        data[`${currentDate.getFullYear()}-${(currentDate.getMonth() + 1).toString().padStart(2, '0')}-${currentDate.getDate().toString().padStart(2, '0')}`] = userData ? AttendanceBodyTemplate(userData.totalHours, item) : 0;
      });

      modifiedColumnDetails.push(data);
    });

    return modifiedColumnDetails;
  };

  const saveDataAsPdf = (values: any[], columns: any[], fileName: string) => {
    const processedValues = changeColumnDetailsPdf(values);
  
  
    import("jspdf").then((jsPDF) => {
      import("jspdf-autotable").then(() => {
        const exportColumns = columns.map((col) => ({
          title: col.field,
          dataKey: col.field,
        }));
  
        const doc:any = new jsPDF.default({ orientation: "landscape", format: "a2" });
  
        doc.autoTable({
          columns: exportColumns,
          body: processedValues,
          styles: { fontSize: 8, cellPadding: 3, overflow: 'linebreak' },
          theme: 'striped',
        });
  
        doc.save(`${fileName}.pdf`);
      });
    });
  };
  const handleSubmit = () => {

  }

  return (
    <React.Fragment>
      <div className="page-content">
        {visible && dialogContainer(error)}
        {/* <DeleteModal
          show={deleteModal}
          undo={selectedStatus == DELETED ? true : false}
          onDeleteClick={() => {
            if (selectedFranchisee?.length > 10) {
              showToast("Cannot delete more than 10 items at once", { type: "error" });
              setSelectedFranchisee([]);
              setDeleteModal(false);
              setSelectAll(false);
              return setRowData(null);
            }

            if (rowData || selectedFranchisee.length == 1) {
              handleDeleteUsers(rowData || selectedFranchisee);
            } else if (selectedFranchisee.length > 1) {
              for (let i = 0; i < selectedFranchisee.length; i++) {
                handleDeleteUsers(selectedFranchisee[i], selectedFranchisee.length, i);
              }
            }
          }}
          onCloseClick={() => {
            setDeleteModal(false);
            setRowData(null);
          }}
        /> */}
        <ArchiveModal
          show={archiveModal}
          message="you want to archieve these administrators?"
          deleteLabel="Yes"
          closeLabel="No"
          onDeleteClick={() => {
            archiveHandler();
          }}
          onCloseClick={() => {
            setArchiveModal(false);
            setSelectAll(false);
            setSelectedFranchisee([]);
          }}
        />

        {/* {false && showCustomizeTable && <CustomizeTable onCancelHandler={customizeTableCancelHandler} onCustomizeColumnSubmitHandler={customizeColumnSubmitHandler} columns={customizeTableColumns} />} */}


        {false && filter && (
          <div className="card">
            <div className="card-body">
              <Formik
                initialValues={initialValues}
                onSubmit={handleSubmit}
                onReset={handleReset}
                enableReinitialize
              >
                {({ values, handleChange }) => {
                  return (
                    <Form onChange={handleChange}>
                      <Row>
                        <Col xs={12} lg={7} id="export-search-clear">

                        </Col>
                        <Col xs={12} lg={5} id="export-search-clear d-flex">
                          <Select
                            label=""
                            name="status"
                            value={values.status}
                            onChange={handleChange}
                            options={options}
                            invalid={false}
                          />
                          <Button className="btn btn-primary ms-2" color="primary" type="reset">
                            Clear </Button>
                          <Button className="btn btn-primary ms-2" color="primary" type="submit">
                            Search </Button>
                        </Col>
                      </Row>
                    </Form>
                  );
                }}
              </Formik>
            </div>
          </div>
        )}
        <DataTableWrapper
          ref={dt}
          columnDetails={columns}
          header={header}
          filterIcon={FilterIcon}
          sortIcon={(setOrder) => <SortIcon order={setOrder} />}
          value={datas}
          lazy
          paginator={true}
          stripedRows={true}
          rowHover={true}
          // filterDisplay="row"
          rowsPerPageOptions={[5, 10, 25, 50]}
          totalRecords={totalCount}
          // loading={loading}
          emptyMessage="No details found for this selected date."
          paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
          currentPageReportTemplate="Displaying Attendance Report  {last} records of {totalRecords} in total"
          selection={selectedFranchisee}
          onSelectionChange={onSelectionChange}
          selectAll={selectAll}
          onSelectAllChange={onSelectAllChange}
          first={lazyState.first}
          onSort={onSort}
          sortField={lazyState.sortField}
          sortOrder={lazyState.sortOrder}
          onFilter={onFilter}
          filters={lazyState.filters}
          onPage={onPage}
          rows={lazyState.rows}
        ></DataTableWrapper>

        {/* <DataTable value={dummyData} header={header} stripedRows >
            {columns.map((col, index) => (
                <Column key={index} field={col.field} header={col.header} body={col.body}/>
            ))}
        </DataTable> */}
      </div>
    </React.Fragment>
  );
};

export default AttendanceReports;