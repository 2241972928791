import { call, put, takeLatest } from "redux-saga/effects";
import { DefectivePartType } from "./actionTypes";
import { API } from "src/util/api";
import { geDefectivePartListSuccess, getDefectivePartListError } from "./actions";

function* getDefectivePartListAsync(action: any) {
    try {
      const response: Promise<any> = yield call(API.getDefectivePartListAPI, { ...action.lazyState })
      yield put(geDefectivePartListSuccess(response))
    }
    catch (error) {
      yield put(getDefectivePartListError(error))
    }
  }

  
function* defectivePartSaga() {
    yield takeLatest(DefectivePartType.GET_DEFECTIVE_PART_LIST_LOADING, getDefectivePartListAsync);
  }
  
  export default defectivePartSaga