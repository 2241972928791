import React, { useEffect } from 'react';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import { API } from 'src/util/api';
import { AUTH_LOGIN_URL, CLIENT_ID, baseUrl, CLIENT_SECRET } from 'src/util/auth/authConfig';
import axios from 'axios';
import { UserTypes } from 'src/constants/userRoles';
import { ROUTER } from 'src/constants/routes';
import { useDispatch } from 'react-redux';
import { setRefreshTime } from 'src/store/authLogin/action';
import { OAuthService } from 'src/util/auth/oauthService';
import { APPENV_URL } from 'src/util/appUrlEnv';
import { saveMapKey } from 'src/store/actions';
import { generateToken } from 'src/Notifications/firebase';
import './Login.scss'



const LoginCallback = () => {
  debugger;
  let [searchParams, setSearchParams] = useSearchParams();
  const navigate = useNavigate();
  const location = useLocation();  
  const code = searchParams.get("code");
  const code_verifier = localStorage.getItem("PKCE_verifier");
  const fronendUrl = localStorage.getItem("fronendUrl");
  
  const dispatch = useDispatch();

  const getToken = () => {
    let model: any = {
      grant_type: 'authorization_code',
      code,
      redirect_uri: baseUrl + "/authentication/login-callback",
      code_verifier,
      client_id: CLIENT_ID,
      // client_secret : CLIENT_SECRET
    };
    const formData = new URLSearchParams();

    for (let key in model) {
      formData.append(key, model[key]);
    }

    const axiosInstance = axios.create({
      baseURL: AUTH_LOGIN_URL,
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded'
      },
    });

    axiosInstance.post("/connect/token", formData, {
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded'
      }
    }).then(async (response) => {
      console.log("response ---------->", response);
      if (response?.data?.access_token) {
        const token = response?.data?.access_token;
        try {
          // FireBase Start
          const firebase = await generateToken();
          // FireBase End

          const Apiresponse = await fetch(APPENV_URL.AppUrl + "/" + "mel-setting-management/gcp-setting", {
            method: "GET",
            headers: {
              "Content-Type": "application/json",
              "Authorization": `Bearer ${token}`
            }
          });
          if (Apiresponse.ok) {
            const data = await Apiresponse.json();
            console.log("Data", data?.mapKey);
            // localStorage.setItem("mapKey",data?.mapKey)
            dispatch(saveMapKey(data?.mapKey));
          }
          // FireBase Start
          if(firebase){
            localStorage.setItem("firebase", firebase);
          }
          // FireBase End
          
        } catch (error) {
          console.log("error", error);
        }
      }
      // localStorage.removeItem("PKCE_verifier");
      const { data } = response;
      const token = data?.access_token;
      const payloadEncoded = token.split('.')?.[1];
      const id_token = data?.id_token;
      const decodedPayload = JSON.parse(atob(payloadEncoded));
      dispatch(setRefreshTime(decodedPayload?.['exp']));
      const { email, role, preferred_username: username, sub: uid } = decodedPayload;
      localStorage.setItem('role', JSON.stringify(role));
      localStorage.setItem("token", `${token}`);
      let loggedInUser = { email, role, username, uid };
      localStorage.setItem("authUser", JSON.stringify(loggedInUser));
      // id_token 
      localStorage.setItem("id_token", id_token);
      localStorage.setItem("exp", decodedPayload?.['exp']);
      if (fronendUrl == "/" || !fronendUrl) {
        if (role.includes(UserTypes[UserTypes['Dealer']])) {
          return navigate(ROUTER.JOB_SHEETS_GRID);
        } else if (role.includes(UserTypes[UserTypes['Franchise']])) {
          return navigate(ROUTER.JOB_SHEETS_GRID);
        } else if (role.includes(UserTypes[UserTypes['FranchiseSubUser']])) {
          // return navigate(ROUTER.ATTENDANCE);
          return navigate(ROUTER.JOB_SHEETS_GRID);
        } else if (role.includes(UserTypes[UserTypes['DealerSubUser']])) {
          return navigate(ROUTER.JOB_SHEETS_GRID);
        }
        else if (role.includes(UserTypes[UserTypes['Customer']])) {
          return navigate(ROUTER.JOB_SHEETS_GRID);
        }
        navigate(ROUTER.JOB_SHEETS_GRID);

      } else if (fronendUrl != "/") {
        
        navigate(fronendUrl);
        localStorage.removeItem("fronendUrl");
      }

    }).catch(error => {
      
      let oauth = new OAuthService();
      oauth.autoRefresh();
      oauth.createLoginUrl().then(data => {
        let authUrl = AUTH_LOGIN_URL + "/Account/Login?ReturnUrl=";
        let culture = localStorage.getItem("i18nextLng");
        const loginURL = data + `&culture=${culture}&ui-culture=${culture}`;
        authUrl = authUrl + '/connect/authorize' + encodeURIComponent(loginURL);
        setTimeout(() => {
          window.open(authUrl, "_self");
        }, 500);
      }).catch(error => {
        console.log(error);
      });
    });
  };
  useEffect(() => {
    getToken();
  }, [code]);


  return (
    <div className='spinner_position'>
    <div className="lds-spinner">
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
    </div>
  </div>
  );
};

export default LoginCallback;