import { call, put, takeLatest } from "redux-saga/effects";
import { API } from "src/util/api";
import { getPartTransactionListError, getPartTransactionListSuccess } from "./action";
import { PartTransactionTypes } from "./actionTypes";

function* getPartTransactionListAsync(action : any) {
  
    try {
      const response : Promise<any> = yield call(API.getPartTransactionListApi,{...action.lazyState,})
      
      yield put(getPartTransactionListSuccess(response));
    } catch (error) {
      yield put(getPartTransactionListError(error));
    }
  }

function* PartTransactionSaga() {
    yield takeLatest(PartTransactionTypes.GET_DATE_WISE_PART_TRANSACTION_LIST, getPartTransactionListAsync);
}
  
  export default PartTransactionSaga;