import { BulkEmailInitialState } from "src/models/pages/BulkEmailModel";
import { BulkEmailTypes} from "./actionTypes";
import { messages } from "src/common/data";




const initialState: BulkEmailInitialState = {
  error: "",
  loading: false,
  message: "",
  paginatorCount: 0,
  BulkEmailList: [],
  totalRecords: 0,
  employeeEmail:"",
  dealerEmail:"",
  customerEmail:"",
  franchiseEmail:"",
  supplierEmail:"",
  manufacturerEmail:"",
  bulkEmailDetails:"",
  statusList:[],
  prefixList:"",
  prefixEmail:""
};



const BulkEmail = (state = initialState, action: any) => {
  switch (action.type) {
    case BulkEmailTypes.GET_BULK_EMAIL_LIST_LOADING:
    case BulkEmailTypes.CREATE_BULK_EMAIL_LOADING:
    case BulkEmailTypes.DELETE_BULK_EMAIL_LOADING:
    case BulkEmailTypes.GET_BY_ID_BULK_EMAIL_LOADING:
    case BulkEmailTypes.GET_PREFIX_EMAIL_LOADING:
      return {
        ...state,
        loading: true,
        paginatorCount: 0,
        message: "",
        error: ""
      };


    case BulkEmailTypes.GET_BULK_EMAIL_LIST_SUCCESS:
      return {
        ...state,
        loading: false,
        BulkEmailList:action.payload.data.items,
        totalRecords : action.payload.data.totalCount,
        paginatorCount:action.payload.data.items.length
      };
    case BulkEmailTypes.GET_BY_ID_BULK_EMAIL_SUCCESS:
      return {
        ...state,
        loading: false,
        bulkEmailDetails:action.payload.data,
      };
    case BulkEmailTypes.GET_EMPLOYEE_EMAIL_SUCCESS:
      return {
        ...state,
        loading: false,
        employeeEmail: action.payload.data?.filter((item:any) => !item.isDeleted).map((item:any) =>  ({name:item.name,phone:item.email}))
      };
    case BulkEmailTypes.GET_DEALER_EMAIL_SUCCESS:
      return {
        ...state,
        loading: false,
        dealerEmail:action.payload.data?.filter((item:any) => !item.isDeleted).map((item:any) =>  ({name:item.name,phone:item.email}))
      };

    case BulkEmailTypes.GET_CUSTOMER_EMAIL_SUCCESS:
      return {
        ...state,
        loading: false,
        customerEmail:action.payload.data?.filter((item:any) => !item.isDeleted).map((item:any) =>  ({name:item.name,phone:item.email}))
      };

    case BulkEmailTypes.GET_FRANCHISE_EMAIL_SUCCESS:
      return {
        ...state,
        loading: false,
        franchiseEmail:action.payload.data?.filter((item:any) => !item.isDeleted).map((item:any) =>  ({name:item.name,phone:item.email}))
      };

    case BulkEmailTypes.GET_SUPPLIER_EMAIL_SUCCESS:
      return {
        ...state,
        loading: false,
        supplierEmail:action.payload.data?.filter((item:any) => !item.isDeleted).map((item:any) =>  ({name:item.name,phone:item.email}))
      };
    case BulkEmailTypes.GET_MANUFACTURER_EMAIL_SUCCESS:
      return {
        ...state,
        loading: false,
        manufacturerEmail:action.payload.data?.filter((item:any) => !item.isDeleted).map((item:any) =>  ({name:item.name,phone:item.email}))
      };
    case BulkEmailTypes.GET_PREFIX_EMAIL_SUCCESS:
      return {
        ...state,
        loading: false,
        prefixEmail:action.payload.data?.filter((item:any) => !item.isDeleted).map((item:any) =>  ({name:item.name,phone:item.email}))
      };
    case BulkEmailTypes.CREATE_BULK_EMAIL_SUCCESS:
      return {
        ...state,
        loading: false,
        message:"Email Posted Successfully"
      };
    case BulkEmailTypes.GET_BULK_EMAILSTATUS_SUCCESS:
      return {
        ...state,
        loading: false,
        statusList:action.payload.data
      };
    case BulkEmailTypes.DELETE_BULK_EMAIL_SUCCESS:
      return {
        ...state,
        loading: false,
        message:"Email Sending Cancelled Successfully"
      };
    case BulkEmailTypes.GET_PREFIX_SUCCESS:
      return {
        ...state,
        loading: false,
        prefixList:action.payload.data
      };
    case BulkEmailTypes.CLEAR_BULK_EMAIL_MESSAGE:
      return {
        ...state,
        loading: false,
        error:"",
        message:""
      };

    case BulkEmailTypes.GET_BULK_EMAIL_LIST_ERROR:
    case BulkEmailTypes.CREATE_BULK_EMAIL_ERROR:
    case BulkEmailTypes.DELETE_BULK_EMAIL_ERROR:
    case BulkEmailTypes.GET_BY_ID_BULK_EMAIL_ERROR:
    case BulkEmailTypes.GET_BULK_EMAILSTATUS_ERROR:
    case BulkEmailTypes.GET_EMPLOYEE_EMAIL_ERROR:
    case BulkEmailTypes.GET_DEALER_EMAIL_ERROR:
    case BulkEmailTypes.GET_CUSTOMER_EMAIL_ERROR:
    case BulkEmailTypes.GET_FRANCHISE_EMAIL_ERROR:
    case BulkEmailTypes.GET_SUPPLIER_EMAIL_ERROR:
    case BulkEmailTypes.GET_PREFIX_EMAIL_ERROR:
      return {
        ...state,
        loading: false,
        error: action.payload
      };


    default:
      return { ...state };
  }
};

export default BulkEmail;
