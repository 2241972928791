import React, { useEffect, useMemo, useState } from 'react'
import { useNavigate, useParams } from 'react-router'
import { Col, Container, Row } from 'reactstrap';
import BreadcrumbWithFilter from 'src/components/Common/BreadcrumbWithFilter/BreadCrumbWithFilter'
import { ROUTER } from 'src/constants/routes'
import { useDispatch, useSelector } from 'react-redux'
import { RootReducerState } from 'src/store/reducers'
import StatusHghlighter from 'src/components/Common/StatusHighlighter/StatusHighlighter'
import { DataTable } from 'primereact/datatable'
import ValidationPopup from 'src/components/Common/ValidationMessage/ValidationPopup';
import { clearVoucher, getPaymentType, getVoucherDetailsById, printVoucherByIdLoading } from 'src/store/voucher/action';
import { CurrencyFormatter, formatDateTOYYYYMMDD } from 'src/helpers/common_helpers';
import { Column } from 'primereact/column';


const ViewVoucher = () => {
  const navigate = useNavigate()
  const { id } = useParams()
  const [visible, setVisible] = useState(false);
  const editUserClick = () => {
    navigate(`${ROUTER.VOUCHER_EDIT}/${id}`)
  }

  const { error, message, loading, voucherDetails , voucherTypes ,paymentTypeList} = useSelector((state: RootReducerState) => state.VoucherReducer);

  const dispatch = useDispatch();

  useEffect(() => {
    const preloader: any = document.getElementById("preloader");
    if (loading) {
      preloader.style.display = "block";
    } else {
      preloader.style.display = "none";
    }
  }, [loading]);

  const statusBodyTemplate = (value: boolean) => {
    let status = value;
    return (
      <StatusHghlighter status={status} />
    )

  }


  useEffect(() => {
    if (id) {
        dispatch(getVoucherDetailsById(id))
        dispatch(getPaymentType())
    }
    return () => {
      dispatch(clearVoucher())
    }
  }, []);



 
  useEffect(()=>{
   if(error){
    setVisible(true);
   }
  },[error])
  const VoucherType = [
    {label:"Expense",value:"0"},
    {label:"Purchase",value:1}
]

  const initialvalue = useMemo(()=>{
    let voucherId = voucherTypes?.find((val:any)=>val.id == voucherDetails?.voucherTypeId)?.vocherCategorieTypeId
    let userType = ""
    return{
      voucherTypeId: voucherId,
      voucherSubTypeId: voucherDetails?.voucherTypeId,
      voucherNumber: voucherDetails?.voucherNumber,
      vocherDate: voucherDetails?.vocherDate  ? formatDateTOYYYYMMDD(voucherDetails?.vocherDate) : "",
      userType:userType,
      initiatedName: voucherDetails?.initiatedName,
      attachedBills: voucherDetails?.attachedBills ? voucherDetails?.attachedBills : false,
      approvedDate: voucherDetails?.approvedDate  ? formatDateTOYYYYMMDD(voucherDetails?.approvedDate) : "",
      approvedReceiverNumber: voucherDetails?.approvedReceiverNumber,
      total: voucherDetails?.total,
      lessTDS: voucherDetails?.lessTDS,
      roundOff: voucherDetails?.roundOff,
      grandTotal: voucherDetails?.grandTotal,
      remittanceDetails: voucherDetails?.remittanceDetails,
      paymentPaidBy: voucherDetails?.paymentPaidBy,
      payoutDate: voucherDetails?.payoutDate  ? formatDateTOYYYYMMDD(voucherDetails?.payoutDate) : "",
      dealerId: voucherDetails?.dealerId,
      customerId: voucherDetails?.customerId,
      franchiseId: voucherDetails?.franchiseId,
      supplierId: voucherDetails?.supplierId,
      employeeId: voucherDetails?.employeeId,
      voucherDetailsDto: voucherDetails?.voucherDetailsDto?.filter((val:any)=>val.isDeleted != true)?.map((data:any)=>({
          id: data?.id,
          voucherMasterId: data?.voucherMasterId,
          subject: data?.subject,
          rateRs: data?.rateRs,
          qty: data?.qty,
          amount: data?.amount,
          isDeleted: data?.isDeleted
      }))
  }
},[voucherDetails,voucherTypes]);


const currencyBodyTemplate = (amount:any) => {
    return (<>{CurrencyFormatter({ value: amount })}</>);
  };


  const userView = {
    generalDetails: [
      {
        name: "Voucher Number",
        value: voucherDetails?.voucherNumber
      },
      {
        name: "Voucher Type",
        value: voucherDetails?.voucherCategorieName
      },
      {
        name: "Voucher Sub Type",
        value: voucherDetails?.voucherName
      },
      {
        name: "Voucher Date",
        value: voucherDetails?.vocherDate  ? formatDateTOYYYYMMDD(voucherDetails?.vocherDate) : ""
      },
      {
        name: "Initiated Name",
        value: voucherDetails?.initiatedName
      },
      {
        name: "Approved By",
        value:voucherDetails?.approvedByName  ? voucherDetails?.approvedByName : ""
      },
      {
        name: "Approved Date",
        value:voucherDetails?.approvedDate  ? formatDateTOYYYYMMDD(voucherDetails?.approvedDate) : ""
      },
      {
        name:"Approved Receiver Number",
        value:voucherDetails?.approvedReceiverNumber
      },
      {
        name: "Attached Bills",
        value: statusBodyTemplate(voucherDetails?.attachedBills)
      },
      {
        name:"Remittance Details",
        value:voucherDetails?.remittanceDetails
      },
      {
        name:"Payment Paid By",
        value:paymentTypeList?.find((val:any)=>val?.id == voucherDetails?.paymentPaidBy)?.name
      },
      {
        name: "Payout Date",
        value:voucherDetails?.payoutDate  ? formatDateTOYYYYMMDD(voucherDetails?.payoutDate) : ""
      },
      {
        name: "Total",
        value:currencyBodyTemplate(voucherDetails?.total),
      },
      {
        name: "Less TDS",
        value:currencyBodyTemplate(voucherDetails?.lessTDS),
      },
      {
        name: "Round Off",
        value:currencyBodyTemplate(voucherDetails?.roundOff),
      },
      {
        name: "Grand Total",
        value:currencyBodyTemplate(voucherDetails?.grandTotal),
      },
    ],
    userDetails:[
        {
            name:"Name",
            value:voucherDetails?.printUserDto?.name
        },
        {
            name:"User Type",
            value:voucherDetails?.printUserDto?.userType
        },
        {
            name:"Mobile",
            value:voucherDetails?.printUserDto?.phoneNumber
        },
        {
            name:"Email",
            value:voucherDetails?.printUserDto?.email
        },
        {
            name:"Address",
            value:voucherDetails?.printUserDto?.address
        },
        {
            name:"GST Number",
            value:voucherDetails?.printUserDto?.gstNumber
        },
    ]
  }

  const headerTemplate =(val:any)=>{
    return(
      <div className="d-flex justify-content-start">
        <div className="d-flex justify-content-end"style={{width:"100px"}}  >
        <div className="align-right">
          {val}
        </div>
        </div>
      </div>
    )
    }
 
  

  const hidePopup = ()=>{
    setVisible(false); 
    dispatch(clearVoucher());
  }

  const dialogContainer = (error: any) => {
    return (
      <>
      <ValidationPopup error={error} visible={visible} onHide={hidePopup} />
      </>
    );
  };
let voucherDetailsValue = useMemo(()=>{
    let data = voucherDetails?.voucherDetailsDto?.filter((val:any)=>val.isDeleted != true)
    return data
},[voucherDetails])

const printVoucher = () =>{
  dispatch(printVoucherByIdLoading(id))
}
 
  return (
    <React.Fragment>
        
      {visible && dialogContainer(error)}
      <div className="userpage">
        <Container fluid>
          <BreadcrumbWithFilter
            title="Voucher"
            titlePath={ROUTER.VOUCHER_GRID}
            breadcrumbItem="Voucher Details"
            isShowCreate={false}
            isShowFilter={false}
            filter={false}
            isShowFirstButton={true}
            firstButtonLabel="Edit Voucher"
            onFirstButtonClick={editUserClick}
            isShowSecondButton={true}
            secondButtonLabel='Print Voucher'
            onSecondButtonClick={printVoucher}
          />
          <Row>
            <div className="view">
              <div className="general-details">
                <b>General Details</b>
              </div>
              <div className="subview">
                <div className="parentCls">
                  {userView.generalDetails.map((data, index) => {
                    return (
                      <div key={index} className={` pt-1 childCls`}>
                        <div>{data.name}</div>
                        <div>
                          {data.value ? (
                            data.value
                          ) : (
                            <div className="view-none-text">-</div>
                          )}
                        </div>
                      </div>
                    );
                  })}
                </div>
              </div>
            </div>
          </Row>

          <Row>
            <div className="view">
              <div className="general-details">
                <b>User Details</b>
              </div>
              <div className="subview">
                <div className="parentCls">
                  {userView.userDetails.map((data, index) => {
                    return (
                      <div key={index} className={` pt-1 childCls`}>
                        <div>{data.name}</div>
                        <div>
                          {data.value ? (
                            data.value
                          ) : (
                            <div className="view-none-text">-</div>
                          )}
                        </div>
                      </div>
                    );
                  })}
                </div>
              </div>
            </div>
          </Row>

          <Row>
            <p><b>Voucher Details</b></p>
            <div className="view">
              <DataTable value={voucherDetailsValue} tableStyle={{ minWidth: "50rem" }} scrollable scrollHeight="400px">
                <Column field="subject" header="Subject"></Column>
                <Column field="rateRs" header="Rate" body={(rowData:any)=>currencyBodyTemplate(rowData.rateRs)}></Column>
                <Column field="qty" header="Quantity"></Column>
                <Column field="amount" header="Amount" body={(rowData:any)=>currencyBodyTemplate(rowData.amount)}></Column>
              </DataTable>
            </div>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
}

export default ViewVoucher