import { BulkEmailTypes } from "./actionTypes";

export const getBulkEmailList = (lazyState: any) => {
    return {
      type: BulkEmailTypes.GET_BULK_EMAIL_LIST_LOADING,
      lazyState,
    };
  };
  
  
  export const getBulkEmailListSuccess = (payload: any) => {
    return {
      type: BulkEmailTypes.GET_BULK_EMAIL_LIST_SUCCESS,
      payload: payload,
    };
  };
  
  export const getBulkEmailListError = (error: any) => {
    return {
      type: BulkEmailTypes.GET_BULK_EMAIL_LIST_ERROR,
      payload: error,
    };
  };

export const getEmployeeEmailList = () => {
    return {
      type: BulkEmailTypes.GET_EMPLOYEE_EMAIL_LOADING,
    };
  };
  
  
  export const getEmployeeEmailSuccess = (payload: any) => {
    return {
      type: BulkEmailTypes.GET_EMPLOYEE_EMAIL_SUCCESS,
      payload: payload,
    };
  };
  
  export const getEmployeeEmailError = (error: any) => {
    return {
      type: BulkEmailTypes.GET_EMPLOYEE_EMAIL_ERROR,
      payload: error,
    };
  };

export const getDealerEmailList = () => {
    return {
      type: BulkEmailTypes.GET_DEALER_EMAIL_LOADING,
    };
  };
  
  
  export const getDealerEmailSuccess = (payload: any) => {
    return {
      type: BulkEmailTypes.GET_DEALER_EMAIL_SUCCESS,
      payload: payload,
    };
  };
  
  export const getDealerEmailError = (error: any) => {
    return {
      type: BulkEmailTypes.GET_DEALER_EMAIL_ERROR,
      payload: error,
    };
  };

export const getCustomerEmailList = () => {
    return {
      type: BulkEmailTypes.GET_CUSTOMER_EMAIL_LOADING,
    };
  };
  
  
  export const getCustomerEmailSuccess = (payload: any) => {
    return {
      type: BulkEmailTypes.GET_CUSTOMER_EMAIL_SUCCESS,
      payload: payload,
    };
  };
  
  export const getCustomerEmailError = (error: any) => {
    return {
      type: BulkEmailTypes.GET_CUSTOMER_EMAIL_ERROR,
      payload: error,
    };
  };

export const getFranchiseEmailList = () => {
    return {
      type: BulkEmailTypes.GET_FRANCHISE_EMAIL_LOADING,
    };
  };
  
  
  export const getFranchiseEmailSuccess = (payload: any) => {
    return {
      type: BulkEmailTypes.GET_FRANCHISE_EMAIL_SUCCESS,
      payload: payload,
    };
  };
  
  export const getFranchiseEmailError = (error: any) => {
    return {
      type: BulkEmailTypes.GET_FRANCHISE_EMAIL_ERROR,
      payload: error,
    };
  };

export const getDealerSubuserEmailList = () => {
    return {
      type: BulkEmailTypes.GET_DEALER_SUBUSER_EMAIL_LOADING,
    };
  };
  
  
  export const getDealerSubuserEmaiSuccess = (payload: any) => {
    return {
      type: BulkEmailTypes.GET_DEALER_SUBUSER_EMAIL_SUCCESS,
      payload: payload,
    };
  };
  
  export const getDealerSubuserEmaiError = (error: any) => {
    return {
      type: BulkEmailTypes.GET_DEALER_SUBUSER_EMAIL_ERROR,
      payload: error,
    };
  };

export const getFranchiseSubuserEmailList = () => {
    return {
      type: BulkEmailTypes.GET_FRANCHISE_SUBUSER_EMAIL_LOADING,
    };
  };
  
  
  export const getFranchiseSubuserEmaiSuccess = (payload: any) => {
    return {
      type: BulkEmailTypes.GET_FRANCHISE_SUBUSER_EMAIL_SUCCESS,
      payload: payload,
    };
  };
  
  export const getFranchiseSubuserEmaiError = (error: any) => {
    return {
      type: BulkEmailTypes.GET_FRANCHISE_SUBUSER_EMAIL_ERROR,
      payload: error,
    };
  };

export const getSupplierEmailList = () => {
    return {
      type: BulkEmailTypes.GET_SUPPLIER_EMAIL_LOADING,
    };
  };
  
  
  export const getSupplierEmailSuccess = (payload: any) => {
    return {
      type: BulkEmailTypes.GET_SUPPLIER_EMAIL_SUCCESS,
      payload: payload,
    };
  };
  
  export const getSupplierEmailError = (error: any) => {
    return {
      type: BulkEmailTypes.GET_SUPPLIER_EMAIL_ERROR,
      payload: error,
    };
  };

export const getManufacturerEmailList = () => {
    return {
      type: BulkEmailTypes.GET_MANUFACTURER_EMAIL_LOADING,
    };
  };
  
  
  export const getManufacturerEmailSuccess = (payload: any) => {
    return {
      type: BulkEmailTypes.GET_MANUFACTURER_EMAIL_SUCCESS,
      payload: payload,
    };
  };
  
  export const getManufacturerEmailError = (error: any) => {
    return {
      type: BulkEmailTypes.GET_MANUFACTURER_EMAIL_ERROR,
      payload: error,
    };
  };

export const createBulkEmail = (payload:any) => {
    return {
      type: BulkEmailTypes.CREATE_BULK_EMAIL_LOADING,
      payload
    };
  };
  
  
  export const createBulkEmailSuccess = (payload: any) => {
    return {
      type: BulkEmailTypes.CREATE_BULK_EMAIL_SUCCESS,
      payload: payload,
    };
  };
  
  export const createBulkEmailError = (error: any) => {
    return {
      type: BulkEmailTypes.CREATE_BULK_EMAIL_ERROR,
      payload: error,
    };
  };
export const deleteBulkEmail = (id:any) => {
  console.log(id)
    return {
      type: BulkEmailTypes.DELETE_BULK_EMAIL_LOADING,
      id:id
    };
  };
  
  
  export const deleteBulkEmailSuccess = (payload: any) => {
    return {
      type: BulkEmailTypes.DELETE_BULK_EMAIL_SUCCESS,
      payload: payload,
    };
  };
  
  export const deleteBulkEmailError = (error: any) => {
    return {
      type: BulkEmailTypes.DELETE_BULK_EMAIL_ERROR,
      payload: error,
    };
  };
export const getBulkEmailStatus = () => {
    return {
      type: BulkEmailTypes.GET_BULK_EMAILSTATUS_LOADING,
    };
  };
  
  
  export const getBulkEmailStatusSuccess = (payload: any) => {
    return {
      type: BulkEmailTypes.GET_BULK_EMAILSTATUS_SUCCESS,
      payload: payload,
    };
  };
  
  export const getBulkEmailStatusError = (error: any) => {
    return {
      type: BulkEmailTypes.GET_BULK_EMAILSTATUS_ERROR,
      payload: error,
    };
  };
export const getBulkEmailById = (id:any) => {
  console.log(id)
    return {
      type: BulkEmailTypes.GET_BY_ID_BULK_EMAIL_LOADING,
      id:id
    };
  };
  
  
  export const getBulkEmailByIdSuccess = (payload: any) => {
    return {
      type: BulkEmailTypes.GET_BY_ID_BULK_EMAIL_SUCCESS,
      payload: payload,
    };
  };
  
  export const getBulkEmailByIdError = (error: any) => {
    return {
      type: BulkEmailTypes.GET_BY_ID_BULK_EMAIL_ERROR,
      payload: error,
    };
  };

export const getPrefix = () => {
    return {
      type: BulkEmailTypes.GET_PREFIX_LOADING,
    };
  };
  
  
  export const getPrefixSuccess = (payload: any) => {
    return {
      type: BulkEmailTypes.GET_PREFIX_SUCCESS,
      payload: payload,
    };
  };
  
  export const getPrefixError = (error: any) => {
    return {
      type: BulkEmailTypes.GET_PREFIX_ERROR,
      payload: error,
    };
  };

export const getEmailByPrefix = (payload:any) => {
    return {
      type: BulkEmailTypes.GET_PREFIX_EMAIL_LOADING,
      payload:payload
    };
  };
  
  
  export const getEmailByPrefixSuccess = (payload: any) => {
    return {
      type: BulkEmailTypes.GET_PREFIX_EMAIL_SUCCESS,
      payload: payload,
    };
  };
  
  export const getEmailByPrefixError = (error: any) => {
    return {
      type: BulkEmailTypes.GET_PREFIX_EMAIL_ERROR,
      payload: error,
    };
  };
  
  export const clearBulkEmailMessage = () => {
    return {
      type: BulkEmailTypes.CLEAR_BULK_EMAIL_MESSAGE,
    };
  };