import { messages } from 'src/common/data';
import { StockTransferActionTypes } from "./actionTypes";
import { StockTransferInitialState } from 'src/models/pages/stockTransferModel';

const INITIAL_STATE: StockTransferInitialState = {
  error: "",
  message: "",
  stockTransferError: "",
  stockTransferMessage: "",
  loading: false,
  franchiseList: [],
  stockTransferList: {
    values: [],
    totalRecords: 0,
  },
  stockTransferDetail: null,
  partList: [],
  paginatorCount: 0,
  courierDropdown: [],
  deliverablePerson: [],
  partSkuValue:"",
  mailmessage:"",
  employeeList:""
};


const StockTransferReducer = (state = INITIAL_STATE, action: any) => {
  const { type, payload } = action;
  switch (type) {
    case StockTransferActionTypes.GET_STOCK_TRANSFER_LIST_LOADING:
    case StockTransferActionTypes.GET_FRANCHISE_LIST_FOR_STOCK_TRANSFER_LOADING:
    case StockTransferActionTypes.GET_EMPLOYEE_LIST_FOR_STOCK_TRANSFER_LOADING:
    case StockTransferActionTypes.DELETE_STOCK_TRANSFER_LIST_LOADING:
    case StockTransferActionTypes.UPDATE_STOCK_TRANSFER_LOADING:
    case StockTransferActionTypes.CREATE_STOCK_TRANSFER_LOADING:
    case StockTransferActionTypes.GET_STOCK_TRANSFER_BY_ID_LOADING:
    case StockTransferActionTypes.GET_PART_LIST_FOR_STOCK_TRANSFER_LOADING:
    case StockTransferActionTypes.APPROVE_STOCK_TRANSFER_LOADING:
    case StockTransferActionTypes.REJECT_STOCK_TRANSFER_LOADING:
    case StockTransferActionTypes.DISPATCH_STOCK_TRANSFER_LOADING:
    case StockTransferActionTypes.RECEIVE_STOCK_TRANSFER_LOADING:
    case StockTransferActionTypes.GET_STOCK_TRANSFER_DELIVERABLE_USER_LIST_LOADING:
    case StockTransferActionTypes.GET_STOCK_TRANSFER_COURIER_LIST_LOADING:
    case StockTransferActionTypes.POST_COURIER_STOCK_TRANSFER_LOADING:
    case StockTransferActionTypes.PARTS_VALIDATE:
    case StockTransferActionTypes.PRINT_STOCK_TRANSFER:
    case StockTransferActionTypes.EMAIL_STOCK_TRANSFER:

      return {
        ...state,
        // error: "",
        // message: "",
        loading: true,
      };

    case StockTransferActionTypes.GET_STOCK_TRANSFER_BY_ID_SUCCESS:
      return { ...state, stockTransferDetail: payload };

    case StockTransferActionTypes.CREATE_STOCK_TRANSFER_SUCCESS:
      return {
        ...state,
        loading: false,
        error: "",
        message: "Stock Transfer created successfully",
      };

    case StockTransferActionTypes.UPDATE_STOCK_TRANSFER_SUCCESS:
      return {
        ...state,
        loading: false,
        error: "",
        message: "Stock Transfer updated successfully",
      };

    case StockTransferActionTypes.GET_STOCK_TRANSFER_LIST_SUCCESS:
      return {
        ...state,
        loading: false,
        stockTransferList: {
          ...state.stockTransferList,
          values: action.payload.data.items,
          totalRecords: action.payload.data.totalCount,
        },
        paginatorCount: action.payload.data.items.length
      };

    case StockTransferActionTypes.DELETE_STOCK_TRANSFER_LIST_SUCCESS:

      const url = new URL(action.payload.raw.url);
      const undoParam = url.searchParams.get('undo');
      let msg = " ";
      if (undoParam !== null) {
        const undoValue = undoParam === 'true';
        msg = undoValue ? "Stock Transfer restored successfully" : "Stock Transfer deleted successfully";
      } else {
        msg = "Stock Transfer deleted successfully"
      }
      return {
        ...state,
        loading: false,
        message: msg,
        error: ""
      };

    case StockTransferActionTypes.GET_STOCK_TRANSFER_COURIER_LIST_SUCCESS:
      return {
        ...state,
        loading: false,
        courierDropdown: action.payload.data?.filter((eachCourier: any) => !eachCourier.isDeleted).filter((eachCourier: any) => eachCourier.companyName != "")
      }

    case StockTransferActionTypes.GET_STOCK_TRANSFER_DELIVERABLE_USER_LIST_SUCCESS:
      return {
        ...state,
        loading: false,
        deliverablePerson : action.payload.data
      }

    case StockTransferActionTypes.GET_FRANCHISE_LIST_FOR_STOCK_TRANSFER_SUCCESS:
      return {
        ...state,
        loading: false,
        error: "",
        message: "",
        mailmessage:"",
        franchiseList: action.payload.data,
      };

    case StockTransferActionTypes.GET_EMPLOYEE_LIST_FOR_STOCK_TRANSFER_SUCCESS:
      return {
        ...state,
        loading: false,
        error: "",
        message: "",
        mailmessage:"",
        employeeList: action.payload.data,
      };

    case StockTransferActionTypes.GET_PART_LIST_FOR_STOCK_TRANSFER_SUCCESS:
      return {
        ...state,
        loading: false,
        partList: action.payload.data,
      };


    case StockTransferActionTypes.APPROVE_STOCK_TRANSFER_SUCCESS:
      return {
        ...state,
        loading: false,
        message: "StockTransfer approved successfully",
        error: ""
      };

    case StockTransferActionTypes.REJECT_STOCK_TRANSFER_SUCCESS:
      return {
        ...state,
        loading: false,
        message: "Stock Transfer rejected successfully",
        error: ""
      };

    case StockTransferActionTypes.DISPATCH_STOCK_TRANSFER_SUCCESS:
      return {
        ...state,
        loading: false,
        // stockTransferMessage: "StockTransfer dispatched successfully",
        stockTransferMessage: "Stock Transfer transitions to a pending state successfully.",
        error: "",
        message: "",
        mailmessage:"",
        stockTransferError: ""
      };
    
    case StockTransferActionTypes.POST_COURIER_STOCK_TRANSFER_SUCCESS:
      return {
        ...state,
        loading : false,
        error : "",
        message : "Stock Transfer couriered successfully"
      }  

    case StockTransferActionTypes.RECEIVE_STOCK_TRANSFER_SUCCESS:
      return {
        ...state,
        loading: false,
        stockTransferMessage: "Stock Transfer received successfully",
        error: "",
        message: "",
        mailmessage:"",
        stockTransferError: ""

      };

    case StockTransferActionTypes.PRINT_STOCK_TRANSFER_SUCCESS:
      return {
        ...state,
        loading: false,
      };

    case StockTransferActionTypes.EMAIL_STOCK_TRANSFER_SUCCESS:
      return {
        ...state,
        loading: false,
        mailmessage:"Stock Transfer has been successfully emailed"
      };



    case StockTransferActionTypes.GET_STOCK_TRANSFER_LIST_FAIL:
    case StockTransferActionTypes.GET_FRANCHISE_LIST_FOR_STOCK_TRANSFER_ERROR:
    case StockTransferActionTypes.GET_EMPLOYEE_LIST_FOR_STOCK_TRANSFER_ERROR:
    case StockTransferActionTypes.DELETE_STOCK_TRANSFER_LIST_ERROR:
    case StockTransferActionTypes.UPDATE_STOCK_TRANSFER_ERROR:
    case StockTransferActionTypes.CREATE_STOCK_TRANSFER_ERROR:
    case StockTransferActionTypes.GET_STOCK_TRANSFER_BY_ID_ERROR:
    case StockTransferActionTypes.GET_PART_LIST_FOR_STOCK_TRANSFER_ERROR:
    case StockTransferActionTypes.APPROVE_STOCK_TRANSFER_ERROR:
    case StockTransferActionTypes.REJECT_STOCK_TRANSFER_ERROR:
    case StockTransferActionTypes.GET_STOCK_TRANSFER_DELIVERABLE_USER_LIST_ERROR:
    case StockTransferActionTypes.GET_STOCK_TRANSFER_COURIER_LIST_ERROR:
    case StockTransferActionTypes.POST_COURIER_STOCK_TRANSFER_ERROR:
    case StockTransferActionTypes.PARTS_VALIDATE_ERROR:
    case StockTransferActionTypes.PRINT_STOCK_TRANSFER_ERROR:
    case StockTransferActionTypes.EMAIL_STOCK_TRANSFER_ERROR:
      return {
        ...state,
        loading: false,
        error: action.payload
      };

    case StockTransferActionTypes.DISPATCH_STOCK_TRANSFER_ERROR:
    case StockTransferActionTypes.RECEIVE_STOCK_TRANSFER_ERROR:
      return {
        ...state,
        loading: false,
        stockTransferError: action.payload,
        stockTransferMessage: "",
        message: "",
        error: "",
        mailmessage:""
      };

    // clear dealer
    case StockTransferActionTypes.RESET_STOCK_TRANSFER_VALUE:
      return {
        ...state,
        ...INITIAL_STATE,
      };
    case StockTransferActionTypes.CLEAR_STOCK_TRANSFER_MESSAGE:
      return {
        ...state,
        message: "",
        error: "",
        stockTransferError: "",
        stockTransferMessage: "",
        loading: false,
        mailmessage:""
      };
    case StockTransferActionTypes.PARTS_VALIDATE_SUCCESS:
      return {
        ...state,
       partSkuValue:action.payload.data,
        loading: false,
      };

    default:
      return { ...state };
  }
};

export default StockTransferReducer;
