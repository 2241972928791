import { call, put, takeEvery, takeLatest } from "redux-saga/effects";
import { API } from "src/util/api";
import { createPartsInboundError, createPartsInboundSuccess, deletePartsInboundFromListError, deletePartsInboundFromListSuccess, getPartsDropdownError, getPartsDropdownSuccess, getPartsInboundByIdSuccess, getPartsInboundList, getPartsInboundListError, getPartsInboundListSuccess, getPurchaseOrderDropdownError, getPurchaseOrderDropdownSuccess, getPurposeDropdownError, getPurposeDropdownSuccess, getStockLocationDropdownError, getStockLocationDropdownSuccess, getSupplierDropdownError, getSupplierDropdownSuccess, partsinboundSkugeneratedError, partsinboundSkugeneratedSuccess, updatePartsInboundError, updatePartsInboundSuccess } from "./action";
import { PartsInboundTypes } from "./acionTypes";


function* getPartsInboundListAsync(action: any) {
  try {
    const response: Promise<any> = yield call(API.getPartsInboundListAPI, { ...action.lazyState });
    
    yield put(getPartsInboundListSuccess(response));
  } catch (error) {
    yield put(getPartsInboundListError(error));
  }
}


function* getPartsInboundById(action: any) {
  try {
    const response: Promise<any> = yield call(API.getPartsInboundById, { id: action.id })

    yield put(getPartsInboundByIdSuccess(response));
  } catch (error) {
  }
}


function* createPartsInboundAsync(action: any) {
  try {
    
    
    const response: Promise<any> = yield call(API.createPartsInboundApi, (action.payload))
    yield put(createPartsInboundSuccess(response))
  } catch (error) {
    
    yield put(createPartsInboundError(error))
  }
}


function* updatePartsInboundAsync(action: any) {
  try {
    const response: Promise<any> = yield call(
      API.updatePartsInboundListAPI, { action }
    )
    yield put(updatePartsInboundSuccess(response))
  } catch (error) {
    yield put(updatePartsInboundError(error));
  }
}


function* deletePartsInboundAsync(action: any) {
  try {
    const response: Promise<any> = yield call(
      API.deletePartsInboundListAPI, { id: action.payload.id, undo: action.payload.undo }
    )
    yield put(deletePartsInboundFromListSuccess(response,action.payload.undo))
  } catch (error) {
    
    
    yield put(deletePartsInboundFromListError(error));
  }
}

function* getSupplierDropdownAsync(){
  try{
    const response:Promise<any>=yield call(API.getSupplierListFromMasterManagement,'')
    yield put(getSupplierDropdownSuccess(response))
  }catch (err){
    yield put(getSupplierDropdownError(err))
  }
}

function* getStockLocationDropdownAsync(){
  try{
    const response:Promise<any>=yield call(API.getStockLocationDropdownApi)
    yield put(getStockLocationDropdownSuccess(response))
  }catch (err){
    yield put(getStockLocationDropdownError(err))
  }
}
function* getPurchaseOrderDropdownAsync(){
  try{
    const response:Promise<any>=yield call(API.getPurchaseOrederListFromMasterManagement,'')
    yield put(getPurchaseOrderDropdownSuccess(response))
  }catch (err){
    yield put(getPurchaseOrderDropdownError(err))
  }
}

function* getPartsDropdownAsync(){
  try{
    const response:Promise<any>=yield call(API.getPartsFromMasterManagement)
    yield put(getPartsDropdownSuccess(response))
  }catch (err){
    yield put(getPartsDropdownError(err))
  }
}

//parst sku generated api

function* partsSkuGeneratedAsync(action : any){
  
  try {
    const response:Promise<any>=yield call(API.partsSkugeneratedApi,{action : action.payload}) ;
    
    yield put(partsinboundSkugeneratedSuccess(response));
  } catch (error) {
    yield put(partsinboundSkugeneratedError(error));
  }
}

function* getPurposeDropdownAsync(){
  try{
    const response:Promise<any>=yield call(API.getPurposeListFromMasterManagement,'')
    yield put(getPurposeDropdownSuccess(response))
  }catch (err){
    yield put(getPurposeDropdownError(err))
  }
}

function* PartsInboundSaga() {
  yield takeLatest(PartsInboundTypes.GET_PARTS_INBOUND_LIST, getPartsInboundListAsync);
  yield takeLatest(PartsInboundTypes.DELETE_PARTS_INBOUND, deletePartsInboundAsync);
  yield takeLatest(PartsInboundTypes.GET_PARTS_INBOUND_BY_ID, getPartsInboundById);
  yield takeLatest(PartsInboundTypes.UPDATE_PARTS_INBOUND, updatePartsInboundAsync);
  yield takeLatest(PartsInboundTypes.CREATE_PARTS_INBOUND, createPartsInboundAsync);
  yield takeLatest(PartsInboundTypes.GET_SUPPLIER_DROPDOWN,getSupplierDropdownAsync);
  yield takeLatest(PartsInboundTypes.GET_STOCK_LOCATION_DROPDOWN,getStockLocationDropdownAsync);
  yield takeLatest(PartsInboundTypes.GET_PURCHASE_ORDER_DROPDOWN,getPurchaseOrderDropdownAsync);
  yield takeLatest(PartsInboundTypes.GET_PARTS_DROPDOWN,getPartsDropdownAsync);
  yield takeLatest(PartsInboundTypes.PARTS_INBOUND_SKU_GENERATED_LOADING,partsSkuGeneratedAsync);
  yield takeLatest(PartsInboundTypes.GET_PURPOSE_DROPDOWN,getPurposeDropdownAsync);

}

export default PartsInboundSaga;