export enum JobSheetTypes {
  // Grid
  GET_JOBSHEETS_LIST = "@@jobsheet/GET_JOBSHEETS_LIST",
  GET_JOBSHEETS_LIST_SUCCESS = "@@jobsheet/GET_JOBSHEETS_LIST_SUCCESS",
  GET_JOBSHEETS_LIST_FAIL = "@@jobsheet/GET_JOBSHEETS_LIST_FAIL",

  //  pdf service request 
  DOWNLAOAD_SERVICE_REQUEST_FOR_JOB = "@@jobsheet/DOWNLAOAD_SERVICE_REQUEST_FOR_JOB",
  DOWNLAOAD_SERVICE_REQUEST_FOR_JOB_SUCCESS = "@@jobsheet/DOWNLAOAD_SERVICE_REQUEST_FOR_JOB_SUCCESS",
  DOWNLAOAD_SERVICE_REQUEST_FOR_JOB_FAIL = "@@jobsheet/DOWNLAOAD_SERVICE_REQUEST_FOR_JOB_FAIL",

  //  pdf service request 
  DOWNLAOAD_JOB_SHEET = "@@jobsheet/DOWNLAOAD_JOB_SHEET",
  DOWNLAOAD_JOB_SHEET_SUCCESS = "@@jobsheet/DOWNLAOAD_JOB_SHEET_SUCCESS",
  DOWNLAOAD_JOB_SHEET_FAIL = "@@jobsheet/DOWNLAOAD_JOB_SHEET_FAIL",

  //  pdf estimate 
  DOWNLAOAD_ESTIMATE_FOR_JOB_SHEET = "@@jobsheet/DOWNLAOAD_ESTIMATE_FOR_JOB_SHEET",
  DOWNLAOAD_ESTIMATE_FOR_JOB_SHEET_SUCCESS = "@@jobsheet/DOWNLAOAD_ESTIMATE_FOR_JOB_SHEET_SUCCESS",
  DOWNLAOAD_ESTIMATE_FOR_JOB_SHEET_FAIL = "@@jobsheet/DOWNLAOAD_ESTIMATE_FOR_JOB_SHEET_FAIL",

  //  SEND MAIL FOR estimate 
  SEND_EMAIL_ESTIMATE_FOR_JOB_SHEET = "@@jobsheet/SEND_EMAIL_ESTIMATE_FOR_JOB_SHEET",
  SEND_EMAIL_ESTIMATE_FOR_JOB_SHEET_SUCCESS = "@@jobsheet/SEND_EMAIL_ESTIMATE_FOR_JOB_SHEET_SUCCESS",
  SEND_EMAIL_ESTIMATE_FOR_JOB_SHEET_FAIL = "@@jobsheet/SEND_EMAIL_ESTIMATE_FOR_JOB_SHEET_FAIL",

  //Whatsapp Estimate
  SEND_WHATSAPP_ESTIMATE_FOR_JOB_SHEET = "@@jobsheet/SEND_WHATSAPP_ESTIMATE_FOR_JOB_SHEET",
  SEND_WHATSAPP_ESTIMATE_FOR_JOB_SHEET_SUCCESS = "@@jobsheet/SEND_WHATSAPP_ESTIMATE_FOR_JOB_SHEET_SUCCESS",
  SEND_WHATSAPP_ESTIMATE_FOR_JOB_SHEET_FAIL = "@@jobsheet/SEND_WHATSAPP_ESTIMATE_FOR_JOB_SHEET_FAIL",

  //  SEND INVOICE IN MAIL 
  SEND_INVOICE_EMAIL_FOR_JOB_SHEET = "@@jobsheet/SEND_INVOICE_EMAIL_FOR_JOB_SHEET",
  SEND_INVOICE_EMAIL_FOR_JOB_SHEET_SUCCESS = "@@jobsheet/SEND_INVOICE_EMAIL_FOR_JOB_SHEET_SUCCESS",
  SEND_INVOICE_EMAIL_FOR_JOB_SHEET_FAIL = "@@jobsheet/SEND_INVOICE_EMAIL_FOR_JOB_SHEET_FAIL",

  //  SEND INVOICE IN MAIL 
  SEND_INVOICE_WHATSAPP_FOR_JOB_SHEET = "@@jobsheet/SEND_INVOICE_WHATSAPP_FOR_JOB_SHEET",
  SEND_INVOICE_WHATSAPP_FOR_JOB_SHEET_SUCCESS = "@@jobsheet/SEND_INVOICE_WHATSAPP_FOR_JOB_SHEET_SUCCESS",
  SEND_INVOICE_WHATSAPP_FOR_JOB_SHEET_FAIL = "@@jobsheet/SEND_INVOICE_WHATSAPP_FOR_JOB_SHEET_FAIL",

  //  DOWNLOAD INVOICE IN MAIL 
  DOWNLOAD_INVOICE_FOR_JOB_SHEET = "@@jobsheet/DOWNLOAD_INVOICE_FOR_JOB_SHEET",
  DOWNLOAD_INVOICE_FOR_JOB_SHEET_SUCCESS = "@@jobsheet/DOWNLOAD_INVOICE_FOR_JOB_SHEET_SUCCESS",
  DOWNLOAD_INVOICE_FOR_JOB_SHEET_FAIL = "@@jobsheet/DOWNLOAD_INVOICE_FOR_JOB_SHEET_FAIL",

  //  SEND SMS FOR estimate 
  SEND_SMS_ESTIMATE_FOR_JOB_SHEET = "@@jobsheet/SEND_SMS_ESTIMATE_FOR_JOB_SHEET",
  SEND_SMS_ESTIMATE_FOR_JOB_SHEET_SUCCESS = "@@jobsheet/SEND_SMS_ESTIMATE_FOR_JOB_SHEET_SUCCESS",
  SEND_SMS_ESTIMATE_FOR_JOB_SHEET_FAIL = "@@jobsheet/SEND_SMS_ESTIMATE_FOR_JOB_SHEET_FAIL",

  // Grid
  GET_JOBSHEETS_HISTORY_DATA = "@@jobsheet/GET_JOBSHEETS_HISTORY_DATA",
  GET_JOBSHEETS_HISTORY_DATA_SUCCESS = "@@jobsheet/GET_JOBSHEETS_HISTORY_DATA_SUCCESS",
  GET_JOBSHEETS_HISTORY_DATA_FAIL = "@@jobsheet/GET_JOBSHEETS_HISTORY_DATA_FAIL",

  //delete JobSheet
  DELETE_JOBSHEETS = "@@jobsheet/DELETE_JOBSHEETS",
  DELETE_JOBSHEETS_SUCCESS = "@@jobsheet/DELETE_JOBSHEETS_SUCCESS",
  DELETE_JOBSHEETS_FAIL = "@@jobsheet/DELETE_JOBSHEETS_FAIL",

  //GetById
  GET_JOBSHEETBYID = "@@jobsheet/GET_JOBSHEETBYID",
  GET_JOBSHEETBYID_SUCCESS = "@@jobsheet/GET_JOBSHEETBYID_SUCCESS",
  GET_JOBSHEETBYID_FAIL = "@@jobsheet/GET_JOBSHEETBYID_FAIL",

  //create JobSheet
  CREATE_JOBSHEET_LOADING = "@@jobsheet/CREATE_JOBSHEET_LOADING",
  CREATE_JOBSHEET_SUCCESS = "@@jobsheet/CREATE_JOBSHEET_SUCCESS",
  CREATE_JOBSHEET_FAIL = "@@jobsheet/CREATE_JOBSHEET_FAIL",

  // get employee drodpdown

  GET_EMPLOYEE_DROPDOWN_LOADING = "@@jobsheet/GET_EMPLOYEE_DROPDOWN_LOADING",
  GET_EMPLOYEE_DROPDOWN_SUCCESS = "@@jobsheet/GET_EMPLOYEE_DROPDOWN_SUCCESS",
  GET_EMPLOYEE_DROPDOWN_FAIL = "@@jobsheet/GET_EMPLOYEE_DROPDOWN_FAIL",


  //  get courier dropdown for dealer or customer
  GET_COURIER_DROPDOWN_LOADING = "@@jobsheet/GET_COURIER_DROPDOWN_LOADING",
  GET_COURIER_DROPDOWN_SUCCESS = "@@jobsheet/GET_COURIER_DROPDOWN_SUCCESS",
  GET_COURIER_DROPDOWN_FAIL = "@@jobsheet/GET_COURIER_DROPDOWN_FAIL",

  // get courier dropdown for all data
  GET_COURIER_DROPDOWN_ALL_DATA_LOADING = "@@jobsheet/GET_COURIER_DROPDOWN_ALL_DATA_LOADING",
  GET_COURIER_DROPDOWN_ALL_DATA_SUCCESS = "@@jobsheet/GET_COURIER_DROPDOWN_ALL_DATA_SUCCESS",
  GET_COURIER_DROPDOWN_ALL_DATA_ERROR = "@@jobsheet/GET_COURIER_DROPDOWN_ALL_DATA_ERROR",

  //  get parts dropdown
  GET_PARTS_DROPDOWN_LOADING = "@@jobsheet/GET_PARTS_DROPDOWN_LOADING",
  GET_PARTS_DROPDOWN_SUCCESS = "@@jobsheet/GET_PARTS_DROPDOWN_SUCCESS",
  GET_PARTS_DROPDOWN_FAIL = "@@jobsheet/GET_PARTS_DROPDOWN_FAIL",

  //  get taxes dropdown
  GET_TAXES_DROPDOWN_LOADING = "@@jobsheet/GET_TAXES_DROPDOWN_LOADING",
  GET_TAXES_DROPDOWN_SUCCESS = "@@jobsheet/GET_TAXES_DROPDOWN_SUCCESS",
  GET_TAXES_DROPDOWN_FAIL = "@@jobsheet/GET_TAXES_DROPDOWN_FAIL",

  //  get taxes dropdown
  GET_TAX_DETAILS_LOADING = "@@jobsheet/GET_TAX_DETAILS_LOADING",
  GET_TAX_DETAILS_SUCCESS = "@@jobsheet/GET_TAX_DETAILS_SUCCESS",
  GET_TAX_DETAILS_FAIL = "@@jobsheet/GET_TAX_DETAILS_FAIL",

  //  get part deails for job
  GET_PART_DETAILS_FOR_JOB = "@@jobsheet/GET_PART_DETAILS_FOR_JOB",
  GET_PART_DETAILS_FOR_JOB_SUCCESS = "@@jobsheet/GET_PART_DETAILS_FOR_JOB_SUCCESS",
  GET_PART_DETAILS_FOR_JOB_FAIL = "@@jobsheet/GET_PART_DETAILS_FOR_JOB_FAIL",

  RESET_PART_DETAILS_FOR_JOB = "@@jobsheet/RESET_PART_DETAILS_FOR_JOB",
  RESET_TAX_DETAILS_FOR_JOB = "@@jobsheet/RESET_TAX_DETAILS_FOR_JOB",

  // update pending commands
  UPDATE_PENDING_COMMAND_LOADING = "@@jobsheet/UPDATE_PENDING_COMMAND_LOADING",
  UPDATE_PENDING_COMMAND_SUCCESS = "@@jobsheet/UPDATE_PENDING_COMMAND_SUCCESS",
  UPDATE_PENDING_COMMAND_FAIL = "@@jobsheet/UPDATE_PENDING_COMMAND_FAIL",

  // delete pending commands
  DELETE_PENDING_COMMAND_LOADING = "@@jobsheet/DELETE_PENDING_COMMAND_LOADING",
  DELETE_PENDING_COMMAND_SUCCESS = "@@jobsheet/DELETE_PENDING_COMMAND_SUCCESS",
  DELETE_PENDING_COMMAND_FAIL = "@@jobsheet/DELETE_PENDING_COMMAND_FAIL",

  //update JobSheet
  SERVICE_REQUEST_JOB = "@@jobsheet/SERVICE_REQUEST_JOB",
  SERVICE_REQUEST_JOB_SUCCESS = "@@jobsheet/SERVICE_REQUEST_JOB_SUCCESS",
  SERVICE_REQUEST_JOB_FAIL = "@@jobsheet/SERVICE_REQUEST_JOB_FAIL",

  REPLACEMENT_SYSTEM_DISPATCH = "@@jobsheet/REPLACEMENT_SYSTEM_DISPATCH",
  REPLACEMENT_SYSTEM_DISPATCH_SUCCESS = "@@jobsheet/REPLACEMENT_SYSTEM_DISPATCH_SUCCESS",
  REPLACEMENT_SYSTEM_DISPATCH_FAIL = "@@jobsheet/REPLACEMENT_SYSTEM_DISPATCH_FAIL",

  CREATE_JOB = "@@jobsheet/CREATE_JOB",
  CREATE_JOB_SUCCESS = "@@jobsheet/CREATE_JOB_SUCCESS",
  CREATE_JOB_FAIL = "@@jobsheet/CREATE_JOB_FAIL",

  ASSIGN_TO_ESTIMATE_JOB = "@@jobsheet/ASSIGN_TO_ESTIMATE_JOB",
  ASSIGN_TO_ESTIMATE_JOB_SUCCESS = "@@jobsheet/ASSIGN_TO_ESTIMATE_JOB_SUCCESS",
  ASSIGN_TO_ESTIMATE_JOB_FAIL = "@@jobsheet/ASSIGN_TO_ESTIMATE_JOB_FAIL",

  ESTIMATE_COMPLETE_JOB = "@@jobsheet/ESTIMATE_COMPLETE_JOB",
  ESTIMATE_COMPLETE_JOB_SUCCESS = "@@jobsheet/ESTIMATE_COMPLETE_JOB_SUCCESS",
  ESTIMATE_COMPLETE_JOB_FAIL = "@@jobsheet/ESTIMATE_COMPLETE_JOB_FAIL",

  ESTIMATE_APPROVAL_JOB = "@@jobsheet/ESTIMATE_APPROVAL_JOB",
  ESTIMATE_APPROVAL_JOB_SUCCESS = "@@jobsheet/ESTIMATE_APPROVAL_JOB_SUCCESS",
  ESTIMATE_APPROVAL_JOB_FAIL = "@@jobsheet/ESTIMATE_APPROVAL_JOB_FAIL",

  CANCEL_JOB = "@@jobsheet/CANCEL_JOB",
  CANCEL_JOB_SUCCESS = "@@jobsheet/CANCEL_JOB_SUCCESS",
  CANCEL_JOB_FAIL = "@@jobsheet/CANCEL_JOB_FAIL",

  ASSIGN_TO_TECHNICIAN = "@@jobsheet/ASSIGN_TO_TECHNICIAN",
  ASSIGN_TO_TECHNICIAN_SUCCESS = "@@jobsheet/ASSIGN_TO_TECHNICIAN_SUCCESS",
  ASSIGN_TO_TECHNICIAN_FAIL = "@@jobsheet/ASSIGN_TO_TECHNICIAN_FAIL",

  ZERO_ESTIMATE_ASSIGN_TO_TECHNICIAN = "@@jobsheet/ZERO_ESTIMATE_ASSIGN_TO_TECHNICIAN",
  ZERO_ESTIMATE_ASSIGN_TO_TECHNICIAN_SUCCESS = "@@jobsheet/ZERO_ESTIMATE_ASSIGN_TO_TECHNICIAN_SUCCESS",
  ZERO_ESTIMATE_ASSIGN_TO_TECHNICIAN_FAIL = "@@jobsheet/ZERO_ESTIMATE_ASSIGN_TO_TECHNICIAN_FAIL",

  READY_TO_INVOICE = "@@jobsheet/READY_TO_INVOICE",
  READY_TO_INVOICE_SUCCESS = "@@jobsheet/READY_TO_INVOICE_SUCCESS",
  READY_TO_INVOICE_FAIL = "@@jobsheet/READY_TO_INVOICE_FAIL",

  JOB_INVOICED = "@@jobsheet/JOB_INVOICED",
  JOB_INVOICED_SUCCESS = "@@jobsheet/JOB_INVOICED_SUCCESS",
  JOB_INVOICED_FAIL = "@@jobsheet/JOB_INVOICED_FAIL",

  JOB_CLOSE = "@@jobsheet/JOB_CLOSE",
  JOB_CLOSE_SUCCESS = "@@jobsheet/JOB_CLOSE_SUCCESS",
  JOB_CLOSE_FAIL = "@@jobsheet/JOB_CLOSE_FAIL",




  //get dealer dropdown list
  DEALERDROPDOWNLIST_LOADING = "@@jobsheet/DEALERDROPDOWNLIST_LOADING",
  DEALERDROPDOWNLIST_SUCCESS = "@@jobsheet/DEALERDROPDOWNLIST_SUCCESS",
  DEALERDROPDOWNLIST_ERROR = "@@jobsheet/DEALERDROPDOWNLIST_ERROR",


  //get vechiclebarandList related to dealer
  GETVEHICLEBRAND_RELATEDTO_DEALER_LOADING = "@@jobsheet/GETVEHICLEBRAND_RELATEDTO_DEALER_LOADING",
  GETVEHICLEBRAND_RELATEDTO_DEALER_SUCCESS = "@@jobsheet/GETVEHICLEBRAND_RELATEDTO_DEALER_SUCCESS",
  GETVEHICLEBRAND_RELATEDTO_DEALER_ERROR = "@@jobsheet/GETVEHICLEBRAND_RELATEDTO_DEALER_ERROR",

  //get customer dropdownList
  GETCUSTOMER_DROPDOWNLIST_LOADING = "@@jobsheet/GETCUSTOMER_DROPDOWNLIST_LOADING",
  GETCUSTOMER_DROPDOWNLIST_SUCCESS = "@@jobsheet/GETCUSTOMER_DROPDOWNLIST_SUCCESS",
  GETCUSTOMER_DROPDOWNLIST_ERROR = "@@jobsheet/GETCUSTOMER_DROPDOWNLIST_ERROR",

  //get AudioModel dropdown
  GET_AUDIOMODEL_JOB_DROPDOWNLIST_LOADING = "@@jobsheet/GET_AUDIOMODEL_JOB_DROPDOWNLIST_LOADING",
  GET_AUDIOMODEL_JOB_DROPDOWNLIST_SUCCESS = "@@jobsheet/GET_AUDIOMODEL_JOB_DROPDOWNLIST_SUCCESS",
  GET_AUDIOMODEL_JOB_DROPDOWNLIST_ERROR = "@@jobsheet/GET_AUDIOMODEL_JOB_DROPDOWNLIST_ERROR",

  // get Audio model details based audio model selected
  GET_AUDIO_MODEL_DETAILS_FOR_JOBSHEET_LOADING = "@@jobsheet/GET_AUDIO_MODEL_DETAILS_FOR_JOBSHEET_LOADING",
  GET_AUDIO_MODEL_DETAILS_FOR_JOBSHEET_SUCCESS = "@@jobsheet/GET_AUDIO_MODEL_DETAILS_FOR_JOBSHEET_SUCCESS",
  GET_AUDIO_MODEL_DETAILS_FOR_JOBSHEET_ERROR = "@@jobsheet/GET_AUDIO_MODEL_DETAILS_FOR_JOBSHEET_ERROR",

  //get pending reason
  GET_PENDING_REASON_LOADING = "@@jobsheet/GET_PENDING_REASON_LOADING",
  GET_PENDING_REASON_SUCCESS = "@@jobsheet/GET_PENDING_REASON_SUCCESS",
  GET_PENDING_REASON_ERROR = "@@jobsheet/GET_PENDING_REASON_ERROR",

  //clear message
  CLEAR_JOBSHEET_MESSAGES = "@@jobsheet/CLEAR_JOBSHEET_MESSAGES",
  //reset customer
  RESET_JOBSHEET = "@@jobsheet/RESET_JOBSHEET",
  CLEAR_JOBSHEET = "@@jobsheet/CLEAR_JOBSHEET",

  //technician dropdown
  GET_JOBSHEET_TECHNICIAN_DROPDOWN_LOADING = "@@jobsheet/GET_JOBSHEET_TECHNICIAN_DROPDOWN_LOADING",
  GET_JOBSHEET_TECHNICIAN_DROPDOWN_SUCCESS = "@@jobsheet/GET_JOBSHEET_TECHNICIAN_DROPDOWN_SUCCESS",
  GET_JOBSHEET_TECHNICIAN_DROPDOWN_ERROR = "@@jobsheet/GET_JOBSHEET_TECHNICIAN_DROPDOWN_ERROR",

  //estimator dropdown
  GET_JOBSHEET_ESTIMATOR_DROPDOWN_LOADING = "@@jobsheet/GET_JOBSHEET_ESTIMATOR_DROPDOWN_LOADING",
  GET_JOBSHEET_ESTIMATOR_DROPDOWN_SUCCESS = "@@jobsheet/GET_JOBSHEET_ESTIMATOR_DROPDOWN_SUCCESS",
  GET_JOBSHEET_ESTIMATOR_DROPDOWN_ERROR = "@@jobsheet/GET_JOBSHEET_ESTIMATOR_DROPDOWN_ERROR",

  //get vehiclebarnsList dropdown

  GET_JOBSHEET_VEHICLEBRAND_DROPDOWN_LOADING = "@@jobsheet/GET_JOBSHEET_VEHICLEBRAND_DROPDOWN_LOADING",
  GET_JOBSHEET_VEHICLEBRAND_DROPDOWN_SUCCESS = "@@jobsheet/GET_JOBSHEET_VEHICLEBRAND_DROPDOWN_SUCCESS",
  GET_JOBSHEET_VEHICLEBRAND_DROPDOWN_ERROR = "@@jobsheet/GET_JOBSHEET_VEHICLEBRAND_DROPDOWN_ERROR",

  //  Get Invoice Details by jobId

  GET_INVOICE_DETAILS_BY_JOB_ID_LOADING = "@@jobsheet/GET_INVOICE_DETAILS_BY_JOB_ID_LOADING",
  GET_INVOICE_DETAILS_BY_JOB_ID_SUCCESS = "@@jobsheet/GET_INVOICE_DETAILS_BY_JOB_ID_SUCCESS",
  GET_INVOICE_DETAILS_BY_JOB_ID_ERROR = "@@jobsheet/GET_INVOICE_DETAILS_BY_JOB_ID_ERROR",

  GET_INVOICE_RECEIVED_PAYMNET = "@@jobsheet/GET_INVOICE_RECEIVED_PAYMNET",
  GET_INVOICE_RECEIVED_PAYMNET_SUCCESS = "@@jobsheet/GET_INVOICE_RECEIVED_PAYMNET_SUCCESS",
  GET_INVOICE_RECEIVED_PAYMNET_ERROR = "@@jobsheet/GET_INVOICE_RECEIVED_PAYMNET_ERROR",


  //  GET  ESTIMATE CONCERN IMAGE
  GET_ESTIMATE_CONCERN_IMAGE = "@@jobsheet/GET_ESTIMATE_CONCERN_IMAGE",
  GET_ESTIMATE_CONCERN_IMAGE_SUCCESS = "@@jobsheet/GET_ESTIMATE_CONCERN_IMAGE_SUCCESS",
  GET_ESTIMATE_CONCERN_IMAGE_FAIL = "@@jobsheet/GET_ESTIMATE_CONCERN_IMAGE_FAIL",

  //get prepared by id
  GET_PREPARED_BYID_LOADING = "@@jobsheet/GET_PREPARED_BYID_LOADING",
  GET_PREPARED_BYID_SUCCESS = "@@jobsheet/GET_PREPARED_BYID_SUCCESS",
  GET_PREPARED_BYID_ERROR = "@@jobsheet/GET_PREPARED_BYID_ERROR",

  //get updatedby id

  GET_UPDATED_BYID_USER_LOADING = "@@jobsheet/GET_UPDATED_BYID_USER_LOADING",
  GET_UPDATED_BYID_USER_SUCCESS = "@@jobsheet/GET_UPDATED_BYID_USER_SUCCESS",
  GET_UPDATED_BYID_USER_ERROR = "@@jobsheet/GET_UPDATED_BYID_USER_ERROR",

  //get Technician by id
  GET_TECHNICIAN_BYID_LOADING = "@@jobsheet/GET_TECHNICIAN_BYID_LOADING",
  GET_TECHNICIAN_BYID_SUCCESS = "@@jobsheet/GET_TECHNICIAN_BYID_SUCCESS",
  GET_TECHNICIAN_BYID_ERROR = "@@jobsheet/GET_TECHNICIAN_BYID_ERROR",

  //get Estimator id

  GET_ESTIMATOR_BYID_USER_LOADING = "@@jobsheet/GET_ESTIMATOR_BYID_USER_LOADING",
  GET_ESTIMATOR_BYID_USER_SUCCESS = "@@jobsheet/GET_ESTIMATOR_BYID_USER_SUCCESS",
  GET_ESTIMATOR_BYID_USER_ERROR = "@@jobsheet/GET_ESTIMATOR_BYID_USER_ERROR",

  //get taxes types in jobsheet

  GET_TAXES_TYPES_IN_JOBSHEET_LOADING = "@@jobsheet/GET_TAXES_TYPES_IN_JOBSHEET_LOADING",
  GET_TAXES_TYPES_IN_JOBSHEET_SUCCESS = "@@jobsheet/GET_TAXES_TYPES_IN_JOBSHEET_SUCCESS",
  GET_TAXES_TYPES_IN_JOBSHEET_ERROR = "@@jobsheet/GET_TAXES_TYPES_IN_JOBSHEET_ERROR",

  //franchise arrived in jobsheets
  FRANCHISEE_ARRIVED_LOADING = "@@jobsheet/FRANCHISEE_ARRIVED_LOADING",
  FRANCHISEE_ARRIVED_SUCCESS = "@@jobsheet/FRANCHISEE_ARRIVED_SUCCESS",
  FRANCHISEE_ARRIVED_ERROR = "@@jobsheet/FRANCHISEE_ARRIVED_ERROR",

  //franchise completed job

  FRANCHISEE_COMPLETED_JOB_LOADING = "@@jobsheet/FRANCHISEE_COMPLETED_JOB_LOADING",
  FRANCHISEE_COMPLETED_JOB_SUCCESS = "@@jobsheet/FRANCHISEE_COMPLETED_JOB_SUCCESS",
  FRANCHISEE_COMPLETED_JOB_ERROR = "@@jobsheet/FRANCHISEE_COMPLETED_JOB_ERROR",

  //get customer or delaer currentLocation : 

  GET_DELAER_OR_CUSTOMER_LOCATION_LOADING = "@@jobsheet/GET_DELAER_OR_CUSTOMER_LOCATION_LOADING",
  GET_DELAER_OR_CUSTOMER_LOCATION_SUCCESS = "@@jobsheet/GET_DELAER_OR_CUSTOMER_LOCATION_SUCCESS",
  GET_DELAER_OR_CUSTOMER_LOCATION_ERROR = "@@jobsheet/GET_DELAER_OR_CUSTOMER_LOCATION_ERROR",

  //additional expenses document uploaded   
  ADDITIONAL_EXPENSES_DOCUMENT_UPLOAD_LOADING = "@@jobsheet/ADDITIONAL_EXPENSES_DOCUMENT_UPLOAD_LOADING",
  ADDITIONAL_EXPENSES_DOCUMENT_UPLOAD_SUCCESS = "@@jobsheet/ADDITIONAL_EXPENSES_DOCUMENT_UPLOAD_SUCCESS",
  ADDITIONAL_EXPENSES_DOCUMENT_UPLOAD_ERROR = "@@jobsheet/ADDITIONAL_EXPENSES_DOCUMENT_UPLOAD_ERROR",

  //additional expenses grid   
  ADDITIONAL_EXPENSES_GRID_LOADING = "@@jobsheet/ADDITIONAL_EXPENSES_GRID_LOADING",
  ADDITIONAL_EXPENSES_GRID_SUCCESS = "@@jobsheet/ADDITIONAL_EXPENSES_GRID_SUCCESS",
  ADDITIONAL_EXPENSES_GRID_ERROR = "@@jobsheet/ADDITIONAL_EXPENSES_GRID_ERROR",

  //additional expenses update
  ADDITIONAL_EXPENSES_UPDATE_LOADING = "@@jobsheet/ADDITIONAL_EXPENSES_UPDATE_LOADING",
  ADDITIONAL_EXPENSES_UPDATE_SUCCESS = "@@jobsheet/ADDITIONAL_EXPENSES_UPDATE_SUCCESS",
  ADDITIONAL_EXPENSES_UPDATE_ERROR = "@@jobsheet/ADDITIONAL_EXPENSES_UPDATE_ERROR",

  //additional expense approve
  ADDITIONAL_EXPENSES_APPROVE_LOADING = "@@jobsheet/ADDITIONAL_EXPENSES_APPROVE_LOADING",
  ADDITIONAL_EXPENSES_APPROVE_SUCCESS = "@@jobsheet/ADDITIONAL_EXPENSES_APPROVE_SUCCESS",
  ADDITIONAL_EXPENSES_APPROVE_ERROR = "@@jobsheet/ADDITIONAL_EXPENSES_APPROVE_ERROR",

  //get additional expense details  
  GET_ADDITIONAL_EXPENSES_DETAILS_LOADING = "@@jobsheet/GET_ADDITIONAL_EXPENSES_DETAILS_LOADING",
  GET_ADDITIONAL_EXPENSES_DETAILS_SUCCESS = "@@jobsheet/GET_ADDITIONAL_EXPENSES_DETAILS_SUCCESS",
  GET_ADDITIONAL_EXPENSES_DETAILS_ERROR = "@@jobsheet/GET_ADDITIONAL_EXPENSES_DETAILS_ERROR",


  // get franchise uplaoded document
  GET_FRANCHISE_UPLOADED_DOCUMENT_LOADING = "@@jobsheet/GET_FRANCHISE_UPLOADED_DOCUMENT_LOADING",
  GET_FRANCHISE_UPLOADED_DOCUMENT_SUCCESS = "@@jobsheet/GET_FRANCHISE_UPLOADED_DOCUMENT_SUCCESS",
  GET_FRANCHISE_UPLOADED_DOCUMENT_ERROR = "@@jobsheet/GET_FRANCHISE_UPLOADED_DOCUMENT_ERROR",

  //get franchise document link
  GET_FRANCHISE_UPLOADED_DOCUMENT_LINK_LOADING = "@@jobsheet/GET_FRANCHISE_UPLOADED_DOCUMENT_LINK_LOADING",
  GET_FRANCHISE_UPLOADED_DOCUMENT_LINK_SUCCESS = "@@jobsheet/GET_FRANCHISE_UPLOADED_DOCUMENT_LINK_SUCCESS",
  GET_FRANCHISE_UPLOADED_DOCUMENT_LINK_ERROR = "@@jobsheet/GET_FRANCHISE_UPLOADED_DOCUMENT_LINK_ERROR",

  //franchise document delete api
  FRANCHISE_DOCUMENT_DELETE_LOADING = "@@jobsheet/FRANCHISE_DOCUMENT_DELETE_LOADING",
  FRANCHISE_DOCUMENT_DELETE_SUCCESS = "@@jobsheet/FRANCHISE_DOCUMENT_DELETE_SUCCESS",
  FRANCHISE_DOCUMENT_DELETE_ERROR = "@@jobsheet/FRANCHISE_DOCUMENT_DELETE_ERROR",


  //clear document link
  CLEAR_FRANCHISE_DOCUMENT_LINK = "@@jobsheet/CLEAR_FRANCHISE_DOCUMENT_LINK",

  GET_JOBSHEET_STATUS = "@@jobsheet/GET_JOBSHEET_STATUS",
  GET_JOBSHEET_STATUS_SUCCESS = "@@jobsheet/GET_JOBSHEET_STATUS_SUCCESS",
  SET_JOBSHEET_STATUS = "@@jobsheet/SET_JOBSHEET_STATUS",
  SET_JOBSHEET_STATUS_SUCCESS = "@@jobsheet/SET_JOBSHEET_STATUS_SUCCESS",

  GET_JOBSHEET_ENQUIRY_BY_ID = "@@jobsheet/GET_JOBSHEET_ENQUIRY_BY_ID",
  GET_JOBSHEET_ENQUIRY_BY_ID_SUCCESS = "@@jobsheet/GET_JOBSHEET_ENQUIRY_BY_ID_SUCCESS",
  GET_JOBSHEET_ENQUIRY_BY_ID_FAIL = "@@jobsheet/GET_JOBSHEET_ENQUIRY_BY_ID_FAIL",


  /// ==========  print Exchnage invoice loading state =========

  PRINT_EXCHANGE_INVOICE_LOADING = "@@jobsheet/PRINT_EXCHANGE_INVOICE_LOADING",
  PRINT_EXCHANGE_INVOICE_SUCCESS = "@@jobsheet/PRINT_EXCHANGE_INVOICE_SUCCESS",
  PRINT_EXCHANGE_INVOICE_ERROR = "@@jobsheet/PRINT_EXCHANGE_INVOICE_ERROR",

  // ====== Email Exchnage Invoice ============

  EMAIL_EXCHNAGE_INVOICE_LOADING = "@@jobsheet/EMAIL_EXCHNAGE_INVOICE_LOADING",
  EMAIL_EXCHNAGE_INVOICE_SUCCESS = "@@jobsheet/EMAIL_EXCHNAGE_INVOICE_SUCCESS",
  EMAIL_EXCHNAGE_INVOICE_ERROR = "@@jobsheet/EMAIL_EXCHNAGE_INVOICE_ERROR",
// whatsapp exchange invoice
  WHATSAPP_EXCHNAGE_INVOICE_LOADING = "@@jobsheet/WHATSAPP_EXCHNAGE_INVOICE_LOADING",
  WHATSAPP_EXCHNAGE_INVOICE_SUCCESS = "@@jobsheet/WHATSAPP_EXCHNAGE_INVOICE_SUCCESS",
  WHATSAPP_EXCHNAGE_INVOICE_ERROR = "@@jobsheet/WHATSAPP_EXCHNAGE_INVOICE_ERROR",

  // ====== Email Service History ============

  EMAIL_SERVICE_HISTORY_LOADING = "@@jobsheet/EMAIL_SERVICE_HISTORY_LOADING",
  EMAIL_SERVICE_HISTORY_SUCCESS = "@@jobsheet/EMAIL_SERVICE_HISTORY_SUCCESS",
  EMAIL_SERVICE_HISTORY_ERROR = "@@jobsheet/EMAIL_SERVICE_HISTORY_ERROR",

  // ====== print Service History ============

  PRINT_SERVICE_HISTORY_LOADING = "@@jobsheet/PRINT_SERVICE_HISTORY_LOADING",
  PRINT_SERVICE_HISTORY_SUCCESS = "@@jobsheet/PRINT_SERVICE_HISTORY_SUCCESS",
  PRINT_SERVICE_HISTORY_ERROR = "@@jobsheet/PRINT_SERVICE_HISTORY_ERROR",

  // ========== delete additional expenses documents ==============
  DELETE_ADDITIONAL_EXPENSES_LOADING = "@@jobsheet/DELETE_ADDITIONAL_EXPENSES_LOADING",
  DELETE_ADDITIONAL_EXPENSES_SUCCESS = "@@jobsheet/DELETE_ADDITIONAL_EXPENSES_SUCCESS",
  DELETE_ADDITIONAL_EXPENSES_ERROR = "@@jobsheet/DELETE_ADDITIONAL_EXPENSES_ERROR",

  //get additional expenses without query pass

  GET_ADDITIONAL_EXPENSES_WITHOUT_QUERY_LOADING = "@@jobsheet/GET_ADDITIONAL_EXPENSES_WITHOUT_QUERY_LOADING",
  GET_ADDITIONAL_EXPENSES_WITHOUT_QUERY_SUCCESS = "@@jobsheet/GET_ADDITIONAL_EXPENSES_WITHOUT_QUERY_SUCCESS",
  GET_ADDITIONAL_EXPENSES_WITHOUT_QUERY_ERROR = "@@jobsheet/GET_ADDITIONAL_EXPENSES_WITHOUT_QUERY_ERROR",


  //// get approve uplaoded document  
  GET_APPROVE_DOCUMENT_LOADING = "@@jobsheet/GET_APPROVE_DOCUMENT_LOADING",
  GET_APPROVE_DOCUMENT_SUCCESS = "@@jobsheet/GET_APPROVE_DOCUMENT_SUCCESS",
  GET_APPROVE_DOCUMENT_ERROR = "@@jobsheet/GET_APPROVE_DOCUMENT_ERROR",

  //get approve document link
  GET_APPROVE_DOCUMENT_LINK_LOADING = "@@jobsheet/GET_APPROVE_DOCUMENT_LINK_LOADING",
  GET_APPROVE_DOCUMENT_LINK_SUCCESS = "@@jobsheet/GET_APPROVE_DOCUMENT_LINK_SUCCESS",
  GET_APPROVE_DOCUMENT_LINK_ERROR = "@@jobsheet/GET_APPROVE_DOCUMENT_LINK_ERROR",

  //clear approve document link
  CLEAR_APPROVE_DOCUMENT_LINK = "@@jobsheet/CLEAR_APPROVE_DOCUMENT_LINK",


  GET_ADDITIONAL_EXPENSE_DOCUMENT_LINK_LOADING = "@@jobsheet/GET_ADDITIONAL_EXPENSE_DOCUMENT_LINK_LOADING",
  GET_ADDITIONAL_EXPENSE_DOCUMENT_LINK_SUCCESS = "@@jobsheet/GET_ADDITIONAL_EXPENSE_DOCUMENT_LINK_SUCCESS",
  GET_ADDITIONAL_EXPENSE_DOCUMENT_LINK_ERROR = "@@jobsheet/GET_ADDITIONAL_EXPENSE_DOCUMENT_LINK_ERROR",

  //clear additional expenses document link
  CLEAR_ADDITIONAL_EXPENSES_DOCUMENT_LINK = "@@jobsheet/CLEAR_ADDITIONAL_EXPENSES_DOCUMENT_LINK",

  //get job sheet values on dealer and login page

  GET_DEALER_OR_CUSTOMER_FEEDBACK_JOB_SHEET_LIST_LOADING = "@@jobsheet/GET_DEALER_OR_CUSTOMER_FEEDBACK_JOB_SHEET_LIST_LOADING",
  GET_DEALER_OR_CUSTOMER_FEEDBACK_JOB_SHEET_LIST_SUCCESS = "@@jobsheet/GET_DEALER_OR_CUSTOMER_FEEDBACK_JOB_SHEET_LIST_SUCCESS",
  GET_DEALER_OR_CUSTOMER_FEEDBACK_JOB_SHEET_LIST_ERROR = "@@jobsheet/GET_DEALER_OR_CUSTOMER_FEEDBACK_JOB_SHEET_LIST_ERROR",


  //check dealer have feedback pending true or false

  CHECK_DEALER_HAVE_FEEDBACK_PENDING_LOADING = "@@jobsheet/CHECK_DEALER_HAVE_FEEDBACK_PENDING_LOADING",
  CHECK_DEALER_HAVE_FEEDBACK_PENDING_SUCCESS = "@@jobsheet/CHECK_DEALER_HAVE_FEEDBACK_PENDING_SUCCESS",
  CHECK_DEALER_HAVE_FEEDBACK_PENDING_ERROR = "@@jobsheet/CHECK_DEALER_HAVE_FEEDBACK_PENDING_ERROR",



  //get customer details
  
  GET_CUSTOMER_STATUS_IN_JOBSHEET_LOADING = "@@jobsheet/GET_CUSTOMER_STATUS_IN_JOBSHEET_LOADING",
  GET_CUSTOMER_STATUS_IN_JOBSHEET_SUCCESS = "@@jobsheet/GET_CUSTOMER_STATUS_IN_JOBSHEET_SUCCESS",
  GET_CUSTOMER_STATUS_IN_JOBSHEET_ERROR = "@@jobsheet/GET_CUSTOMER_STATUS_IN_JOBSHEET_ERROR",
  
  GET_CUSTOMER_PROFILE_LOADING = "@@jobsheet/GET_CUSTOMER_PROFILE_LOADING",
  GET_CUSTOMER_PROFILE_SUCCESS = "@@jobsheet/GET_CUSTOMER_PROFILE_SUCCESS",
  GET_CUSTOMER_PROFILE_ERROR = "@@jobsheet/GET_CUSTOMER_PROFILE_ERROR",



  //get user current address lat lng

  GET_CURRENT_ADDRESS_LAT_LNG_LOADING = "@@jobsheet/GET_CURRENT_ADDRESS_LAT_LNG_LOADING",
  GET_CURRENT_ADDRESS_LAT_LNG_SUCCESS = "@@jobsheet/GET_CURRENT_ADDRESS_LAT_LNG_SUCCESS",
  GET_CURRENT_ADDRESS_LAT_LNG_ERROR = "@@jobsheet/GET_CURRENT_ADDRESS_LAT_LNG_ERROR",

  GET_JOBSHEET_NUMBER_LOADING = "@@jobsheet/GET_JOBSHEET_NUMBER_LOADING",
  GET_JOBSHEET_NUMBER_SUCCESS = "@@jobsheet/GET_JOBSHEET_NUMBER_SUCCESS",
  GET_JOBSHEET_NUMBER_ERROR = "@@jobsheet/GET_JOBSHEET_NUMBER_ERROR",

  GET_AUDIO_MODEL_LOADING = "@@jobsheet/GET_AUDIO_MODEL_LOADING",
  GET_AUDIO_MODEL_SUCCESS = "@@jobsheet/GET_AUDIO_MODEL_SUCCESS",
  GET_AUDIO_MODEL_ERROR = "@@jobsheet/GET_AUDIO_MODEL_ERROR",


  ///  New Additional expenses updated.

  NEW_ADDITIONAL_EXPENSES_UPDATED_LOADING = "@@jobsheet/NEW_ADDITIONAL_EXPENSES_UPDATED_LOADING",
  NEW_ADDITIONAL_EXPENSES_UPDATED_SUCCESS = "@@jobsheet/NEW_ADDITIONAL_EXPENSES_UPDATED_SUCCESS",
  NEW_ADDITIONAL_EXPENSES_UPDATED_ERROR = "@@jobsheet/NEW_ADDITIONAL_EXPENSES_UPDATED_ERROR",

  GET_ADDITIONAL_EXPENSE_USER_DETAILS_LOADING = "@@jobsheet/GET_ADDITIONAL_EXPENSE_USER_DETAILS_LOADING",
  GET_ADDITIONAL_EXPENSE_USER_DETAILS_SUCCESS = "@@jobsheet/GET_ADDITIONAL_EXPENSE_USER_DETAILS_SUCCESS",
  GET_ADDITIONAL_EXPENSE_USER_DETAILS_ERROR = "@@jobsheet/GET_ADDITIONAL_EXPENSE_USER_DETAILS_ERROR",

  GET_DEFECTIVE_PART_BY_JOB_ID_LOADING = "@@jobsheet/GET_DEFECTIVE_PART_BY_JOB_ID_LOADING",
  GET_DEFECTIVE_PART_BY_JOB_ID_SUCCESS = "@@jobsheet/GET_DEFECTIVE_PART_BY_JOB_ID_SUCCESS",
  GET_DEFECTIVE_PART_BY_JOB_ID_ERROR = "@@jobsheet/GET_DEFECTIVE_PART_BY_JOB_ID_ERROR",

  CHANGE_TECHNICIAN_LOADING = "@@jobsheet/CHANGE_TECHNICIAN_LOADING",
  CHANGE_TECHNICIAN_SUCCESS = "@@jobsheet/CHANGE_TECHNICIAN_SUCCESS",
  CHANGE_TECHNICIAN_ERROR = "@@jobsheet/CHANGE_TECHNICIAN_ERROR",

  UPDATE_NEW_AUDIO_SERIAL_NUMBER_LOADING = "@@jobsheet/UPDATE_NEW_AUDIO_SERIAL_NUMBER_LOADING",
  UPDATE_NEW_AUDIO_SERIAL_NUMBER_SUCCESS = "@@jobsheet/UPDATE_NEW_AUDIO_SERIAL_NUMBER_SUCCESS",
  UPDATE_NEW_AUDIO_SERIAL_NUMBER_ERROR = "@@jobsheet/UPDATE_NEW_AUDIO_SERIAL_NUMBER_ERROR",
}
