import { Formik, Form, ErrorMessage } from "formik";
import React, { useEffect, useRef, useState } from "react";
import { useNavigate, useParams } from "react-router";
import { Card, CardBody, Col, Container, Row } from "reactstrap";
import InputCom from "src/components/Common/Input";
import * as Yup from "yup";
import { ROUTER } from "src/constants/routes";
import Button from "src/components/UI/Button";
import { FieldModal } from "src/models/components/inputWrapperModel";
import { useDispatch, useSelector } from "react-redux";
import { RootReducerState } from "src/store/reducers";
import { useToast } from "src/components/Common/ReactToaster";
import ValidationPopup from "src/components/Common/ValidationMessage/ValidationPopup";
import MultiSelector from "src/components/Common/MultiSelector";
import ErrorText from "src/components/UI/ErrorText";
import { clearWhatsappMessageMessage, createWhatsappMessage, getCustomerNumList, getDealerNumList, getEmployeeNumList, getFranchiseNumList, getSupplierNumList } from "src/store/WhatsApp/action";
import { FileUpload } from "primereact/fileupload";
import { Tag } from "primereact/tag";
import FeatherIcon from "feather-icons-react";
import MarketingBreadcrumb from "../MarketingBreadcrumb";

const SendWhatsAppMessage: React.FC = () => {
  const [uploadFiles, setUploadFiles] = useState<any>([]);
  const [localFormFields, setLocalFormFields] = useState<FieldModal[]>([]);
  const [totalSize, setTotalSize] = useState(0);
  const fileUploadRef = useRef<any>(null);
  const [visible, setVisible] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const { id } = useParams();
  const { showToast } = useToast();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const chooseOptions = { icon: <FeatherIcon className="upload" icon="file" size={15} />, iconOnly: true, className: 'custom-choose-btn ' };
  const cancelOptions = { icon: <FeatherIcon className="cancel" icon="x" />, iconOnly: true, className: 'custom-cancel-btn' };
  const uploadOptions = { icon: <FeatherIcon icon="file" size={15} />, iconOnly: true, className: "uploadOptionNewStyle" };

  const validateMobileNumbers = (array: any) => {
    const mobileNumberRegex = /^\+?\d{10,}$/;
    return allMobileNum.every((mobileNumber: any) => {
      if (!mobileNumber.phone) return true;
      return mobileNumberRegex.test(mobileNumber.phone)
    });
  };

  const validationSchema = Yup.object().shape({
    toUserTypes: Yup.array().of(Yup.string()).min(1, "At least one user type must be selected")
      .required("User type is required"),
    TextContent: Yup.string().required("Chat content is required").max(1024,"Chat content should be less than 1024 characters"),
    allMobileNumber: Yup.array().test('is-valid-mobile-numbers', 'All should be valid mobile numbers', (array) => {
      return validateMobileNumbers(array);
    }),
  });

  const { error, message, loading, employeeNum, dealerNum, customerNum, franchiseNum, supplierNum } = useSelector((state: RootReducerState) => state.WhatsappReducer);
  const initialValue: any = {
    toUserTypes: [],
    allMobileNumber: [],
    TextContent: ""
  };

  useEffect(() => {
    if (error) {
      setVisible(true);
      setIsSubmitting(false);
    }
    if (message) {
      showToast(message, { type: "success" });
      dispatch(clearWhatsappMessageMessage())
      setTimeout(() => {
        setIsSubmitting(false);
        navigate(ROUTER.WHATSAPP_MESSAGE_LIST);
      }, 500);
    }
  }, [error, message]);

  useEffect(() => {
    const preloader: any = document.getElementById("preloader");
    if (loading) {
      preloader.style.display = "block";
    } else {
      preloader.style.display = "none";
    }
  }, [loading]);

  const hidePopup = () => {
    setVisible(false);
    dispatch(clearWhatsappMessageMessage());
  };

  const dialogContainer = (error: any) => {
    return (
      <>
        <ValidationPopup error={error} visible={visible} onHide={hidePopup} />
      </>
    );
  };

  const handleSubmit = async (values: any) => {
    setIsSubmitting(true);
    let num=allMobileNum?.map((numbers:any)=>numbers.phone)
    // let formattedTextContent = values.TextContent.replace(/\n/g, '\\n')
    // formattedTextContent = formattedTextContent.replace(/ {4,}/g, ' ');
    let formattedTextContent = values?.TextContent?.replace(/\r/g, '');
    formattedTextContent = formattedTextContent?.replace(/\n/g, '\\n'); 
    
    formattedTextContent = formattedTextContent?.replace(/ {4,}/g, ' ');
  
    let formatted={
      ...values,
      id:0,
      allMobileNumber:num,
      TextContent:formattedTextContent,
      toUserTypes:values.toUserTypes.length>0 ? values.toUserTypes : null,
      uploadFiles:null,
    }
    
    dispatch(createWhatsappMessage(formatted))
  };

  const handleReset = (resetForm: any) => {
    resetForm(getEmptyInitialValues());
    setTotalSize(0);
    setUploadFiles([])
    setIsSubmitting(false)
    if (fileUploadRef.current) {
      fileUploadRef.current.clear();
    }
  };

  const getEmptyInitialValues = () => {
    const initialValue = localFormFields?.reduce((acc: any, field: any) => {
      if (field.type !== "checkbox") {
        acc[field.name] = "";
      } else {
        acc[field.name] = false;
      }
      return acc;
    }, {});
    return initialValue;
  };

  const toUserTypes = [
    { label: "Employee", value: "Employee" },
    { label: "Dealer", value: "Dealer" },
    { label: "Customer", value: "Customer" },
    { label: "Franchise", value: "Franchise" },
    { label: "Supplier", value: "Supplier" },
    { label: "Others", value: "Others" },
  ];

  useEffect(() => {
    dispatch(getEmployeeNumList())
    dispatch(getDealerNumList())
    dispatch(getCustomerNumList())
    dispatch(getFranchiseNumList())
    dispatch(getSupplierNumList())
    return () => {
      dispatch(clearWhatsappMessageMessage())
    }
  }, [])
  const [allMobileNum, setAllMobilenum] = useState<any>([]);
  const handleSelect = (selectedOptions: any, setFieldValue: any) => {
    setFieldValue('toUserTypes', selectedOptions)
    const updatedPhoneNum = [];
    if (selectedOptions.includes("Employee")) {
      updatedPhoneNum.push(...employeeNum);
    }
    if (selectedOptions.includes("Dealer")) {
      updatedPhoneNum.push(...dealerNum);
    }
    if (selectedOptions.includes("Customer")) {
      updatedPhoneNum.push(...customerNum);
    }
    if (selectedOptions.includes("Franchise")) {
      updatedPhoneNum.push(...franchiseNum);
    }
    if (selectedOptions.includes("Supplier")) {
      updatedPhoneNum.push(...supplierNum);
    }
    setFieldValue("allMobileNumber", updatedPhoneNum?.filter((item:any)=>item.phone));
    setAllMobilenum(updatedPhoneNum)
  };


  const onTemplateSelect = (e: any) => {
    let _totalSize = totalSize;
    let files = e.files;
    setUploadFiles(files)
    Object.keys(files).forEach((key) => {
      _totalSize += files[key].size || 0;
    });
    setTotalSize(_totalSize);
  };

  const onTemplateClear = () => {
    setTotalSize(0);
    setUploadFiles([])
  };

  const headerTemplate = (options: any) => {
    const { className, chooseButton, uploadButton, cancelButton } = options;
    const value = totalSize / 10000;
    const formatedValue = fileUploadRef && fileUploadRef.current ? fileUploadRef.current.formatSize(totalSize) : '0 B';
    return (
      <div className={className} style={{ backgroundColor: 'transparent', display: 'flex', alignItems: 'center', justifyContent: "space-between", padding: "5px" }}>
        <div>
          {chooseButton}
          {uploadButton}
          {cancelButton}
        </div>
        {/* <div className="">
          <span>{formatedValue} / 1 MB</span>
          <ProgressBar value={value} showValue={false} style={{ width: '10rem', height: '12px' }}></ProgressBar>
        </div> */}
      </div>
    );
  };

  const onTemplateRemove = (file: any, callback: any) => {
    debugger
    setTotalSize(totalSize - file.size);
    setUploadFiles((prevFiles : any) => prevFiles.filter((f : any) => f.name !== file.name));
    callback();
  };

  const itemTemplate = (file: any, props: any) => {
    return (
      <div className="new_upload_design" id="new_upload_design">
        <div className="uploaded_files_preview">
          <div style={{
            width: "60px", height: "60px", display: "flex",
            justifyContent: "center",
            alignItems: "center"
          }}>
            <img alt={file.name} role="presentation" src={file.objectURL} width={"100%"} />
          </div>
          <span className="flex flex-column text-left ml-3">
            {file.name}
            <small>{new Date().toLocaleDateString()}</small>
          </span>
        </div>
        <Tag value={props.formatSize} severity="warning" style={{ padding: "0.2rem", width: "fitContent" }} />
        <div style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
          <FeatherIcon icon="x" onClick={() => onTemplateRemove(file, props.onRemove)} />
        </div>
      </div>
    );
  };

  const emptyTemplate = () => {
    return (
      <div className="flex align-items-center flex-column" style={{ display: "flex" }}>
        <i className="fas fa-file mt-3" style={{ fontSize: '25px', borderRadius: '50%', backgroundColor: 'var(--surface-b)', color: 'var(--surface-d)' }}></i>
        <span style={{ color: 'var(--text-color-secondary)', marginTop: "5px" }}>
          Drag and Drop Image Here
        </span>
      </div>
    );
  };

  const[text,setText]=useState<any>("")
  const [filter, setFilter] = useState('');
  const handleRemoveParts = (id: number) => {
    const RemoveParts = allMobileNum.filter(
      (item: any, index: number) => index !== id
    );
    setAllMobilenum(RemoveParts);
  };
  const handleMobileAdd = (event: any) => {
    if (event.key == "Enter" || event.key=="Tab" ) {
    event.preventDefault()
      setAllMobilenum((prevState: any) => [...prevState, {name:"",phone:event.target.value.trim()}]);
      setText("")
    }
  };
  return (
    <React.Fragment>
      {visible && dialogContainer(error)}

      <div className="page-content" id="view-brand">
        <Container fluid>
          <MarketingBreadcrumb
            title="WhatsApp Message List"
            titlePath={ROUTER.WHATSAPP_MESSAGE_LIST}
            breadcrumbItem={id ? `Edit Chat` : `New Chat`}
            filter={filter}
            setFilter={setFilter}
          />
          <Row id="email">
            <Card>
              <CardBody>
                <React.Fragment>
                  <Formik
                    onSubmit={handleSubmit}
                    initialValues={initialValue}
                    validationSchema={validationSchema}
                    enableReinitialize
                  >
                    {({
                      values,
                      handleChange,
                      touched,
                      errors,
                      handleBlur,
                      resetForm,
                      reset,
                      setFieldValue,
                      ...rest
                    }: any) => {
                      return (
                        <Form onChange={handleChange}>
                          <Row>
                            <Col lg={3} className="mb-2">
                              <MultiSelector
                                name="toUserTypes"
                                label="User Types"
                                options={toUserTypes}
                                optionLabel="label"
                                id="toUserTypes"
                                labelClassName="form-Label"
                                onBlur={handleBlur("toUserTypes")}
                                value={values.toUserTypes}
                                onChange={(e: any) => { handleSelect(e.target.value, setFieldValue) }}
                                isRequired={true}
                                invalid={
                                  touched["toUserTypes"] && errors["toUserTypes"]
                                    ? true
                                    : false
                                }
                              />
                            </Col>
                            <Row>
                              <Col lg={6} className="mt-2">
                                <InputCom
                                  labelName="Chat Content"
                                  inputclassName="form-control"
                                  labelClassName="form-Label"
                                  type="textarea"
                                  name="TextContent"
                                  onBlur={handleBlur}
                                  value={values.TextContent}
                                  onChange={handleChange}
                                  isRequired={true}
                                  invalid={
                                    touched["TextContent"] && errors["TextContent"]
                                      ? true
                                      : false
                                  }
                                  rowHeight={7}
                                />
                              </Col>
                              {/* <Col lg={6} className="mt-2">
                                <FileUpload ref={fileUploadRef} name="demo[]" url="/api/upload" multiple accept="image/*" maxFileSize={1000000} onSelect={onTemplateSelect} onError={onTemplateClear} onClear={onTemplateClear} headerTemplate={headerTemplate} itemTemplate={itemTemplate} emptyTemplate={emptyTemplate} chooseOptions={chooseOptions} cancelOptions={cancelOptions} uploadOptions={uploadOptions} className="prime_react_file_upload" />
                              </Col> */}
                            </Row>
                            
                            {values.toUserTypes.length>0 &&
                              <Col lg={12} className="mt-2">
                                <label htmlFor="allMobileNumber">To<span className="text-danger ml-05">*</span></label>
                                <div className="p-chips p-component p-inputwrapper p-inputwrapper-filled custom-chip">
                                  <ul className="p-inputtext p-chips-multiple-container p-variant-filled" style={{ display: 'flex', flexWrap: 'wrap' }}>
                                    {allMobileNum.map(
                                      
                                      (item: any, index: any) => {
                                        const isMatch = filter && (
                                          item.name?.toLowerCase().includes(filter.toLowerCase()) ||
                                          item.phone.toLowerCase().includes(filter.toLowerCase())
                                      );
                                        return (
                                        <li key={index}className={isMatch ? "p-chips-token highlight-chip" : "p-chips-token"}>
                                          <span className="p-chips-token-label">{item.name ? `${item.name} - ${item.phone}` : item.phone}</span>
                                          <FeatherIcon icon="x-circle" className="p-icon p-chips-token-icon" onClick={() => handleRemoveParts(index) }/>
                                        </li>
                                      )}
                                    )}
                                    <li className="p-chips-token"  style={{listStyle:"none",background:"none",flex:"1 0 auto"}} id="email-input">
                                      <input
                                        type="text"
                                        style={{backgroundColor:"rgb(235 235 235)",width:"100%",outline:"none",border:"none"}}
                                        onKeyDown={(e) => handleMobileAdd(e)}
                                        value={text}
                                        onChange={(e:any)=>setText(e.target.value)}
                                      />
                                    </li>
                                  </ul>
                                </div>
                                <p style={{fontSize:"12px",marginTop:"-10px"}}>Please press the Enter key after entering the mobile number.</p>
                                {allMobileNum.length>0 && 
                                <ErrorMessage
                                  name={"allMobileNumber" || ""}
                                  component={ErrorText}
                                />
                    }
                                { (isSubmitting && allMobileNum.length<1) ? <div className="text-danger">Mobile Number is required</div>:<></>}
                              </Col>
                            }
                            <div className="d-flex justify-content-end mt-2">
                              <Button
                                className="secondary-btn me-2"
                                label={"Reset"}
                                type="button"
                                onClick={() => handleReset(resetForm)}
                              />
                              <Button
                                className="btn-primary me-2 btn-primary-shadow"
                                label={"Send Chat"}
                                type="submit"
                                onClick={()=>setIsSubmitting(true)}
                              />
                            </div>
                          </Row>
                        </Form>
                      );
                    }}
                  </Formik>
                </React.Fragment>
              </CardBody>
            </Card>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default SendWhatsAppMessage;
