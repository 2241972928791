import { call, put, takeLatest } from "redux-saga/effects";
import { downloadOutgoingDispatchError, downloadOutgoingDispatchSuccess, getDeliveriedBYIDError, getDeliveriedBYIDSuccess, getOutgoingDispatchListError, getOutgoingDispatchListSuccess, sentMailToOutgoingDispatchError, sentMailToOutgoingDispatchSuccess, sentWhatsappToOutgoingDispatchError, sentWhatsappToOutgoingDispatchSuccess, viewOutgoingDispatchError, viewOutgoingDispatchSuccess } from "./action";

import { OutGoing_dispatch_Action } from "./actionTypes";
import { API } from "src/util/api";

// worker function

//get outgoing dispatch list Api

function* fetchOutgoingDispatchAsync(action: any) {
  try {
    const response: Promise<any> = yield call(API.fetchOutgoingDispatchApi, { ...action.lazyState });
    yield put(getOutgoingDispatchListSuccess(response));
  } catch (error) {
    yield put(getOutgoingDispatchListError(error));
  }
}

//view api

function* viewOutgoingDispatchAsync(action : any){
  
  try {
    const response: Promise<any> = yield call(API.getOutgoingDispatchById,action.payload);
    yield put(viewOutgoingDispatchSuccess(response));
  } catch (error) {
    yield put(viewOutgoingDispatchError(error))
  }
}


//saga watcher function 
function* sentMailToOutgoingDispatchAsync(action : any) {
    try {
      const response : Promise<any> = yield call(API.sentEmailToOutgoingDispatch,action.payload )
      yield put(sentMailToOutgoingDispatchSuccess(response));
    } catch (error) {
      yield put(sentMailToOutgoingDispatchError(error));
    }
  }

function* sentWhatsappToOutgoingDispatchAsync(action : any) {
    try {
      const response : Promise<any> = yield call(API.sentWhatsappToOutgoingDispatch,action.payload )
      yield put(sentWhatsappToOutgoingDispatchSuccess(response));
    } catch (error) {
      yield put(sentWhatsappToOutgoingDispatchError(error));
    }
  }


function* downloadOutgoingDispatchAsync(action : any) {
    try {
      const response : Promise<any> = yield call(API.downloadOutgoingDispatch,action.payload )
      yield put(downloadOutgoingDispatchSuccess(response));
    } catch (error) {
      yield put(downloadOutgoingDispatchError(error));
    }
  }

  
function* getDeliveriedByIdAsync(action: any) {
  try {
    const response: Promise<any> = yield call(API.getPrepareyIdData, action.id)    
    yield put(getDeliveriedBYIDSuccess(response));
  } catch (error) {
    yield put(getDeliveriedBYIDError(error));
  }
}

function* outGoingDispatchSaga() {
  yield takeLatest(OutGoing_dispatch_Action.GET_OUT_GOING_DISPATCH_LIST_LOADING, fetchOutgoingDispatchAsync);
  yield takeLatest(OutGoing_dispatch_Action.VIEW_OUT_GOING_DISPATCH_LOADING ,viewOutgoingDispatchAsync)
  yield takeLatest(OutGoing_dispatch_Action.SENT_MAIL_TO_OUTGOING_DISPATCH ,sentMailToOutgoingDispatchAsync)
  yield takeLatest(OutGoing_dispatch_Action.SENT_WHATSAPP_OUTGOING_DISPATCH ,sentWhatsappToOutgoingDispatchAsync)
  yield takeLatest(OutGoing_dispatch_Action.DOWNLOAD_OUTGOING_DISPATCH ,downloadOutgoingDispatchAsync)
  yield takeLatest(OutGoing_dispatch_Action.GET_DELIVERIED_BY_ID_LOADING ,getDeliveriedByIdAsync)

}

export default outGoingDispatchSaga;