import React, { useEffect, useRef, useState } from "react";
import { Row, Col, Button, UncontrolledTooltip } from "reactstrap";
import { Form, Formik } from "formik";
import * as Yup from "yup";
import { Option } from "src/models/components/inputWrapperModel";
import "./DealerList.scss";
import {
  Columns,
  LazyTableState,
} from "src/models/components/dataTableWrapperModel";
import DataTableWrapper from "src/components/Grid/DataTableWrapper";
import { DataTable, DataTableFilterMeta } from "primereact/datatable";
import { useDispatch, useSelector } from "react-redux";
import ColumnAction from "src/components/Common/ColumnAction/ColumnAction";
import { useNavigate } from "react-router-dom";
import DeleteModal from "../Calendar/DeleteModal";
import ArchiveModal from "../Calendar/DeleteModal";
import { useToast } from "src/components/Common/ReactToaster";
import Select from "src/components/Common/Select";
import StatusHghlighter from "src/components/Common/StatusHighlighter/StatusHighlighter";
import { DealerDetails } from "src/models/pages/dealerModel";
import { ClearDealerImage, clearDealerData, clearDealerMessage, deleteDealerFromList, getDealerList } from "src/store/dealer/actions";
import { CurrencyFormatter, saveAsExcelFile } from "src/helpers/common_helpers";
import { ROUTER } from "src/constants/routes";
import { MultiSelectChangeEvent } from "primereact/multiselect";
import { Dropdown } from "primereact/dropdown";
import CustomizeTable from "src/components/Common/CustomizeTable/CustomizeTable";
import FeatherIcon from "feather-icons-react";
import { AnimatePresence, motion } from "framer-motion";
import { BUTTON_VARIANT } from "src/constants/bulkActionVariant";
import EmailBodyTemplate from "src/components/Common/EmailBodyTemplate/EmailBodyTemplate";
import ExportFile from "src/components/Common/ExcelPdf Export/ExportFile";
import BulkActionStatus from "src/components/Common/BulkActionStatus/BulkActionStatus";
import FilterIcon from "src/components/Common/CustomFilterandSortIcon/FilterIcon";
import SortIcon from "src/components/Common/CustomFilterandSortIcon/SortIcon";
import TooltipMEL from "src/components/UI/Tooltip";
import ValidationPopup from "src/components/Common/ValidationMessage/ValidationPopup";
import { API } from "src/util/api";
import { ALL } from "src/constants/commonConstants";
import { customizeTableColumns } from "src/models/components/CustomizeTableColumns";

const dealerListConverson = (values: any[]) => {
  const modifeidValue = values?.map((eachValue) => {
    let data: any = {};
    data['id'] = eachValue?.id;
    data['Name'] = eachValue?.user?.name;
    data['Email'] = eachValue?.user?.email;
    data['PhoneNumber'] = eachValue?.user?.phoneNumber;
    data['alternateNumber'] = eachValue?.user?.extraProperties?.AlternateNumber;
    data['dealerCode'] = eachValue?.dealerCode;
    data['gstNumber'] = eachValue?.user?.extraProperties?.GstNumber;
    data['permitFormRequired'] = eachValue?.permitFormRequired;
    data['address'] = eachValue?.user?.extraProperties?.Address;
    data['phoneNumberConfirmed']=eachValue?.user?.phoneNumberConfirmed;
    data['creditAmount'] = eachValue?.creditAmount;
    data['creditDays'] = eachValue?.creditDays;
    data['paymentType'] = eachValue?.paymentType;
    data['sendSms'] = eachValue?.user?.extraProperties?.SendSms;
    data['sendEmail'] = eachValue?.user?.extraProperties?.SendEmail;
    data['sendWhatsapp'] = eachValue?.user?.extraProperties?.SendWhatsapp;
    data['feedbackRequired'] = eachValue?.feedbackRequired;
    data['isActive'] = eachValue?.user?.isActive;
    data['isDeleted'] = eachValue?.isDeleted;
    return data;
  });
  return modifeidValue;
};

const ACTIVE = "Active";
const DELETED = "Deleted";
const EMAIL_VERIFIED = "Email verified";

export default function Dealers() {
  const navigate = useNavigate();
  const [filter, setFilter] = useState(false);
  const [isTooltipVisible, setIsTooltipVisible] = useState<boolean>(false);
  const { values, totalRecords } = useSelector((state: any) => state.dealer.dealerList);
  const { error, message, dealerTaxList, paginatorCount } = useSelector(
    (state: any) => state.dealer
  );
  const modifiedValues: DealerDetails[] = dealerListConverson(values);

  const { showToast } = useToast();
  const loading = useSelector((state: any) => state.dealer.loading);
  const[pdfLoading,setPdfLoading]=useState<boolean>(false)
  const dispatch = useDispatch();
  const dt = useRef<DataTable<any>>(null);
  // for delete invoice
  const [deleteModal, setDeleteModal] = useState(false);
  const [archiveModal, setArchiveModal] = useState(false);
  const [rowData, setRowData] = useState<DealerDetails | null>(null);
  const filterDetails: DataTableFilterMeta = {
    Name: { value: "", matchMode: "contains" },
    PhoneNumber: { value: "", matchMode: "contains" },
    Email: { value: "", matchMode: "contains" },
    Locked: { value: null, matchMode: "contains" },
    IsDeleted: { value: "false", matchMode: "contains" },
  };
  let initialLazyState: LazyTableState = {
    first: 0,
    rows: 10,
    page: 1,
    sortField: "CreationTime",
    sortOrder: -1,
    // sortField: "Name",
    // sortOrder: 1,
    filters: filterDetails,
  };
  const [lazyState, setLazyState] = useState(initialLazyState);

  const initialValues = {
    status: "",
  };

  const onFilterClickHandler = () => {
    setFilter((preState) => !preState);
  };

  const onCreateClickHandler = () => {
    navigate(ROUTER.DEALERS_CREATE);
  };

  const viewHandler = (rowData: DealerDetails) => {
    navigate(`${ROUTER.DEALERS_VIEW}/${rowData["id"]}`);
  };

  const editHandler = (rowData: DealerDetails) => {
    navigate(`${ROUTER.DEALERS_EDIT}/${rowData["id"]}`);
  };
  const deleteHandler = (rowData: DealerDetails) => {
    setDeleteModal(true);
    setRowData(rowData);
  };

  const addDealerSubUserClick = (rowData: DealerDetails) => {
    navigate(`${ROUTER.DEALER_SUB_USER_CREATE_ID}/${rowData["id"]}` , { state: rowData });
  }

useEffect(()=>{
dispatch(ClearDealerImage())
},[])
  const [visible, setVisible] = useState(false);
  useEffect(() => {
    if (error) {
      setVisible(true);
      // showToast(error, { type: "error" })
    }
    if (message) {
      showToast(message, { type: "success" });
      dispatch(clearDealerMessage());
    }
  }, [error, message]);

  const emailBodyTemplate = (rowData: DealerDetails, field: string) => {
    let data = rowData[field] as string;
    return (
      <EmailBodyTemplate data={data} />
    );
  };

  const hidePopup = () => { 
    setVisible(false); 
    dispatch(clearDealerMessage()); 
  }


  const dialogContainer = (error: any) => {
    return (
      
      <>
      <ValidationPopup error={error} visible={visible} onHide={hidePopup} />
        {/* <Dialog header="Validation Error" visible={visible} maximizable className="vw30" onHide={() => { setVisible(false); dispatch(clearDealerMessage()); }}>
          <div className="m-0 validation-text">
            {error && error.split(".,").map((eachLine: string, index: number) => {
              return (<div key={index}><span className="validation-error-index">{index + 1}</span><span className="validation-error-line">{eachLine}</span></div>);
            })}
          </div>
        </Dialog> */}
      </>
    );
  };

  const actionBodyTemplate = (rowData: DealerDetails, value: string) => {
    return (
      <>
        {
          rowData.isDeleted == false ? (
            <ColumnAction
              displayData={value as string}
              title={true}
              titleData={`${rowData.Name} - ${rowData.address}`}
              isEdit={true}
              isDelete={true}
              isView={true}
              dealerSubUser={true}
              onViewClick={() => viewHandler(rowData)}
              onEditClick={() => editHandler(rowData)}
              onDeleteClick={() => deleteHandler(rowData)}
              onDealersubuserClick={() => addDealerSubUserClick(rowData)}
            />
          ) : (
            <ColumnAction
              displayData={value as string}
              title={true}
              titleData={`${rowData.Name} - ${rowData.address}`}
              undo={rowData.isDeleted ? true : false}
              onDeleteClick={() => deleteHandler(rowData)}
            />
          )
        }
      </>
    );
  };

  const statusBodyTemplate = (rowData: DealerDetails, field: string) => {
    let status = rowData[field];
    return (
      <StatusHghlighter status={status} />
    );

  };

  const phoneVerifiedTemplate =(rowData:any) => {
    return(
      <div className="d-flex gap-1 align-items-center">
      <b>{rowData.PhoneNumber}</b>
      {rowData.phoneNumberConfirmed ? <FeatherIcon icon="check-circle" className={`icon-lg right-icon`} style={{ color:"green",width:"20px"}}/>:null}
      </div>
    )
  }

  const currencyBodyTemplate = (rowData: DealerDetails, field: string) => {
    let amount = rowData[field];
    return (<>{CurrencyFormatter({ value: amount })}</>);
  };

  const options: Option[] = [
    { label: "All", value: "All" },
    { label: "Active", value: "Active" },
    { label: "Deleted", value: "Deleted" },
    { label: "Email verified", value: "Email verified" },
  ];

  const columns: Columns[] = [
    {
      field: "select",
      sortable: false,
      header: "",
      filter: false,
      showFilterMenu: false,
      frozen : true,
      selectionMode: "multiple",
      headerStyle: { width: "3rem", whiteSpace: "nowrap" },
      isDefault: true,
      isStatic: false,
    },
    {
      field: "Name",
      sortable: true,
      header: "Name",
      filter: true,
      showFilterMenu: true,
      frozen : true,
      headerStyle: { whiteSpace: "nowrap", width: "250px" },
      body: (rowData) => actionBodyTemplate(rowData, rowData.Name),
      isDefault: true,
      isStatic: true,
    },
    {
      field: "Email",
      sortable: true,
      header: "Email",
      filter: true,
      showFilterMenu: true,
      headerStyle: { whiteSpace: "nowrap" },
      body: (rowData) => emailBodyTemplate(rowData, "Email"),
      isDefault: true,
      isStatic: false,
    },
    {
      field: "PhoneNumber",
      sortable: true,
      header: "Mobile",
      filter: true,
      showFilterMenu: true,
      headerStyle: { whiteSpace: "nowrap" },
      body:(rowData:any)=>phoneVerifiedTemplate(rowData),
      isDefault: true,
      isStatic: false,
    },
    {
      field: "alternateNumber",
      sortable: false,
      header: "Alternate Mobile Number",
      filter: false,
      showFilterMenu: false,
      headerStyle: { whiteSpace: "nowrap" },
      isDefault: false,
      isStatic: false,
    },

    {
      field: "dealerCode",
      sortable: false,
      header: "Dealer Code",
      filter: false,
      showFilterMenu: false,
      headerStyle: { whiteSpace: "nowrap" },
      isDefault: false,
      isStatic: false,
    },
    {
      field: "gstNumber",
      sortable: false,
      header: "Gst Number",
      filter: false,
      showFilterMenu: false,
      headerStyle: { whiteSpace: "nowrap" },
      isDefault: true,
      isStatic: false,
    },
    {
      field: "permitFormRequired",
      sortable: false,
      header: "PFR",
      filter: false,
      showFilterMenu: false,
      body: (rowData) => statusBodyTemplate(rowData, 'permitFormRequired'),
      headerStyle: { whiteSpace: "nowrap" },
      isDefault: true,
      isStatic: false,
    },
    {
      field: "address",
      sortable: false,
      header: "Address",
      filter: false,
      showFilterMenu: false,
      className: "addressstyle",
      headerStyle: { maxWidth: "250px", textOverflow: "ellipsis", overflowX: "hidden" },
      isDefault: false,
      isStatic: false,
    },
    {
      field: "creditAmount",
      sortable: false,
      header: "Credit Amount",
      filter: false,
      showFilterMenu: false,
      body: (rowData) => currencyBodyTemplate(rowData, 'creditAmount'),
      headerStyle: { whiteSpace: "nowrap" },
      isDefault: true,
      isStatic: false,
      className: "align-right"
    },
    {
      field: "creditDays",
      sortable: false,
      header: "Credit Days",
      filter: false,
      showFilterMenu: false,
      headerStyle: { whiteSpace: "nowrap" },
      isDefault: false,
      isStatic: false,
    },
    {
      field: "paymentType",
      sortable: false,
      header: "Payment Type",
      filter: false,
      showFilterMenu: false,
      headerStyle: { whiteSpace: "nowrap" },
      isDefault: false,
      isStatic: false,
    },
    {
      field: "isActive",
      sortable: false,
      header: "Is Active",
      filter: false,
      showFilterMenu: false,
      body: (rowData) => statusBodyTemplate(rowData, 'isActive'),
      headerStyle: { whiteSpace: "nowrap" },
      isDefault: true,
      isStatic: false,
    },
    {
      field: "sendSms",
      sortable: false,
      header: "Send Sms",
      filter: false,
      showFilterMenu: false,
      body: (rowData) => statusBodyTemplate(rowData, 'sendSms'),
      headerStyle: { whiteSpace: "nowrap" },
      isDefault: true,
      isStatic: false,
    },
    {
      field: "sendEmail",
      sortable: false,
      header: "Send Email",
      filter: false,
      showFilterMenu: false,
      body: (rowData) => statusBodyTemplate(rowData, 'sendEmail'),
      headerStyle: { whiteSpace: "nowrap" },
      isDefault: true,
      isStatic: false,
    },
    {
      field: "sendWhatsapp",
      sortable: false,
      header: "Send Whatsapp",
      filter: false,
      showFilterMenu: false,
      body: (rowData) => statusBodyTemplate(rowData, 'sendWhatsapp'),
      headerStyle: { whiteSpace: "nowrap" },
      isDefault: true,
      isStatic: false,
    },
    // {
    //   field: "vehicleBrands",
    //   sortable: false,
    //   header: "Vehicle Brands",
    //   filter: true,
    //   showFilterMenu: true,
    //   headerStyle: { whiteSpace: "nowrap" },
    //   isDefault:true,
    //   isStatic: false,
    // },
    // {
    //   field: "taxType",
    //   sortable: false,
    //   header: "Tax Type",
    //   filter: false,
    //   showFilterMenu: false,
    //   headerStyle: { whiteSpace: "nowrap" },
    //   isDefault:false,
    //   isStatic: false,
    // },
    // {
    //   field: "creditBlocked",
    //   sortable: false,
    //   header: "Credit Blocked",
    //   filter: false,
    //   showFilterMenu: false,
    //   body: (rowData) => statusBodyTemplate(rowData, 'creditBlocked'),
    //   headerStyle: { whiteSpace: "nowrap" },
    //   isDefault:true,
    //   isStatic: false,
    // },
    {
      field: "feedbackRequired",
      sortable: false,
      header: "Feedback",
      filter: false,
      showFilterMenu: false,
      body: (rowData) => statusBodyTemplate(rowData, 'feedbackRequired'),
      headerStyle: { whiteSpace: "nowrap" },
      isDefault: true,
      isStatic: false,
    },

    // {
    //   field: "action",
    //   sortable: false,
    //   header: "Action",
    //   filter: false,
    //   showFilterMenu: false,
    //   body: actionBodyTemplate,
    //   headerStyle: { whiteSpace: "nowrap" },
    // },
  ];

  const [visibleColumns, setVisibleColumns] = useState(columns.filter((eachColumn: Columns) => eachColumn.isDefault));

  const changeColumnDetails = (values: any[]) => {
    let modifiedColumnDetails = values?.map((eachDealer) => {
      const { id: Id, Name, Email, PhoneNumber: Mobile, gstNumber, permitFormRequired, creditAmount, sendEmail,sendWhatsapp, sendSms, address: Address,isActive,phoneNumberConfirmed,alternateNumber } = eachDealer;
      let data: any = {};
      data['Alternate Number'] = alternateNumber;
      data['GST number'] = gstNumber;
      data['Permit form required'] = permitFormRequired;
      data['Credit amount'] = creditAmount;
      data['Send SMS'] = sendSms;
      data['Send Email'] = sendEmail;
      data['Send Whatsapp'] = sendWhatsapp;
      data['Mobile Number Verified']=phoneNumberConfirmed;
      data['Is Active'] = isActive;
      return { ...{ Name, Email, Mobile, Address }, ...data };
    });
    return modifiedColumnDetails;
  };

  const saveDataAsExcel = (values: any[], fileName: string) => {
    const modifiedColumnDetails = changeColumnDetails(values)
    import('xlsx').then((xlsx) => {
      const worksheet = xlsx.utils.json_to_sheet(modifiedColumnDetails);
      const workbook = { Sheets: { data: worksheet }, SheetNames: ['data'] };
      const excelBuffer = xlsx.write(workbook, {
        bookType: 'xlsx',
        type: 'array'
      });
      saveAsExcelFile(excelBuffer, fileName);
    });
  };
  const saveDataAsPdf = (values: any[], columns: any[], fileName: string) => {
    const destructureedData = values?.map((data) => {
      let datas = {
        id: data.id,
        Name: data.Name,
        Email: data.Email,
        PhoneNumber: data?.PhoneNumber,
        alternateNumber: data?.alternateNumber,
        dealerCode: data?.dealerCode,
        gstNumber: data?.gstNumber,
        permitFormRequired: data?.permitFormRequired,
        phoneNumberConfirmed:data?.phoneNumberConfirmed,
        creditAmount: data?.creditAmount,
        paymentType: data?.paymentType,
        sendEmail: data?.sendEmail,
        feedbackRequired: data?.feedbackRequired,
        sendSms: data?.sendSms,
        sendWhatsapp: data?.sendWhatsapp,
        isActive : data?.isActive,
        address: data?.address ? data.address.replace(/\n/g, '<br>') : '',
      };
      return datas;
    });
    import("jspdf").then((jsPDF) => {

      import("jspdf-autotable").then(() => {
        const exportColumns = columns?.map((col) => ({
          title: col.header,
          dataKey: col.field,
          styles: {
            cellPadding: 2,
            halign: 'center',
          },
          columnStyles: {
            address: { cellWidth: "40px" },
          },
        }));
        let columnsWithVerified=[...exportColumns,{
          title:"Mobile Number Verified",
          dataKey:"phoneNumberConfirmed",
          styles: {
            cellPadding: 2,
            halign: 'center',
          },
          columnStyles: {
            address: { cellWidth: "40px" },
          },

        }]
        const doc = new (jsPDF as any).default({
          orientation: 'landscape',
          format: 'a3',
        });

        doc.autoTable(columnsWithVerified, destructureedData);
        doc.save(`${fileName}.pdf`);
      });
    });
  };
  const fetchData = async (isPdf:boolean) => {
    let state:any= {
      ...lazyState,
      rows:totalRecords>5000 ? 5000 : totalRecords<1 ? 1 : totalRecords,
      first:0,
      filters: {
        ...lazyState.filters,
      },
    };
    
    try {
      setPdfLoading(true)
      const response = await API.getDealersListAPI(state);
      if (!response) {
        throw new Error('Network response was not ok');
      }
      let resp=await dealerListConverson(response?.data?.items)
     if(isPdf){

       saveDataAsPdf(resp, columns, "dealers");
     }else{
      saveDataAsExcel(resp, "dealers");
     }
     setPdfLoading(false)
    } catch (error:any) {
      // console.log(error);
      showToast(error?.message,{type : "error"})
      setPdfLoading(false)
      
    } finally {
      setPdfLoading(false)
    }
  };
  const csvClickHandler = () => {
    // saveDataAsExcel(modifiedValues, "dealers");
    fetchData(false)
  };

  const pdfClickHandler = () => {
    // saveDataAsPdf(modifiedValues, columns, "dealers");
    fetchData(true)
  };

  const validationSchema = Yup.object({});

  const callbackFunction = (lazyState: LazyTableState) => {
    dispatch(getDealerList(lazyState));
  };

  const [selectAll, setSelectAll] = useState(false);
  const [selectedDealers, setSelectedDealers] = useState<DealerDetails[]>([]);

  const onSelectionChange = (event: any) => {
    const value = event.value;
    setSelectedDealers(value);
    // setSelectAll(value.length === totalRecords);
    setSelectAll(value.length === paginatorCount);
  };

  const onSelectAllChange = (event: any) => {
    const selectAll = event.checked;

    if (selectAll) {
      setSelectAll(true);
      setSelectedDealers(modifiedValues);
    } else {
      setSelectAll(false);
      setSelectedDealers([]);
    }
  };

  const handleStatusBlur = () => { };

  const onPage = (event: any) => {
    setLazyState(event);
  };

  const onSort = (event: any) => {
    setSelectAll(false);
    setSelectedDealers([]);
    setLazyState(event);
  };
  const onFilter = (event: any) => {
    event["first"] = 0;
    setLazyState(event);
  };

  const handleSubmit = (formValue: any) => {
    setselectedStatus(formValue);
    if (formValue == ACTIVE) {
      setLazyState((state) => ({
        ...state,
        filters: {
          ...state.filters,
          IsDeleted: { value: "false", matchMode: "contains" },
          Locked: { value: null, matchMode: "contains" },
        },
      }));
    }else if (formValue == ALL) {
      setLazyState((state) => ({
        ...state,
        filters: {
          ...state.filters,
          IsDeleted: { value: null, matchMode: "contains" },
          Locked: { value: null, matchMode: "contains" },
        },
      }));
    }
    else if (formValue == DELETED) {
      setLazyState((state) => ({
        ...state,
        filters: {
          ...state.filters,
          IsDeleted: { value: "true", matchMode: "contains" },
          IsVerfied: { value: null, matchMode: "contains" },
        },
      }));
    } else if (formValue == EMAIL_VERIFIED) {
      setLazyState((state) => ({
        ...state,
        filters: {
          ...state.filters,
          IsDeleted: { value: null, matchMode: "contains" },
          IsVerfied: { value: "true", matchMode: "contains" },
        },
      }));
    } else {
      setLazyState((state) => ({
        ...state,
        filters: {
          ...state.filters,
          IsDeleted: { value: null, matchMode: "contains" },
          IsVerfied: { value: null, matchMode: "contains" },
        },
      }));
    }
    setIsTooltipVisible(false)
    setVisibleColumns(visibleColumns)
  };

  const handleReset = (formValue: any) => {
    setLazyState((state) => ({
      ...state,
      filters: {
        ...state.filters,
        status: {
          ...state.filters.status,
          value: "",
        }
      },
    }));
  };

  useEffect(() => {
    setSelectAll(false);
    setSelectedDealers([]);
    callbackFunction(lazyState);

    return () => {
      dispatch(clearDealerData());
    };
  }, [lazyState]);

  const handleDeleteUsers = (rowData: DealerDetails, length: number = 0, index: number = 0) => {
    let payload = {
      id: rowData["id"],
      undo: rowData['isDeleted'] ?? false,
      lazyState,
    };
    dispatch(deleteDealerFromList(payload));

    setDeleteModal(false);
    setRowData(null);

    if (index + 1 === length || index === length) {
      setSelectAll(false);
      setSelectedDealers([]);
      setTimeout(() => {
        dispatch(getDealerList(lazyState));
      }, 500);
    }

  };

  const archiveHandler = () => {
    setSelectAll(false);
    setSelectedDealers([]);
    setArchiveModal(false);
  };


  const defaultColumns = {
    ...columns[0]
  };

  const onColumnToggle = (event: MultiSelectChangeEvent) => {
    let selectedColumns = event.value;
    let orderedSelectedColumns = columns.filter((col) => selectedColumns.some((sCol: any) => sCol.field === col.field));

    setVisibleColumns([{ ...defaultColumns }, ...orderedSelectedColumns]);
  };

  // const header = <MultiSelect value={visibleColumns.map((eachColumns: Columns) => {
  //   let data: any = {};
  //   data['field'] = eachColumns['field'];
  //   data['header'] = eachColumns['header'];
  //   return data;
  // }

  // ).filter((eachColumn:Columns)=>{
  //   return eachColumn['field'] != 'select';
  // })}
  //   options={columns.map((eachColumns: Columns) => {
  //     let data: any = {};
  //     data['field'] = eachColumns['field'];
  //     data['header'] = eachColumns['header'];
  //     return data;
  //   }).filter((eachColumn:Columns)=>{
  //     return eachColumn['field'] != 'select';
  //   })} optionLabel="header" onChange={onColumnToggle} className="w-full sm:w-20rem" display="chip" />;
  const [tooltipOpen, setTooltipOpen] = useState(false);

  const toggleTooltip = () => setTooltipOpen(!tooltipOpen);
  const [selectedStatus, setselectedStatus] = useState(ACTIVE);
  const [showCustomizeTable, setShowCustomizeTable] = useState(false);
  const customizeTableClickHandler = () => {
    setShowCustomizeTable(true);
  };


  const bulkActionClickHandler = () => {
    if (modifiedValues.length > 0) {
      setSelectAll(true);
      setSelectedDealers(modifiedValues);
    }
  };
  const cancelBulkActionHadler = () => {
    setSelectAll(false);
    setSelectedDealers([]);
  };
  const clearFilter=()=>{
    setLazyState(initialLazyState)
    setselectedStatus(ACTIVE)
  }
  const header =
    <div className="table-header-container">
      <div>
        Dealers List
      </div>
      {/* <BreadcrumbWithFilter
        title="Admin"
        breadcrumbItem="Dealers"
        isShowFilter={false}
        isShowCreate={false}
        selectedCustomers={selectedDealers}
        bulkActionSatusTitle="Dealers"
        showBorderRight={true}
      /> */}
      <div className="table-header-container-item table-header-container-item-special">
        {(selectedDealers.length == 0&& selectedStatus!="All") && <Button type="button" className="customize-table-button mr-1 " onClick={bulkActionClickHandler}>
          <FeatherIcon icon="layers" className="mr-1" />
          Bulk actions
        </Button>}
        <AnimatePresence>
          {selectedDealers.length > 0 &&
            <>
              {/* <motion.div initial="initial" animate="animate" exit="exit" variants={BUTTON_VARIANT}>
            <Button type="button" className="secondary-btn  mr-1" onClick={() => { setArchiveModal(true); }}>
              <FeatherIcon icon="archive" className="mr-1" />
              Archive
            </Button>
          </motion.div> */}
              <motion.div initial="initial" animate="animate" exit="exit" variants={BUTTON_VARIANT}>
                <Button type="button" className="secondary-btn  mr-1 delete-space btn_space" onClick={() => { setDeleteModal(true); }}>
                {selectedStatus == DELETED ? <><i className="fa fa-undo option-icons special"></i> <span className="remove_lables">Restore</span></> : <><FeatherIcon    icon="trash-2" className="mr-1" /> <span className="remove_lables">Delete</span></> }
                </Button>
              </motion.div>
              <motion.div initial="initial" animate="animate" exit="exit" variants={BUTTON_VARIANT}>
                <Button type="button" className="customize-table-button btn_space mr-1" onClick={cancelBulkActionHadler} > <FeatherIcon icon="x" className="" /> <span className="remove_lables">Cancel selection</span></Button>
              </motion.div>
              <motion.div initial="initial" animate="animate" exit="exit" variants={BUTTON_VARIANT}>
                {selectedDealers.length > 0 && <BulkActionStatus count={selectedDealers.length} title="Dealers"></BulkActionStatus>}
              </motion.div>
            </>
          }
        </AnimatePresence>

      </div>
      <div className="flex-grow-1"></div>
      <div className="table-header-container-item">

        <div style={{ display: "flex" }}>
          <ExportFile exportExcel={csvClickHandler} exportPdf={pdfClickHandler} clearFilter={clearFilter} isFilter={true}/>
          <TooltipMEL title="Select Status" position="bottom" tooltipVisible={isTooltipVisible} setTooltipVisible={setIsTooltipVisible}>
            <Dropdown showClear={false} value={selectedStatus} onChange={(e) => handleSubmit(e.value)} options={options} optionLabel="label"
              placeholder="Select status .."
              filter className="w-full md:w-14rem" />
          </TooltipMEL>
          <Button type="button" className="customize-table-button" id="ScheduleUpdateTooltip"
            onMouseEnter={() => setTooltipOpen(true)}
            onMouseLeave={() => setTooltipOpen(false)} data-btn="btn" onClick={customizeTableClickHandler}>
            <FeatherIcon icon="sliders" className="" />
          </Button>
        </div>
        <div className="reponsive_position">
          {/* <TooltipMEL title="Add Dealer" position="bottom"> */}
          <Button type="button" className="btn btn-primary add_btn" onClick={onCreateClickHandler}>
            <FeatherIcon icon="plus-circle" className={"feathet-icon"} />
            Add Dealer
          </Button>
        </div>

        {/* </TooltipMEL> */}
        <UncontrolledTooltip
          placement="bottom"
          target="ScheduleUpdateTooltip"
          isOpen={tooltipOpen}
          toggle={toggleTooltip}
          innerClassName="tooltip-color"
        >
          Choose fields you want to see in the table
        </UncontrolledTooltip>
      </div>

    </div>;


  const customizeTableCancelHandler = () => {
    setShowCustomizeTable(false);
  };

  const customizeColumnSubmitHandler = (customizeColumnObject: any[]) => {
    let orderedSelectedColumns = columns.filter((col) => customizeColumnObject.some((sCol: any) => sCol === col.field));

    setVisibleColumns([{ ...defaultColumns }, ...orderedSelectedColumns]);
    setShowCustomizeTable(false);
  };

  const customizeTableColumns: customizeTableColumns[] = columns.map((eachColumns: Columns) => {
    let data: any = {};
    data['field'] = eachColumns.field;
    data['value'] = visibleColumns.some((sCol: any) => sCol.field === eachColumns.field);
    data['isDisable'] = eachColumns.isStatic;
    data['header'] = eachColumns.header;
    return data;
  }).filter((eachColumns: customizeTableColumns) => eachColumns.field != 'select');

  useEffect(() => {
    const preloader: any = document.getElementById("preloader");
    if (loading||pdfLoading) {
      preloader.style.display = "block";
    } else {
      preloader.style.display = "none";
    }
  }, [loading,pdfLoading]);

  return (
    <>
      <div className="page-content">
        {visible && dialogContainer(error)}
        <DeleteModal
          show={deleteModal}
          undo={selectedStatus == DELETED ? true : false}
          onDeleteClick={() => {
            if (rowData) {
              return handleDeleteUsers(rowData);
            }
            if (selectedDealers?.length > 10) {
              showToast("Cannot delete more than 10 items at once", { type: "error" });
              setSelectedDealers([]);
              setDeleteModal(false);
              setSelectAll(false);
              return setRowData(null);
            }

            if (selectedDealers.length > 0) {
              for (let i = 0; i < selectedDealers.length; i++) {
                handleDeleteUsers(selectedDealers[i], selectedDealers.length, i);
              }
            }
          }}
          onCloseClick={() => {
            setDeleteModal(false);
            setRowData(null);
          }}
        />
        <ArchiveModal
          show={archiveModal}
          message="you want to archieve these customers?"
          deleteLabel="Yes"
          closeLabel="No"
          onDeleteClick={() => {
            archiveHandler();
          }}
          onCloseClick={() => {
            setArchiveModal(false);
            setSelectAll(false);
            setSelectedDealers([]);
          }}
        />
        {showCustomizeTable && <CustomizeTable onCancelHandler={customizeTableCancelHandler} onCustomizeColumnSubmitHandler={customizeColumnSubmitHandler} columns={customizeTableColumns} />}


        {filter && (
          <div className="card">
            <div className="card-body">
              <Formik
                initialValues={initialValues}
                validationSchema={validationSchema}
                onSubmit={handleSubmit}
                onReset={handleReset}
                enableReinitialize
              >
                {({ values, handleChange }) => {
                  return (
                    <Form onChange={handleChange}>
                      <Row id="export-search-clear">
                        <Col xs={12} lg={7} >

                        </Col>
                        <Col xs={12} lg={5} className="select-clear-search-container">
                          <Select
                            label=""
                            name="status"
                            onBlur={handleStatusBlur}
                            value={values.status}
                            onChange={handleChange}
                            options={options}
                            invalid={false}
                          />
                          <Button className="btn btn-primary ms-2" color="primary" type="reset">
                            Clear </Button>
                          <Button className="btn btn-primary ms-2" color="primary" type="submit">
                            Search </Button>
                        </Col>


                      </Row>
                    </Form>
                  );
                }}
              </Formik>
            </div>
          </div>
        )}
        <DataTableWrapper
          ref={dt}
          columnDetails={selectedStatus=="All"? [...visibleColumns]?.filter((items:any)=>items.field!="select") : visibleColumns}
          header={header}
          value={modifiedValues}
          sortIcon={(setOrder) => <SortIcon order={setOrder} />}
          filterIcon={FilterIcon}
          lazy
          paginator={true}
          stripedRows={true}
          rowHover={true}
          // filterDisplay="row"
          rowsPerPageOptions={[5, 10, 25, 50]}
          totalRecords={totalRecords}
          // loading={loading}
          emptyMessage="No details found."
          paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
          currentPageReportTemplate="Displaying Dealers  {last} records of {totalRecords} in total"
          selection={selectedDealers}
          onSelectionChange={onSelectionChange}
          selectAll={selectAll}
          onSelectAllChange={onSelectAllChange}
          first={lazyState.first}
          onSort={onSort}
          sortField={lazyState.sortField}
          sortOrder={lazyState.sortOrder}
          onFilter={onFilter}
          filters={lazyState.filters}
          onPage={onPage}
          rows={lazyState.rows}
        ></DataTableWrapper>
      </div>
    </>
  );
}
