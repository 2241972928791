import { pendingJobsheetTatReportsActions } from "./actionTypes"


export const getPendingJobsheetTatReportsLoading=(lazyState :any)=>{
  return {
    type : pendingJobsheetTatReportsActions.GET_PENDING_JOBSHEETS_TAT_REPORTS_LOADING,
    lazyState
  }
}

export const getPendingjobsheetTatReportsSuccess=(resp : any)=>{
  return {
    type : pendingJobsheetTatReportsActions.GET_PENDING_JOBSHEETS_TAT_REPORTS_SUCCESS,
    payload : resp
  }
}

export const getPendingJobsheetTatReportsError=(error : any)=>{
  return {
    type : pendingJobsheetTatReportsActions.GET_PENDING_JOBSHEETS_TAT_REPORTS_ERROR,
    payload : error
  }
}