export enum BulkSmsTypes {

    GET_BULK_SMS_LIST_LOADING = "@@bulkSms/GET_BULK_SMS_LIST_LOADING",
    GET_BULK_SMS_LIST_SUCCESS = "@@bulkSms/GET_BULK_SMS_LIST_SUCCESS",
    GET_BULK_SMS_LIST_ERROR = "@@bulkSms/GET_BULK_SMS_LIST_ERROR",

    GET_EMPLOYEE_NUM_LOADING = "@@bulkSms/GET_EMPLOYEE_NUM_LOADING",
    GET_EMPLOYEE_NUM_SUCCESS = "@@bulkSms/GET_EMPLOYEE_NUM_SUCCESS",
    GET_EMPLOYEE_NUM_ERROR = "@@bulkSms/GET_EMPLOYEE_NUM_ERROR",

    GET_DEALER_NUM_LOADING = "@@bulkSms/GET_DEALER_NUM_LOADING",
    GET_DEALER_NUM_SUCCESS = "@@bulkSms/GET_DEALER_NUM_SUCCESS",
    GET_DEALER_NUM_ERROR = "@@bulkSms/GET_DEALER_NUM_ERROR",

    GET_CUSTOMER_NUM_LOADING = "@@bulkSms/GET_CUSTOMER_NUM_LOADING",
    GET_CUSTOMER_NUM_SUCCESS = "@@bulkSms/GET_CUSTOMER_NUM_SUCCESS",
    GET_CUSTOMER_NUM_ERROR = "@@bulkSms/GET_CUSTOMER_NUM_ERROR",

    GET_FRANCHISE_NUM_LOADING = "@@bulkSms/GET_FRANCHISE_NUM_LOADING",
    GET_FRANCHISE_NUM_SUCCESS = "@@bulkSms/GET_FRANCHISE_NUM_SUCCESS",
    GET_FRANCHISE_NUM_ERROR = "@@bulkSms/GET_FRANCHISE_NUM_ERROR",

    GET_DEALER_SUBUSER_NUM_LOADING = "@@bulkSms/GET_DEALER_SUBUSER_NUM_LOADING",
    GET_DEALER_SUBUSER_NUM_SUCCESS = "@@bulkSms/GET_DEALER_SUBUSER_NUM_SUCCESS",
    GET_DEALER_SUBUSER_NUM_ERROR = "@@bulkSms/GET_DEALER_SUBUSER_NUM_ERROR",

    GET_FRANCHISE_SUBUSER_NUM_LOADING = "@@bulkSms/GET_FRANCHISE_SUBUSER_NUM_LOADING",
    GET_FRANCHISE_SUBUSER_NUM_SUCCESS = "@@bulkSms/GET_FRANCHISE_SUBUSER_NUM_SUCCESS",
    GET_FRANCHISE_SUBUSER_NUM_ERROR = "@@bulkSms/GET_FRANCHISE_SUBUSER_NUM_ERROR",

    GET_SUPPLIER_NUM_LOADING = "@@bulkSms/GET_SUPPLIER_NUM_LOADING",
    GET_SUPPLIER_NUM_SUCCESS = "@@bulkSms/GET_SUPPLIER_NUM_SUCCESS",
    GET_SUPPLIER_NUM_ERROR = "@@bulkSms/GET_SUPPLIER_NUM_ERROR",

    GET_MANUFACTURER_NUM_LOADING = "@@bulkSms/GET_MANUFACTURER_NUM_LOADING",
    GET_MANUFACTURER_NUM_SUCCESS = "@@bulkSms/GET_MANUFACTURER_NUM_SUCCESS",
    GET_MANUFACTURER_NUM_ERROR = "@@bulkSms/GET_MANUFACTURER_NUM_ERROR",

    CREATE_BULK_SMS_LOADING = "@@bulkSms/CREATE_BULK_SMS_LOADING",
    CREATE_BULK_SMS_SUCCESS = "@@bulkSms/CREATE_BULK_SMS_SUCCESS",
    CREATE_BULK_SMS_ERROR = "@@bulkSms/CREATE_BULK_SMS_ERROR",

    DELETE_BULK_SMS_LOADING = "@@bulkSms/DELETE_BULK_SMS_LOADING",
    DELETE_BULK_SMS_SUCCESS = "@@bulkSms/DELETE_BULK_SMS_SUCCESS",
    DELETE_BULK_SMS_ERROR = "@@bulkSms/DELETE_BULK_SMS_ERROR",

    GET_BY_ID_BULK_SMS_LOADING = "@@bulkSms/GET_BY_ID_BULK_SMS_LOADING",
    GET_BY_ID_BULK_SMS_SUCCESS = "@@bulkSms/GET_BY_ID_BULK_SMS_SUCCESS",
    GET_BY_ID_BULK_SMS_ERROR = "@@bulkSms/GET_BY_ID_BULK_SMS_ERROR",

    GET_BULK_SMS_STATUS_LOADING = "@@bulkSms/GET_BULK_SMS_STATUS_LOADING",
    GET_BULK_SMS_STATUS_SUCCESS = "@@bulkSms/GET_BULK_SMS_STATUSL_SUCCESS",
    GET_BULK_SMS_STATUS_ERROR = "@@bulkSms/GET_BULK_SMS_STATUS_ERROR",

    GET_GET_NUMBER_BY_PREFIX_LOADING = "@@bulkSms/GET_GET_NUMBER_BY_PREFIX_LOADING",
    GET_GET_NUMBER_BY_PREFIX_SUCCESS = "@@bulkSms/GET_GET_NUMBER_BY_PREFIX_SUCCESS",
    GET_GET_NUMBER_BY_PREFIX_ERROR = "@@bulkSms/GET_GET_NUMBER_BY_PREFIX_ERROR",


    CLEAR_BULK_SMS_MESSAGE = "@@bulkSms/CLEAR_BULK_SMS_MESSAGE",
}