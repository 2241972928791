import { stockRegisterInitialState } from "src/models/pages/stockRegisterModel";
import { PartTransactionTypes } from "./actionTypes";
import { partTransactionInitialState } from "src/models/pages/partTransactionModal";

const initialState: partTransactionInitialState = {
  error: "",
  loading: false,
  message: "",
  partTransactionList: {
    totalRecords : 0,
    values : []
  }
};

const partTransactionReducer = (state = initialState, action: any) => {
  switch (action.type) {
    // Grid

    case PartTransactionTypes.GET_DATE_WISE_PART_TRANSACTION_LIST:
      return {
        ...state,
        loading: true,
      };

    case PartTransactionTypes.GET_DATE_WISE_PART_TRANSACTION_LIST_SUCCESS:
      return {
        ...state,
        loading: false,
        partTransactionList: {
          ...state.partTransactionList,
          values: action.payload.data,
          totalRecords: action.payload.data.totalCount,
        }
      };

    case PartTransactionTypes.GET_DATE_WISE_PART_TRANSACTION_LIST_FAIL:
      return {
        ...state,
        loading: false,
        error:action.payload
      };
      

    default:
      return { ...state };
  }
};

export default partTransactionReducer;