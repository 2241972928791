import { API } from "src/util/api";
import { call, put, takeLatest } from "redux-saga/effects"
import { pendingJobsheetTatReportsActions } from "./actionTypes";
import { getPendingJobsheetTatReportsError, getPendingjobsheetTatReportsSuccess } from "./action";


//worker function

//get method

function* getJobsheetReportAsync(action: any) {
  try {
    const response: Promise<any> = yield call(API.getPendingJobSheetTatReportsApi, { ...action.lazyState });
    yield put(getPendingjobsheetTatReportsSuccess(response));
  } catch (error) {
    yield put(getPendingJobsheetTatReportsError(error));
  } 
}


//watcher function

function* pendingJobsheetTatReportsSaga() {
  yield takeLatest(pendingJobsheetTatReportsActions.GET_PENDING_JOBSHEETS_TAT_REPORTS_LOADING, getJobsheetReportAsync)
}


export default pendingJobsheetTatReportsSaga ;