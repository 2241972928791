import { call, put, takeLatest } from "redux-saga/effects";
import { VoucherTypes } from "./actionTypes";
import { API } from "src/util/api";
import { createVoucherError, createVoucherSuccess, deleteVoucherError, deleteVoucherSuccess, getCustomerDropdownListError, getCustomerDropdownListSuccess, getDealerDropdownListError, getDealerDropdownListSuccess, getEmployeeDropdownListError, getEmployeeDropdownListSuccess, getFranchiseDropdownListError, getFranchiseDropdownListSuccess, getPaymentTypeError, getPaymentTypeSuccess, getSupplierDropdownListError, getSupplierDropdownListSuccess, getVoucherDetailsByIdError, getVoucherDetailsByIdSuccess, getVoucherListError, getVoucherListSuccess, getVoucherTypeError, getVoucherTypeSuccess, printVoucherByIdError, printVoucherByIdSuccess, updateVoucherError, updateVoucherSuccess } from "./action";


function* getVoucherListAsync(action : any) {
    try {
      const response : Promise<any> = yield call(API.getVoucherListAPI,{ ...action.lazyState,})
      yield put(getVoucherListSuccess(response));
    } catch (error) {
      yield put(getVoucherListError(error));
    }
  }

function* getVoucherTypeAsync() {
    try {
      const response : Promise<any> = yield call(API.getVoucherTypeAPI)
      yield put(getVoucherTypeSuccess(response));
    } catch (error) {
      yield put(getVoucherTypeError(error));
    }
  }

  function* getEmployeeDropdownListAsync() {
    
    try {
        const response: Promise<any> = yield call( API.getEmployeeDrpFromIdentity )        
        yield put(getEmployeeDropdownListSuccess(response));
    } catch (error) {
        yield put(getEmployeeDropdownListError(error));
    }
}

function* getFranchiseDropdownListAsync() {
    
    try {
        const response: Promise<any> = yield call( API.getFranchiseDrpFromIdentity )        
        yield put(getFranchiseDropdownListSuccess(response));
    } catch (error) {
        yield put(getFranchiseDropdownListError(error));
    }
}


function* getDealerDropdownListAsync() {
    try {
        const response: Promise<any> = yield call(API.getDealerDropdownApi)
        yield put(getDealerDropdownListSuccess(response));
    } catch (error) {
        yield put(getDealerDropdownListError(error));
    }
}


function* getCustomerDropdownListAsync() {
    try {
      // const response: Promise<any> = yield call(API.getCustomerDropForJobAPI);
      const response: Promise<any> = yield call(API.getCustomerDrpFromIdentity);
      yield put(getCustomerDropdownListSuccess(response));
    } catch (error) {
      yield put(getCustomerDropdownListError(error));
    }
  }

  
function* getSupplierDropdownAsync() {
    try {
      const response : Promise<any> = yield call(API.getSupplierDropdown);    
      yield put(getSupplierDropdownListSuccess(response));
  
    } catch (error) {
      yield put(getSupplierDropdownListError(error));
    }
  }

  
function* createVoucherAsync({ payload }: any) {
  try {
    const response: Promise<any> = yield call(API.postVoucherAPI, payload);
    yield put(createVoucherSuccess(response));
    
  } catch (error) {
    yield put(createVoucherError(error));
  }
}

function* getVoucherDetailsByIdAsync({ id }: any): Generator<any, void, any> {
  try {
    let { data } = yield call(API.getVoucherByIdAPI, id);
    yield put(getVoucherDetailsByIdSuccess(data));
    
  } catch (error) {
    yield put(getVoucherDetailsByIdError(error));
  }
}

function* updateVoucherAsync( action : any) {
try {
  const response: Promise<any> = yield call(API.updateVoucherAPI, action.payload.voucherData , action.payload.id);
  yield put(updateVoucherSuccess(response));
  
} catch (error) {
  yield put(updateVoucherError(error));
}
}

function* getPaymentTypeAsync() {
try {
  const response: Promise<any> = yield call(API.getPaymentTypeAPI);
  yield put(getPaymentTypeSuccess(response));
  
} catch (error) {
  yield put(getPaymentTypeError(error));
}
}

function* deleteVoucherDetailsByIdAsync( action : any): Generator<any, void, any> {
  try {
    let { data } = yield call(API.deleteVoucherAPI, action.payload);
    yield put(deleteVoucherSuccess({data,undo:action.payload.undo}));
    
  } catch (error) {
    yield put(deleteVoucherError(error));
  }
}

function* printVoucherByIdAsync( action : any): Generator<any, void, any> {
  debugger
  try {
    let { data } = yield call(API.printVoucherByIdAsync, action.payload);
    yield put(printVoucherByIdSuccess(data));
    
  } catch (error) {
    yield put(printVoucherByIdError(error));
  }
}

function* VoucherSaga() {
    yield takeLatest(VoucherTypes.GET_VOUCHER_LIST, getVoucherListAsync);
    yield takeLatest(VoucherTypes.GET_VOUCHER_TYPE, getVoucherTypeAsync);
    yield takeLatest(VoucherTypes.GET_EMPLOYEE_DROPDOWN_LIST, getEmployeeDropdownListAsync);
    yield takeLatest(VoucherTypes.GET_FRANCHISE_DROPDOWN_LIST, getFranchiseDropdownListAsync);
    yield takeLatest(VoucherTypes.GET_DEALER_DROPDOWN_LIST, getDealerDropdownListAsync);
    yield takeLatest(VoucherTypes.GET_CUSTOMER_DROPDOWN_LIST, getCustomerDropdownListAsync);
    yield takeLatest(VoucherTypes.GET_SUPPLIER_DROPDOWN_LIST, getSupplierDropdownAsync);
    yield takeLatest(VoucherTypes.CREATE_VOUCHER_LOADING, createVoucherAsync);
    yield takeLatest(VoucherTypes.GET_VOUCHER_DETAILS, getVoucherDetailsByIdAsync);
    yield takeLatest(VoucherTypes.UPDATE_VOUCHER_LOADING, updateVoucherAsync);
    yield takeLatest(VoucherTypes.DELETE_VOUCHER_DETAILS, deleteVoucherDetailsByIdAsync);
    yield takeLatest(VoucherTypes.GET_PAYMENT_TYPE, getPaymentTypeAsync);
    yield takeLatest(VoucherTypes.PRINT_VOUCHER_DETAILS_BY_ID_LOADING, printVoucherByIdAsync);
  }
  
  export default VoucherSaga