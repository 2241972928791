import React, { useEffect, useRef, useState } from "react";
import { Row, Col, Button, UncontrolledTooltip, Input } from "reactstrap";
import { Form, Formik } from "formik";
import * as Yup from "yup";
import { Option } from "src/models/components/inputWrapperModel";
import {
  Columns,
  LazyTableState,
} from "src/models/components/dataTableWrapperModel";
import DataTableWrapper from "src/components/Grid/DataTableWrapper";
import { DataTable, DataTableFilterMeta } from "primereact/datatable";
import { useDispatch, useSelector } from "react-redux";
import ColumnAction from "src/components/Common/ColumnAction/ColumnAction";
import { Link, useNavigate } from "react-router-dom";
// import DeleteModal from "../../Calendar/DeleteModal";
// import ArchiveModal from "../../Calendar/DeleteModal";
import { useToast } from "src/components/Common/ReactToaster";
import Select from "src/components/Common/Select";
import StatusHghlighter from "src/components/Common/StatusHighlighter/StatusHighlighter";
import { RootReducerState } from "src/store/reducers";
import { formatDateMEL, saveAsExcelFile } from "src/helpers/common_helpers";
import { parts } from "src/models/pages/partsModel";
import { deletePartsFromList, getPartsList } from "src/store/parts/action";
import { ROUTER } from "src/constants/routes";
import { MultiSelectChangeEvent } from "primereact/multiselect";
import { Dropdown, DropdownChangeEvent } from "primereact/dropdown";
import FeatherIcon from "feather-icons-react";
import CustomizeTable from "src/components/Common/CustomizeTable/CustomizeTable";
import TooltipMEL from "src/components/UI/Tooltip";
import SortIcon from "src/components/Common/CustomFilterandSortIcon/SortIcon";
import { ACTIVE, DELETED } from "src/constants/commonConstants";
import ExportFile from "src/components/Common/ExcelPdf Export/ExportFile";
import FilterIcon from "src/components/Common/CustomFilterandSortIcon/FilterIcon";
import ValidationPopup from "src/components/Common/ValidationMessage/ValidationPopup";
import DeleteModal from "../Calendar/DeleteModal";
import ArchiveModal from "../Calendar/DeleteModal";
import { clearOutgoingDispatch, getDeliveriedBYID, getOutgoingDispatchListLoading, resetOutgoingDispatchMessage } from "src/store/outGoingDispatch/action";
import { ColumnFilterElementTemplateOptions } from "primereact/column";
import { API } from "src/util/api";
import { customizeTableColumns } from "src/models/components/CustomizeTableColumns";

const partsListConverson = (values: any[]) => {
  const modifeidValue = values.map((eachValue) => {
    let data: any = {};
    data['id'] = eachValue.id;
    data['number'] = eachValue?.number;
    data['jobId'] = eachValue?.jobId ? eachValue?.jobId : "";
    data['courierName'] = eachValue?.courierName ? eachValue?.courierName : eachValue?.companyName ? eachValue?.companyName : "";
    data['packedBy'] = eachValue?.packedBy;
    data['packedDate'] = eachValue?.packedDate;
    data['deliveryDate'] = eachValue?.deliveryDate;
    data['deliveryType'] = eachValue?.deliveryType;
    data['deliveredById'] = eachValue?.deliveredById;
    data['deliveredTo'] = eachValue?.deliveredTo;
    data['deliveredByName'] = eachValue?.deliveredByName;
    data['approximateValue'] = eachValue?.approximateValue;
    data['isDeleted'] = eachValue?.isDeleted;
    data['courierId'] = eachValue?.courierId;
    data['employeeId'] = eachValue?.employeeId;
    data['intentId'] = eachValue?.intentId;
    data['stockTransferId'] = eachValue?.stockTransferId;
    return data;
  });
  return modifeidValue;
};

export default function OutgoingDispatchList() {
  const navigate = useNavigate();
  const [filter, setFilter] = useState(false);
  const { items, totalRecords, } = useSelector((state: any) => state.outGoingDispatchReducer?.outgoingDispatchList);
  const modifiedValues: parts[] = partsListConverson(items);
  const { showToast } = useToast();
  const loading = useSelector((state: RootReducerState) => state.outGoingDispatchReducer?.loading);
  const [pdfLoading, setPdfLoading] = useState<boolean>(false);

  const { error, message, paginatorCount, DeliveriedById } = useSelector((state: RootReducerState) => state?.outGoingDispatchReducer);
  const dispatch = useDispatch();
  const dt = useRef<DataTable<any>>(null);
  // for delete invoice
  const [deleteModal, setDeleteModal] = useState(false);
  const [archiveModal, setArchiveModal] = useState(false);
  const [rowData, setRowData] = useState<parts | null>(null);
  const filterDetails: DataTableFilterMeta = {
    number: { value: "", matchMode: "contains" },
    jobId: { value: "", matchMode: "contains" },
    deliveryType: { value: "", matchMode: "contains" },
    ReorderLevel: { value: "", matchMode: "contains" },
    PackedStartDate: { value: "", matchMode: "contains" },
    PackedEndDate: { value: "", matchMode: "contains" },
    packedDate: { value: "", matchMode: "contains" },
    DeliveryStartDate: { value: "", matchMode: "contains" },
    DeliveryEndDate: { value: "", matchMode: "contains" },
    deliveryDate: { value: "", matchMode: "contains" },

  };
  let initialLazyState: LazyTableState = {
    first: 0,
    rows: 10,
    page: 1,
    // sortField: "PartNumber",
    // sortOrder: 1,
    sortField: "CreationTime",
    sortOrder: -1,
    filters: filterDetails,
  };
  const [lazyState, setLazyState] = useState(initialLazyState);

  const initialValues = {
    status: "",
  };

  const onFilterClickHandler = () => {
    setFilter((preState) => !preState);
  };



  const viewHandler = (rowData: parts) => {
    navigate(`${ROUTER.VIEW_OUTGOING_DISPATCH}/${rowData["id"]}`);
  };

  useEffect(() => {
    const preloader: any = document.getElementById("preloader");
    if (loading || pdfLoading) {
      preloader.style.display = "block";
    } else {
      preloader.style.display = "none";
    }
  }, [loading, pdfLoading]);



  const actionBodyTemplate = (rowData: parts, field: string) => {
    return (
      <>
        <ColumnAction
          displayData={rowData[field] as string}
          isView={true}
          onViewClick={() => viewHandler(rowData)}
        />
      </>
    );
  };
  const moveJobPage = (rowData: parts,) => {
    let isJob = rowData.jobId ? true : false;
    let isIntend = rowData.intentId ? true : false;
    let isStockTransfer = rowData.stockTransferId ? true : false;
    return (
      <>
        {(isJob && rowData["jobId"]) ?
          <Link to={`${ROUTER.JOB_SHEETS_VIEW}/${rowData["jobId"]}`}>{rowData["jobId"] as string ? "View job sheet" as string : "-"}</Link>
          :(isIntend && rowData["intentId"]) ?
          <Link to={`${ROUTER.INTENT_VIEW}/${rowData["intentId"]}`}>{rowData["intentId"] as string ? "View Indent" as string : "-"}</Link>
          :(isStockTransfer && rowData["stockTransferId"]) ?
          <Link to={`${ROUTER.STOCK_TRANSFER_VIEW}/${rowData["stockTransferId"]}`}>{rowData["stockTransferId"] as string ? "View Stock Transfer" as string : "-"}</Link>
          : <></>
        }
      </>
    );
  };

  const moveCourierPage = (rowData: parts, field: string) => {
    return (
      <>
        {
          rowData[field] ? <Link to={`${ROUTER.VIEW_COURIER}/${rowData["courierId"]}`}>{rowData[field] as string ? rowData[field] as string : "-"}</Link> : ""
        }

      </>
    );
  };

  useEffect(() => {
    if (DeliveriedById) {
      if (DeliveriedById.userType == "Employee") {
        navigate(`${ROUTER.USERS_VIEW}/${DeliveriedById.melEmployeeId}`);
      }
      if (DeliveriedById.userType == "Franchise") {
        navigate(`${ROUTER.FRANCHISEE_VIEW}/${DeliveriedById.franchiseId}`);
      }
      if (DeliveriedById.userType == "Dealer") {
        navigate(`${ROUTER.DEALERS_VIEW}/${DeliveriedById.dealerId}`);
      }
      if (DeliveriedById.userType == "Customer") {
        navigate(`${ROUTER.USERS_VIEW}/${DeliveriedById.customerId}`);
      }
    }
  }, [DeliveriedById]);

  const moveEmployeePage = (rowData: parts, field: string) => {
    return (
      <>
        {
          rowData[field] ? <Link to={``} onClick={() => { dispatch(getDeliveriedBYID(rowData.deliveredById)); }}>{rowData[field] as string ? rowData[field] as string : "-"}</Link> : ""
        }

      </>
    );
  };



  const [visible, setVisible] = useState(false);
  const [isTooltipVisible, setIsTooltipVisible] = useState<boolean>(false);
  useEffect(() => {
    if (error) {
      setVisible(true);
      // showToast(error, { type: "error" })
    }
    if (message) {
      showToast(message, { type: "success" });
      dispatch(resetOutgoingDispatchMessage());
    }
  }, [error, message]);

  const hidePopup = () => {
    setVisible(false);
    dispatch(resetOutgoingDispatchMessage());
  };

  const dialogContainer = (error: any) => {
    return (
      <>
        <ValidationPopup error={error} visible={visible} onHide={hidePopup} />
        {/* <Dialog header="Validation Error" visible={visible} maximizable className="vw30" onHide={() => { setVisible(false); dispatch(clearPartsMessage()); }}>
          <div className="m-0 validation-text">
            {error && error.split(".,").map((eachLine: string, index: number) => {
              return (<div key={index}><span className="validation-error-index">{index + 1}</span><span className="validation-error-line">{eachLine}</span></div>);
            })}
          </div>
        </Dialog> */}
      </>
    );
  };


  const percentageBodyTemplate = (rowData: parts, field: string) => {
    return (
      <div>{rowData[field] + ' %'}</div>
    );
  };

  const statusBodyTemplate = (rowData: parts, field: string) => {
    let status = rowData[field] as string;
    return (
      <StatusHghlighter status={status} />
    );

  };

  const options: Option[] = [
    { label: "All", value: "All" },
    { label: "Hand Carry", value: "Hand Carry" },
    { label: "Courier", value: "Courier" },
  ];

  const dateFilterTemplate = (options: ColumnFilterElementTemplateOptions) => {
    return (<>
      <label htmlFor="">Start Date</label>
      <Input
        type="date"
        required
        value={options?.value?.startDate || ""}
        max={options?.value?.endDate ? options?.value?.endDate : ""}
        onChange={(e: any) => {
          options;
          let modifiedDate: any = options.value || { startDate: "", endDate: "" };
          modifiedDate['startDate'] = e.target.value;
          options.filterCallback(modifiedDate, options.index);
        }} />
      <br />
      <label htmlFor="">End Date</label>
      <Input type="date"
        required
        value={options?.value?.endDate || ""}
        min={options?.value?.startDate ? options?.value?.startDate : ""}
        onChange={(e: any) => {
          options;
          let modifiedDate: any = options.value || { startDate: "", endDate: "" };
          modifiedDate['endDate'] = e.target.value;
          options.filterCallback(modifiedDate, options.index);
        }} />
    </>);
  };
  const deliveryType = [
    { label: "Hand Carry", value: "Hand Carry" },
    { label: "Courier", value: "Courier" },
  ];
  const deliveryTypeFilterTemplate = (options: ColumnFilterElementTemplateOptions) => {
    return (<>
      <Dropdown
        options={(deliveryType?.length > 0) ? (deliveryType?.map((eachSupplier: any) => {
          let data: any = {};
          data['label'] = eachSupplier?.label;
          data['value'] = eachSupplier?.value;
          return data;
        })) : []}
        name="deliverypye"
        optionLabel="label"
        filter
        onChange={(e: DropdownChangeEvent) => {
          options.filterCallback(e.value, options.index);
          return {};
        }}
        required={true}
        value={options.value}
      />
    </>);
  };

  const columns: Columns[] = [
    {
      field: "number",
      sortable: true,
      header: "Number",
      filter: true,
      showFilterMenu: true,
      headerStyle: { whiteSpace: "nowrap", width: "250px" },
      body: (rowData) => actionBodyTemplate(rowData, "number"),
      isDefault: true,
      frozen: true,
      isStatic: true,
    },
    {
      field: "jobId",
      sortable: false,
      header: "References",
      filter: false,
      showFilterMenu: true,
      headerStyle: { whiteSpace: "nowrap" },
      body: (rowData) => moveJobPage(rowData),
      isDefault: true,
      isStatic: false,
      className: "table-cell-style"
    },

    {
      field: "courierName",
      sortable: true,
      header: "Courier",
      filter: false,
      showFilterMenu: false,
      headerStyle: { whiteSpace: "nowrap", },
      body: (rowData) => moveCourierPage(rowData, "courierName"),
      isDefault: true,
      isStatic: false,
      className: "table-cell-style"
    },
    {
      field: "packedBy",
      sortable: true,
      header: "Packed By",
      filter: false,
      showFilterMenu: false,
      headerStyle: { whiteSpace: "nowrap" },
      isDefault: true,
      isStatic: false,
      className: "table-cell-style"
    },
    {
      field: "packedDate",
      sortable: true,
      header: "Packed Date",
      filter: true,
      showFilterMenu: true,
      headerStyle: { whiteSpace: "nowrap" },
      body: (rowData) => formatDateMEL(rowData.packedDate),
      isDefault: true,
      isStatic: false,
      className: "table-cell-style",
      filterElement: dateFilterTemplate
    },
    {
      field: "deliveryDate",
      sortable: true,
      header: "Delivery Date",
      filter: true,
      showFilterMenu: true,
      headerStyle: { whiteSpace: "nowrap" },
      body: (rowData) => formatDateMEL(rowData.deliveryDate),
      isDefault: true,
      isStatic: false,
      className: "table-cell-style",
      filterElement: dateFilterTemplate
    },
    {
      field: "deliveryType",
      sortable: true,
      header: "Delivery Type",
      filter: true,
      showFilterMenu: true,
      headerStyle: { whiteSpace: "nowrap" },
      isDefault: true,
      isStatic: false,
      className: "table-cell-style",
      filterElement: deliveryTypeFilterTemplate

    },
    {
      field: "deliveredTo",
      sortable: true,
      header: "Delivered To",
      filter: false,
      showFilterMenu: false,
      headerStyle: { whiteSpace: "nowrap" },
      isDefault: true,
      isStatic: false,
      className: "table-cell-style"
    },
    {
      field: "deliveredByName",
      sortable: false,
      header: "Delivered By",
      filter: false,
      showFilterMenu: false,
      headerStyle: { whiteSpace: "nowrap" },
      body: (rowData) => moveEmployeePage(rowData, "deliveredByName"),
      isDefault: true,
      isStatic: false,
      className: "table-cell-style",

    },
    {
      field: "approximateValue",
      sortable: true,
      header: "Approximate Value",
      filter: false,
      showFilterMenu: false,
      headerStyle: { whiteSpace: "nowrap" },
      isDefault: true,
      isStatic: false,
      className: "align-right pr-4"
    },
  ];

  const [visibleColumns, setVisibleColumns] = useState(columns.filter((eachColumn: Columns) => eachColumn.isDefault));


  const changeColumnDetails = (values: any[]) => {
    let modifiedColumnDetails = values.map((eachValue) => {
      let data: any = {};
      data['number'] = eachValue?.number;
      // data['jobId'] = eachValue?.jobId;
      data['courierName'] = eachValue?.courierName;
      data['packedBy'] = eachValue?.packedBy;
      data['packedDate'] = formatDateMEL(eachValue?.packedDate);
      data['deliveryDate'] = formatDateMEL(eachValue?.deliveryDate);
      data['deliveryType'] = eachValue?.deliveryType;
      data['deliveredTo'] = eachValue?.deliveredTo;
      data['deliveredByName'] = eachValue?.deliveredByName;
      data['approximateValue'] = eachValue?.approximateValue;
      return { ...data };
    });
    return modifiedColumnDetails;
  };

  const saveDataAsExcel = (values: any[], fileName: string) => {
    const modifiedColumnDetails = changeColumnDetails(values);
    import('xlsx').then((xlsx) => {
      const worksheet = xlsx.utils.json_to_sheet(modifiedColumnDetails);
      const workbook = { Sheets: { data: worksheet }, SheetNames: ['data'] };
      const excelBuffer = xlsx.write(workbook, {
        bookType: 'xlsx',
        type: 'array'
      });
      saveAsExcelFile(excelBuffer, fileName);
    });
  };

  const saveDataAsPdf = (values: any[], columns: any[], fileName: string) => {
    let column = columns?.filter((item: any) => item.field != "jobId");
    const destructureedData = values.map((data) => {
      let datas = {
        number: data?.number,
        jobId: data?.jobId,
        courierName: data?.courierName,
        packedBy: data?.packedBy,
        packedDate: formatDateMEL(data?.packedDate),
        deliveryDate: formatDateMEL(data?.deliveryDate),
        deliveryType: data?.deliveryType,
        deliveredTo: data?.deliveredTo,
        deliveredByName: data?.deliveredByName,
        approximateValue: data?.approximateValue
      };
      return datas;
    });
    import("jspdf").then((jsPDF) => {
      import("jspdf-autotable").then(() => {
        const exportColumns = column.map((col) => ({
          title: col.header,
          dataKey: col.field,
        }));
        // const doc = new (jsPDF as any).default(0, 0);
        const doc = new (jsPDF as any).default({
          orientation: 'landscape',
          format: 'a3',
          useCss: true,
          theme: "striped",
          showHead: "everyPage",
          columns: exportColumns,
          body: items,
          showFoot: "everyPage",
          tableWidth: "auto",
        });
        doc.autoTable(exportColumns, destructureedData);
        doc.save(`${fileName}.pdf`);
      });
    });
  };
  const fetchData = async (isPdf: boolean) => {
    let lazy: any = {
      ...lazyState,
      rows: totalRecords > 5000 ? 5000 : totalRecords < 1 ? 1 : totalRecords,
      first: 0,
      filters: {
        ...lazyState.filters,
      },
    };

    try {
      setPdfLoading(true);
      const response = await API.fetchOutgoingDispatchApi(lazy);
      if (!response) {
        throw new Error('Network response was not ok');
      }
      const modifeidValue = response?.data?.items?.map((eachValue: any) => {
        let data: any = {};
        data['id'] = eachValue.id;
        data['number'] = eachValue?.number;
        data['jobId'] = eachValue?.jobId ? eachValue?.jobId : "";
        data['courierName'] = eachValue?.courierName ? eachValue?.courierName : eachValue?.companyName ? eachValue?.companyName : "";
        data['packedBy'] = eachValue?.packedBy;
        data['packedDate'] = eachValue?.packedDate;
        data['deliveryDate'] = eachValue?.deliveryDate;
        data['deliveryType'] = eachValue?.deliveryType;
        data['deliveredById'] = eachValue?.deliveredById;
        data['deliveredTo'] = eachValue?.deliveredTo;
        data['deliveredByName'] = eachValue?.deliveredByName;
        data['approximateValue'] = eachValue?.approximateValue;
        data['isDeleted'] = eachValue?.isDeleted;
        data['courierId'] = eachValue?.courierId;
        data['employeeId'] = eachValue?.employeeId;
        data['intentId'] = eachValue?.intentId;
        return data;
      });
      // let resp=await partsListConverson(response?.data?.items)
      if (isPdf) {

        saveDataAsPdf(modifeidValue, columns, "OutGoingDispatch");
      } else {
        saveDataAsExcel(modifeidValue, "OutGoingDispatch");
      }
      setPdfLoading(false);
    } catch (error: any) {
      showToast(error.message, { type: "error" });
      setPdfLoading(false);

    } finally {
      setPdfLoading(false);
    }
  };

  const csvClickHandler = () => {
    // saveDataAsExcel(modifiedValues, "OutGoingDispatch");
    fetchData(false);
  };

  const pdfClickHandler = () => {
    // saveDataAsPdf(modifiedValues, columns, "OutGoingDispatch");
    fetchData(true);
  };

  const validationSchema = Yup.object({});

  const callbackFunction = (lazyState: LazyTableState) => {
    let modifiedLazyState: any = { ...lazyState };
    if (modifiedLazyState.filters && modifiedLazyState.filters.deliveryDate) {
      modifiedLazyState.filters.deliveryDate.value = "";
    }
    if (modifiedLazyState.filters && modifiedLazyState.filters.packedDate) {
      modifiedLazyState.filters.packedDate.value = "";
    }
    dispatch(getOutgoingDispatchListLoading(lazyState));
  };

  const [selectAll, setSelectAll] = useState(false);
  const [selectedParts, setSelectedParts] = useState<any>([]);

  const onSelectionChange = (event: any) => {
    const value = event.value;
    setSelectedParts(value);
    // setSelectAll(value.length === totalRecords);
    setSelectAll(value.length === paginatorCount);
  };

  const onSelectAllChange = (event: any) => {
    const selectAll = event.checked;

    if (selectAll) {
      setSelectAll(true);
      setSelectedParts(modifiedValues);
    } else {
      setSelectAll(false);
      setSelectedParts([]);
    }
  };

  const handleStatusBlur = () => { };

  const onPage = (event: any) => {
    setLazyState(event);
  };

  const onSort = (event: any) => {
    setSelectAll(false);
    setSelectedParts([]);
    setLazyState(event);
  };


  const onFilter = (event: any) => {
    event["first"] = 0;
    event['filters']['DeliveryStartDate']['value'] = event?.filters?.deliveryDate?.value?.startDate ? event?.filters?.deliveryDate?.value?.startDate : '';
    event['filters']['DeliveryEndDate']['value'] = event?.filters?.deliveryDate?.value?.endDate ? event?.filters?.deliveryDate?.value?.endDate : '';
    event['filters']['PackedStartDate']['value'] = event?.filters?.packedDate?.value?.startDate ? event?.filters?.packedDate?.value?.startDate : '';
    event['filters']['PackedEndDate']['value'] = event?.filters?.packedDate?.value?.endDate ? event?.filters?.packedDate?.value?.endDate : '';

    setLazyState(event);
  };

  const handleSubmit = (formValue: any) => {
    setselectedStatus(formValue);
    if (formValue == "All") {
      setLazyState((state) => ({
        ...state,
        filters: {
          ...state.filters,
          IsDeleted: { value: "", matchMode: "contains" },
          deliveryType: { value: "", matchMode: "contains" },
        },
      }));
    }
    else if (formValue == "Hand Carry") {
      setLazyState((state) => ({
        ...state,
        filters: {
          ...state.filters,
          IsDeleted: { value: "", matchMode: "contains" },
          deliveryType: { value: formValue, matchMode: "contains" },
        },
      }));
    }
    else if (formValue == "Courier") {
      setLazyState((state) => ({
        ...state,
        filters: {
          ...state.filters,
          IsDeleted: { value: "", matchMode: "contains" },
          deliveryType: { value: formValue, matchMode: "contains" },
        },
      }));
    }
    else {
      setLazyState((state) => ({
        ...state,
        filters: {
          ...state.filters,
          IsDeleted: { value: null, matchMode: "contains" },
          deliveryType: { value: "", matchMode: "contains" },
        },
      }));
    }
    setIsTooltipVisible(false);
  };

  const handleReset = (formValue: any) => {
    setLazyState((state) => ({
      ...state,
      filters: {
        ...state.filters,
        status: {
          ...state.filters.status,
          value: "",
        }
      },
    }));
  };

  useEffect(() => {
    callbackFunction(lazyState);

    return () => {
      dispatch(clearOutgoingDispatch());
    };
  }, [lazyState]);



  const handleDeleteUsers = (rowData: any, length: number = 0, index: number = 0) => {
    let payload = {
      lazyState,
      id: rowData["id"] ? rowData["id"] : rowData[0]?.id,
      undo: rowData['isDeleted'] ? true : (rowData[0]?.isDeleted ? true : false),
    };
    dispatch(deletePartsFromList(payload));
    setDeleteModal(false);
    setRowData(null);

    if (index + 1 === length || index === length) {
      setSelectAll(false);
      setSelectedParts([]);
      // showToast("Part Deleted Successfully", { type: "success" });
      setTimeout(() => {
        dispatch(getPartsList(lazyState));
      }, 500);
    }
  };

  const archiveHandler = () => {
    setSelectAll(false);
    setSelectedParts([]);
    setArchiveModal(false);
  };

  const defaultColumns = {
    ...columns[0]
  };

  const onColumnToggle = (event: MultiSelectChangeEvent) => {
    let selectedColumns = event.value;
    let orderedSelectedColumns = columns.filter((col) => selectedColumns.some((sCol: any) => sCol.field === col.field));

    setVisibleColumns([{ ...defaultColumns }, ...orderedSelectedColumns]);
  };

  const [showCustomizeTable, setShowCustomizeTable] = useState(false);
  const customizeTableClickHandler = () => {
    setShowCustomizeTable(true);
  };


  const [tooltipOpen, setTooltipOpen] = useState(false);

  const toggleTooltip = () => setTooltipOpen(!tooltipOpen);
  const [selectedStatus, setselectedStatus] = useState("All");

  const bulkActionClickHandler = () => {
    if (modifiedValues.length > 0) {
      setSelectAll(true);
      setSelectedParts(modifiedValues);
    }
  };

  const cancelBulkActionHadler = () => {
    setSelectAll(false);
    setSelectedParts([]);
  };
  const customizeTableColumns: customizeTableColumns[] = columns.map((eachColumns: Columns) => {
    let data: any = {};
    data['field'] = eachColumns.field;
    data['value'] = visibleColumns.some((sCol: any) => sCol.field === eachColumns.field);
    data['isDisable'] = eachColumns.isStatic;
    data['header'] = eachColumns.header;
    return data;
  }).filter((eachColumns: customizeTableColumns) => eachColumns.field != 'select');
  const customizeTableCancelHandler = () => {
    setShowCustomizeTable(false);
  };
  const customizeColumnSubmitHandler = (customizeColumnObject: any[]) => {
    // let orderedSelectedColumns = columns.filter((col) => customizeColumnObject.some((sCol: any) => sCol === col.field));
    let orderedSelectedColumns = columns.filter((col) => customizeColumnObject?.includes(col.field) && col?.field);
    setVisibleColumns([...orderedSelectedColumns]);
    setShowCustomizeTable(false);
  };
  const clearFilter = () => {
    setLazyState(initialLazyState);
    setselectedStatus(ACTIVE);
  };

  const header =
    <div className="table-header-container">
      <div>
        Outgoing Dispatches
      </div>
      <div className="flex-grow-1"></div>
      <div className="table-header-container-item">
        <div style={{ display: "flex" }}>
          <ExportFile exportExcel={csvClickHandler} exportPdf={pdfClickHandler} clearFilter={clearFilter} isFilter={true} />
          <TooltipMEL title="Select Status" position="bottom" tooltipVisible={isTooltipVisible} setTooltipVisible={setIsTooltipVisible}>
            <Dropdown value={selectedStatus} onChange={(e) => handleSubmit(e.value)} options={options} optionLabel="label" placeholder="Select status .."
              filter className="w-full md:w-14rem" />
          </TooltipMEL>

          <Button type="button" className="customize-table-button mt-1" id="ScheduleUpdateTooltip"
            onMouseEnter={() => setTooltipOpen(true)}
            onMouseLeave={() => setTooltipOpen(false)} data-btn="btn" onClick={customizeTableClickHandler}>
            <FeatherIcon icon="sliders" className="" />
          </Button>
        </div>
        {/* <div className="reponsive_position">
          <TooltipMEL title="Add Parts" position="bottom">
          <Button type="button" className="btn btn-primary" onClick={onCreateClickHandler}>
            <FeatherIcon icon="plus-circle" className={"feathet-icon"} />
            Add Parts
          </Button>
        </div> 
         </TooltipMEL> */}
        <UncontrolledTooltip
          placement="bottom"
          target="ScheduleUpdateTooltip"
          isOpen={tooltipOpen}
          toggle={toggleTooltip}
          innerClassName="tooltip-color"
        >
          Choose fields you want to see in the table
        </UncontrolledTooltip>
      </div>

    </div>;

  return (
    <>
      <div className="page-content">
        {visible && dialogContainer(error)}
        <DeleteModal
          show={deleteModal}
          undo={selectedStatus == DELETED ? true : false}
          onDeleteClick={() => {
            if (rowData) {
              return handleDeleteUsers(rowData);
            }
            if (selectedParts?.length > 10) {
              showToast("Cannot delete more than 10 items at once", { type: "error" });
              setSelectedParts([]);
              setDeleteModal(false);
              setSelectAll(false);
              return setRowData(null);
            }
            if (rowData || selectedParts.length === 1) {
              handleDeleteUsers(rowData || selectedParts);
            } else if (selectedParts.length > 0) {
              for (let i = 0; i < selectedParts.length; i++) {
                handleDeleteUsers(selectedParts[i], selectedParts.length, i);
              }
            }
            // if (rowData) {
            //   handleDeleteUsers(rowData);
            // }
          }}
          onCloseClick={() => {
            setDeleteModal(false);
            setRowData(null);
          }}
        />
        <ArchiveModal
          show={archiveModal}
          message="you want to archieve these parts?"
          deleteLabel="Yes"
          closeLabel="No"
          onDeleteClick={() => {
            archiveHandler();
          }}
          onCloseClick={() => {
            setArchiveModal(false);
            setSelectAll(false);
            setSelectedParts([]);
          }}
        />
        {showCustomizeTable && <CustomizeTable onCancelHandler={customizeTableCancelHandler} onCustomizeColumnSubmitHandler={customizeColumnSubmitHandler} columns={customizeTableColumns} />}

        {filter && (
          <div className="card">
            <div className="card-body">
              <Formik
                initialValues={initialValues}
                validationSchema={validationSchema}
                onSubmit={handleSubmit}
                onReset={handleReset}
                enableReinitialize
              >
                {({ values, handleChange }) => {
                  return (
                    <Form onChange={handleChange}>
                      <Row>
                        <Col xs={12} lg={7} id="export-search-clear">

                        </Col>
                        <Col xs={12} lg={5} id="export-search-clear d-flex">
                          <Select
                            label=""
                            name="status"
                            onBlur={handleStatusBlur}
                            value={values.status}
                            onChange={handleChange}
                            options={options}
                            invalid={false}
                          />
                          <Button className="btn btn-primary ms-2" color="primary" type="reset">
                            Clear </Button>
                          <Button className="btn btn-primary ms-2" color="primary" type="submit">
                            Search </Button>
                        </Col>
                      </Row>
                    </Form>
                  );
                }}
              </Formik>
            </div>
          </div>
        )}
        <DataTableWrapper
          ref={dt}
          columnDetails={visibleColumns}
          header={header}
          value={modifiedValues}
          lazy
          paginator={true}
          stripedRows={true}
          filterIcon={FilterIcon}
          rowHover={true}
          // filterDisplay="row"
          sortIcon={(setOrder) => <SortIcon order={setOrder} />}
          rowsPerPageOptions={[5, 10, 25, 50]}
          totalRecords={totalRecords}
          loading={loading}
          emptyMessage="No details found."
          paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
          currentPageReportTemplate="Displaying Details  {last} records of {totalRecords} in total"
          selection={selectedParts}
          onSelectionChange={onSelectionChange}
          selectAll={selectAll}
          onSelectAllChange={onSelectAllChange}
          first={lazyState.first}
          onSort={onSort}
          sortField={lazyState.sortField}
          sortOrder={lazyState.sortOrder}
          onFilter={onFilter}
          filters={lazyState.filters}
          onPage={onPage}
          rows={lazyState.rows}
        ></DataTableWrapper>
      </div>
    </>
  );
}
