import { all, fork } from "redux-saga/effects"

//Layout
import LayoutSaga from "./layout/saga";

//Calendar
import calendarSaga from "./calendar/saga";

//Chat
import chatSaga from "./chat/saga";

//Invoices
import invoiceSaga from "./invoices/saga";

//Contact
import contactsSaga from "./contacts/saga";

//Login
import authSaga from "./auth/login/saga";

//Register
import registerSaga from "./auth/register/saga";

//User Profile
import ProfileSaga from "./auth/profile/saga";

// Forget Password
import forgetPasswordSaga from "./auth/forgetpwd/saga"

//import dashboard
import dashBoardSaga from "./dashboard/saga";

//import Meltable
import MeltableSaga from "./melTable/saga"

// profile
import profileSaga from "./profile/saga";

import supplierSaga from './supplier/saga';

import outGoingDispatchSaga from "./outGoingDispatch/saga";


//user
import melUserSaga from "./users/saga"
import customerSaga from "./customers/saga";
import DealerSaga from "./dealer/saga";
import administratorSaga from "./administrator/saga";
import audioBrandsSaga from "./audioBrands/saga";
import audioModelsSaga from "./audioModels/saga";
import partsSaga from "./parts/saga";
import taxesSaga from "./taxes/saga";
import vehicleBrandsSaga from "./vehicleBrands/saga";
import partSkuSaga from "./partSku/saga";
import franchiseeSaga from "./franchisee/saga"
import subuserSaga from "./subuser/saga"
import purchaseOrderSaga from "./purchaseOrders/saga";
import PartsInboundSaga from "./partsInbound/saga";
import StockAdjustmentSaga from "./stockAdjustment/saga";
import StockRegisterSaga from "./stockRegister/saga";
import dealerSubUserSaga from "./dealersubuser/saga";
import stockValueBySupplier from "./stockValueBySupplier/saga";
import stockQuantityBySupplier from "./StockQuantityBySupplier/saga";
import stockValueByMusicBrand from "./stockValueByMusicSystemBrand/saga";
import stockQuantityByMusicBrand from "./stockQuantityByMusicSystemBrand/saga"
import stockValuebyPartSaga from "./stockValuebyPart/saga";
import stockQtybyPartSaga from "./stockQtybyPart/saga";
import InvoiceItemWiseRegistersListSaga from "./invoiceItemWiseRegisters/saga";
import enquirySaga from "./enquiries/saga";
import stockLocationSage from "./stockLocation/saga";
import incomingDispatchSaga from "./incomingDispatch/saga";
import attendanceSaga from "./attendance/saga";
/* JOBSHEET */
import jobsheetSaga from "./jobsheets/saga";
import IntentSaga from "./intent/saga";
import stockInspectionSaga from "./stockInspection/saga";

import courierSaga from "./couriers/saga" ;
import jobSheetReportSaga from "./jobSheetReports/saga";
import PendingPartsConsolidateSaga from "./pendingpartsconsolidated/saga";
import jobsheetTatReportsSaga from "./jobsheetTatReports/saga";
import monthlyPartSummarySaga from "./monthlyPartSummary/saga";
import pendingPartsSaga from "./pendingParts/saga"
import receivedPaymentSaga from "./receivedPayment/saga";
import loginSaga from "./authLogin/saga";
import feedbackSaga from "./feedback/saga";
import franchiseAdditionalDetailSaga from "./franchiseAdditionalDetails/saga";
import employeeProfileSaga from "./EmployeeProfile/saga";
import attendanceReportSaga from "./AttendanceReport/saga";
import ticketsystemSaga from "./ticketSystem/saga"
import BulkEmailSaga from "./BulkEmail/saga";
import BulkSmsSaga from "./BulkSms/saga";
import incentiveSaga from "./incentive/saga";
import rewardValueSaga from "./RewardValue/saga";
import totalWorkingDaysSaga from "./TotalWorkingDays/saga";
import incentiveRewardSaga from "./IncentiveReward/saga";
import additionalExpenseReportsSaga from "./additionalExpenseReports/saga";
import permissionSaga from "./permission/saga";
import whatsappSaga from "./WhatsApp/saga";
import ManufacturerSaga from "./manufacturer/saga";
import pendingJobsheetTatReportsSaga from "./PendingJobsheetTatReports/saga";
import VoucherSaga from "./voucher/saga";
import StockTransferSaga from "./stockTransfer/saga";
import defectivePartSaga from "./defectivePart/saga";
import PartTransactionSaga from "./partTransaction/saga";


export default function* rootSaga() {
  yield all([
    //public
    fork(LayoutSaga),
    fork(calendarSaga),
    fork(chatSaga),
    fork(invoiceSaga),
    fork(contactsSaga),
    fork(authSaga),
    fork(registerSaga),
    fork(forgetPasswordSaga),
    fork(dashBoardSaga),
    fork(MeltableSaga),
    fork(profileSaga),
    fork(supplierSaga),
    fork(melUserSaga),
    fork(DealerSaga),
    fork(customerSaga),
    fork(administratorSaga),
    fork(audioBrandsSaga),
    fork(audioModelsSaga),
    fork(partsSaga),
    fork(taxesSaga),
    fork(vehicleBrandsSaga),
    fork(partSkuSaga),
    fork(franchiseeSaga),
    fork(subuserSaga),
    fork(purchaseOrderSaga),
    fork(PartsInboundSaga),
    fork(StockAdjustmentSaga),
    fork(StockRegisterSaga),
    fork(dealerSubUserSaga),
    fork(stockValuebyPartSaga),
    fork(stockValueBySupplier),
    fork(stockQuantityBySupplier),
    fork(stockValueByMusicBrand),
    fork(stockQuantityByMusicBrand),
    fork(stockQtybyPartSaga),
    fork(InvoiceItemWiseRegistersListSaga),
    fork(enquirySaga),
    fork(outGoingDispatchSaga),
    fork(stockLocationSage),
    fork(incomingDispatchSaga),
    fork(attendanceSaga),
    fork(jobsheetSaga),
    fork(IntentSaga),
    fork(stockInspectionSaga),
    fork(courierSaga),
    fork(jobSheetReportSaga),
    fork(PendingPartsConsolidateSaga),
    fork(jobsheetTatReportsSaga),
    fork(monthlyPartSummarySaga),
    fork(pendingPartsSaga),
    fork(receivedPaymentSaga),
    fork(loginSaga),
    fork(feedbackSaga),
    fork(franchiseAdditionalDetailSaga),
    fork(employeeProfileSaga),
    fork(attendanceReportSaga),
    fork(ticketsystemSaga),
    fork(BulkEmailSaga),
    fork(BulkSmsSaga),
    fork(incentiveSaga),
    fork(rewardValueSaga),
    fork(totalWorkingDaysSaga),
    fork(incentiveRewardSaga),
    fork(additionalExpenseReportsSaga),
    fork(permissionSaga),
    fork(whatsappSaga),
    fork(ManufacturerSaga),
    fork(pendingJobsheetTatReportsSaga),
    fork(VoucherSaga),
    fork(StockTransferSaga),
    fork(defectivePartSaga),
    fork(PartTransactionSaga),
  ])
}
