import { DefectivePartInitialStore } from "src/models/pages/defectivePartModal"
import { DefectivePartType } from "./actionTypes"


const initialState: DefectivePartInitialStore = {
  loading: false,
  error: "",
  message: "",
  paginatorCount: 0,
  defectivePartList: {
    totalRecords: 0,
    values: null
  },
}

const defectivePart = (state = initialState, action: any) => {
  switch (action.type) {
    case DefectivePartType.GET_DEFECTIVE_PART_LIST_LOADING:
      return {
        ...state,
        loading: true,
        error: "",
        message: "",
        paginatorCount: 0
      }

    case DefectivePartType.GET_DEFECTIVE_PART_LIST_SUCCESS:
      return {
        loading: false,
        defectivePartList: {
          values: action.payload.data.items,
          totalRecords: action.payload.data.totalCount
        },
        paginatorCount: action.payload.data.items.length
      }

    
    case DefectivePartType.GET_DEFECTIVE_PART_LIST_ERROR:
      return {
        ...state,
        loading: false,
        error: action.payload
      }
    default:
      return { ...state }
  }
}

export default defectivePart